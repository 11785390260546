import React, { useState, useEffect } from "react";
import { AddonModifierLabel } from "./AddonModifierLabel";
import { ReactComponent as IconCheck } from "../../../../_common/icons/IconCheck.svg";
import useWindowSize from "../../../../_common/hooks/useWindowSize";

export const NestedMultipleChoiceAddonModifier = (props) => {
  const {
    nestedOptionGroup,
    nestedOptions,
    topLevelOptionKey,
    maxQuantity,
    minQuantity,
    selectedOptions,
    updateSelectedOption,
    topLevelOption,
    instructionsText,
    mandatoryOptionClass,
    setNoNestedSelectionMade,
    setProgressiveIconClass,
    labelId,
  } = props;

  const [activeCheckboxes, setActiveCheckboxes] = useState(0);

  useEffect(() => {
    if (nestedOptions) {
      let selectedOptions = 0;
      Object.keys(nestedOptions).forEach((nestedKey) => {
        if (nestedOptions[nestedKey].isSelected) {
          selectedOptions++;
        }
      });
      setActiveCheckboxes(selectedOptions);
      isMaxQuantityReached(selectedOptions);
    }
  }, [nestedOptions]);

  const objectToArray = (obj) => {
    var result = Object.keys(obj).map(function (key) {
      return obj[key];
    });
    return result;
  };

  const [showCheckMark, setShowCheckMark] = useState(false);
  useEffect(() => {
    if (nestedOptions) {
      setShowCheckMark(
        objectToArray(nestedOptions).filter((option) => option.isSelected).length > 0 &&
          objectToArray(nestedOptions).filter((option) => option.isSelected).length >=
            nestedOptionGroup.minQuantity
      );
    }
  }, [nestedOptions]);

  const onCheckboxChange = (event) => {
    let tempSelectedOptions = { ...selectedOptions };
    if (selectedOptions === null || Object.keys(selectedOptions).length === 0) {
      tempSelectedOptions = { ...topLevelOption };
    }

    /** Remove the class 'error' to remove the red borders */
    const targetParentContainer = event.target.parentElement.parentElement;
    targetParentContainer.querySelectorAll("label").forEach((label) => {
      label.classList.remove("error");
    });

    const field = event.target;
    const tempNestedOptions = { ...nestedOptions };

    const isSingleChoiceParent = tempSelectedOptions.id;

    if (isSingleChoiceParent) {
      const tempNestedOptionsGroup =
        tempSelectedOptions.modifierGroups[
          "addonModifierGroup-" + tempNestedOptions[field.dataset.keyName].parentId
        ];

      if (field.checked) {
        if (document.getElementById(labelId)) {
          document.getElementById(labelId).classList.remove("error");
        }
        tempNestedOptions[field.dataset.keyName].isSelected = true;
        setActiveCheckboxes(activeCheckboxes + 1);
        isMaxQuantityReached(activeCheckboxes + 1);

        if (!tempNestedOptionsGroup.isSelected) {
          tempNestedOptionsGroup.isSelected = true;
        }
      } else {
        delete tempNestedOptions[field.dataset.keyName].isSelected;
        setActiveCheckboxes(activeCheckboxes - 1);
        isMaxQuantityReached(activeCheckboxes - 1);

        if (activeCheckboxes - 1 === 0) {
          delete tempNestedOptionsGroup.isSelected;
        }
      }

      tempNestedOptionsGroup.items[field.dataset.keyName] =
        tempNestedOptions[field.dataset.keyName];
    } else {
      const tempNestedOptionsGroup =
        tempSelectedOptions[topLevelOptionKey].modifierGroups[
          "addonModifierGroup-" + tempNestedOptions[field.dataset.keyName].parentId
        ];

      if (field.checked) {
        if (document.getElementById(labelId)) {
          document.getElementById(labelId).classList.remove("error");
        }
        tempNestedOptions[field.dataset.keyName].isSelected = true;
        setActiveCheckboxes(activeCheckboxes + 1);
        isMaxQuantityReached(activeCheckboxes + 1);

        if (!tempNestedOptionsGroup.isSelected) {
          tempNestedOptionsGroup.isSelected = true;
        }
      } else {
        delete tempNestedOptions[field.dataset.keyName].isSelected;
        setActiveCheckboxes(activeCheckboxes - 1);
        isMaxQuantityReached(activeCheckboxes - 1);
        if (activeCheckboxes - 1 === 0) {
          delete tempNestedOptionsGroup.isSelected;
        }
      }

      tempNestedOptionsGroup.items[field.dataset.keyName] =
        tempNestedOptions[field.dataset.keyName];
    }

    setNoNestedSelectionMade(Object.keys(tempSelectedOptions).length === 0);

    updateSelectedOption(tempSelectedOptions);
  };

  const [isDisabled, setIsDisabled] = useState(false);
  const isMaxQuantityReached = (numberOfActiveCheckboxes) => {
    if (
      (maxQuantity !== "0" && numberOfActiveCheckboxes >= parseInt(maxQuantity)) ||
      (maxQuantity === "0" && numberOfActiveCheckboxes >= parseInt(minQuantity)) ||
      (maxQuantity !== "0" &&
        minQuantity !== "0" &&
        numberOfActiveCheckboxes >= parseInt(minQuantity))
    ) {
      if (maxQuantity !== "0" && minQuantity !== "0") {
        setIsDisabled(numberOfActiveCheckboxes >= parseInt(maxQuantity));
        setProgressiveIconClass("full-progression");
      } else if (
        maxQuantity !== "0" &&
        minQuantity === "0" &&
        numberOfActiveCheckboxes >= parseInt(maxQuantity)
      ) {
        setIsDisabled(true);
      }
    } else {
      setIsDisabled(false);
      if (maxQuantity !== "0" && minQuantity !== "0") {
        setProgressiveIconClass("halfWay-progression");
      }
    }

    if (parseInt(nestedOptionGroup.minQuantity) === "0" && numberOfActiveCheckboxes >= 1) {
      if (maxQuantity !== "0" && minQuantity !== "0") {
        setProgressiveIconClass("full-progression");
      }
    }
  };

  const deviceWidth = useWindowSize().width;

  return (
    <>
      <header className="nested-options__form-header">
        <span>
          {nestedOptionGroup.name} {showCheckMark && <IconCheck />}
        </span>
        <span className={`item-detail__option-note${mandatoryOptionClass}`}>
          {instructionsText}
        </span>
      </header>

      {deviceWidth > 660 ? (
        <div>
          {Object.keys(nestedOptions).map((key, index) => (
            <div
              key={key}
              className="form__field-wrapper form__field-wrapper--checkbox-radio form__field-wrapper--checkbox nested-modifiers__option-wrapper"
            >
              <input
                onChange={onCheckboxChange}
                type="checkbox"
                value={nestedOptions[key].id}
                name={nestedOptionGroup.parentId + "__" + nestedOptionGroup.name}
                id={
                  nestedOptionGroup.parentId +
                  "__option-" +
                  nestedOptions[key].parentId +
                  "-" +
                  nestedOptions[key].id +
                  "__" +
                  topLevelOption.parentId
                }
                className="form__field--checkbox-radio form__field--checkbox visually-hidden"
                data-key-name={key}
                data-index={index}
                disabled={
                  (isDisabled && !nestedOptions[key].isSelected) ||
                  (!!nestedOptions[key].item_count && Number(nestedOptions[key].item_count) <= 0)
                }
                defaultChecked={nestedOptions[key].isSelected}
              />
              <AddonModifierLabel
                option={nestedOptions[key]}
                isNested={true}
                topOptionGroupId={nestedOptionGroup.parentId}
                inputId={
                  nestedOptionGroup.parentId +
                  "__option-" +
                  nestedOptions[key].parentId +
                  "-" +
                  nestedOptions[key].id +
                  "__" +
                  topLevelOption.parentId
                }
              />
            </div>
          ))}
        </div>
      ) : (
        <>
          {Object.keys(nestedOptions).map((key, index) => (
            <div
              key={key}
              className="form__field-wrapper form__field-wrapper--checkbox-radio form__field-wrapper--checkbox nested-modifiers__option-wrapper"
            >
              <input
                onChange={onCheckboxChange}
                type="checkbox"
                value={nestedOptions[key].id}
                name={nestedOptionGroup.parentId + "__" + nestedOptionGroup.name}
                id={
                  nestedOptionGroup.parentId +
                  "__option-" +
                  nestedOptions[key].parentId +
                  "-" +
                  nestedOptions[key].id +
                  "__" +
                  topLevelOption.parentId
                }
                className="form__field--checkbox-radio form__field--checkbox visually-hidden"
                data-key-name={key}
                data-index={index}
                disabled={
                  (isDisabled && !nestedOptions[key].isSelected) ||
                  (!!nestedOptions[key].item_count && Number(nestedOptions[key].item_count) <= 0)
                }
                defaultChecked={nestedOptions[key].isSelected}
              />
              <AddonModifierLabel
                option={nestedOptions[key]}
                isNested={true}
                topOptionGroupId={nestedOptionGroup.parentId}
                inputId={
                  nestedOptionGroup.parentId +
                  "__option-" +
                  nestedOptions[key].parentId +
                  "-" +
                  nestedOptions[key].id +
                  "__" +
                  topLevelOption.parentId
                }
                id={
                  nestedOptionGroup.parentId +
                  "__option-" +
                  nestedOptions[key].parentId +
                  "-" +
                  nestedOptions[key].id +
                  "__" +
                  topLevelOption.parentId +
                  "-label"
                }
              />
            </div>
          ))}
        </>
      )}
    </>
  );
};
