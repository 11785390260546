import React, { useContext, useEffect, useLayoutEffect, useRef, useState } from "react";
import ReactDOM from "react-dom";
import AppLabelsContext from "../AppLabelsContext";
import { AppFooterSubnavItem } from "./AppFooterSubnavItem";

export const AppFooterSubnav = (props) => {
  const { name, subnavItems, closeSubnav } = props;

  const appLabels = useContext(AppLabelsContext);

  const subnavRef = useRef();

  useEffect(() => {
    function handleClickOutside(event) {
      if (
        subnavRef.current &&
        !subnavRef.current.contains(event.target) &&
        event.target.dataset.name !== subnavRef.current.dataset.name &&
        !event.target.classList.contains("dialog-modal-container")
      ) {
        closeSubnav();
      }
    }

    document.addEventListener("mousedown", handleClickOutside);
    document.addEventListener("touchstart", handleClickOutside);

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
      document.removeEventListener("touchstart", handleClickOutside);
    };
  }, [subnavRef]);

  const [subnavHeight, setSubnavHeight] = useState(0);
  useLayoutEffect(() => {
    if (subnavRef.current) {
      setSubnavHeight(subnavRef.current.getBoundingClientRect().height);
    }
  }, [subnavRef]);

  return (
    <>
      <ul ref={subnavRef} className="footer-subnav__list" data-name={name}>
        {subnavItems &&
          subnavItems.map((item, index) => <AppFooterSubnavItem item={item} key={index} />)}
        {name === "settings" && (
          <li className="footer__subnav-item footer-subnav__app-version">{`${
            appLabels["general"]["app-version"].split("[number]")[0]
          } ${process.env.REACT_APP_VERSION}`}</li>
        )}
      </ul>
      {subnavHeight &&
        ReactDOM.createPortal(
          <>
            <div className="footer__subnav-background" style={{ height: `${subnavHeight}px` }} />
            <div className="footer__subnav-overlay" />
          </>,
          document.body
        )}
    </>
  );
};
