export const loginRegisterLabels = {
  "hello-there": "Hola",
  "welcome-to": "Bienvenido a [merchant-name]",
  "all-in-one-solution": "Solución Todo en Uno",
  register: "Registrarse",
  "sign-in": "Iniciar Sesión",
  "continue-as-guest": "Continuar como visitante",
  next: "Siguiente",
  previous: "Anterior",
  "rewards-card-question": "Cuenta con tarjeta de recompensas?",
  "rewards-card-question-blurb-2": "Ingrese el numero de tarjeta",
  "no-rewards-card-want-one": "No, pero me gustaría una",
  "have-rewards-card": "Si y tengo el numero de tarjeta!",
  "successful-registration-heading": "Registrado con éxito",
  "successful-registration-paragraph":
    "Como miembro de nuestra familia, Ud. tiene acceso a descuentos y promociones exclusivas. Comience a acumular puntos y redímalos por alimentos y bebidas gratis. Asegúrese de que cada Pesos gastado valga la pena",
  "user-already-exists-heading": "Ya existe una cuenta con esta dirección de correo",
  "user-already-exists-paragraph":
    "Intente iniciar sesión con esta dirección de correo y recuerde que siempre puede reestablecer su contraseña",
  "forgot-password": "Olvide mi contraseña",
  "signing-in": "Iniciando Sesión...",
  "forgot-password-heading": "Olvido su contraseña?",
  "forgot-password-paragraph":
    "Confirme su correo electrónico y le enviaremos instrucciones de inmediato",
  "login-email": "E-mail de Inicio de Sesión",
  "reset-password": "Reestablecer contraseña",
  "sending-password": "Enviando contraseña...",
  "temporary-login-paragraph":
    "Le hemos enviado un mail, utilice la contraseña temporal para iniciar sesión",
  "form-step-status": "Paso [current-step] de [total-steps]",
  "dont-have-account": "Ud no tiene una cuenta",
};
