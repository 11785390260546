import React, { useRef, useState, useEffect, useContext } from "react";
import AppLabelsContext from "../../App/AppLabelsContext";
import { ReactComponent as IconArrowDown } from "../icons/IconArrowDown.svg";
import "./FormSelect.css";

export const FormSelect = (props) => {
  const {
    onSelectChange,
    defaultValue,
    fieldData,
    isSmall,
    id,
    label,
    type,
    name,
    isRequired,
    isSkipEmptyOption,
    showAstr,
    customLabelClass,
  } = props;

  const [selectInFocusClass, setSelectInFocusClass] = useState("");

  useEffect(() => {
    // If a non-empty option is pre-selected set the select style to be in-focus
    if (fieldData.value !== "") setSelectInFocusClass(" form__field-wrapper--in-focus");
  }, []);

  const onChange = (event) => {
    onSelectChange(event.target);

    if (!!props.callBack) {
      props.callBack(event.target);
    }

    if (event.target.value !== "") {
      setSelectInFocusClass(" form__field-wrapper--in-focus");
    } else {
      setSelectInFocusClass("");
    }
  };

  // if Select has a default value - Set focused label class and perform its onSelectChange
  const selectRef = useRef();
  useEffect(() => {
    if (defaultValue && !selectInFocusClass && selectRef.current) {
      setSelectInFocusClass(" form__field-wrapper--in-focus");
      onSelectChange(selectRef.current);
    }
  }, [selectRef.current]);

  const appLabels = useContext(AppLabelsContext);

  return (
    !fieldData.isHidden && (
      <div
        style={!!props.isHidden ? { display: "none" } : {}}
        className={`form__field-wrapper${
          isSmall ? " form__field-wrapper--small" : ""
        }${selectInFocusClass}`}
      >
        <label htmlFor={id} className={`form__field-label ${customLabelClass}`}>
          {!!showAstr && <span aria-hidden="true">*</span>}
          {(fieldData.label || label) +
            (!fieldData.isRequired ? ` (${appLabels["form"]["optional-label"]})` : "")}
        </label>
        <select
          onChange={onChange}
          className="form__field"
          value={fieldData.value}
          type={type}
          id={id}
          name={name}
          aria-required={isRequired}
          ref={selectRef}
        >
          {!isSkipEmptyOption && <option value=""></option>}
          {fieldData.options &&
            fieldData.options.length > 0 &&
            fieldData.options.map((option) => (
              <option key={option.value} value={option.value}>
                {option.displayValue}
              </option>
            ))}
        </select>
        <IconArrowDown />
        {!fieldData.isValid && <div className="form__field-error">{fieldData.errorMessage}</div>}
      </div>
    )
  );
};

FormSelect.displayName = "FormSelect";
