import { useContext, useEffect, useState } from "react";
import MerchantConfigContext from "../../App/MerchantConfigContext";

export const useLoyaltySetup = () => {
  const merchantConfigContext = useContext(MerchantConfigContext);
  const merchantConfig = merchantConfigContext.merchant;

  const [loyaltyContext, setLoyaltyContext] = useState("idle");

  useEffect(() => {
    const isLoyaltyEnabled = merchantConfig.I22 === "t";

    if (isLoyaltyEnabled) {
      const initialLoyaltyContext = { isEnabled: true };
      setLoyaltyContext(initialLoyaltyContext);
    } else {
      // loyalty module is disabled
      setLoyaltyContext(false);
    }
  }, []);

  return loyaltyContext;
};
