import React, { useContext } from "react";
import { Link } from "react-router-dom";
import localforage from "localforage";
import MerchantConfigContext from "../MerchantConfigContext";

export const AppDesktopHeaderSubmenu = ({ subnavItems }) => {
  return (
    <div className="desktop-header__subnav">
      <ul className="desktop-header-subnav__content desktop-header-subnav__list">
        {subnavItems.map((item, index) => (
          <li key={index} className="desktop-header-subnav__list-item">
            {item.action && (
              <button
                onClick={item.action}
                className="desktop-header-nav__submenu-button"
                type="button"
              >
                {item.displayName}
              </button>
            )}
            {item.linkPathname && <SubmenuItemLink item={item} />}
          </li>
        ))}
      </ul>
    </div>
  );
};

const SubmenuItemLink = ({ item }) => {
  const { skin } = useContext(MerchantConfigContext);
  const handleClick = () => {
    localforage.removeItem(skin + "__itemSelectionInProgress");
    localforage.removeItem(skin + "__storeIdToBeFaved");
    sessionStorage.removeItem(skin + "__orderStoreState");
  };

  return (
    <>
      {!item.isExternalLink && !item.isLanguageLink && (
        <Link
          to={item.linkPathname}
          className="desktop-header-nav__submenu-link"
          onClick={handleClick}
        >
          {item.displayName}
        </Link>
      )}
      {item.isExternalLink && (
        <a
          href={item.linkPathname}
          className="desktop-header-nav__submenu-link"
          target="_blank"
          rel="noopener noreferrer"
          onClick={handleClick}
        >
          {item.displayName}
        </a>
      )}
      {item.isLanguageLink && (
        <a
          href={item.linkPathname}
          className="desktop-header-nav__submenu-link"
          onClick={handleClick}
        >
          {item.displayName}
        </a>
      )}
    </>
  );
};
