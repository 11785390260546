import React, { useState, useEffect, useContext } from "react";

//UI Components
import { ReactComponent as IconHeart } from "../_common/icons/IconHeart.svg";
import { LoadingSpinner } from "../_common/LoadingSpinner";
import AppLabelsContext from "../App/AppLabelsContext";

export const OrderStoreFavoriteButton = (props) => {
  const {
    customClassName,
    store,
    favoriteLocations,
    removeLocationFromFavorites,
    addLocationToFavorites,
    setIsFavStore,
    favButtonId,
    isCalling1327,
  } = props;

  const [isFavorited, setIsFavorited] = useState(null);
  const [isHeartAnimated, setIsHeartAnimated] = useState(false);
  const appLabels = useContext(AppLabelsContext)

  useEffect(() => {
    if (favoriteLocations && favoriteLocations.length > 0) {
      setIsFavorited(!!favoriteLocations.find((storeId) => storeId === store.storeId));
    } else {
      setIsFavorited(false);
    }
  }, [favoriteLocations]);

  const handleHeartClick = () => {
    if (isFavorited) removeLocationFromFavorites(store);
    else addLocationToFavorites(store);

    setIsHeartAnimated(true);
    setTimeout(() => setIsHeartAnimated(false), 500); // wait for animation to end
  };

  useEffect(() => {
    setIsFavStore(isFavorited);
  }, [isFavorited]);

  return (
    <button
      onClick={handleHeartClick}
      aria-label={isFavorited ? appLabels["order"]["remove-store-location-favorite"] : appLabels["order"]["set-store-location-favorite"]}
      type="button"
      className={customClassName}
      id={favButtonId ? favButtonId : ""}
    >
      {!!isCalling1327 && isCalling1327 === store.storeId ? (
        <LoadingSpinner customClass={"order-stores__fav-button"} />
      ) : (
        <IconHeart
          className={isHeartAnimated ? "heart-icon--flicker" : ""}
          stroke="#FC8181"
          fill={isFavorited ? "#FC8181" : "#fff"}
        />
      )}
    </button>
  );
};
