import React, { useState, useEffect, useContext } from "react";
import { useHistory } from "react-router-dom";
import localforage from "localforage";
import he from "he";

import { formatCartForApi } from "../../../../_common/CartHelpers";
import { formatOrderType, getDeviceTypeId } from "../../../../_common/helpers";
import { callPaymentAPI } from "../../../../_common/Api";
import {
  getDayOfWeekFromIndex,
  getMonthFromIndex,
} from "../../../Locations/helpers/isStoreOpenOrClosed";
import { getTimeAmPm, getTrueBusinessDate } from "../../../../Dashboard/DashboardOrder/dateHelpers";
import {
  updateLocationData,
  ensureOrderTimeMinInterval,
  isOrderTimePassed,
  isOrderTimeBeforeOpeningTime,
  isOrderTimeBlocked,
  isLateOrderAttempted,
  isStoreClosedToday,
} from "../../../../_common/PaymentHelpers";

import MerchantConfigContext from "../../../../App/MerchantConfigContext";
import OrderTypeContext from "../../../OrderTypeContext";
import StoreContext from "../../../StoreContext";
import CartContext from "../../../Cart/CartContext";
import OrderTimeContext from "../../../OrderTimeContext";
import AppLabelsContext from "../../../../App/AppLabelsContext";
import AppLanguageContext from "../../../../App/AppLanguageContext";
import BillContext from "../../../Menu/Bill/BillContext";
import UserRoleContext from "../../../../App/UserRoleContext";

import { DialogModal } from "../../../../_common/DialogModal/DialogModal";
import { LoadingSpinner } from "../../../../_common/LoadingSpinner";
import { ReactComponent as IconApplePay } from "../../../../_common/icons/IconApplePay.svg";
import PaymentMethodApplePayButton from "./PaymentMethodApplePayButton";
import PaymentOverlay from "../../PaymentOverlay";
import { checkBillRequestStatus, generateBillsForItems } from "../../../Menu/Bill/BillHelpers";

const PaymentMethodApplePay = (props) => {
  const { orderTotal, promoCode, rewards, paymentMethodExpanded } = props;

  const history = useHistory();

  const merchantConfig = useContext(MerchantConfigContext);
  const appLabels = useContext(AppLabelsContext);
  const appLanguage = useContext(AppLanguageContext);
  const userRoleContext = useContext(UserRoleContext);

  const skin = merchantConfig.skin;

  const orderTypeContext = useContext(OrderTypeContext);
  const storeContext = useContext(StoreContext);
  const cart = useContext(CartContext);

  const billContext = useContext(BillContext);
  const billItems = billContext.value;
  const billDetails = billContext.details;

  const activeOrderType = orderTypeContext.value;
  const activeOrderStore = storeContext.activeOrderStore;

  const loginToken = userRoleContext.loginToken;
  const isLoggedIn = userRoleContext.status === "logged-in";

  const [apiError, setAPIError] = useState("");

  const [isAPISubmitting, setIsAPISubmitting] = useState(null);

  const [customerInfo, setCustomerInfo] = useState({});
  const [orderTypeInstructions, setOrderTypeInstructions] = useState("None");
  const [deliveryAddress, setDeliveryAddress] = useState(null);

  const [isLateOrderAttempt, setIsLateOrderAttempt] = useState(false);
  const [isPassedOrderTime, setIsPassedOrderTime] = useState(false);
  const [isOrderTimeBeforeOpening, setIsOrderTimeBeforeOpening] = useState(false);
  const [nextAvailableOrderTime, setNextAvailableOrderTime] = useState(null);
  const [orderTimeIsBlocked, setOrderTimeIsBlocked] = useState(false);
  const [isStoreClosed, setIsStoreClosed] = useState(false);

  const [appliedCoupon, setAppliedCoupon] = useState(null);
  const [tipAmount, setTipAmount] = useState(0);

  useEffect(() => {
    if (appliedCoupon == null) {
      localforage.getItem(skin + "__coupon").then((coupon) => {
        if (coupon) setAppliedCoupon(coupon);
      });
    }
  }, [appliedCoupon]);

  useEffect(() => {
    localforage.getItem(skin + "__customerInfo").then((customerInfo) => {
      if (customerInfo) setCustomerInfo(customerInfo);
      //else history.goBack();
    });

    localforage.getItem(skin + "__orderTypeSpecialInstruction").then((orderInstruction) => {
      if (orderInstruction) {
        localforage.getItem(skin + "__stadium-schema").then((schema) => {
          if (schema) {
            let parsedSchema = JSON.parse(schema);
            let orderInstructionCopy = orderInstruction;
            orderInstructionCopy += `, ${
              appLabels["form"]["section"]
            }:${parsedSchema.section.toUpperCase()}/${
              appLabels["form"]["row"]
            }:${parsedSchema.row.toUpperCase()}/${
              appLabels["form"]["seat"]
            }:${parsedSchema.seat.toUpperCase()}`;

            setOrderTypeInstructions(orderInstructionCopy);
          } else {
            setOrderTypeInstructions(orderInstruction);
          }
        });
      } else {
        //there is no order instruction set
        localforage.getItem(skin + "__stadium-schema").then((schema) => {
          if (schema) {
            let parsedSchema = JSON.parse(schema);
            let sectionRowSeatInfo = `${
              appLabels["form"]["section"]
            }:${parsedSchema.section.toUpperCase()}/${
              appLabels["form"]["row"]
            }:${parsedSchema.row.toUpperCase()}/${
              appLabels["form"]["seat"]
            }:${parsedSchema.seat.toUpperCase()}`;

            setOrderTypeInstructions(sectionRowSeatInfo);
          }
        });
      }
    });

    if (activeOrderType === "delivery") {
      localforage.getItem(skin + "__userDeliveryAddress").then((storedDeliveryAddress) => {
        if (storedDeliveryAddress) setDeliveryAddress(storedDeliveryAddress);
        else setDeliveryAddress(false);
      });
    }

    localforage.getItem(skin + "__storedTip").then((storedTip) => {
      if (storedTip) {
        setTipAmount(storedTip.tipAmount);
      }
    });
  }, []);

  // Scroll to the top of the expand/collapse toggle button after details panel is expanded
  useEffect(() => {
    const timer = setTimeout(() => paymentMethodExpanded(), 300);
    return () => clearTimeout(timer);
  }, []);

  const orderTimeContext = useContext(OrderTimeContext);

  const getCurrentTimestamp = () => {
    const date = new Date(
      ensureOrderTimeMinInterval(orderTimeContext.value.value, activeOrderStore, activeOrderType)
    );
    const month = date.getMonth() + 1;
    const day = date.getDate();
    const year = date.getFullYear();
    const hours = date.getHours();
    const minutes = date.getMinutes();
    const seconds = date.getSeconds();
    const timestamp = `${year}-${month}-${day} ${hours}:${minutes}:${seconds}`;
    return timestamp;
  };

  const formatOrderTime = (orderTime) => {
    const date = new Date(orderTime);
    const month = date.getMonth();
    const calDate = date.getDate();
    const day = date.getDay();

    let hours = date.getHours();
    let minutes = date.getMinutes();

    const ampm = hours >= 12 ? "pm" : "am";
    hours = hours % 12;
    hours = hours ? hours : 12; // the hour '0' should be '12'
    minutes = minutes < 10 ? "0" + minutes : minutes;
    const strTime = hours + ":" + minutes + " " + ampm;

    const nameOfDay = getDayOfWeekFromIndex(day);
    const timestamp = `${
      nameOfDay.substr(0, 3).charAt(0).toUpperCase() + nameOfDay.substr(1, 2)
    } ${getMonthFromIndex(month)} ${calDate} at ${strTime}`;

    return (
      <>
        <br /> <br /> <span style={{ fontWeight: 700 }}>{timestamp} </span>
        <br /> <br />{" "}
      </>
    );
  };

  const updateOrderTime = () => {
    orderTimeContext.update({
      value: nextAvailableOrderTime,
      displayValue: getTimeAmPm(nextAvailableOrderTime),
      trueBusinessDate: getTrueBusinessDate(nextAvailableOrderTime, activeOrderStore),
      timeAtOrdering: new Date()
    });
    setIsPassedOrderTime(false);
  };

  const isStadiumSchema = merchantConfig.merchant.I55 === "stadium";

  const processPayment = async (hostTransactionId, encodedPaymentToken) => {
    let order_address = activeOrderStore.address;
    let order_postal = activeOrderStore.postal;
    let order_city = activeOrderStore.city;

    const placeOrderData = await callPaymentAPI(skin, "dc_vxl_pay_bill", {
      params: [
        skin,
        "consumer",
        appLanguage,
        activeOrderStore.storeId, //outlet id
        hostTransactionId, //Host transaction ID
        "", //workstation ID (optional)
        {
          customer_id: customerInfo.id || "",
          order_email: customerInfo.email || "",
          order_fname: customerInfo.firstName || "",
          order_lname: customerInfo.lastName || "",
          order_phone: customerInfo.phone || "",
          order_mobile: customerInfo.mobile || "",
          order_address,
          order_postal,
          order_city,
          order_prov: activeOrderStore.province,
          order_country: activeOrderStore.country,
          discount_for_the_whole_order: promoCode,
          redeem_rewards: rewards,
        }, // CustomerInfo
        [], // gc_number_list
        "f", // save_credit_card
        "", // tm_session_id
        "", // scene_cardnum
        "APPLEPAY", // payment_type
        "", // cc_id
        "", // cc_type
        "", // cc_number
        "", // cc_name
        "", // cc_security
        "", // cc_issue
        "", // cc_expiry_month
        "", // cc_expiry_year
        "", // cc_start_month
        "", // cc_start_year
        "", // cc_address1
        "", // cc_address2
        "", // cc_city
        "", // cc_province
        "", // cc_country
        "", // cc_postal
        customerInfo.email, // cc_email
        customerInfo.phone || "", // cc_phone
        "", // pa_res
        "", // return_url
        "", // cancel_url
        "", // success_url
        "", // error_url
        "", // fail_url
        encodedPaymentToken, // unique_pay_id
        "", // wechat_oauth_id
        "", // url_3ds_success
        "", // url_3ds_failure
        activeOrderType === "pickup" || activeOrderType === "dinein"
          ? orderTypeInstructions
          : "None", //Special Instruction (pickup / dinein)
        tipAmount, //tip
        customerInfo.email || "", //Receipt Email
      ],
      id: "dc_vxl_pay_bill",
    });

    if (placeOrderData.result.I0) {
      /* successfully placed an order */
      localforage.setItem(skin + "__completedOrderId", placeOrderData.result.I0);
      if (!!placeOrderData.result.I10) {
        localforage.setItem(skin + "__pointsIssued", placeOrderData.result.I10);
      }

      setTimeout(() => {
        history.push("/online-ordering/payment-confirmation");
      }, 500);
    } else {
      /* failed to place an order */
      setShowPaymentOverlay(false);
      setAPIError(placeOrderData.error.message);
    }
  };

  const [showPaymentOverlay, setShowPaymentOverlay] = useState(false);
  const [paymentProgressSpeed, setPaymentProgressSpeed] = useState(0);
  const [headerLabel, setHeaderLabel] = useState(null);
  const [secondaryHeaderLabel, setSecondaryHeaderLabel] = useState(null);

  const checkBillStatus = async (hostTransactionId) => {
    const billStatusData = await checkBillRequestStatus(
      appLanguage,
      skin,
      hostTransactionId,
      activeOrderStore.storeId
    );

    if (billStatusData) {
      return billStatusData;
    }
  };

  const payDineinBill = async (encodedPaymentToken) => {
    let orderLineList = [];

    billItems.forEach((billItem) => {
      orderLineList.push(billItem["vxl_order_line_id"]);
    });

    const billAPIData = await generateBillsForItems(
      appLanguage,
      skin,
      billDetails["vxl_order_id"], // vexilor order id
      orderLineList, //order line id list
      "", //online order id
      "", //loyalty card
      activeOrderStore.storeId
    );

    setPaymentProgressSpeed(10000);
    setHeaderLabel(appLabels["order"]["preparing-bill-for-verification"]);
    setSecondaryHeaderLabel(appLabels["order"]["retrieving-bill-details"]);

    setTimeout(() => {
      setPaymentProgressSpeed(10000);
      setHeaderLabel(appLabels["order"]["retrieving-bill-details"]);
      setSecondaryHeaderLabel(appLabels["order"]["processing-your-bill"]);
    }, 10000);

    setTimeout(() => {
      setPaymentProgressSpeed(5000);
      setHeaderLabel(appLabels["order"]["processing-your-bill"]);
      setSecondaryHeaderLabel(appLabels["order"]["verifying-bill-details"]);
    }, 20000);

    setTimeout(() => {
      setPaymentProgressSpeed(5000);
      setHeaderLabel(appLabels["order"]["verifying-bill-details"]);
      setSecondaryHeaderLabel(appLabels["order"]["confirming-bill-details"]);
    }, 25000);

    if (billAPIData && billAPIData.status === 0) {
      const hostTransactionId = billAPIData.result.I2;

      setTimeout(async () => {
        setPaymentProgressSpeed(5000);
        setHeaderLabel(appLabels["order"]["confirming-bill-details"]);
        setSecondaryHeaderLabel(appLabels["order"]["processing-your-payment"]);

        const billStatusData = await checkBillStatus(hostTransactionId);

        if (billStatusData && billStatusData.status === 0) {
          /** if the bill status is complete */
          if (billStatusData.result.I2["request_status"] === "complete") {
            setTimeout(() => {
              setPaymentProgressSpeed(3000);
              setHeaderLabel(appLabels["order"]["processing-your-payment"]);
              setSecondaryHeaderLabel(appLabels["order"]["all-done"]);
            }, 3000);

            setTimeout(() => {
              setPaymentProgressSpeed(3000);
              setHeaderLabel(appLabels["order"]["all-done"]);
            }, 6000);

            processPayment(hostTransactionId, encodedPaymentToken);
          } else if (
            billStatusData.result.I2["request_status"] === "pending" ||
            billStatusData.result.I2["request_status"] === "ready"
          ) {
            /** if bill status is not complete but it is pending or ready, wait three seconds and then check again */
            setPaymentProgressSpeed(5000);
            setHeaderLabel(appLabels["order"]["double-checking-your-bill"]);
            setSecondaryHeaderLabel(appLabels["order"]["processing-your-payment"]);

            setTimeout(() => {
              setPaymentProgressSpeed(5000);
              setHeaderLabel(appLabels["order"]["confirming-bill-details"]);
              setSecondaryHeaderLabel(appLabels["order"]["processing-your-payment"]);

              checkBillStatus(hostTransactionId).then((billStatusDataSecondTry) => {
                if (billStatusDataSecondTry && billStatusDataSecondTry.status === 0) {
                  if (billStatusDataSecondTry.result.I2["request_status"] === "complete") {
                    setTimeout(() => {
                      setPaymentProgressSpeed(3000);
                      setHeaderLabel(appLabels["order"]["processing-your-payment"]);
                      setSecondaryHeaderLabel(appLabels["order"]["all-done"]);
                    }, 3000);

                    setTimeout(() => {
                      setPaymentProgressSpeed(3000);
                      setHeaderLabel(appLabels["order"]["all-done"]);
                    }, 6000);

                    processPayment(hostTransactionId, encodedPaymentToken);
                  } else if (
                    billStatusDataSecondTry.result.I2["request_status"] === "pending" ||
                    billStatusDataSecondTry.result.I2["request_status"] === "ready"
                  ) {
                    setPaymentProgressSpeed(5000);
                    setHeaderLabel(appLabels["order"]["double-checking-your-bill"]);
                    setSecondaryHeaderLabel(appLabels["order"]["processing-your-payment"]);

                    setTimeout(() => {
                      setPaymentProgressSpeed(5000);
                      setHeaderLabel(appLabels["order"]["confirming-bill-details"]);
                      setSecondaryHeaderLabel(appLabels["order"]["processing-your-payment"]);

                      /** Second retry, in case the after the first retry bill was still not ready */
                      checkBillStatus(hostTransactionId).then((billStatusDataThirdTry) => {
                        if (billStatusDataThirdTry && billStatusDataThirdTry.status === 0) {
                          if (billStatusDataSecondTry.result.I2["request_status"] === "complete") {
                            setTimeout(() => {
                              setPaymentProgressSpeed(3000);
                              setHeaderLabel(appLabels["order"]["processing-your-payment"]);
                              setSecondaryHeaderLabel(appLabels["order"]["all-done"]);
                            }, 3000);

                            setTimeout(() => {
                              setPaymentProgressSpeed(3000);
                              setHeaderLabel(appLabels["order"]["all-done"]);
                            }, 6000);

                            processPayment(hostTransactionId, encodedPaymentToken);
                          } else {
                            setPaymentProgressSpeed(0);
                            setShowPaymentOverlay(false);
                            setAPIError(appLabels["order"]["bill-api-error"]);
                            setIsAPISubmitting(null);
                          }
                        } else {
                          setPaymentProgressSpeed(0);
                          setShowPaymentOverlay(false);
                          setAPIError(appLabels["order"]["bill-api-error"]);
                          setIsAPISubmitting(null);
                        }
                      });
                    }, 5000);
                  } else {
                    setPaymentProgressSpeed(0);
                    setShowPaymentOverlay(false);
                    setAPIError(appLabels["order"]["bill-api-error"]);
                    setIsAPISubmitting(null);
                  }
                } else {
                  setPaymentProgressSpeed(0);
                  setShowPaymentOverlay(false);
                  setAPIError(appLabels["order"]["bill-api-error"]);
                  setIsAPISubmitting(null);
                }
              });
            }, 5000);
          } else {
            setPaymentProgressSpeed(0);
            setShowPaymentOverlay(false);
            setAPIError(appLabels["order"]["bill-api-error"]);
            setIsAPISubmitting(null);
          }
        } else {
          setPaymentProgressSpeed(0);
          setShowPaymentOverlay(false);
          setAPIError(appLabels["order"]["bill-api-error"]);
          setIsAPISubmitting(null);
        }
      }, 30000);
    } else {
      setPaymentProgressSpeed(0);
      setShowPaymentOverlay(false);
      setAPIError(appLabels["order"]["bill-api-error"]);
      setIsAPISubmitting(null);
    }
  };

  const submitApplePayOrder = async (paymentToken) => {
    setIsAPISubmitting(true);

    const paymentData = JSON.stringify(paymentToken.paymentData);
    const encodedPaymentToken = Buffer.from(paymentData).toString("base64");

    if (activeOrderType === "dinein" && window.location.href.includes("bill-payment")) {
      /** Bill Payment */
      setShowPaymentOverlay(true);
      payDineinBill(encodedPaymentToken);
      return;
    } else {
      /** Order Submition */

      const formattedOrderType = formatOrderType(activeOrderType);
      const timestamp = getCurrentTimestamp();
      const formattedCartItems = formatCartForApi(cart.value);

      let order_address = activeOrderStore.address;
      let order_postal = activeOrderStore.postal;
      let order_city = activeOrderStore.city;

      if (activeOrderType === "delivery") {
        if (isStadiumSchema) {
          order_postal = deliveryAddress;
        } else {
          const isSecondAddress = deliveryAddress["second-address"];
          order_address =
            deliveryAddress["first-address"] +
            (isSecondAddress ? ", #" + deliveryAddress["second-address"] : "");
          order_postal = deliveryAddress.postal;
          order_city = deliveryAddress.city;
        }
      }

      const placeOrderData = await callPaymentAPI(skin, "dc_cws_vxl_online_order", {
        params: [
          skin,
          "consumer",
          "en",
          activeOrderStore.storeId,
          formattedOrderType,
          timestamp || "", // Order Pickup/Delivery Timestamp (optional),
          {
            customer_id: customerInfo.id || "",
            order_email: customerInfo.email || "",
            order_fname: customerInfo.firstName || "",
            order_lname: customerInfo.lastName || "",
            order_phone: customerInfo.phone || "",
            order_mobile: customerInfo.mobile || "",
            order_address,
            order_postal,
            order_city,
            order_prov: activeOrderStore.province,
            order_country: activeOrderStore.country,
            discount_for_the_whole_order: promoCode,
            redeem_rewards: rewards,
            customer_token: isLoggedIn ? loginToken : ""
          }, // CustomerInfo
          formattedCartItems, // ItemsList
          activeOrderType === "delivery" ? orderTypeInstructions : "None", // Delivery Instructions // Table Number (optional)
          activeOrderType === "dinein" ? storeContext.activeOrderStoreTable : "", // Table Number (optional),
          customerInfo.cpfNumber, // government_id
          "", // gc_number_list
          "", // save_credit_card
          "", // tm_session_id
          "", // scene_cardnum
          "APPLEPAY", // payment_type
          "", // cc_id
          "", // cc_type
          "", // cc_number
          "", // cc_name
          "", // cc_security
          "", // cc_issue
          "", // cc_expiry_month
          "", // cc_expiry_year
          "", // cc_start_month
          "", // cc_start_year
          "", // cc_address1
          "", // cc_address2
          "", // cc_city
          "", // cc_province
          "", // cc_country
          "", // cc_postal
          customerInfo.email, // cc_email
          customerInfo.phone, // cc_phone
          "", // pa_res
          "", // return_url
          "", // cancel_url
          "", // success_url
          "", // error_url
          "", // fail_url
          encodedPaymentToken, // unique_pay_id
          "", // wechat_oauth_id
          "", // url_3ds_success
          "", // url_3ds_failure
          activeOrderType === "pickup" || activeOrderType === "dinein"
            ? orderTypeInstructions
            : "None", // //Special Instruction (pickup)
          tipAmount, //tip
          getDeviceTypeId(formattedOrderType), // device type id
        ],
        id: "dc_cws_vxl_online_order",
      });

      if (placeOrderData) {
        //if no errors:
        if (placeOrderData.status === 0) {
          const completedOrderId = placeOrderData.result.I0;
          const pointsIssued = placeOrderData.result.I10;
          const paymentDetails =
            appLabels["order"]["apple-pay"] + " (" + paymentToken.paymentMethod.displayName + ")";

          localforage.setItem(skin + "__completedOrderId", completedOrderId);
          localforage.setItem(skin + "__usedPaymentMethod", "APPLEPAY");
          localforage.setItem(skin + "__paymentDetails", paymentDetails);

          if (pointsIssued) {
            localforage.setItem(skin + "__pointsIssued", pointsIssued);
          }

          setTimeout(() => {
            history.push("/online-ordering/order-confirmation");
          }, 2000);
        } else {
          // failed to place an order
          setIsAPISubmitting(null);
          if (
            placeOrderData.error &&
            placeOrderData.error.message.includes(
              "Customer ID can only be used when a customer is logged in"
            )
          ) {
            userRoleContext.handleLoginTokenExpiration();
          } else {
            setAPIError(
              `${appLabels["general"]["error-code"]}: ${placeOrderData.error.code}. ${he.decode(
                placeOrderData.result.message
              )}`
            );
          }
        }
      }
    }
  };

  const validateOrderTime = async () => {
    //update the location with the latest data, in case the store hours have been changed
    const updatedLocation = await updateLocationData(
      activeOrderStore,
      activeOrderType,
      skin,
      appLanguage
    );
    if (updatedLocation) {
      let isStoreClosedForTheDay = isStoreClosedToday(updatedLocation, orderTimeContext)
      
      const orderTimeIsPassed = isOrderTimePassed(
        updatedLocation,
        activeOrderType,
        orderTimeContext,
        setNextAvailableOrderTime
      );
      const orderTimeIsBeforeOpening = isOrderTimeBeforeOpeningTime(
        updatedLocation,
        orderTimeContext
      );
      const orderTimeIsBlocked = isOrderTimeBlocked(
        updatedLocation,
        orderTimeContext,
        activeOrderType
      );
      const lateOrderAttempt = isLateOrderAttempted(
        updatedLocation,
        activeOrderType,
        orderTimeContext,
        skin,
        setNextAvailableOrderTime
      );

      if (lateOrderAttempt) {
        isStoreClosedForTheDay = true
      }

      if (isStoreClosedForTheDay) {
        setIsStoreClosed(true)
        return;
      }
      else if (orderTimeIsPassed) {
        setIsPassedOrderTime(true);
        return false;
      } else if (orderTimeIsBeforeOpening) {
        setIsOrderTimeBeforeOpening(true);

        return false;
      } else if (orderTimeIsBlocked) {
        setOrderTimeIsBlocked(true);
        return;
      } else {
        return true;
      }
    } else {
      // if updatedLocation returns null, it means the selected location does not exist anymore
      history.push("/dashboard");
    }
  };

  return (
    <>
      <div className="apple-pay-button__container">
        <p>
          {appLabels["order"]["complete-order-pay-with-apple-pay"].split("[apple-pay-icon]")[0]}
          <IconApplePay />
          {appLabels["order"]["complete-order-pay-with-apple-pay"]
            .split("[apple-pay-icon]")
            .pop("[apple-pay-icon]")}
        </p>
      </div>

      <PaymentMethodApplePayButton
        activeOrderStore={activeOrderStore}
        skin={skin}
        validateOrderTime={validateOrderTime}
        orderTotal={orderTotal}
        appLanguage={appLanguage}
        submitApplePayOrder={submitApplePayOrder}
      />
      {isStoreClosed && (
        <DialogModal
          hideCloseButton={true}
          closeModalAction={() => {
            setIsStoreClosed(false);
          }}
          isCancelConfirm={false}
          hideConfirmButton={false}
          isCustomConfirmText={appLabels["order"]["pick-new-time"]}
          resetRemoveDialog={() => {
            history.push({
              pathname: "/dashboard",
              state: {
                showTimePanel: true,
                timeAtOrdering: orderTimeContext.value.timeAtOrdering,
                originalOrderTimeContext: orderTimeContext
              },
            });
          }}
          message={
            <div>
              <h2>{appLabels["order"]["sorry-it-is-closing-time"]}</h2>
              <p>{appLabels["order"]["store-closed-message"]}</p>
            </div>
          }
        />
      )}
      {isLateOrderAttempt && (
        <DialogModal
          message={appLabels["order"]["order-too-close-to-closing-time-error"]}
          resetRemoveDialog={() => {
            setIsLateOrderAttempt(false);
            orderTimeContext.update({
              value: nextAvailableOrderTime,
              displayValue: getTimeAmPm(nextAvailableOrderTime),
              trueBusinessDate: getTrueBusinessDate(nextAvailableOrderTime, activeOrderStore),
            });
            history.push("/dashboard");
            window.location.reload(); // This will have to change when the BE adds support for validating store hours.
          }}
        />
      )}
      {isPassedOrderTime && (
        <DialogModal
          message={
            <div>
              {appLabels["order"]["select-order-time-passed-message"]}
              {": "}
              {formatOrderTime(nextAvailableOrderTime)}
              {appLabels["order"]["confirm-updated-order-time-message"]}
            </div>
          }
          resetRemoveDialog={() => setIsPassedOrderTime(false)}
          isCancelConfirm={true}
          confirmAction={updateOrderTime}
          isHTMLContent={true}
        />
      )}

      {isOrderTimeBeforeOpening && (
        <DialogModal
          message={appLabels["order"]["order-before-store-opening-time-error"]}
          resetRemoveDialog={() => {
            setIsOrderTimeBeforeOpening(false);
            history.push("/dashboard");
            window.location.reload(); // This will have to change when the BE adds support for validating store hours.
          }}
        />
      )}

      {orderTimeIsBlocked && (
        <DialogModal
          message={appLabels["order"]["order-time-is-blocked"]}
          resetRemoveDialog={() => {
            setOrderTimeIsBlocked(false);
            history.push("/dashboard");
            window.location.reload(); // This will have to change when the BE adds support for validating store hours.
          }}
        />
      )}

      {!!apiError && (
        <DialogModal
          message={apiError}
          resetRemoveDialog={() => {
            setAPIError(null);
            setIsAPISubmitting(null);
          }}
        />
      )}

      {isAPISubmitting && <LoadingSpinner />}

      {showPaymentOverlay && headerLabel && (
        <PaymentOverlay
          headerLabels={headerLabel}
          paymentProgressSpeed={paymentProgressSpeed}
          secondaryHeaderLabel={secondaryHeaderLabel}
        />
      )}
    </>
  );
};

export default PaymentMethodApplePay;
