import React, { useContext, useState, useEffect } from "react";
import localforage from "localforage";

// Custom hooks
import useWindowSize from "../_common/hooks/useWindowSize";

//Context
import AppLabelsContext from "../App/AppLabelsContext";
import ManifestContext from "../App/ManifestContext";
import BrandingContext from "../App/BrandingContext";
import MerchantConfigContext from "../App/MerchantConfigContext";
import UserRoleContext from "../App/UserRoleContext";

//UI Component
import { OrderStoreFavoriteButton } from "./OrderStoreFavoriteButton";
import { ReactComponent as IconX } from "../_common/icons/IconX.svg";
import { ReactComponent as IconMapPin } from "../_common/icons/IconMapPin.svg";
import { ReactComponent as IconClock } from "../_common/icons/IconClock.svg";
import { ReactComponent as IconPhoneCall } from "../_common/icons/IconPhoneCall.svg";
import { LoadingDots } from "../_common/LoadingDots";

//Helper Functions
import { StoreInfoDetailsHours } from "../OnlineOrdering/GoogleMaps/StoreInfoDetails/StoreInfoDetailsHours";
import { setStoreHoursProperties } from "../OnlineOrdering/GoogleMaps/dateHelpers";

export const OrderStoreInfoCard = (props) => {
  const {
    store,
    userLocation,
    favoriteLocations,
    addLocationToFavorites,
    removeLocationFromFavorites,
    isStoreOnImpression,
    updateActiveStore,
    isDineinInfoCard,
    isShowingCartClearPopup,
    checkLocalForageForFavStore,
    isCalling1327,
  } = props;

  const deviceWidth = useWindowSize().width;
  const appLabels = useContext(AppLabelsContext);
  const branding = useContext(BrandingContext);
  const manifestShortName = useContext(ManifestContext).short_name;
  const merchantConfig = useContext(MerchantConfigContext);
  const skin = merchantConfig.skin;
  const userRoleContext = useContext(UserRoleContext);

  const isStadiumSchema = merchantConfig.merchant.I55 === "stadium";

  const isLoggedIn = userRoleContext.status === "logged-in";

  const [showHoursAndInfo, setShowHoursAndInfo] = useState(null);

  const openHoursAndInfo = () => {
    setShowHoursAndInfo(true);
  };

  const getDirections = (store) => {
    let url = "";
    if (userLocation.lat) {
      //if the users location is known
      url = `https://www.google.com/maps/dir/?api=1&origin=${userLocation.lat},${userLocation.lng}&destination=${store.latitude},${store.longitude}`;
    } else {
      //if users location is not known
      url = `https://www.google.com/maps/search/?api=1&query=${store.latitude},${store.longitude}`;
    }
    window.open(url, "_blank");
  };

  useEffect(() => {
    if (isStoreOnImpression) {
      if (document.getElementById(store.storeId)) {
        document
          .getElementById(store.storeId)
          .scrollIntoView({ behavior: "smooth", block: "start", inline: "center" });
      }
    }
  }, [isStoreOnImpression]);

  const [isFavStore, setIsFavStore] = useState(false);
  const [showLoadingDots, setShowLoadingDots] = useState(false);

  useEffect(() => {
    if (isShowingCartClearPopup) {
      setShowLoadingDots(false);
    }
  }, [isShowingCartClearPopup]);

  useEffect(() => {
    if (
      checkLocalForageForFavStore &&
      checkLocalForageForFavStore.current === true &&
      favoriteLocations !== null &&
      isLoggedIn
    ) {
      checkLocalForageForFavStore.current = false;
      localforage.getItem(skin + "__storeIdToBeFaved").then((storeId) => {
        localforage.removeItem(skin + "__storeIdToBeFaved").then(() => {
          if (storeId) {
            if (document.getElementById(`order-store-fav-button__${storeId}`)) {
              setTimeout(() => {
                if (favoriteLocations.length === 0) {
                  document.getElementById(`order-store-fav-button__${storeId}`).click();
                } else {
                  let locationIsAlreadyFaved = false;
                  for (let i = 0; i < favoriteLocations.length; i++) {
                    let favStoreId = favoriteLocations[i];
                    if (favStoreId === storeId) {
                      locationIsAlreadyFaved = true;
                      break;
                    }
                  }
                  //auto fav the location if it is not already a favourite location
                  if (!locationIsAlreadyFaved) {
                    document.getElementById(`order-store-fav-button__${storeId}`).click();
                  }
                }
                checkLocalForageForFavStore.current = true;
              }, 1000);
            }
          }
        });
      });
    }
  }, [favoriteLocations, isLoggedIn]);

  return (
    <>
      <div
        className={`order-stores__store-wrapper  ${
          isDineinInfoCard ? "order-stores__store-wrapper-dinein" : ""
        }`}
        id={store.storeId}
      >
        <div className="order-stores__store-row">
          <span className="order-stores__store-name">{store.name}</span>
          <OrderStoreFavoriteButton
            customClassName={"order-stores__store-favorite-button"}
            store={store}
            favoriteLocations={favoriteLocations}
            addLocationToFavorites={addLocationToFavorites}
            removeLocationFromFavorites={removeLocationFromFavorites}
            setIsFavStore={setIsFavStore}
            favButtonId={`order-store-fav-button__${store.storeId}`}
            isCalling1327={isCalling1327}
          />
        </div>

        {/** Store Address */}
        <div className="order-stores__store-row order-stores__store-address">
          <span>
            {store.address}, {store.city},
            {store.province && store.province.split(":").length === 1 ? store.province + "," : ""}{" "}
            {store.postal}
          </span>
        </div>
        <div className="order-stores__store-row">
          <div className="order-stores__store-column">
            {store.distanceFromUserLocationInKilometers && !isStadiumSchema && (
              <>
                {!isDineinInfoCard && (
                  <>
                    <span className="order-stores__store-distance">
                      {appLabels["order"]["distance-to-store"].split("[distance-from-user]")[0]}
                      {store.country === "US"
                        ? store.distanceFromUserLocationInMiles
                        : store.distanceFromUserLocationInKilometers}
                      {store.country === "US"
                        ? " mi"
                        : appLabels["order"]["distance-to-store"].split("[distance-from-user]")[1]}
                    </span>
                  </>
                )}
              </>
            )}
            <span
              className="order-stores__store-closing"
              style={!setStoreHoursProperties(store).isOpen ? { color: `var(--color-error)` } : {}}
            >
              {setStoreHoursProperties(store).isOpen
                ? " "
                : appLabels["order"]["store-closed-status"] + " "}
              {setStoreHoursProperties(store).isOpen
                ? appLabels["order"]["store-open-until-closing-time"].split("[closing-time]")[0] +
                  setStoreHoursProperties(store).displayClosingTime
                : ""}
            </span>
          </div>

          {!isDineinInfoCard && (
            <div className="order-stores__store-column">
              <button
                className="order-stores__store-select-button button--primary button--primary-with-border button--primary-small button--white"
                type="button"
                onClick={() => {
                  updateActiveStore(store, isFavStore, setShowLoadingDots);
                }}
                disabled={isStadiumSchema && !setStoreHoursProperties(store).isOpen}
              >
                {showLoadingDots ? (
                  <LoadingDots />
                ) : (
                  <>{appLabels["order"]["select-store-select-store"]}</>
                )}
              </button>
            </div>
          )}
        </div>
        {!isDineinInfoCard && !isStadiumSchema && (
          <div className="order-stores__store-row">
            <button className="order-stores__store-hours-button" onClick={openHoursAndInfo}>
              {appLabels["order"]["select-store-hours-info"]}
            </button>
            <button
              onClick={() => getDirections(store)}
              className="order-stores__store-direction-button"
            >
              {appLabels["order"]["select-store-get-direction"]}
            </button>
          </div>
        )}
      </div>

      {showHoursAndInfo && (
        <div className="order-stores__store-info-wrapper">
          <div className="order-stores__store-info-inner-wrapper">
            {deviceWidth < 660 ? (
              <>
                <div className="order-stores__store-info-header-container order-stores__store-column">
                  <div className="order-stores__store-row order-stores__store-info-header-top">
                    <button type="button" onClick={() => setShowHoursAndInfo(false)}
                      aria-label={appLabels["general"]["close-modal"]}>
                      <IconX />
                    </button>
                    <h3>{store.name}</h3>

                    <OrderStoreFavoriteButton
                      customClassName={"order-stores__store-favorite-button"}
                      store={store}
                      favoriteLocations={favoriteLocations}
                      addLocationToFavorites={addLocationToFavorites}
                      removeLocationFromFavorites={removeLocationFromFavorites}
                      setIsFavStore={setIsFavStore}
                      favButtonId={`order-store-fav-button__${store.storeId}`}
                      isCalling1327={isCalling1327}
                    />
                  </div>

                  <div className="order-stores__store-info-header-bottom order-stores__store-row">
                    <div className="mobile-app-header-logo_wrapper page-title ">
                      <img
                        src={branding["main-logo"]}
                        alt={`${manifestShortName} logo`}
                        className="mobile-app-header-logo"
                      />
                    </div>
                  </div>
                </div>

                {/** Store Address */}
                <div className="order-stores__store-row order-stores__store-info-line">
                  <IconMapPin aria-label={appLabels["order"]["type-in-address"]} />
                  <span>
                    {store.address} ,{store.city} ,{store.province}, {store.postal}
                  </span>
                </div>

                {/** store phone number */}
                <div className="order-stores__store-row order-stores__store-info-line order-stores__store-info-line__phone">
                  <IconPhoneCall aria-label={appLabels["form"]["phone"]} />
                  <a href={`tel:${store.phone}`}>{store.phone}</a>
                </div>

                {/** store hours */}
                <div className="order-stores__store-row order-stores__store-info-line order-stores__store-info-line__hours">
                  <IconClock aria-label={appLabels["order"]["store-hours"]} />
                  <StoreInfoDetailsHours allHours={store.hours} />
                </div>

                {/** action buttons */}

                <div className="order-stores__store-row order-stores__store-info-line order-stores__store-info-line__actions">
                  <div className="order-stores__store-column">
                    {store.distanceFromUserLocationInKilometers && (
                      <>
                        {!isDineinInfoCard && (
                          <>
                            <span className="order-stores__store-distance">
                              {
                                appLabels["order"]["distance-to-store"].split(
                                  "[distance-from-user]"
                                )[0]
                              }
                              {store.country === "US"
                                ? store.distanceFromUserLocationInMiles
                                : store.distanceFromUserLocationInKilometers}
                              {store.country === "US"
                                ? " mi"
                                : appLabels["order"]["distance-to-store"].split(
                                    "[distance-from-user]"
                                  )[1]}
                            </span>
                          </>
                        )}
                      </>
                    )}

                    <button
                      className="order-stores__store-direction-button"
                      onClick={() => getDirections(store)}
                    >
                      {appLabels["order"]["select-store-get-direction"]}
                    </button>
                  </div>

                  <div className="order-stores__store-column">
                    <button
                      className="order-stores__store-select-button button--primary button--primary-with-border button--primary-small button--white"
                      type="button"
                      onClick={() => {
                        updateActiveStore(store, isFavStore, setShowLoadingDots);
                      }}
                    >
                      {showLoadingDots ? (
                        <LoadingDots />
                      ) : (
                        <>{appLabels["order"]["select-store-select-store"]}</>
                      )}
                    </button>
                  </div>
                </div>
              </>
            ) : (
              <>
                <div className="order-stores__store-info-header-container order-stores__store-column">
                  <div className="order-stores__store-row x-button-row">
                      <button aria-label={appLabels["general"]["close"] + " " + appLabels["order"]["select-store-hours-info"]} type="button" onClick={() => setShowHoursAndInfo(false)}>
                      <IconX />
                    </button>
                  </div>
                  <div className="order-stores__store-row order-stores__store-info-header-top">
                    <OrderStoreFavoriteButton
                      customClassName={"order-stores__store-favorite-button"}
                      store={store}
                      favoriteLocations={favoriteLocations}
                      addLocationToFavorites={addLocationToFavorites}
                      removeLocationFromFavorites={removeLocationFromFavorites}
                      setIsFavStore={setIsFavStore}
                      favButtonId={`order-store-fav-button__${store.storeId}`}
                      isCalling1327={isCalling1327}
                    />
                    <h3>{store.name}</h3>
                  </div>

                  <div className="order-stores__store-info-header-bottom order-stores__store-row">
                    <div className="mobile-app-header-logo_wrapper page-title ">
                      <img
                        src={branding["main-logo"]}
                        alt={`${manifestShortName} logo`}
                        className="mobile-app-header-logo"
                      />
                    </div>
                  </div>
                </div>

                {/** Store Address */}
                <div className="order-stores__store-row order-stores__store-info-line store-address-and-distance">
                  <div>
                      <IconMapPin aria-label={appLabels["order"]["type-in-address"]} />
                    <span>
                      {store.address}, {store.city},{" "}
                      {store.province && store.province.split(":").length === 1
                        ? store.province + ","
                        : ""}{" "}
                      {store.postal}
                    </span>
                  </div>
                  {store.distanceFromUserLocationInKilometers && (
                    <>
                      {!isDineinInfoCard && (
                        <>
                          <span className="order-stores__store-distance">
                            {
                              appLabels["order"]["distance-to-store"].split(
                                "[distance-from-user]"
                              )[0]
                            }
                            {store.country === "US"
                              ? store.distanceFromUserLocationInMiles
                              : store.distanceFromUserLocationInKilometers}
                            {store.country === "US"
                              ? " mi"
                              : appLabels["order"]["distance-to-store"].split(
                                  "[distance-from-user]"
                                )[1]}
                          </span>
                        </>
                      )}
                    </>
                  )}
                </div>

                {/** store phone number */}
                <div className="order-stores__store-row order-stores__store-info-line order-stores__store-info-line__phone">
                    <IconPhoneCall aria-label={appLabels["form"]["phone"]} />
                  <span>{store.phone}</span>
                </div>

                {/** store hours */}
                <div className="order-stores__store-row order-stores__store-info-line order-stores__store-info-line__hours">
                  <IconClock aria-label={appLabels["order"]["store-hours"]} />

                  <StoreInfoDetailsHours allHours={store.hours} />
                </div>

                {/** action buttons */}

                <div className="order-stores__store-row order-stores__store-info-line order-stores__store-info-line__actions">
                  <div className="order-stores__store-column">
                    <button
                      className="order-stores__store-direction-button"
                      onClick={() => getDirections(store)}
                    >
                      {appLabels["order"]["select-store-get-direction"]}
                    </button>
                  </div>

                  <div className="order-stores__store-column">
                    <button
                      className="order-stores__store-select-button button--primary button--primary-with-border button--primary-small button--white"
                      type="button"
                      onClick={() => {
                        updateActiveStore(store, isFavStore, setShowLoadingDots);
                      }}
                    >
                      {showLoadingDots ? (
                        <LoadingDots />
                      ) : (
                        <>{appLabels["order"]["select-store-select-store"]}</>
                      )}
                    </button>
                  </div>
                </div>
              </>
            )}
          </div>
        </div>
      )}
    </>
  );
};
