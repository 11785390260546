import React, { useContext, useEffect, useState } from "react";
import { Link, useHistory } from "react-router-dom";
import localforage from "localforage";

/** Contexts */
import BrandingContext from "../../../../App/BrandingContext";
import { MenuContext } from "../../MenuContext";
import AppLabelsContext from "../../../../App/AppLabelsContext";
import AppSettingsContext from "../../../../App/AppSettingsContext";
import AppLanguageContext from "../../../../App/AppLanguageContext";
import MerchantConfigContext from "../../../../App/MerchantConfigContext";

/** Helpers */
import { jsonCopy, toDollars } from "../../../../_common/helpers";
import {
  handleModifyClick,
  updateComboChildItemQuantity,
  updateItemQuantity,
} from "../../helpers/orderingHelpers";

/** UI Components */
import { DialogModal } from "../../../../_common/DialogModal/DialogModal";
import { CartItemQuantityButtons } from "../../../Cart/CartItemQuantityButtons";

/** Assets */
import { IconEdit, IconDelete } from "../../../../_common/icons";
import { ReactComponent as IconX } from "../../../../_common/icons/IconX.svg";

/** CSS */
import "./QuickAddCustomizationModal.css";
import { getContrastColor } from "../../../../_common/colorHelpers";

const getCustomizationModalItem = (
  item, //item to be displayed in the customization modal
  cartData, //cart context
  appLabels, //app labels context
  handleRemoveItem, //function to remove an item from cart
  isSingleItem, //flag to indicate if there is only one item in the cart
  orderMenu, //Menu Context
  comboCartItem, //object which holds the data for combo selection
  isComboChildItem, //flag which determines if dealing with a combo child item
  updateActiveItem, //function which redirects user to combo sub item page
  isModifyDisplay, //flag to determine if it is modify or menu display
  subGroupQuantityReached //function to determine if subgroup max selection is reached
) => {
  const appSettings = useContext(AppSettingsContext);
  const appLanguage = useContext(AppLanguageContext);
  const skin = useContext(MerchantConfigContext).skin;
  const history = useHistory();
  const isAddonQuantityEnabled = appSettings["enable-addon-quantity-buttons"] === "yes";
  const [isPerformingAction, setIsPerformingAction] = useState(false);

  useEffect(() => {
    if (isPerformingAction) {
      setTimeout(() => {
        setIsPerformingAction(false);
      }, 250);
    }
  }, [isPerformingAction]);
  return (
    <li
      key={Date.now()}
      className={`cartItem ${item.className ? item.className : ""}`}
      id={`${item.id}-${item.entryKey}-modal`}
    >
      <div className="cartItem__wrapper">
        <div>
          <div className="cartItem__infoWrapper">
            <div className="cart-item__name-price-wrapper">
              <h3 className="cartItem__heading">{item.name}</h3>
              <span className="cartItem__price">
                {!!item.calorie_count &&
                  item.calorie_count + ` ${appLabels["order"]["calories"]} | `}
                {toDollars(
                  appSettings["currency-symbol"],
                  appSettings["currency-symbol-side"],
                  item.price,
                  appLanguage
                )}
              </span>
            </div>

            <ul className="cartItem__modifiers">
              {/** Item Modifiers */}
              {item.hasOwnProperty("modifierGroups") &&
                Object.keys(item.modifierGroups).length > 0 &&
                Object.keys(item.modifierGroups).map((optionGroupKey, index) => (
                  <React.Fragment key={`${optionGroupKey}-${index}`}>
                    {Object.keys(item.modifierGroups[optionGroupKey].items).map(
                      (optionKey, index) => (
                        <React.Fragment key={`${optionKey}-${index}`}>
                          {item.modifierGroups[optionGroupKey].items[optionKey].isSelected && (
                            <>
                              <li className="cart-item__option indentOneLevel">
                                <p className="cart-item__option-name">
                                  <span>
                                    {item.modifierGroups[optionGroupKey].items[optionKey].name}
                                  </span>
                                </p>

                                {item.modifierGroups[optionGroupKey].items[optionKey].price &&
                                  Number(
                                    item.modifierGroups[optionGroupKey].items[optionKey].price
                                  ) > 0 && (
                                    <span className="cart-item__option-price">
                                      {!!item.modifierGroups[optionGroupKey].items[optionKey]
                                        .calorie_count &&
                                        item.modifierGroups[optionGroupKey].items[optionKey]
                                          .calorie_count + ` ${appLabels["order"]["calories"]} | `}
                                      {item.modifierGroups[optionGroupKey].items[optionKey].price}
                                    </span>
                                  )}
                              </li>
                            </>
                          )}
                        </React.Fragment>
                      )
                    )}
                  </React.Fragment>
                ))}

              {/** Item Addons */}
              {item.hasOwnProperty("addonGroups") &&
                Object.keys(item.addonGroups).length > 0 &&
                Object.keys(item.addonGroups).map((optionGroupKey, index) => (
                  <React.Fragment key={`${optionGroupKey}-${index}`}>
                    {Object.keys(item.addonGroups[optionGroupKey].items).map((optionKey, index) => (
                      <React.Fragment key={`${optionKey}-${index}`}>
                        {item.addonGroups[optionGroupKey].items[optionKey].isSelected && (
                          <>
                            <li className="cart-item__option indentOneLevel">
                              <p className="cart-item__option-name">
                                {isAddonQuantityEnabled && (
                                  <span>
                                    {item.addonGroups[optionGroupKey].items[optionKey].quantity ||
                                      1}
                                    x{" "}
                                  </span>
                                )}
                                <span>
                                  {item.addonGroups[optionGroupKey].items[optionKey].name}
                                </span>
                              </p>
                              {item.addonGroups[optionGroupKey].items[optionKey].price &&
                                Number(item.addonGroups[optionGroupKey].items[optionKey].price) >
                                  0 && (
                                  <span className="cart-item__option-price">
                                    {!!item.addonGroups[optionGroupKey].items[optionKey]
                                      .calorie_count &&
                                      item.addonGroups[optionGroupKey].items[optionKey]
                                        .calorie_count + ` ${appLabels["order"]["calories"]} | `}
                                    {item.addonGroups[optionGroupKey].items[optionKey].price}
                                  </span>
                                )}
                            </li>

                            {item.addonGroups[optionGroupKey].items[optionKey].modifierGroups &&
                              Object.keys(
                                item.addonGroups[optionGroupKey].items[optionKey].modifierGroups
                              ).length > 0 && (
                                <ul>
                                  {Object.keys(
                                    item.addonGroups[optionGroupKey].items[optionKey].modifierGroups
                                  ).map((nestedModGroupKey, i) => (
                                    <React.Fragment key={`${nestedModGroupKey}-${i}`}>
                                      {Object.keys(
                                        item.addonGroups[optionGroupKey].items[optionKey]
                                          .modifierGroups[nestedModGroupKey].items
                                      ).map((nestedModKey, index) => (
                                        <React.Fragment key={`${nestedModKey}-${i}`}>
                                          {item.addonGroups[optionGroupKey].items[optionKey]
                                            .modifierGroups[nestedModGroupKey].items[nestedModKey]
                                            .isSelected && (
                                            <li className="cart-item__option indentTwoLevels">
                                              <span className="cart-item__option-name cart-combo-child-item__option-name cart-combo-child-item__nested-option-name">
                                                {
                                                  item.addonGroups[optionGroupKey].items[optionKey]
                                                    .modifierGroups[nestedModGroupKey].items[
                                                    nestedModKey
                                                  ].name
                                                }
                                              </span>
                                            </li>
                                          )}
                                        </React.Fragment>
                                      ))}
                                    </React.Fragment>
                                  ))}
                                </ul>
                              )}
                          </>
                        )}
                      </React.Fragment>
                    ))}
                  </React.Fragment>
                ))}

              {item.hasOwnProperty("combo_child_items") &&
                item["combo_child_items"].length > 0 &&
                item.combo_child_items.map((comboChildItem, index) => (
                  <React.Fragment key={`${comboChildItem.id}-${index}`}>
                    <li className="cart-item__option cart-combo-child-item">
                      <span className="cart-item__option-name cart-combo-child-item__name">
                        {comboChildItem.groupMinQty && (
                          <span>{comboChildItem.quantity || 1}x </span>
                        )}
                        {comboChildItem.name}
                      </span>
                      {comboChildItem["price_for_display"] &&
                        comboChildItem["price_for_display"] > 0 && (
                          <span className="cart-item__option-price cart-combo-child-item__price">
                            {!!comboChildItem.calorie_count &&
                              comboChildItem.calorie_count +
                                ` ${appLabels["order"]["calories"]} | `}
                            {toDollars(
                              appSettings["currency-symbol"],
                              appSettings["currency-symbol-side"],
                              comboChildItem["price_for_display"],
                              appLanguage
                            )}
                          </span>
                        )}
                    </li>
                    {comboChildItem.hasOwnProperty("modifierGroups") &&
                      Object.keys(comboChildItem.modifierGroups).length > 0 &&
                      Object.keys(comboChildItem.modifierGroups).map((optionGroupKey, index) => (
                        <React.Fragment key={`${optionGroupKey}-${index}`}>
                          {Object.keys(comboChildItem.modifierGroups[optionGroupKey].items).map(
                            (optionKey, index) => (
                              <React.Fragment key={`${optionKey}-${index}`}>
                                {comboChildItem.modifierGroups[optionGroupKey].items[optionKey]
                                  .isSelected && (
                                  <>
                                    <li className="cart-item__option indentOneLevel">
                                      <p className="cart-item__option-name">
                                        <span>
                                          {
                                            comboChildItem.modifierGroups[optionGroupKey].items[
                                              optionKey
                                            ].name
                                          }
                                        </span>
                                      </p>

                                      {comboChildItem.modifierGroups[optionGroupKey].items[
                                        optionKey
                                      ].price &&
                                        Number(
                                          comboChildItem.modifierGroups[optionGroupKey].items[
                                            optionKey
                                          ].price
                                        ) > 0 && (
                                          <span className="cart-item__option-price">
                                            {!!comboChildItem.modifierGroups[optionGroupKey].items[
                                              optionKey
                                            ].calorie_count &&
                                              comboChildItem.modifierGroups[optionGroupKey].items[
                                                optionKey
                                              ].calorie_count +
                                                ` ${appLabels["order"]["calories"]} | `}
                                            {
                                              comboChildItem.modifierGroups[optionGroupKey].items[
                                                optionKey
                                              ].price
                                            }
                                          </span>
                                        )}
                                    </li>
                                  </>
                                )}
                              </React.Fragment>
                            )
                          )}
                        </React.Fragment>
                      ))}

                    {comboChildItem.hasOwnProperty("addonGroups") &&
                      Object.keys(comboChildItem.addonGroups).length > 0 &&
                      Object.keys(comboChildItem.addonGroups).map((optionGroupKey, index) => (
                        <React.Fragment key={`${optionGroupKey}-${index}`}>
                          {Object.keys(comboChildItem.addonGroups[optionGroupKey].items).map(
                            (optionKey, index) => (
                              <React.Fragment key={`${optionKey}-${index}`}>
                                {comboChildItem.addonGroups[optionGroupKey].items[optionKey]
                                  .isSelected && (
                                  <>
                                    <li className="cart-item__option indentOneLevel">
                                      <p className="cart-item__option-name">
                                        {isAddonQuantityEnabled && (
                                          <span>
                                            {comboChildItem.addonGroups[optionGroupKey].items[
                                              optionKey
                                            ].quantity || 1}
                                            x{" "}
                                          </span>
                                        )}
                                        <span>
                                          {
                                            comboChildItem.addonGroups[optionGroupKey].items[
                                              optionKey
                                            ].name
                                          }
                                        </span>
                                      </p>
                                      {comboChildItem.addonGroups[optionGroupKey].items[optionKey]
                                        .price &&
                                        Number(
                                          comboChildItem.addonGroups[optionGroupKey].items[
                                            optionKey
                                          ].price
                                        ) > 0 && (
                                          <span className="cart-item__option-price">
                                            {!!comboChildItem.addonGroups[optionGroupKey].items[
                                              optionKey
                                            ].calorie_count &&
                                              comboChildItem.addonGroups[optionGroupKey].items[
                                                optionKey
                                              ].calorie_count +
                                                ` ${appLabels["order"]["calories"]} | `}
                                            {
                                              comboChildItem.addonGroups[optionGroupKey].items[
                                                optionKey
                                              ].price
                                            }
                                          </span>
                                        )}
                                    </li>

                                    {comboChildItem.addonGroups[optionGroupKey].items[optionKey]
                                      .modifierGroups &&
                                      Object.keys(
                                        comboChildItem.addonGroups[optionGroupKey].items[optionKey]
                                          .modifierGroups
                                      ).length > 0 && (
                                        <ul>
                                          {Object.keys(
                                            comboChildItem.addonGroups[optionGroupKey].items[
                                              optionKey
                                            ].modifierGroups
                                          ).map((nestedModGroupKey, i) => (
                                            <React.Fragment key={`${nestedModGroupKey}-${i}`}>
                                              {Object.keys(
                                                comboChildItem.addonGroups[optionGroupKey].items[
                                                  optionKey
                                                ].modifierGroups[nestedModGroupKey].items
                                              ).map((nestedModKey, index) => (
                                                <React.Fragment key={`${nestedModKey}-${i}`}>
                                                  {comboChildItem.addonGroups[optionGroupKey].items[
                                                    optionKey
                                                  ].modifierGroups[nestedModGroupKey].items[
                                                    nestedModKey
                                                  ].isSelected && (
                                                    <li className="cart-item__option indentTwoLevels">
                                                      <span className="cart-item__option-name cart-combo-child-item__option-name cart-combo-child-item__nested-option-name">
                                                        {
                                                          comboChildItem.addonGroups[optionGroupKey]
                                                            .items[optionKey].modifierGroups[
                                                            nestedModGroupKey
                                                          ].items[nestedModKey].name
                                                        }
                                                      </span>
                                                    </li>
                                                  )}
                                                </React.Fragment>
                                              ))}
                                            </React.Fragment>
                                          ))}
                                        </ul>
                                      )}
                                  </>
                                )}
                              </React.Fragment>
                            )
                          )}
                        </React.Fragment>
                      ))}
                  </React.Fragment>
                ))}
            </ul>

            {item.specialInstructions && (
              <div
                className={`cart-item__special-instructions cart-item__special-instructions--expanded`}
              >
                {item.specialInstructions}
              </div>
            )}
          </div>
        </div>

        <div className="cartItem__buttons-container">
          <button
            className="button cartItem__button--delete"
            onClick={() => {
              if (isSingleItem) {
                handleRemoveItem(item);
              } else {
                document.getElementById("customization-modal__done-btn").disabled = true;
                document
                  .getElementById(`${item.id}-${item.entryKey}-modal`)
                  .classList.add("slideRight");
                setTimeout(() => {
                  document
                    .getElementById("customization-modal__done-btn")
                    .removeAttribute("disabled");
                  handleRemoveItem(item);
                }, 1500);
              }
            }}
            aria-label={appLabels["order"]["remove-item-from-cart"].replace(
              "[item-name]",
              item.name
            )}
            type="button"
          >
            <IconDelete className="cartItem__icon cartItem__icon--delete" aria-hidden="true" />
            <span className="cartItem__button-text">{appLabels["general"]["remove"]}</span>
          </button>

          <CartItemQuantityButtons
            cartItem={item}
            updateItemQuantity={!!item.group_id ? updateComboChildItemQuantity : updateItemQuantity}
            appLabels={appLabels}
            cartData={cartData}
            comboCartItem={comboCartItem}
            subGroupQuantityReached={subGroupQuantityReached}
            isPerformingAction={isPerformingAction}
            setIsPerformingAction={setIsPerformingAction}
          />

          <button
            className="button cartItem__button--edit"
            onClick={async () => {
              if (isComboChildItem) {
                updateActiveItem(
                  item.id,
                  item.parentId,
                  item.type,
                  true, //isForComboChildItem
                  !!comboCartItem.combo_child_items.length &&
                    !!comboCartItem.combo_child_items[0].groupMinQty
                    ? jsonCopy(item)
                    : item,
                  isModifyDisplay, //isModifyDisplay
                  true, //isCombo added
                  true //isFromCustomizationModal
                );
              } else {
                const pathnameLink = await handleModifyClick(skin, item, orderMenu);
                history.push({
                  pathname: pathnameLink,
                  state: { isModifyingItem: true, from: "customization-modal" },
                });
              }
            }}
            aria-label={appLabels["order"]["modify-item-in-cart"].replace("[item-name]", item.name)}
            type="button"
          >
            <IconEdit className="cartItem__icon cartItem__icon--edit" />
            <span className="cartItem__button-text">{appLabels["order"]["modify"]}</span>
          </button>
        </div>
      </div>
    </li>
  );
};

const setupCustomizationPopupContent = (
  items,
  cartData,
  appLabels,
  handleRemoveAll,
  handleRemoveItem,
  setShowCustomizationPopup,
  comboCartItem,
  updateActiveItem,
  subGroupQuantityReached,
  isModifyDisplay,
  skin,
  setValidateGoingToNextLevel
) => {
  const orderMenu = useContext(MenuContext);
  const branding = useContext(BrandingContext);
  const contrastTextColor = getContrastColor(branding["online-order-settings-background-colour"]);
  const isThreeLevelMenu = orderMenu.menuLevel === "threeLevels";
  const isComboItem =
    items[0].type === "comboItem" ||
    (items[0].combo_child_items && items[0].combo_child_items.length > 0);
  const isComboChildItem = !!items[0].group_id;

  const getItemLink = (itemData) => {
    let itemLink = "";
    if (isThreeLevelMenu) {
      itemLink = `/online-ordering/menu/category/${itemData.category}/subcategory/${
        itemData.subcategory
      }/${isComboItem ? "combo" : "item"}/${itemData.id}`;
    } else {
      itemLink = `/online-ordering/menu/subcategory/${itemData.subcategory}/${
        isComboItem ? "combo" : "item"
      }/${itemData.id}`;
    }

    return itemLink;
  };

  return (
    <>
      <div className="customization-modal__header">
        <h2>{appLabels["order"]["customization"]}</h2>
        <div className="customization-modal__top-buttons">
          {isComboChildItem ? (
            <button
              onClick={() => {
                updateActiveItem(
                  items[0].id,
                  items[0].parentId,
                  items[0].type,
                  true,
                  !!comboCartItem.combo_child_items.length &&
                    !!comboCartItem.combo_child_items[0].groupMinQty
                    ? jsonCopy(items[0])
                    : items[0],
                  false, //isModifyDisplay
                  false //isCombo added
                );
              }}
              disabled={subGroupQuantityReached(items[0].group_id)}
            >
              {appLabels["order"]["add-new-item"]}
            </button>
          ) : (
            <Link to={getItemLink(items[0])}>{appLabels["order"]["add-new-item"]}</Link>
          )}

          {items.length > 1 && (
            <button onClick={handleRemoveAll} aria-label={appLabels["order"]["remove-all-items"]}>
              {appLabels["order"]["remove-all-items"]}
            </button>
          )}
        </div>

        <button
          onClick={() => {
            setShowCustomizationPopup(false);
            localforage.removeItem(skin + "__activeItemInCustomizationModel");
            window.location.href = window.location.href.replace("?customization-modal", "");
            if (setValidateGoingToNextLevel) {
              setValidateGoingToNextLevel(true);
            }
          }}
          aria-label={appLabels["general"]["close-dialog-modal"]}
          className="button dialog-modal__close-button"
          type="button"
        >
          <IconX aria-hidden="true" />
        </button>
      </div>
      <div className="customization-modal__content__wrapper">
        <ul className="customization-modal__items">
          {items.map((item, index) => (
            <React.Fragment key={`${item.id}-${index}`}>
              {getCustomizationModalItem(
                item,
                cartData,
                appLabels,
                handleRemoveItem,
                items.length === 1,
                orderMenu,
                comboCartItem,
                isComboChildItem,
                updateActiveItem,
                isModifyDisplay,
                subGroupQuantityReached
              )}
            </React.Fragment>
          ))}
        </ul>
      </div>
      <div className="customization-modal__done_button_container">
        <button
          type="button"
          aria-label={appLabels["general"]["done"]}
          onClick={() => {
            setShowCustomizationPopup(false);
            localforage.removeItem(skin + "__activeItemInCustomizationModel");
            window.location.href = window.location.href.replace("?customization-modal", "");
            if (setValidateGoingToNextLevel) {
              setValidateGoingToNextLevel(true);
            }
          }}
          className="button button--primary"
          style={{
            color: contrastTextColor,
            backgroundColor: "transparent",
            border: `2px solid ${contrastTextColor}`,
          }}
          id="customization-modal__done-btn"
        >
          {appLabels["general"]["done"]}
        </button>
      </div>
    </>
  );
};

const QuickAddCustomizationModal = (props) => {
  const {
    setShowCustomizationPopup, //the state to toggle the popup
    setIsRemovingAllItems, //the state to toggle remove all items popup
    handleRemoveItem, //function to remove an instance of the item from the cart
    relatedCartItems, //related items to this modal
    isRemovingItem, // the state to determine if an item is being removed
    cartData, //cart items
    comboCartItem, //object that keeps track of the combo selection before getting added to the cart
    updateActiveItem, //function which redirect the user to the combo item details page
    subGroupQuantityReached, //function which determines if the max quantity of combo sub group is reached
    isModifyDisplay,
    setValidateGoingToNextLevel,
  } = props;

  const appLabels = useContext(AppLabelsContext);
  const skin = useContext(MerchantConfigContext).skin;
  const handleRemoveAll = () => {
    setShowCustomizationPopup(false);
    setIsRemovingAllItems(true);
    localforage.removeItem(skin + "__activeItemInCustomizationModel");
    window.location.href = window.location.href.replace("?customization-modal", "");
  };

  return (
    <DialogModal
      message={
        !isRemovingItem
          ? setupCustomizationPopupContent(
              relatedCartItems,
              cartData,
              appLabels,
              handleRemoveAll,
              handleRemoveItem,
              setShowCustomizationPopup,
              comboCartItem,
              updateActiveItem,
              subGroupQuantityReached,
              isModifyDisplay,
              skin,
              setValidateGoingToNextLevel
            )
          : "loading"
      }
      resetRemoveDialog={() => {
        setShowCustomizationPopup(false);
        localforage.removeItem(skin + "__activeItemInCustomizationModel");
        window.location.href = window.location.href.replace("?customization-modal", "");
        if (setValidateGoingToNextLevel) {
          setValidateGoingToNextLevel(true);
        }
      }}
      isCancelConfirm={false}
      hideConfirmButton={true}
      isHTMLContent={true}
      customClass={"customization-modal__container"}
      hideCloseButton={true}
    />
  );
};
export default QuickAddCustomizationModal;
