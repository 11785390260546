// Libraries
import React, { useContext, useEffect } from "react";
import { useHistory } from "react-router-dom";

// Contexts
import MerchantConfigContext from "../App/MerchantConfigContext";
import UserRoleContext from "../App/UserRoleContext";

// UI components
import { LoginRegisterLinks } from "./LoginRegisterLinks/LoginRegisterLinks";
import { Login } from "./Login/Login";
import OrderTypeContext from "../OnlineOrdering/OrderTypeContext";

export const LoginRegister = () => {
  const history = useHistory();

  const merchantConfigContext = useContext(MerchantConfigContext);
  const merchantSkinName = merchantConfigContext.skin;

  const merchantConfig = merchantConfigContext.merchant;
  const isRegistrationEnabled = merchantConfig.I24 === "t";
  const orderTypeContext = useContext(OrderTypeContext);
  const activeOrderType = orderTypeContext.value;

  const userRoleContext = useContext(UserRoleContext);

  const proceedToDashboard = () => {
    userRoleContext.updateStatus("guest");

    if (
      sessionStorage.getItem(merchantSkinName + "__redirectToCheckout") &&
      sessionStorage.getItem(merchantSkinName + "__redirectToCheckout") === "true"
    ) {
      history.push({ pathname: "/online-ordering/checkout", state: { from: "login-register" } });
    } else if (
      sessionStorage.getItem(merchantSkinName + "__lastVisitedLink") &&
      !sessionStorage.getItem(merchantSkinName + "__lastVisitedLink").includes("register") &&
      !sessionStorage.getItem(merchantSkinName + "__lastVisitedLink").includes("login-register") &&
      !sessionStorage.getItem(merchantSkinName + "__lastVisitedLink").includes("past-orders") &&
      !sessionStorage.getItem(merchantSkinName + "__lastVisitedLink").includes("favorites")
    ) {
      let pathLink = sessionStorage.getItem(merchantSkinName + "__lastVisitedLink");
      sessionStorage.removeItem(merchantSkinName + "__lastVisitedLink");

      // Redirect to checkout page if user came from the payment page,
      // because they need to enter their customer info for the order
      if (pathLink === "/online-ordering/payment") {
        if (activeOrderType !== "dinein") {
          pathLink = "/online-ordering/checkout";
        } else {
          pathLink = "/online-ordering/review-order";
        }
      } else if (pathLink.includes("/account") || pathLink.includes("/pay-in-store")) {
        pathLink = "/dashboard";
      }

      history.push({
        pathname: pathLink,
        state: { from: "login-register" },
      });
    } else {
      history.push("/dashboard");
    }
  };

  useEffect(() => {
    if (!isRegistrationEnabled) proceedToDashboard();
  }, []);

  return (
    <div className="login-register__main-wrapper">
      {isRegistrationEnabled && (
        <>
          <Login />
          <LoginRegisterLinks proceedToDashboard={proceedToDashboard} />
        </>
      )}
    </div>
  );
};
