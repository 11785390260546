import React, { useState, useContext } from "react";
import localforage from "localforage";
import { isValidItemQuantity, validateOptions } from "../../../_common/MenuHelpers";
import { findPos } from "../../../_common/helpers";
import { FAB } from "../../../_common/buttons/FAB/FAB";
import AppLabelsContext from "../../../App/AppLabelsContext";
import BrandingContext from "../../../App/BrandingContext";
import { getContrastColor } from "../../../_common/colorHelpers";
import useWindowSize from "../../../_common/hooks/useWindowSize";
import { useLocation } from "react-router-dom";
import MerchantConfigContext from "../../../App/MerchantConfigContext";
import {
  onAddProductToCartXtreme,
  onRemoveProductFromCartXtreme,
} from "../../../_common/helpers/xtremePushHelper";

export default function CartSaveChanges(props) {
  const {
    cart,
    specialInstructions,
    isFAB,
    item,
    closeItemDetail,
    isRelativePositioning,
    relativeContainerId,
  } = props;

  const [areOptionsValid, setAreOptionsValid] = useState(null);
  const [isQuantityValid, setIsQuantityValid] = useState(null);
  const deviceWidth = useWindowSize().width;

  const branding = useContext(BrandingContext);
  const { state } = useLocation();
  const skin = useContext(MerchantConfigContext).skin;

  const saveChanges = async () => {
    const { errors, idOfGroupWithErrors, idOfParentOfGroupWithErrors, idOfMainAddon } =
      validateOptions(item);
    if (errors === 0) {
      if (!isValidItemQuantity(cart.value, item, true)) {
        setIsQuantityValid(false);
        return;
      }

      let index = null;
      index = cart.value.findIndex((element) => element.entryKey === item.entryKey);

      const originalQuantity = cart.value[index].quantity;

      const newItem = { ...item };
      newItem.entryKey = Date.now();
      newItem.specialInstructions = specialInstructions;

      const newQuantity = newItem.quantity;

      let newCart = [...cart.value];
      newCart[index] = newItem;

      newCart.forEach((element) => {
        delete element.entryKey;
        if (element.addonGroups) {
          Object.keys(element.addonGroups).forEach((key) => {
            const addonGroup = element.addonGroups[key];
            delete addonGroup.positioningTemplateIndex;
            const addons = addonGroup.items;
            Object.keys(addons).forEach((addonKey) => {
              const addon = addons[addonKey];
              if (addon.modifierGroups) {
                const nestedModifierGroups = addon.modifierGroups;
                Object.keys(nestedModifierGroups).forEach((nestedModifierGroupKey) => {
                  delete nestedModifierGroups[nestedModifierGroupKey].isSelected;
                });
              }
            });
          });
        }

        if (element.modifierGroups) {
          Object.keys(element.modifierGroups).forEach((key) => {
            delete element.modifierGroups[key].positioningTemplateIndex;
          });
        }
      });
      cart.updateCart(newCart);

      // If the quantity of the item has changed, call the xtremePush functions
      if (originalQuantity < newQuantity) {
        onAddProductToCartXtreme(
          { ...newItem, quantity: newQuantity - originalQuantity },
          newItem.category,
          newItem.subcategory,
          skin
        );
      } else if (originalQuantity > newQuantity) {
        onRemoveProductFromCartXtreme(
          { ...newItem, quantity: originalQuantity - newQuantity },
          newItem.category,
          newItem.subcategory,
          false,
          skin
        );
      }

      if (!!state && !!state.from && state.from === "customization-modal") {
        await localforage.setItem(skin + "__activeItemInCustomizationModel", item.id);
      }
      localforage.removeItem(skin + "__itemSelectionInProgress");
      sessionStorage.removeItem(skin + "__orderStoreState");
      setAreOptionsValid(true);
      closeItemDetail();
    } else {
      setAreOptionsValid(false);
      setIsQuantityValid(false);
      //TODO: simplify the validation mechanism
      setTimeout(() => {
        setAreOptionsValid(null);
        setIsQuantityValid(null);
      }, 2000);

      /** Scroll to the addon/modifier group with the id idOfGroupWithErrors */
      const containerWithError =
        document.getElementById(`${idOfGroupWithErrors}-header`) ||
        document.getElementById(
          `${idOfGroupWithErrors}_${idOfParentOfGroupWithErrors}_${idOfMainAddon}`
        );
      if (containerWithError) {
        const groupContainerButton = containerWithError.querySelectorAll("button");
        const optionItemContainer = containerWithError.nextSibling;
        /** if the options groups is collapsed, expand it */
        if (
          optionItemContainer &&
          optionItemContainer.classList.value.includes("visually-hidden")
        ) {
          groupContainerButton[0].click();
        }

        if (!!document.getElementsByClassName("item-details-main__Wrapper").length) {
          setTimeout(() => {
            document.getElementsByClassName("item-details-main__Wrapper")[0].scrollTo({
              top: findPos(containerWithError) - (deviceWidth >= 660 ? 140 : 200),
              behavior: "smooth",
            });
          }, 250);
        }

        if (
          deviceWidth >= 660 &&
          document.getElementsByClassName("item-details-main__Wrapper").length > 0
        ) {
          setTimeout(() => {
            document.getElementsByClassName("item-details-main__Wrapper")[0].scrollTo({
              top: findPos(containerWithError) - (deviceWidth >= 660 ? 140 : 200),
              behavior: "smooth",
            });
          }, 250);

          let elementLabels;
          /** if nested addon/modifier group */
          if (
            containerWithError &&
            containerWithError.classList.value.includes("nested-addons-fieldset-wrapper")
          ) {
            elementLabels = containerWithError.querySelectorAll(`label`);
            let elementButtons = containerWithError.querySelectorAll("button");
            elementButtons.forEach((button) => {
              button.classList.add("error");
            });
          } else {
            //if regular addon modifier group
            if ((elementLabels = containerWithError.nextSibling)) {
              elementLabels = containerWithError.nextSibling.querySelectorAll(
                `.form__field-label--checkbox-radio`
              );
            }
          }
          /** Add the error class to each label element */
          if (elementLabels) {
            elementLabels.forEach((label) => {
              label.classList.add("error");
            });
          }
        } else {
          let elementLabels;
          /** if nested addon/modifier group */
          if (
            containerWithError &&
            containerWithError.classList.value.includes("nested-addons-fieldset-wrapper")
          ) {
            elementLabels = containerWithError.querySelectorAll(`span`);
            let elementButtons = containerWithError.querySelectorAll("button");
            elementButtons.forEach((button) => {
              button.classList.add("error");
            });
          } else {
            //if regular addon modifier group
            if ((elementLabels = containerWithError.nextSibling)) {
              elementLabels = containerWithError.nextSibling.querySelectorAll(
                `.addons-modifiers-form .form__field-label--checkbox-radio`
              );
            }
          }
          /** Add the error class to each label element */
          if (elementLabels) {
            elementLabels.forEach((label) => {
              label.classList.add("error");
            });
          }
        }
      }
    }
  };

  const appLabels = useContext(AppLabelsContext);
  const textIconColor = getContrastColor(branding["online-order-settings-background-colour"]);
  return (
    isFAB && (
      <FAB
        buttonText={appLabels["order"]["save-changes"]}
        buttonSuccessText={appLabels["order"]["button-save-changes-success"]}
        formError={
          !areOptionsValid && areOptionsValid != null
            ? appLabels["form"]["missing-required-addons-modifiers-error"]
            : !isQuantityValid && isQuantityValid != null
            ? appLabels["order"]["invalid-item-quantity-error"]
            : ""
        }
        isFormValid={areOptionsValid || isQuantityValid}
        onSubmit={saveChanges}
        resetInvalidForm={() => {
          setAreOptionsValid(null);
          setIsQuantityValid(null);
        }}
        customClass="FAB--modal-level-2"
        FABCustomClass="save-changes-FAB"
        isBackgroundGradient={true}
        customStyle={{
          border: `2px solid ${textIconColor}`,
          backgroundColor: branding["online-order-settings-background-colour"],
          color: textIconColor,
        }}
        isRelativePositioning={isRelativePositioning}
        relativeContainerId={relativeContainerId}
      />
    )
  );
}
