// Libraries
import React, { useContext, useRef, useEffect, useState } from "react";
import { Link, useHistory } from "react-router-dom";

// Contexts
import CartContext from "../../OnlineOrdering/Cart/CartContext";
import AppLabelsContext from "../../App/AppLabelsContext";
import StoreContext from "../../OnlineOrdering/StoreContext";
import OrderTypeContext from "../../OnlineOrdering/OrderTypeContext";
import UserRoleContext from "../UserRoleContext";
import AppSettingsContext from "../AppSettingsContext";
import AppLanguageContext from "../AppLanguageContext";
import MerchantConfigContext from "../MerchantConfigContext";
import { MenuContext } from "../../OnlineOrdering/Menu/MenuContext";

// UI components
import { BackButton } from "../../_common/BackButton";
import CartIndicator from "../../_common/CartIndicator/CartIndicator";
import { CartOrderDetailsSummary } from "../../OnlineOrdering/Cart/CartOrderDetailsSummary";
import { DashboardHeaderLanguageDropdown } from "../../Dashboard/DashboardHeader/DashboardHeaderLanguageDropdown";
import { ReactComponent as IconGlobe } from "../../_common/icons/IconGlobe.svg";
import BrandingContext from "../BrandingContext";
import ManifestContext from "../ManifestContext";
import XButton from "../../_common/buttons/XButton";
import { DialogModal } from "../../_common/DialogModal/DialogModal";
import { ButtonAddToFavorites } from "../../_common/buttons/ButtonAddToFavorites/ButtonAddToFavorites";

// Assets
import { ReactComponent as IconHouse } from "../../_common/icons/IconHouse.svg";
import { ReactComponent as IconMapPin } from "../../_common/icons/IconMapPin.svg";

// CSS
import "./AppHeader.css";

export const AppHeader = (props) => {
  const {
    isBackButton,
    isHomeLink,
    backButtonDestination,
    pageHeading,
    isCart,
    isMapPin,
    isLanguageButton,
    isLogo,
    isXButton,
    isFavButton,
    customClass,
  } = props;

  const history = useHistory();

  const cartData = useContext(CartContext);
  const homeLinkRef = useRef(null);
  const appLabels = useContext(AppLabelsContext);
  const userRoleContext = useContext(UserRoleContext);

  const storeContext = useContext(StoreContext);
  const activeStore = storeContext.activeOrderStore;

  const orderTypeContext = useContext(OrderTypeContext);
  const activeOrderType = orderTypeContext && orderTypeContext.value;

  const [showHeaderItems, setShowHeaderItems] = useState(false);
  useEffect(() => {
    if (userRoleContext.isGuestEnabled || userRoleContext.loginToken) {
      setShowHeaderItems(true);
    }
  }, []);

  const [showOrderDetails, setShowOrderDetails] = useState(null);
  const onMapPinClick = () => {
    setShowOrderDetails(!showOrderDetails);
  };

  const merchantConfigContext = useContext(MerchantConfigContext);
  const skin = merchantConfigContext.skin;
  const appLanguage = useContext(AppLanguageContext);
  const appSettings = useContext(AppSettingsContext);
  const languageCodes = appSettings["languages-list"];
  const defaultLanguage = merchantConfigContext["default-language"];
  const [isLanguageDropdown, setIsLanguageDropdown] = useState(false);

  const branding = useContext(BrandingContext);
  const manifestShortName = useContext(ManifestContext).short_name;

  const orderMenu = useContext(MenuContext);
  const [isGuestFavoriteAttempt, setIsGuestFavoriteAttempt] = useState(null);
  const [isFavAttemptBeforeSelection, setIsFavAttemptBeforeSelection] = useState(null);
  const [isFavItem, setIsFavItem] = useState(false);
  useEffect(() => {
    if (orderMenu && orderMenu.activeItemDetails) {
      const favorites = orderMenu.favorites;
      if (favorites === null || favorites.length === 0) {
        setIsFavItem(false);
      } else {
        for (let i = 0; i < favorites.length; i++) {
          if (favorites[i].id === orderMenu.activeItem) {
            setIsFavItem(true);
            break;
          } else if (i === favorites.length - 1) {
            setIsFavItem(false);
          }
        }
      }
    }
  }, [orderMenu]);

  return (
    <>
      <header
        className={`header curved-shadow-container ${isLogo ? "header-primary-with-logo" : ""} ${
          customClass ? customClass : ""
        }`}
      >
        <div className="curved-shadow-overlay-hack" />
        <div className={`header-primary ${isLogo ? "header-primary-with-logo" : ""}`}>
          {isBackButton && <BackButton destination={backButtonDestination} appLabels={appLabels} />}
          {isXButton && (
            <XButton destination={backButtonDestination} appLabels={appLabels} skin={skin} />
          )}
          {pageHeading && <h1 className="page-title">{pageHeading}</h1>}
          {isLogo && (
            <div className="mobile-app-header-logo_wrapper page-title">
              <img
                src={branding["main-logo"]}
                alt={`${manifestShortName} logo`}
                className="mobile-app-header-logo"
              />
            </div>
          )}
          {isCart && cartData && (
            <Link
              className={`button button--cart`}
              to="/online-ordering/review-order"
              aria-label="Shopping Cart"
            >
              <CartIndicator quantity={cartData.quantity} isAnimated={cartData.isAnimated} />
            </Link>
          )}
          {!isCart &&
            !isMapPin &&
            isHomeLink &&
            homeLinkRef &&
            !homeLinkRef.current &&
            showHeaderItems && (
              <Link to="/dashboard" className="header__link--home" aria-label="Dashboard">
                <IconHouse />
              </Link>
            )}
          {isMapPin && (
            <button
              type="button"
              aria-label={`${
                showOrderDetails
                  ? appLabels["general"]["hide-app-header-order-summary"]
                  : appLabels["general"]["show-app-header-order-summary"]
              } `}
              onClick={onMapPinClick}
              className={`app-header_map-pin-button ${
                showOrderDetails ? " app-header_map-pin-button-highlighted" : ""
              }`}
            >
              <IconMapPin aria-hidden="true" />
            </button>
          )}

          {isLanguageButton && (
            <>
              {((languageCodes && languageCodes.length > 0) ||
                (appLanguage !== "en" && defaultLanguage === "")) && (
                <>
                  <button
                    onClick={() => setIsLanguageDropdown(!isLanguageDropdown)}
                    aria-label={`${
                      isLanguageDropdown
                        ? appLabels["general"]["close"]
                        : appLabels["general"]["open"]
                    } ${appLabels["general"]["language-dropdown"]}`}
                    className="button dashboard-header__language-dropdown-toggle-button"
                    type="button"
                  >
                    <IconGlobe />
                  </button>
                  {isLanguageDropdown && <DashboardHeaderLanguageDropdown />}
                </>
              )}
            </>
          )}

          {isFavButton && orderMenu && orderMenu.activeItemDetails && (
            <ButtonAddToFavorites
              item={orderMenu.activeItemDetails}
              category={orderMenu.activeTopCategory}
              subcategory={orderMenu.activeSubcategory}
              updatedState={isFavItem}
              setIsGuestFavoriteAttempt={setIsGuestFavoriteAttempt}
              setIsFavAttemptBeforeSelection={setIsFavAttemptBeforeSelection}
            />
          )}

          {!isFavButton && !isHomeLink && !isLanguageButton && !isCart && !isMapPin && (
            <div style={{ width: "40px", height: "40px", visibility: "hidden" }}></div>
          )}
        </div>
      </header>
      {showOrderDetails !== null && (
        <div
          className={`app-header__order-summary-container ${
            showOrderDetails ? " is-sliding-down" : "is-hidden"
          }`}
        >
          <CartOrderDetailsSummary
            activeOrderStore={activeStore}
            activeOrderType={activeOrderType}
            numberOfItems={cartData.quantity}
          />
          <button
            type="button"
            aria-label={`${
              showOrderDetails ? appLabels["general"]["hide-app-header-order-summary"] : ""
            } `}
            aria-hidden={!showOrderDetails}
            onClick={onMapPinClick}
            className="app-header__order-summary-container__overlay-button"
          ></button>
        </div>
      )}

      {isGuestFavoriteAttempt && (
        <DialogModal
          isConfirmText={false}
          hideConfirmButton={true}
          description="Guest User attempting to set item as favourite"
          message={
            <div className="menu-item__favourite-dialog-modal">
              <div>
                <p>{appLabels["order"]["guest-attempt-favorite-item"]}</p>
              </div>
              <div className="menu-item__favourite-dialog-modal-buttons-wrapper">
                <button
                  onClick={() => {
                    sessionStorage.setItem(
                      skin + "__lastVisitedLink",
                      window.location.hash.split("#")[1]
                    );
                    history.push("/login-register");
                  }}
                  className="button dialog-modal__button  button--primary button--primary-small"
                  type="button"
                >
                  {appLabels["login-register"]["sign-in"]}
                </button>

                <button
                  onClick={() => {
                    sessionStorage.setItem(
                      skin + "__lastVisitedLink",
                      window.location.hash.split("#")[1]
                    );
                    history.push("/register");
                  }}
                  className="button dialog-modal__button  button--primary button--primary-small"
                  type="button"
                >
                  {appLabels["login-register"]["register"]}
                </button>
              </div>
            </div>
          }
          resetRemoveDialog={() => {
            setIsGuestFavoriteAttempt(false);
          }}
          isHTMLContent={true}
        />
      )}

      {isFavAttemptBeforeSelection && (
        <DialogModal
          isConfirmText={false}
          description="User attempting to set an item as favorite before making mandatory selections"
          message={appLabels["order"]["setting-favorite-error"]}
          resetRemoveDialog={() => {
            setIsFavAttemptBeforeSelection(false);
          }}
        />
      )}
    </>
  );
};
