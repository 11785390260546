import React, { useContext, useEffect } from "react";
import { Link, useHistory, useParams } from "react-router-dom";
import localforage from "localforage";

/** Hooks */
import useWindowSize from "../../_common/hooks/useWindowSize";

/** Contexts */
import { MenuContext } from "./MenuContext";
import AppLabelsContext from "../../App/AppLabelsContext";
import MerchantConfigContext from "../../App/MerchantConfigContext";

/** UI Assets */
import { ReactComponent as IconChevronRight } from "../../_common/icons/IconArrowRight.svg";

/** CSS */
import "./MenuBreadcrumbs.css";

export const MenuBreadcrumbs = ({ currentLevelCategory }) => {
  const appLabels = useContext(AppLabelsContext);
  const { skin } = useContext(MerchantConfigContext);
  const orderMenu = useContext(MenuContext);
  const menuLevel = orderMenu.menuLevel;

  const history = useHistory();
  const currenPath = history.location.pathname;
  const isOnComboLevel = currenPath.includes("combo");

  const { category, subcategory, combo } = useParams();
  const deviceWidth = useWindowSize().width;
  /** Check the breadcurmbs container, if it's being rendered in two lines, give a margin to the last line */
  useEffect(() => {
    const container = document.getElementsByClassName("menu-breadcrumbs")[0];
    if (container) {
      var containerLineHeight = "";
      if (deviceWidth < 660) {
        containerLineHeight = container.clientHeight;
      } else {
        containerLineHeight =
          container.clientHeight -
          Number(window.getComputedStyle(container).marginTop.replace("px", "")) -
          Number(window.getComputedStyle(container).marginBottom.replace("px", ""));
      }

      const fistLineHeight = container.querySelector("a").clientHeight;
      const nodes = container.querySelectorAll("span");
      const lastNode = nodes[nodes.length - 1];
      if (containerLineHeight > fistLineHeight) {
        lastNode.style.marginTop = "5px";
      } else {
        lastNode.style.marginTop = "0px";
      }
    }
  }, [deviceWidth]);
  return (
    <div className="menu-breadcrumbs">
      <MenuBreadcrumbLink
        path={{ pathname: "/online-ordering/menu", state: { from: "menu-home-breadcrumb" } }}
        displayName={appLabels["order"]["menu"]}
        skin={skin}
        appLabels={appLabels}
      />
      {/** Special Case for handling the breadcrumbs on the favorite page */}
      {currentLevelCategory && currentLevelCategory.type && (
        <>
          {currentLevelCategory !== "favorites" ? (
            <>
              {currentLevelCategory.type.toLowerCase() === "category" && (
                <>
                  <IconChevronRight aria-hidden="true" />
                  <CurrentMenuBreadcrumb
                    displayName={currentLevelCategory.name}
                    appLabels={appLabels}
                  />
                </>
              )}
              {menuLevel === "threeLevels" &&
                ((currentLevelCategory.type.toLowerCase() === "subcategory" &&
                  Object.keys(orderMenu.apiData[`category-${category}`].subcategories).length >
                    1) ||
                  ((currentLevelCategory.type.toLowerCase() === "productitem" ||
                    currentLevelCategory.type.toLowerCase() === "comboitem") &&
                    Object.keys(orderMenu.apiData[`category-${category}`].subcategories).length >
                      1)) && (
                  <>
                    <IconChevronRight aria-hidden="true" />
                    <MenuBreadcrumbLink
                      path={`/online-ordering/menu/category/${category}`}
                      displayName={orderMenu.apiData[`category-${category}`].name}
                      skin={skin}
                      appLabels={appLabels}
                    />
                  </>
                )}
              {menuLevel === "threeLevels" &&
                (currentLevelCategory.type.toLowerCase() === "productitem" ||
                  currentLevelCategory.type.toLowerCase() === "comboitem") && (
                  <>
                    <IconChevronRight aria-hidden="true" />
                    <MenuBreadcrumbLink
                      path={`/online-ordering/menu/category/${category}/subcategory/${subcategory}`}
                      displayName={
                        orderMenu.apiData[`category-${category}`].subcategories[
                          `subcategory-${subcategory}`
                        ].name
                      }
                      skin={skin}
                      appLabels={appLabels}
                    />
                  </>
                )}
              {menuLevel === "twoLevels" &&
                (currentLevelCategory.type.toLowerCase() === "productitem" ||
                  currentLevelCategory.type.toLowerCase() === "comboitem") && (
                  <>
                    <IconChevronRight aria-hidden="true" />
                    <MenuBreadcrumbButton
                      path={`/online-ordering/menu/subcategory/${subcategory}`}
                      displayName={orderMenu.apiData[`subcategory-${subcategory}`].name}
                      skin={skin}
                      appLabels={appLabels}
                    />
                  </>
                )}
              {(currentLevelCategory.type.toLowerCase() === "subcategory" ||
                currentLevelCategory.type.toLowerCase() === "productitem" ||
                currentLevelCategory.type.toLowerCase() === "comboitem") && (
                <>
                  <IconChevronRight aria-hidden="true" />
                  {isOnComboLevel ? (
                    <>
                      {menuLevel === "threeLevels" ? (
                        <>
                          <MenuBreadcrumbButton
                            path={`/online-ordering/menu/category/${category}/subcategory/${subcategory}`}
                            displayName={
                              orderMenu.apiData[`category-${category}`].subcategories[
                                `subcategory-${subcategory}`
                              ].name
                            }
                            skin={skin}
                            appLabels={appLabels}
                          />
                          <IconChevronRight aria-hidden="true" />
                          {orderMenu.apiData[`category-${category}`].subcategories[
                            `subcategory-${subcategory}`
                          ].items[`item-${combo}`] && (
                            <CurrentMenuBreadcrumb
                              displayName={
                                orderMenu.apiData[`category-${category}`].subcategories[
                                  `subcategory-${subcategory}`
                                ].items[`item-${combo}`].name
                              }
                              appLabels={appLabels}
                            />
                          )}
                        </>
                      ) : (
                        <>
                          <MenuBreadcrumbButton
                            path={`/online-ordering/menu/subcategory/${subcategory}`}
                            displayName={orderMenu.apiData[`subcategory-${subcategory}`].name}
                            skin={skin}
                            appLabels={appLabels}
                          />
                          <IconChevronRight aria-hidden="true" />
                          {orderMenu.apiData[`subcategory-${subcategory}`].items[
                            `item-${combo}`
                          ] && (
                            <CurrentMenuBreadcrumb
                              displayName={
                                orderMenu.apiData[`subcategory-${subcategory}`].items[
                                  `item-${combo}`
                                ].name
                              }
                              appLabels={appLabels}
                            />
                          )}
                        </>
                      )}
                    </>
                  ) : (
                    <CurrentMenuBreadcrumb
                      displayName={currentLevelCategory.name}
                      appLabels={appLabels}
                    />
                  )}
                </>
              )}
            </>
          ) : (
            <>
              <IconChevronRight aria-hidden="true" />
              <CurrentMenuBreadcrumb
                displayName={appLabels["order"]["favorites"]}
                appLabels={appLabels}
              />
            </>
          )}
        </>
      )}

      {currentLevelCategory && currentLevelCategory === "favorites" && (
        <>
          <IconChevronRight aria-hidden="true" />
          <CurrentMenuBreadcrumb
            displayName={appLabels["order"]["favorites"]}
            appLabels={appLabels}
          />
        </>
      )}
    </div>
  );
};

const MenuBreadcrumbLink = ({ path, displayName, skin, appLabels }) => {
  const handleClick = () => {
    localforage.removeItem(skin + "__itemSelectionInProgress");
    localforage.removeItem(skin + "__storeIdToBeFaved");
    sessionStorage.removeItem(skin + "__orderStoreState");
  };
  return (
    <Link to={path} className="menu-breadcrumbs__item menu-breadcrumbs__link" onClick={handleClick}>
      {displayName.toLowerCase() === "featured" ? appLabels["order"]["featured"] : displayName}
    </Link>
  );
};

const MenuBreadcrumbButton = ({ path, displayName, skin, appLabels }) => {
  const history = useHistory();

  const handleClick = () => {
    localforage.removeItem(skin + "__itemSelectionInProgress");
    localforage.removeItem(skin + "__storeIdToBeFaved");
    sessionStorage.removeItem(skin + "__orderStoreState");
    history.push({
      pathname: path,
      state: {
        from: "breadcrumbs",
      },
    });
  };

  return (
    <button
      onClick={handleClick}
      className="menu-breadcrumbs__item menu-breadcrumbs__link"
      type="button"
    >
      {displayName.toLowerCase() === "featured" ? appLabels["order"]["featured"] : displayName}
    </button>
  );
};

const CurrentMenuBreadcrumb = ({ displayName, appLabels }) => {
  //const appLabels = useContext(AppLabelsContext);
  //const isFeaturedItem = displayName.toLowerCase() === "featured";
  return (
    <span className="menu-breadcrumbs__item">
      {displayName.toLowerCase() === "featured" ? appLabels["order"]["featured"] : displayName}
    </span>
  );
};
