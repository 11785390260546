export const getPhoneNumberFormat = (activeOrderStore, allLocations) => {
  const countryCode = activeOrderStore
    ? activeOrderStore.country
    : !!allLocations && allLocations.length > 0
    ? allLocations[0].country
    : "";

  let phoneNumberFormat = {
    placeholder: "(xxx) xxx-xxxx",
    minLength: 10, // for validation when # of chars < pattern requirement
    title: "10-digit number",
  };

  //For now, if the country is anything other than US or CA, do not mask the phone number field
  if (countryCode !== "CA" && countryCode !== "US") {
    return false;
  }

  return phoneNumberFormat;
};

export const getDateFormat = (activeOrderStore, allLocations) => {
  const countryCode = activeOrderStore ? activeOrderStore.country : allLocations[0].country;

  let dateFormat = {
    placeholder: "dd/mm/yyyy",
    maskPattern: "99/99/9999",
    displayMaskPattern: "99/99/9999",
    minLength: 10, // for validation when # of chars < pattern requirement
    title: "8-digit numeric birth date in the format of DD/MM/YYYY",
  };

  if (countryCode === "US") {
    dateFormat.placeholder = "mm/dd/yyyy";
    dateFormat.title = "8-digit numeric birth date in the format of MM/DD/YYYY";
  }

  return dateFormat;
};
