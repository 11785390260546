export const loginRegisterLabels = {
  "hello-there": "Olá",
  "welcome-to": "Bem-vindo a [merchant-name]",
  "all-in-one-solution": "Solução completa",
  register: "Registrar",
  "sign-in": "Fazer Login",
  "continue-as-guest": "Continuar como visitante",
  next: "Próximo",
  previous: "Anterior",
  "rewards-card-question": "Você possui um cartão de recompensas?",
  "rewards-card-question-blurb-2": "Insira o número do cartão",
  "no-rewards-card-want-one": "Não, mas eu gostaria de um",
  "have-rewards-card": "Sim e eu tenho o número do cartão!",
  "successful-registration-heading": "Registrado com sucesso",
  "successful-registration-paragraph":
    "Como membro da nossa família, você tem acesso a descontos e promoções exclusivas. Comece a acumular pontos e resgatar por bebidas e alimentos gratuitos. Faça valer a pena cada dólar gasto.",
  "user-already-exists-heading": "Já existe uma conta com esse endereço de e-mail",
  "user-already-exists-paragraph":
    "Tente fazer login com este endereço de e-mail e lembre-se que você sempre pode redefinir sua senha se esquecê-la.",
  "forgot-password": "Esqueci minha senha",
  "signing-in": "Logando...",
  "forgot-password-heading": "Esqueceu sua Senha?",
  "forgot-password-paragraph":
    "Confirme seu endereço de e-mail de login e enviaremos as instruções imediatamente.",
  "login-email": "E-mail de login",
  "reset-password": "Redefinir senha",
  "sending-password": "Enviando senha...",
  "temporary-login-paragraph": "Nós enviamos um e-mail, use a senha temporária para login.",
  "form-step-status": "Passo [current-step] de [total-steps]/Etapa [current-step] de [total-steps]",
  "dont-have-account": "Não tem uma conta",
};
