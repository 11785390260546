import React, { useContext, useState, useEffect } from "react";
import { Link, useLocation } from "react-router-dom";
import BrandingContext from "../BrandingContext";
import { AppFooterSubnav } from "./AppFooterSubnav";
import "./AppFooterItem.css";
import AppLabelsContext from "../AppLabelsContext";

export const AppFooterItem = (props) => {
  const {
    name,
    displayName,
    isLink,
    linkPathname,
    isActive,
    icon,
    subnavItems,
    ariaLabel,
    isAction,
    action,
  } = props.module;

  const appLabels = useContext(AppLabelsContext);
  const branding = useContext(BrandingContext);

  const [isSubnav, setIsSubnav] = useState(false);
  const handleTopLevelNavItemClick = () => {
    setIsSubnav(!isSubnav);
  };

  const handleOnClick = () => {
    action();
  };

  const settingsClass = name === "settings" ? " footer__nav-item--settings" : "";

  const activeClass = isActive ? " footer__nav-item--active-module" : "";

  /** Close the sub-navigation when user clicks on a subnav item and navigates away */
  const location = useLocation();
  useEffect(() => {
    setIsSubnav(false);
  }, [location]);

  return (
    <li className={`footer__nav-item${settingsClass}${activeClass}`}>
      {isLink ? (
        <Link
          to={linkPathname}
          className="footer__nav-link"
          style={{
            color: isActive
              ? branding["header-footer-active-text-colour"]
              : branding["footer-text-and-icon-colour"],
          }}
        >
          {icon}
          <span className="footer__nav-item-title">{displayName}</span>
        </Link>
      ) : (
        <>
          {isAction ? (
            <button
              onClick={handleOnClick}
              className={`button footer__nav-button${activeClass}`}
              style={{
                color: isActive
                  ? branding["header-footer-active-text-colour"]
                  : branding["footer-text-and-icon-colour"],
              }}
              data-name={name}
              aria-label={`${
                isSubnav ? appLabels["general"]["hide"] : appLabels["general"]["show"]
              } ${ariaLabel}`}
              aria-expanded={isSubnav}
            >
              {icon}
              <span className="footer__nav-item-title">{displayName}</span>
            </button>
          ) : (
            <button
              onClick={handleTopLevelNavItemClick}
              className={`button footer__nav-button${activeClass}`}
              style={{
                color: isActive
                  ? branding["header-footer-active-text-colour"]
                  : branding["footer-text-and-icon-colour"],
              }}
              data-name={name}
              aria-label={`${
                isSubnav ? appLabels["general"]["hide"] : appLabels["general"]["show"]
              } ${ariaLabel}`}
              aria-expanded={isSubnav}
            >
              {icon}
              <span className="footer__nav-item-title">{displayName}</span>
            </button>
          )}
        </>
      )}
      {isSubnav && (
        <AppFooterSubnav
          name={name}
          subnavItems={subnavItems}
          closeSubnav={() => setIsSubnav(false)}
        />
      )}
    </li>
  );
};
