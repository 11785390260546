export const loginRegisterLabels = {
  "hello-there": "Hello There",
  "welcome-to": "Welcome to [merchant-name]",
  "all-in-one-solution": "All-in-one solution",
  register: "Register",
  "sign-in": "Sign In",
  "continue-as-guest": "Continue as guest",
  next: "Next",
  previous: "Previous",
  "rewards-card-question": "Do you have a rewards card?",
  "rewards-card-question-blurb-2": "Enter the card number",
  "no-rewards-card-want-one": "No, but I want one",
  "have-rewards-card": "Yes and I have the card number!",
  "successful-registration-heading": "Successfully registered",
  "successful-registration-paragraph":
    "As a member of our family you have access to exclusive discounts, promotions. Start collecting points and redeem for free drinks & food. Make each dollar spent worth.",
  "user-already-exists-heading": "There is already an account with this email address",
  "user-already-exists-paragraph":
    "Try signing in with this email address and remember that you can always reset your password if you forgot yours.",
  "forgot-password": "Forgot Password",
  "signing-in": "Signing In...",
  "forgot-password-heading": "Forgot your password?",
  "forgot-password-paragraph":
    "Confirm your login email address and we will send your instructions right away.",
  "login-email": "Login Email",
  "reset-password": "Reset Password",
  "sending-password": "Sending Password...",
  "temporary-login-paragraph": "We have sent you an email, use the temporary password to login.",
  "form-step-status": "Step [current-step] of [total-steps]",
  "dont-have-account": "Don't have an account",
};
