export const loyaltyLabels = {
  rewards: "Recompensas",
  "start-collecting-now": "Comece a acumular agora!",
  "add-new-card-to-join":
    "Junte-se à nossa família e comece a acumular pontos. Acompanhe seu progresso e troque por bebidas, comidas gratuitas e muito mais.",
  "still-not-convinced": "Ainda não está convencido?",
  "check-out-how-rewards-work": "Confira como as recompensas funcionam",
  "check-out-how-rewards-work-extra-text":
    "E veja como você pode se beneficiar por ser nosso consumidor fiel",
  "how-rewards-work": "Como as Recompensas Funcionam",
  "add-a-new-card": "Adicionar um novo cartão",
  "register-now": "Registrar Agora",
  "my-rewards": "Minhas Recompensas",
  "ends-with": "Termina com",
  "reward-options": "Opções de Recompensas",
  "learn-how-it-works": "Entenda como funciona",
  "start-collecting-points-toward":
    "Comece a acumular pontos para bebidas e comidas gratuitas e mais.",
  "expiry-date": "Data de Validade",
  "bonus-rewards": "recompensas Bônus",
  "redeem-rewards": "Resgatar Recompensas",
  "online-order": "Online",
  "when-ordering-online":
    "Adicione quaisquer itens aplicáveis ao seu pedido. Quando estiver pronto para finalizar a compra, clique no botão “Usar Recompensas” em seu carrinho de compras e escolha qual recompensa quer resgatar.",
  "in-store": "Na Loja",
  "click-on-any-available-reward":
    "Em sua página inicial, clique no botão “Verificar na loja”, escaneie o código e informe ao caixa qual recompensa você deseja resgatar.",
  redeem: "Resgatar",
  "you-need-to-earn-more-points": "Você precisa ganhar mais pontos",
  "collect-points": "Acumular Pontos",
  "reward-redemption-confirmation":
    "Tem certeza que deseja resgatar [reward-name] a recompensa por [reward-cost]",
  "order-confirmation-check-my-rewards-link": "Consultar Minhas Recompensas",
  "register-today-and-start-collecting-points-message":
    "Cadastre-se hoje e comece a acumular pontos para bebidas e comidas gratuitas e mais.",
  "see-how-rewards-work-link": "Veja como as recompensas funcionam",
  "you-could-have-earned-points-message":
    "Você poderia ter acumulado [points] pontos de fidelidade neste pedido!",
  "confirm-rewards-redemption-message": "Você está prestes a resgatar a recompensa [reward-name]",
  "redeem-reward-associated-item-error":
    "Favor verificar se o item associado foi adicionado ao seu pedido antes de tentar resgatar este prêmio",
  "moving-applied-reward-on-card-switch-confirm-text":
    "A troca de cartões removerá qualquer recompensa anteriormente aplicada. Deseja continuar?",
  "available-rewards-subheading": "Recompensas Disponíveis",
  "add-associated-item-before-redeeming-reward-blurb":
    "Favor adicionar o item associado ao seu pedido antes de resgatar sua recompensa",
  "no-rewards-for-card": "Não há recompensas disponíveis para este cartão",
  "cart-change-reapply-rewards-message":
    "Uma ou mais recompensas aplicadas anteriormente se tornaram indisponíveis com a última modificação do carrinho. Favor aplicar novamente todos os prêmios que deseja resgatar.",
  "order-confirmation-points-issued":
    "Você ganhou [points][icon] pontos de fidelidade com este pedido!",
  "redeem-reward-success-message": "Resgatado com sucesso",
  "transaction-reference-number": "Número de referência da transação",
  "where-to-redeem-this-reward": "Onde você gostaria de resgatar esta recompensa",
  "was-added-to-card": "Foi adicionado ao [card-number]",
  "your-new-card-balance": "Seu novo saldo é: [card-balance]",
  "show-reward-item": "Mostrar recompensa de [reward-name]",
  "not-enough-points-for-redeeming":
    "Você não tem pontos suficientes para resgatar [reward-quantity] de [reward-name]",
  "reward-balance": "Saldo de Pontos",
  "rewards-remaining": "[reward-balance] Recompensas Restantes",
  "rewards-remaining-single": "[reward-balance] Recompensa Restante",
  "view-rewards": "Ver Recompensas",
};
