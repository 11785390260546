import React, { useContext, useRef, useEffect } from "react";
import localforage from "localforage";

/** Hooks */
import { useHistory, useLocation, useParams } from "react-router";

/** Helpers */
import { getSubcategoryList } from "../helpers/getMenuData";

/** Contexts */
import { MenuContext } from "../MenuContext";
import MerchantConfigContext from "../../../App/MerchantConfigContext";
import UserRoleContext from "../../../App/UserRoleContext";

/** UI Components */
import { MenuFavoritesCategoryFilterButton } from "./MenuFavoritesCategoryFilterButton";
import { MenuCategoryFilterButton } from "./MenuCategoryFilterButton";

/** CSS */
import "./MenuCategoryFilterButtonsList.css";

const MenuCategoryFilterButtons = () => {
  const orderMenu = useContext(MenuContext);
  const isThreeLevelMenu = orderMenu.menuLevel === "threeLevels";
  const isTwoLevelMenu = orderMenu.menuLevel === "twoLevels";

  const userRoleContext = useContext(UserRoleContext);
  const userStatus = userRoleContext.status;
  const isLoggedIn = userStatus === "logged-in";

  const history = useHistory();

  const { pathname } = useLocation();
  const { category, subcategory } = useParams();

  const { skin } = useContext(MerchantConfigContext);
  const navigateToCategory = (categoryId, parentCategoryId) => {
    localforage.removeItem(skin + "__itemSelectionInProgress");
    sessionStorage.removeItem(skin + "__orderStoreState");
    if (isThreeLevelMenu && parentCategoryId) {
      // Navigate to 3-level menu items view
      orderMenu.updateMenu({
        ...orderMenu,
        activeTopCategory: parentCategoryId,
        activeSubcategory: categoryId,
      });
      history.push(`/online-ordering/menu/category/${parentCategoryId}/subcategory/${categoryId}`);
      //
    } else if (isTwoLevelMenu) {
      // Navigate to 2-level menu items view
      orderMenu.updateMenu({
        ...orderMenu,
        activeTopCategory: null,
        activeSubcategory: categoryId,
      });
      history.push({
        pathname: `/online-ordering/menu/subcategory/${categoryId}`,
        state: { from: "category-button" },
      });
      //
    } else if (isThreeLevelMenu && !parentCategoryId) {
      const subcategoryList = getSubcategoryList(orderMenu, categoryId);

      // If there is only one subcategory under the category user is trying to navigate to skip to items view
      if (Object.entries(subcategoryList).length === 1) {
        const subcategoryId = Object.entries(subcategoryList)[0][1].id;

        orderMenu.updateMenu({
          ...orderMenu,
          activeTopCategory: parentCategoryId,
          activeSubcategory: subcategoryId,
          activeItem: null,
        });

        history.push(`/online-ordering/menu/category/${categoryId}/subcategory/${subcategoryId}`);
        //
      } else {
        // Navigate to categories view
        orderMenu.updateMenu({
          ...orderMenu,
          activeTopCategory: categoryId,
          activeSubcategory: null,
        });
        history.push(`/online-ordering/menu/category/${categoryId}`);
      }
    }
  };

  const categoriesData =
    category &&
    subcategory &&
    Object.keys(orderMenu.apiData[`category-${category}`].subcategories).length > 1
      ? orderMenu.apiData[`category-${category}`].subcategories
      : orderMenu.apiData;

  const isFavoritesRoute = useLocation().pathname === "/online-ordering/menu/favorites";

  const menuCategoryFilterButtonRef = useRef(null);

  useEffect(() => {
    setTimeout(() => {
      if (menuCategoryFilterButtonRef.current && !pathname.includes("combo")) {
        menuCategoryFilterButtonRef.current.scrollIntoView({ behavior: "smooth" });
      } else if (document.getElementsByClassName("progress-bar-container").length > 0) {
        document
          .getElementsByClassName("progress-bar-container")[0]
          .scrollIntoView({ behavior: "smooth" });
      }
    }, 250);
  }, [pathname]);

  return (
    <ul className="menu__category-buttons-list" ref={menuCategoryFilterButtonRef}>
      {isLoggedIn && <MenuFavoritesCategoryFilterButton isActive={isFavoritesRoute} />}
      {categoriesData &&
        Object.keys(categoriesData).length > 0 &&
        Object.keys(categoriesData).map((key, index) => (
          <React.Fragment key={`${key}-${index}`}>
            {categoriesData[key].noOfItems > 0 && (
              <MenuCategoryFilterButton
                categoryData={categoriesData[key]}
                handleClick={navigateToCategory}
                isActive={
                  subcategory === categoriesData[key].id || category === categoriesData[key].id
                }
              />
            )}
          </React.Fragment>
        ))}
    </ul>
  );
};

export const MenuCategoryFilterButtonsList = React.memo(MenuCategoryFilterButtons);
