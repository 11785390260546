import React, { useContext, useState, useEffect } from "react";
import { useHistory } from "react-router-dom";

//Hooks
import useWindowSize from "../_common/hooks/useWindowSize";

//Assets
import { ReactComponent as IconUtensils } from "../_common/icons/IconUtensils.svg";
import { ReactComponent as IconHeart } from "../_common/icons/IconHeart.svg";
import { ReactComponent as IconDocumentPen } from "../_common/icons/IconDocumentPen.svg";
import { ReactComponent as IconStar } from "../_common/icons/IconStar.svg";
import { ReactComponent as IconArrowDown } from "../_common/icons/IconArrowDown.svg";

//Helpers
import { getAPICardList } from "../OnlineOrdering/Payment/PaymentMethod/PaymentMethodGiftCard/apiHelpers/getAPICardList";
import { getContrastColor } from "../_common/colorHelpers";

// UI Components
import { DialogModal } from "../_common/DialogModal/DialogModal";
import { DashboardRewards } from "./DashboardRewards/DashboardRewards";
import { LoadingSpinner } from "../_common/LoadingSpinner";

//Contexts
import OrderContext from "../App/OrderContext";
import OrderTypeContext from "../OnlineOrdering/OrderTypeContext";
import StoreContext from "../OnlineOrdering/StoreContext";
import OrderTimeContext from "../OnlineOrdering/OrderTimeContext";
import AppLabelsContext from "../App/AppLabelsContext";
import MerchantConfigContext from "../App/MerchantConfigContext";
import UserRoleContext from "../App/UserRoleContext";
import LoyaltyContext from "../App/LoyaltyContext";
import AppSettingsContext from "../App/AppSettingsContext";
import AppLanguageContext from "../App/AppLanguageContext";
import BrandingContext from "../App/BrandingContext";

export const DashboardOrderButtons = (props) => {
  const { rewardContainerTopPosition } = props;
  const history = useHistory();
  const appLabels = useContext(AppLabelsContext);
  const orderContext = useContext(OrderContext);
  const orderType = useContext(OrderTypeContext).value;
  const storeContext = useContext(StoreContext);
  const orderTimeContext = useContext(OrderTimeContext);
  const userRoleContext = useContext(UserRoleContext);
  const loyaltyContext = useContext(LoyaltyContext);
  const appSettings = useContext(AppSettingsContext);
  const appLanguage = useContext(AppLanguageContext);
  const branding = useContext(BrandingContext);
  const merchantConfigContext = useContext(MerchantConfigContext);
  const skin = merchantConfigContext.skin;
  const merchantVexilorConfig = merchantConfigContext.vexilor.I2;
  const activeOrderStore = storeContext.activeOrderStore;

  const isLoggedIn = userRoleContext.status === "logged-in";
  const loginToken = userRoleContext.loginToken;

  const isOrderStatusEnabled = merchantVexilorConfig["order_status_access"] === "t";

  const [isGuestAttempt, setIsGuestAttempt] = useState(false);
  const [guestAttemptMessage, setGuestAttemptMessage] = useState("");

  const handleOnFavoriteClick = () => {
    if (isLoggedIn) {
      history.push("/online-ordering/menu/favorites");
    } else {
      sessionStorage.setItem(skin + "__lastVisitedLink", "/online-ordering/menu/favorites");
      setGuestAttemptMessage(appLabels["order"]["guest-attempt-favorite-item-dashboard"]);
      setIsGuestAttempt(true);
    }
  };

  const handleOnRecentClick = () => {
    if (isLoggedIn) {
      if (isOrderStatusEnabled) {
        history.push("/online-ordering/recent-orders/active-orders");
      } else {
        history.push("/online-ordering/recent-orders/past-orders");
      }
    } else {
      sessionStorage.setItem(
        skin + "__lastVisitedLink",
        "/online-ordering/recent-orders/past-orders"
      );
      setGuestAttemptMessage(appLabels["order"]["recent-order-guest-attempt"]);
      setIsGuestAttempt(true);
    }
  };

  const deviceWidth = useWindowSize().width;

  const [isShowingRewardDetails, setIsShowingRewardDetails] = useState(false);
  const handleShowingRewardDetails = () => {
    setIsShowingRewardDetails(!isShowingRewardDetails);
  };

  const [userGiftCards, setUserGiftCards] = useState(null);
  useEffect(() => {
    if (loginToken && isShowingRewardDetails) {
      getAPICardList(
        skin,
        loginToken,
        appSettings["currency-symbol"],
        appSettings["currency-symbol-side"],
        appLanguage,
        branding
      ).then((fetchedData) => {
        if (fetchedData) {
          if (fetchedData.length > 0) {
            setUserGiftCards(fetchedData);
          } else if (fetchedData.status === "expiredLoginToken") {
            userRoleContext.handleLoginTokenExpiration();
          }
        }
      });
    } else {
      setUserGiftCards(null);
    }
  }, [loginToken, isShowingRewardDetails]);

  return (
    <>
      <div className="dashboard-order-buttons-container">
        {/** Desktop Dashboard Order buttons */}
        {deviceWidth >= 660 && (
          <div className="dashboard-order-buttons__row">
            <button
              aria-label={appLabels["general"]["order-now"]}
              onClick={() => {
                orderContext.checkOrderSettings(orderType, storeContext, orderTimeContext);
              }}
              className="dashboard-order-buttons__order-now-container"
              id="dashboard-order-now-button"
            >
              <div className="dashboard-order-buttons__inner-row">
                <div className="dahsboard-order-buttons__order-button-icon-container order-now">
                  <IconUtensils aria-hidden="true" />
                </div>
                <div className="dashboard-order-buttons__column order-now">
                  <span>{appLabels["general"]["order-now"]}</span>
                  <span>{appLabels["order"]["view-full-menu"]}</span>
                </div>
              </div>
            </button>

            {loyaltyContext.isEnabled && isLoggedIn && (
              <div
                className={`dashboard-order-buttons__fav-container dashboard-order-buttons__column favorite ${
                  isShowingRewardDetails ? " expanded-rewards-details" : ""
                }`}
                id="dashboard-rewards-button"
              >
                <div className="dashboard-order-buttons__inner-row">
                  <div className="dahsboard-order-buttons__order-button-icon-container">
                    <IconStar aria-hidden="true" />
                  </div>
                  <div className="dashboard-order-buttons__column">
                    {appLabels["loyalty"]["my-rewards"]}
                  </div>
                  <div className="dashboard-order-buttons__column reward-arrow">
                    {isShowingRewardDetails ? (
                      <IconArrowDown aria-hidden="true" style={{ transform: "rotate(180deg)" }} />
                    ) : (
                      <IconArrowDown aria-hidden="true" />
                    )}
                  </div>
                  <button
                    aria-label={appLabels["loyalty"]["my-rewards"]}
                    onClick={handleShowingRewardDetails}
                    style={{ width: "100%", height: "100%", position: "absolute", top: 0, left: 0 }}
                  ></button>
                </div>
                {loyaltyContext.isEnabled && isLoggedIn && isShowingRewardDetails && (
                  <div
                    className="user-greetings__rewards-container"
                    style={{
                      width: document.getElementById("dashboard-rewards-button")
                        ? document
                            .getElementById("dashboard-rewards-button")
                            .getBoundingClientRect().width + "px"
                        : "unset",
                      position: "fixed",
                      left: document.getElementById("dashboard-rewards-button")
                        ? document
                            .getElementById("dashboard-rewards-button")
                            .getBoundingClientRect().left + "px"
                        : "unset",
                      zIndex: 2,
                      marginTop: "-10px",
                      top: rewardContainerTopPosition + "px",
                    }}
                  >
                    {" "}
                    {userGiftCards ? (
                      <DashboardRewards userGiftCards={userGiftCards} />
                    ) : (
                      <LoadingSpinner customClass={"user-greetings__rewards-container-loader"} />
                    )}
                  </div>
                )}
              </div>
            )}

            {activeOrderStore && (
              <button
                aria-label={appLabels["order"]["favorites"]}
                onClick={handleOnFavoriteClick}
                className="dashboard-order-buttons__fav-container dashboard-order-buttons__column favorite"
              >
                <div className="dashboard-order-buttons__inner-row">
                  <div className="dahsboard-order-buttons__order-button-icon-container">
                    <IconHeart aria-hidden="true" stroke="#FC8181" fill={"#FC8181"} />
                  </div>
                  <div className="dashboard-order-buttons__column">
                    {appLabels["order"]["favorites"]}
                  </div>
                </div>
              </button>
            )}
            <button
              aria-label={appLabels["order"]["recent-orders"]}
              onClick={handleOnRecentClick}
              className="dashboard-order-buttons__recent-container dashboard-order-buttons__column favorite"
            >
              <div className="dashboard-order-buttons__inner-row">
                <div className="dahsboard-order-buttons__order-button-icon-container">
                  <IconDocumentPen
                    aria-hidden="true"
                    style={{ fill: getContrastColor("#eeeeee") }}
                  />
                </div>
                <div className="dashboard-order-buttons__column">
                  {appLabels["order"]["recent-orders"]}
                </div>
              </div>
            </button>
          </div>
        )}

        {deviceWidth < 600 && (
          <>
            <div className="dashboard-order-buttons__row order-now">
              <button
                aria-label={appLabels["general"]["order-now"]}
                onClick={() => {
                  orderContext.checkOrderSettings(orderType, storeContext, orderTimeContext);
                }}
                id="dashboard-order-now-button"
              >
                <div className="dashboard-order-buttons__inner-row">
                  <div className="dahsboard-order-buttons__order-button-icon-container">
                    <IconUtensils aria-hidden="true" />
                  </div>
                  <div className="dashboard-order-buttons__column order-now">
                    <span>{appLabels["general"]["order-now"]}</span>
                    <span>{appLabels["order"]["view-full-menu"]}</span>
                  </div>
                </div>
              </button>
            </div>

            <div className="dashboard-order-buttons__row">
              {activeOrderStore && (
                <button
                  aria-label={appLabels["order"]["favorites"]}
                  onClick={handleOnFavoriteClick}
                  className="dashboard-order-buttons__fav-container dashboard-order-buttons__column favorite"
                  style={{
                    maxHeight: document.getElementById("dashboard-order-now-button")
                      ? document
                          .getElementById("dashboard-order-now-button")
                          .getBoundingClientRect().height + "px"
                      : "57px",
                    minHeight: document.getElementById("dashboard-order-now-button")
                      ? document
                          .getElementById("dashboard-order-now-button")
                          .getBoundingClientRect().height + "px"
                      : "57px",
                  }}
                >
                  <IconHeart aria-hidden="true" stroke="#FC8181" fill={"#FC8181"} />
                  {appLabels["order"]["favorites"]}
                </button>
              )}

              {activeOrderStore ? (
                <button
                  aria-label={appLabels["order"]["recent-orders"]}
                  onClick={handleOnRecentClick}
                  className="dashboard-order-buttons__recent-container dashboard-order-buttons__column favorite"
                  style={{
                    minWidth: activeOrderStore ? "48%" : "100%",
                    maxHeight: document.getElementById("dashboard-order-now-button")
                      ? document
                          .getElementById("dashboard-order-now-button")
                          .getBoundingClientRect().height + "px"
                      : "57px",
                    minHeight: document.getElementById("dashboard-order-now-button")
                      ? document
                          .getElementById("dashboard-order-now-button")
                          .getBoundingClientRect().height + "px"
                      : "57px",
                  }}
                >
                  <IconDocumentPen aria-hidden="true" />
                  {appLabels["order"]["recent-orders"]}
                </button>
              ) : (
                /** Recent Order button has a different style on mobile when there is not favorite button rendered */
                <button
                  aria-label={appLabels["order"]["recent-orders"]}
                  onClick={handleOnRecentClick}
                  className="dashboard-order-buttons__recent-container favorite"
                  style={{
                    maxHeight: document.getElementById("dashboard-order-now-button")
                      ? document
                          .getElementById("dashboard-order-now-button")
                          .getBoundingClientRect().height + "px"
                      : "57px",
                    minHeight: document.getElementById("dashboard-order-now-button")
                      ? document
                          .getElementById("dashboard-order-now-button")
                          .getBoundingClientRect().height + "px"
                      : "57px",
                    width: "100%",
                    padding: 0,
                  }}
                >
                  <div
                    className="dashboard-order-buttons__inner-row"
                    style={{ padding: 0, height: "100%", display: "flex", alignItems: "center" }}
                  >
                    <div
                      className="dahsboard-order-buttons__order-button-icon-container"
                      style={{ backgroundColor: "#eeeeee", height: "100%" }}
                    >
                      <IconDocumentPen
                        aria-hidden="true"
                        style={{ fill: getContrastColor("#eeeeee") }}
                      />
                    </div>
                    <div
                      className="dashboard-order-buttons__column"
                      style={{ paddingLeft: "20px", fontSize: "16px", fontWeight: "600" }}
                    >
                      {appLabels["order"]["recent-orders"]}
                    </div>
                  </div>
                </button>
              )}
            </div>
          </>
        )}
      </div>

      {isGuestAttempt && (
        <DialogModal
          isConfirmText={false}
          hideConfirmButton={true}
          description={guestAttemptMessage}
          message={
            <div className="menu-item__favourite-dialog-modal">
              <div>
                <p style={{ textAlign: "center" }}>{guestAttemptMessage}</p>
              </div>
              <div className="menu-item__favourite-dialog-modal-buttons-wrapper">
                <button
                  onClick={() => {
                    history.push("/login-register");
                  }}
                  className="button dialog-modal__button  button--primary button--primary-small"
                  type="button"
                >
                  {appLabels["login-register"]["sign-in"]}
                </button>

                <button
                  onClick={() => {
                    history.push("/register");
                  }}
                  className="button dialog-modal__button  button--primary button--primary-small"
                  type="button"
                >
                  {appLabels["login-register"]["register"]}
                </button>
              </div>
            </div>
          }
          resetRemoveDialog={() => {
            setIsGuestAttempt(false);
            sessionStorage.removeItem(skin + "__lastVisitedLink");
          }}
          isHTMLContent={true}
        />
      )}
    </>
  );
};
