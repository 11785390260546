//Libraries
import React, { useContext, useEffect, useRef, useState } from "react";
import { Link, useHistory } from "react-router-dom";

//Hooks
import useWindowSize from "../../_common/hooks/useWindowSize";

//Helpers
import { isTouchDevice } from "../../_common/helpers/isTouchDevice";

//UI Elements
import { DashboardImageAndTextCard } from "./DashboardImageAndTextCard";
import { ReactComponent as IconArrowLeft } from "../../_common/icons/IconArrowLeft.svg";
import { ReactComponent as IconArrowRight } from "../../_common/icons/IconArrowRight.svg";

//Contexts
import { MenuContext } from "../../OnlineOrdering/Menu/MenuContext";
import OrderTypeContext from "../../OnlineOrdering/OrderTypeContext";
import StoreContext from "../../OnlineOrdering/StoreContext";
import OrderTimeContext from "../../OnlineOrdering/OrderTimeContext";
import OrderContext from "../../App/OrderContext";
import MerchantConfigContext from "../../App/MerchantConfigContext";

//CSS
import "./DashboardCardsSlider.css";

const SLIDER_RIGHT_PADDING = 24;
const MARGIN_BETWEEN_SLIDES = 21;
const ARROW_BUTTON_WIDTH = 60;

export const DashboardCardsSlider = ({ slidesData, featuredCategoryData, disableImageLink }) => {
  const listRef = useRef(null);

  const currentTransformX = useRef(0);
  const [totalSlideGroups, setTotalSlideGroups] = useState(0);

  const [numberOfSlidesInView, setNumberOfSlidesInView] = useState(1);
  const [inViewSlidesGroupIndex, setInViewSlidesGroupIndex] = useState(0);

  const [isLeftScrollPossible, setIsLeftScrollPossible] = useState(false);
  const [isRightScrollPossible, setIsRightScrollPossible] = useState(false);

  useEffect(() => {
    if (listRef.current) {
      const list = listRef.current;
      const listWidth = list.getBoundingClientRect().width;

      if (list.scrollWidth - SLIDER_RIGHT_PADDING > listWidth) {
        setIsRightScrollPossible(true);
      }
    }
  }, []);

  const deviceWidth = useWindowSize().width;
  useEffect(() => {
    if (deviceWidth >= 660 && deviceWidth < 1024) setNumberOfSlidesInView(2);
    else if (deviceWidth >= 1024) setNumberOfSlidesInView(3);
    else if (deviceWidth < 660) setNumberOfSlidesInView(1);
  }, [deviceWidth]);

  useEffect(() => {
    if (listRef.current) {
      const list = listRef.current;
      const listWidth = list.getBoundingClientRect().width;
      const numberOfSlides = slidesData.length;
      const listItemWidth = list.firstChild.getBoundingClientRect().width;

      let totalSlideGroups = Math.ceil((listItemWidth * numberOfSlides) / listWidth);
      if (totalSlideGroups === 2 && numberOfSlides === 7) {
        totalSlideGroups = 3;
      }

      setTotalSlideGroups(totalSlideGroups);
    }
  }, [numberOfSlidesInView]);

  const scrollLeft = () => {
    const list = listRef.current;
    const allInViewSlidesWidth =
      (list.firstChild.getBoundingClientRect().width + MARGIN_BETWEEN_SLIDES) *
      numberOfSlidesInView;

    list.style.transform =
      inViewSlidesGroupIndex - 1 === 0
        ? "translateX(0)"
        : `translateX(${currentTransformX.current + allInViewSlidesWidth}px)`;

    currentTransformX.current =
      inViewSlidesGroupIndex - 1 === 0
        ? 0
        : currentTransformX.current + allInViewSlidesWidth - ARROW_BUTTON_WIDTH;

    const prevSlidesGroupIndex = inViewSlidesGroupIndex - 1;
    if (prevSlidesGroupIndex === 0) {
      setIsLeftScrollPossible(false);
    }
    setIsRightScrollPossible(true);
    setInViewSlidesGroupIndex((prevIndex) => prevIndex - 1);
  };

  const scrollRight = () => {
    const list = listRef.current;
    const allInViewSlidesWidth =
      (list.firstChild.getBoundingClientRect().width + MARGIN_BETWEEN_SLIDES) *
      numberOfSlidesInView;

    list.style.transform = `translateX(${
      currentTransformX.current -
      (inViewSlidesGroupIndex === 0
        ? allInViewSlidesWidth - ARROW_BUTTON_WIDTH
        : allInViewSlidesWidth)
    }px)`;

    currentTransformX.current =
      currentTransformX.current -
      (inViewSlidesGroupIndex === 0
        ? allInViewSlidesWidth - ARROW_BUTTON_WIDTH
        : allInViewSlidesWidth);

    if (!isLeftScrollPossible) setIsLeftScrollPossible(true);

    const nextSlidesGroupIndex = inViewSlidesGroupIndex + 1;
    if (nextSlidesGroupIndex === totalSlideGroups - 1) {
      setIsRightScrollPossible(false);
    }
    setInViewSlidesGroupIndex((prevIndex) => prevIndex + 1);
  };

  return (
    <div className="dashboard-section__body">
      <div className="dashboard__horizontal-slider-wrapper">
        {!isTouchDevice() && isLeftScrollPossible && (
          <SliderScrollButton
            handleClick={scrollLeft}
            actionDescription="Scroll to the previous group of slides"
            icon={<IconArrowLeft aria-hidden="true" style={{ width: "30px" }} />}
            direction="previous"
          />
        )}
        <ul
          ref={listRef}
          className={`dashboard-horizontal-slider__list dashboard-horizontal-slider__list--${numberOfSlidesInView}-in-view ${
            featuredCategoryData ? " featured-slider" : ""
          }`}
        >
          {slidesData.map((slideData) => (
            <React.Fragment key={slideData.id}>
              {slideData.type === "productItem" || slideData.type === "comboItem" ? (
                <DashboardImageAndTextCard
                  productItem={slideData}
                  featuredCategoryData={featuredCategoryData}
                />
              ) : (
                <>
                  {slideData["image-link"] && !disableImageLink ? (
                    <LinkedImageCard slideData={slideData} />
                  ) : (
                    <StaticImageCard slideData={slideData} />
                  )}
                </>
              )}
            </React.Fragment>
          ))}
          <li style={{ width: `${SLIDER_RIGHT_PADDING}px` }}>&nbsp;</li>
        </ul>
        {!isTouchDevice() &&
          listRef.current &&
          listRef.current.scrollWidth - SLIDER_RIGHT_PADDING >
            listRef.current.getBoundingClientRect().width &&
          isRightScrollPossible && (
            <SliderScrollButton
              handleClick={scrollRight}
              actionDescription="Scroll to the next group of slides"
              icon={<IconArrowRight aria-hidden="true" />}
              direction="next"
            />
          )}
      </div>
      {!isTouchDevice() &&
        listRef.current &&
        listRef.current.scrollWidth - SLIDER_RIGHT_PADDING >
          listRef.current.getBoundingClientRect().width &&
        totalSlideGroups > 1 && (
          <div className="horizontal-slider__status-bar">
            {Array.from({ length: totalSlideGroups }, (unusedValue, index) => (
              <span
                key={index}
                className="horizontal-slider-status-bar__step"
                data-is-active={inViewSlidesGroupIndex === index}
              />
            ))}
          </div>
        )}
    </div>
  );
};

const LinkedImageCard = ({ slideData }) => {
  const orderMenu = useContext(MenuContext);
  const history = useHistory();

  const orderType = useContext(OrderTypeContext);
  const storeContext = useContext(StoreContext);
  const orderTimeContext = useContext(OrderTimeContext);
  const orderContext = useContext(OrderContext);
  const merchantConfig = useContext(MerchantConfigContext);
  const skin = merchantConfig.skin;

  function goToMenuItem() {
    /** Check the order settings before trying to redirect to the menu  */
    if (!orderType || !orderType.value) {
      sessionStorage.setItem(skin + "__featuredItemLink", slideData["image-link"]);
      history.push("/online-ordering/order-type");
    }
    //if order store is not selected redirect to order stores screen
    else if (!storeContext.activeOrderStore) {
      sessionStorage.setItem(skin + "__featuredItemLink", slideData["image-link"]);
      history.push("/online-ordering/order-store");
    } else if (
      orderTimeContext.value === null ||
      (orderTimeContext.value && orderTimeContext.value.value === null)
    ) {
      //if order time is not selected
      orderContext.openOrderTimePopupWidget(orderTimeContext);
    } else {
      //else redirect to menu
      const slideMenuPath = slideData["image-link"];

      const categoryFromPath =
        slideMenuPath.split("/category/")[1] &&
        slideMenuPath.split("/category/")[1].split("/item/")[0];
      const subcategoryFromPath =
        slideMenuPath.split("/subcategory/")[1] &&
        slideMenuPath.split("/subcategory/")[1].split("/item/")[0];

      orderMenu.updateMenu({
        ...orderMenu,
        activeTopCategory: categoryFromPath,
        activeSubcategory: subcategoryFromPath,
        activeItem: slideMenuPath.split("/item/")[1],
        activeComboSubItem: slideMenuPath.split("/combo/")[1],
      });

      history.push({
        pathname: slideData["image-link"],
        state: { from: "dashboard-card-slider" },
      });
    }
  }

  return (
    <li className="dashboard-horizontal-slider__card">
      {slideData.isExternalLink ? (
        <a
          href={slideData["image-link"]}
          target="_blank"
          rel="noopener noreferrer"
          className="dashboard__new-order-item-img-wrapper"
          aria-label={slideData["image-alt"]}
        >
          <CardImage slideData={slideData} />
        </a>
      ) : (
        <>
          {slideData["image-link"].includes("/online-ordering/menu/category/") ||
          slideData["image-link"].includes("/online-ordering/menu/subcategory/") ||
          slideData["image-link"].includes("/online-ordering/menu") ? (
            <>
              <button
                onClick={goToMenuItem}
                className="dashboard__slide-button"
                type="button"
                aria-label={slideData["image-alt"]}
              >
                <CardImage slideData={slideData} />
              </button>
            </>
          ) : (
            <Link
              to={slideData["image-link"]}
              aria-label={slideData["image-alt"]}
              className="dashboard__new-order-item-img-wrapper"
            >
              <CardImage slideData={slideData} />
            </Link>
          )}
        </>
      )}
    </li>
  );
};

const StaticImageCard = ({ slideData }) => {
  return (
    <li className="dashboard-horizontal-slider__card">
      <div className="dashboard__new-order-item-img-wrapper">
        <CardImage slideData={slideData} />
      </div>
    </li>
  );
};

const CardImage = ({ slideData }) => {
  return (
    <img
      src={slideData["image-src"]}
      alt={slideData["image-alt"]}
      className="dashboard__new-order-item-img"
    />
  );
};

export const SliderScrollButton = ({ handleClick, actionDescription, icon, direction }) => {
  return (
    <button
      onClick={handleClick}
      className={`dashboard__horizontal-slider-button dashboard__horizontal-slider-${direction}-button`}
      style={{ width: `${ARROW_BUTTON_WIDTH}px` }}
      type="button"
    >
      <span className="visually-hidden">{actionDescription}</span>
      {icon}
    </button>
  );
};
