import React, { useContext, useState, useEffect } from "react";
import localforage from "localforage";

import {
  formatOrderType,
  get,
  getDeviceTypeId,
  getTimestamp,
  toDollars,
} from "../../../_common/helpers";

import { callAPI } from "../../../_common/Api";
import { CallApi1307 } from "../CallApi1307";

import AppSettingsContext from "../../../App/AppSettingsContext";
import AppLanguageContext from "../../../App/AppLanguageContext";
import MerchantConfigContext from "../../../App/MerchantConfigContext";
import OrderTimeContext from "../../OrderTimeContext";
import AppLabelsContext from "../../../App/AppLabelsContext";
import OrderTypeContext from "../../OrderTypeContext";

import { Form } from "../../../_common/Form/Form";
import { FormInput } from "../../../_common/Form/FormInput";
import { ReactComponent as IconXInCircle } from "../../../_common/icons/IconXInCircle.svg";
import { DialogModal } from "../../../_common/DialogModal/DialogModal";
import { AdditionalDisclaimer } from "../../../_common/AdditionalDisclaimer";

import "./PromoCouponForm.css";

export default function PromoCouponForm(props) {
  const merchantConfig = useContext(MerchantConfigContext);

  const [isPromoAPISubmitValid, setPromoAPISubmitValid] = useState(null);
  const [promoAPIError, setPromoAPIError] = useState("");
  const [isPromoAPIsubmitting, setPromoAPIsubmitting] = useState(false);
  const [appliedPromo, setAppliedPromo] = useState(null);

  const { storedPromoCode, storedCoupons, setCoupon } = { ...props };

  const appLabels = useContext(AppLabelsContext);
  const orderTypeContext = useContext(OrderTypeContext);
  const activeOrderType = orderTypeContext.value;

  // Display stored applied promo
  useEffect(() => {
    if (storedPromoCode) {
      applyPromoCode([{ value: storedPromoCode }]);
      setShowPromoForm(false);
    } else {
      setShowPromoForm(true);
    }
  }, [storedPromoCode]);

  const orderTimeContext = useContext(OrderTimeContext);

  const applyPromoCode = (data) => {
    setPromoAPIsubmitting(true);

    const promoCode = data[0].value.trim();
    const formattedOrderType = formatOrderType(activeOrderType);
    const isStadiumSchema = merchantConfig.merchant.I55 === "stadium";

    CallApi1307(
      props.skin,
      props.formattedCart,
      promoCode,
      formattedOrderType,
      props.currentLocation,
      props.storedRewards,
      appLanguage,
      orderTimeContext.value && orderTimeContext.value.value !== "Select Time"
        ? getTimestamp(orderTimeContext.value.value)
        : "",
      getDeviceTypeId(activeOrderType),
      isStadiumSchema
    ).then((data1307) => {
      setPromoAPIsubmitting(false);

      if (data1307.result.I0) {
        setPromoAPISubmitValid(true);

        //loop through returned Items - if there is a "discounts" array without
        //a "reward_id" - then this discount came from the promo
        const apiItems = get(data1307, "result.I2.items");

        let promoDiscount = 0;

        if (apiItems) {
          apiItems.forEach((apiItem) => {
            if (get(apiItem, "discounts.length")) {
              apiItem.discounts.forEach((discount) => {
                if (!discount.reward_id) promoDiscount += parseFloat(discount.discount_amount);
              });
            }
            //loop through the addons and check for discounts
            if (apiItem.addonGroups && apiItem.addonGroups.length > 0) {
              apiItem.addonGroups.forEach((addonGroup) => {
                addonGroup.items.forEach((addonItem) => {
                  if (get(addonItem, "discounts.length")) {
                    addonItem.discounts.forEach((discount) => {
                      if (!discount.reward_id)
                        promoDiscount += parseFloat(discount.discount_amount);
                    });
                  }
                });
              });
            }
          });
        }

        if (parseFloat(promoDiscount) > 0) {
          setAppliedPromo({ code: promoCode, discount: promoDiscount });
        } else {
          setPromoAPISubmitValid(false);
          setPromoAPIError(appLabels["order"]["invalid-discount-coupon-error"]);
        }
      } else {
        setPromoAPISubmitValid(false);
        setPromoAPIError(data1307.result.message);
      }
    });
  };

  const [showRemoveDialog, setShowRemoveDialog] = useState(null);
  const [showCouponRemoveDialog, setShowCouponRemoveDialog] = useState(null);
  const [showPromoForm, setShowPromoForm] = useState(null);
  const [showCouponForm, setShowCouponForm] = useState(null);

  const confirmRemovePromo = () => {
    localforage.removeItem(merchantConfig.skin + "__storedPromoCode");

    setAppliedPromo(null);
    setShowRemoveDialog(false);
    setShowCouponRemoveDialog(false);
    props.setStoredPromoCode(null);
    props.setOrderSummaryData(null);
    setShowPromoForm(true);
  };

  const confirmRemoveCoupon = () => {
    localforage.removeItem(merchantConfig.skin + "__coupon");

    setAppliedCoupon(null);
    setShowRemoveDialog(false);
    setShowCouponRemoveDialog(false);
    setCoupon(null);
    props.setOrderSummaryData(null);
    setShowCouponForm(true);
  };

  const [isCouponAPISubmitValid, setCouponAPISubmitValid] = useState(null);
  const [couponApiError, setCouponAPIError] = useState("");
  const [isCouponAPIsubmitting, setCouponAPIsubmitting] = useState(false);
  const [appliedCoupon, setAppliedCoupon] = useState(null);

  const applyCouponCode = (data) => {
    setCouponAPIsubmitting(true);
    const couponCode = data[0].value.trim();

    callAPI(props.skin, "dc_994", {
      params: [appLanguage, "994", "mqid", "mqpass", couponCode, "", ""],
      id: "994",
    }).then((data994) => {
      setCouponAPIsubmitting(false);

      /** I18 in API 994 response will contain the order type, for coupons this field should be 1,
       * Otherwise it is not a valid coupon number, and it could be gift card number instead
       */
      if (data994.result.I0 && data994.result.I18 === "1" && parseFloat(data994.result.I2) > 0) {
        setCouponAPISubmitValid(true);
        const couponBalance = parseFloat(data994.result.I2);
        setAppliedCoupon({ code: couponCode, balance: couponBalance });
      } else {
        setCouponAPISubmitValid(false);
        setCouponAPIError(
          data994.result.message
            ? data994.result.message
            : appLabels["order"]["invalid-coupon-number"]
        );
      }
    });
  };

  const applyCodesToOrder = () => {
    let promoCode = "";

    if (appliedPromo) {
      promoCode = appliedPromo.code;
      props.setStoredPromoCode(promoCode);
      localforage.setItem(merchantConfig.skin + "__storedPromoCode", promoCode);
    }

    if (appliedCoupon) {
      props.setCoupon(appliedCoupon);
      localforage.setItem(merchantConfig.skin + "__coupon", appliedCoupon);
    }

    props.updateOrderSummary(promoCode, "", "", appliedCoupon);
    props.closeModal();
  };

  const isApplyButtonDisabled = !appliedPromo && !appliedCoupon;

  //Check for stored coupons
  useEffect(() => {
    if (!!storedCoupons) {
      setAppliedCoupon(storedCoupons);
      setShowCouponForm(false);
    } else {
      if (!props.isCouponEnabled) {
        setShowCouponForm(false);
      } else {
        setShowCouponForm(true);
      }
    }
  }, [storedCoupons, props.isCouponEnabled]);

  const appSettings = useContext(AppSettingsContext);
  const appLanguage = useContext(AppLanguageContext);

  return (
    <div className="promo-form__wrapper elevation--mid">
      {!!showPromoForm && (
        <Form
          id="form--promo"
          submitForm={applyPromoCode}
          submitAPIError={promoAPIError}
          resetAPIError={() => setPromoAPIError("")}
          isAPISubmitValid={isPromoAPISubmitValid}
          isAPIsubmitting={isPromoAPIsubmitting}
          isDisableSubmit={isPromoAPIsubmitting || promoAPIError}
          isSubmitResettable={true}
          submitButtonText={appLabels["order"]["check-discount"]}
          submitButtonLoadingText="Checking..."
          submitButtonCustomClass={
            " button--primary button--primary-small button--primary-with-border button--pill-shaped"
          }
        >
          <FormInput
            type="text"
            label={appLabels["order"]["discount-code"]}
            id="input--promo"
            name="promo-code"
            isRequired={true}
          />
          {!!appliedPromo && (
            <PromoOutput
              appliedPromo={appliedPromo}
              showRemoveDialog={showRemoveDialog}
              showCouponRemoveDialog={showCouponRemoveDialog}
              setShowRemoveDialog={setShowRemoveDialog}
              setShowCouponRemoveDialog={setShowCouponRemoveDialog}
              confirmRemovePromo={confirmRemovePromo}
              confirmRemoveCoupon={confirmRemoveCoupon}
              currencySymbol={appSettings["currency-symbol"]}
              currencySymbolSide={appSettings["currency-symbol-side"]}
              language={appLanguage}
            />
          )}
        </Form>
      )}
      {showPromoForm !== null && !showPromoForm && !!appliedPromo && (
        <>
          <h4>{appLabels["order"]["applied-discount-code"]}:</h4>
          <PromoOutput
            storedPromoCode={storedPromoCode}
            appliedPromo={appliedPromo}
            showRemoveDialog={showRemoveDialog}
            showCouponRemoveDialog={showCouponRemoveDialog}
            setShowRemoveDialog={setShowRemoveDialog}
            setShowCouponRemoveDialog={setShowCouponRemoveDialog}
            confirmRemovePromo={confirmRemovePromo}
            confirmRemoveCoupon={confirmRemoveCoupon}
            currencySymbol={appSettings["currency-symbol"]}
            currencySymbolSide={appSettings["currency-symbol-side"]}
            language={appLanguage}
          />
        </>
      )}

      <AdditionalDisclaimer
        disclaimer={appLabels["order"]["discount-disclaimer"]}
        styleObject={{ marginBottom: "1em", marginTop: "-5px" }}
      />

      {!!showCouponForm && (
        <Form
          id="form--coupon"
          submitForm={applyCouponCode}
          submitAPIError={couponApiError}
          resetAPIError={() => setCouponAPIError("")}
          isAPISubmitValid={isCouponAPISubmitValid}
          isAPIsubmitting={isCouponAPIsubmitting}
          isDisableSubmit={isCouponAPIsubmitting}
          isSubmitResettable={true}
          submitButtonText={appLabels["order"]["check-coupon"]}
          submitButtonLoadingText="Checking..."
          submitButtonCustomClass={
            "button--primary button--primary-small button--primary-with-border button--pill-shaped"
          }
        >
          <FormInput
            type="text"
            label={appLabels["order"]["coupon-code"]}
            id="input--coupon"
            name="coupon-code"
            isRequired={true}
          />
          {appliedCoupon && (
            <div className="promo-form__discount-output">
              <span className="promo-form__discount-coupon-code">
                {appLabels["order"]["coupon"] + ": "}
              </span>
              <span className="promo-form__coupon-balance">
                {toDollars(
                  appSettings["currency-symbol"],
                  appSettings["currency-symbol-side"],
                  appliedCoupon.balance,
                  appLanguage
                )}
              </span>
            </div>
          )}
        </Form>
      )}
      {showCouponForm !== null && !showCouponForm && !!appliedCoupon && (
        <>
          <h4>{appLabels["order"]["applied-coupon-code"]}:</h4>
          <PromoOutput
            storedPromoCode={storedCoupons}
            appliedPromo={appliedCoupon}
            showRemoveDialog={showRemoveDialog}
            showCouponRemoveDialog={showCouponRemoveDialog}
            setShowRemoveDialog={setShowRemoveDialog}
            setShowCouponRemoveDialog={setShowCouponRemoveDialog}
            confirmRemovePromo={confirmRemovePromo}
            confirmRemoveCoupon={confirmRemoveCoupon}
            currencySymbol={appSettings["currency-symbol"]}
            currencySymbolSide={appSettings["currency-symbol-side"]}
            language={appLanguage}
          />
        </>
      )}

      <AdditionalDisclaimer
        disclaimer={appLabels["order"]["coupon-disclaimer"]}
        styleObject={{ marginBottom: "1em", marginTop: "-5px" }}
      />

      <button
        className="button button--primary button--submit-form"
        onClick={applyCodesToOrder}
        disabled={isApplyButtonDisabled}
        type="button"
        id="apply-discount-coupon__button"
      >
        {appLabels["order"]["apply-discount-coupon"]}
      </button>
    </div>
  );
}

const PromoOutput = (props) => {
  const {
    storedPromoCode,
    setShowRemoveDialog,
    setShowCouponRemoveDialog,
    appliedPromo,
    showRemoveDialog,
    showCouponRemoveDialog,
    confirmRemovePromo,
    confirmRemoveCoupon,
    currencySymbol,
    currencySymbolSide,
    language,
  } = props;

  const appLabels = useContext(AppLabelsContext);

  return (
    <div className="applied-promo__wrapper">
      {!!storedPromoCode && (
        <button
          className="button button--remove-promo"
          onClick={() =>
            !!appliedPromo.balance ? setShowCouponRemoveDialog(true) : setShowRemoveDialog(true)
          }
          aria-label={appLabels["order"]["remove-promo"]}
          type="button"
        >
          <IconXInCircle />
        </button>
      )}
      <span className="applied-promo__output">
        <span className="applied-promo__code">{appliedPromo.code + ": "}</span>
        <span className="applied-promo__discount">{`- ${toDollars(
          currencySymbol,
          currencySymbolSide,
          appliedPromo.discount || appliedPromo.balance,
          language
        )}`}</span>
      </span>
      {showRemoveDialog && (
        <DialogModal
          message={appLabels["order"]["discount-remove-confirmation"]}
          confirmAction={confirmRemovePromo}
          isCancelConfirm={true}
          resetRemoveDialog={() => setShowRemoveDialog(false)}
        />
      )}

      {showCouponRemoveDialog && (
        <DialogModal
          message={appLabels["order"]["coupon-remove-confirmation"]}
          confirmAction={confirmRemoveCoupon}
          isCancelConfirm={true}
          resetRemoveDialog={() => setShowCouponRemoveDialog(false)}
        />
      )}
    </div>
  );
};
