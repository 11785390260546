export const loyaltyLabels = {
  rewards: "Recompensas",
  "start-collecting-now": "Comience a acumular ahora!",
  "add-new-card-to-join":
    "Únase a nuestra familia y comience a acumular puntos. Realice un seguimiento de su progreso y canjéelo por bebidas, comida y más gratis.",
  "still-not-convinced": "Aun no esta convencido?",
  "check-out-how-rewards-work": "Aprenda como funcionan las recompensas.",
  "check-out-how-rewards-work-extra-text":
    "Y vea como puede beneficiarse por ser un cliente frecuente",
  "how-rewards-work": "Como Funcionan las Recompensas",
  "add-a-new-card": "Agregar una nueva tarjeta",
  "register-now": "Registrar Ahora",
  "my-rewards": "Mis Recompensas",
  "ends-with": "Termina con",
  "reward-options": "Opciones de Recompensas",
  "learn-how-it-works": "Aprenda como funciona",
  "start-collecting-points-toward":
    "Comience a acumular puntos para bebidas y comidas gratuitas y más",
  "expiry-date": "Fecha de Expiración",
  "bonus-rewards": "Bonus de Recompensas",
  "redeem-rewards": "Redimir Recompensas",
  "online-order": "En línea",
  "when-ordering-online":
    'Agregue cualquier artículo permitido a su pedido. Cuando este listo para finalizar su compra, use el botón "Usar Recompensas" en su carro de compras y seleccione que recompensa va a utilizar',
  "in-store": "En sucursal",
  "click-on-any-available-reward":
    'En su página inicial, haga clic en "Verificar una sucursal", escanee el código e informe al cajero que recompensa desea redimir',
  redeem: "Redimir Recompensas",
  "you-need-to-earn-more-points": "Necesita acumular mas puntos",
  "collect-points": "Acumular Puntos",
  "reward-redemption-confirmation":
    "Esta seguro que desea redimir [reward-name] recompensa por [reward-cost]?",
  "order-confirmation-check-my-rewards-link": "Consultar Mis Recompensas",
  "register-today-and-start-collecting-points-message":
    "Regístrese ahora y comience a acumular puntos para bebidas y comidas gratis y mas",
  "see-how-rewards-work-link": "Vea como funcionan las recompensas",
  "you-could-have-earned-points-message":
    "Ud. Podría haber acumulado [points] de lealtad en este pedido!",
  "confirm-rewards-redemption-message": "Ud. está apunto de redimir [reward-name]",
  "redeem-reward-associated-item-error":
    "Favor de verificar si el artículo asociado es agregado a su pedido antes de intentar redimir esta recompensa",
  "moving-applied-reward-on-card-switch-confirm-text":
    "El cambiar de tarjeta eliminara cualquier recompensa(s) previamente aplicada. Desea continuar?",
  "available-rewards-subheading": "Recompensas Disponibles",
  "add-associated-item-before-redeeming-reward-blurb":
    "Por favor agregue el artículo asociado a tu pedido antes de redimir su recompensa",
  "no-rewards-for-card": "No hay recompensas disponibles para esta tarjeta",
  "cart-change-reapply-rewards-message":
    "Una o mas recompensas previamente aplicadas ya no están disponibles con la ultima modificación de su tarjeta. Por favor aplique nuevamente las recompensas que desea redimir",
  "order-confirmation-points-issued":
    "Ud. ha acumulado [points][icon] puntos de lealtad con este pedido!",
  "redeem-reward-success-message": "Redimida exitosamente",
  "transaction-reference-number": "Numero de referencia de transaccion",
  "where-to-redeem-this-reward": "Donde le gustaria canjear esta recompensa",
  "was-added-to-card": "Fue agregada a [card-number]",
  "your-new-card-balance": "Su nuevo saldo es: [card-balance]",
  "show-reward-item": "Mostrar [reward-name] recompensa",
  "not-enough-points-for-redeeming":
    "No cuenta con puntos suficientes [reward-quantity] para redimir [reward-name]",
  "reward-balance": "Saldo de Recompensas",
  "rewards-remaining": "[reward-balance] Recompensas restantes",
  "rewards-remaining-single": "[reward-balance] Recompensa restante",
  "view-rewards": "Ver recompensas",
};
