import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import { ReactComponent as IconCheck } from "../icons/IconCheck.svg";

export const FormSubmit = (props) => {
  const {
    isFormValid,
    isAPISubmitValid,
    navigateURL,
    navigateURLStateFrom,
    isBufferBeforeNavigationDisabled,
    resetInvalidForm,
    buttonSuccessText,
    buttonText,
    isAPIsubmitting,
    buttonLoadingText,
    formId,
    onSubmit,
    submitButtonCustomClass,
    formError,
    buttonWrapperClass,
    isSubmitResettable,
  } = props;

  const history = useHistory();

  const [isDisabled, setIsDisabled] = useState(false);
  const [formErrorHideClass, setFormErrorHideClass] = useState("");
  const [isCheckIcon, setIsCheckIcon] = useState(false);

  // Check if isDisabled is externally passed via props
  useEffect(() => {
    if (typeof props.isDisabled !== undefined) {
      setIsDisabled(props.isDisabled);
    }
  }, [props.isDisabled]);

  useEffect(() => {
    let timer1;
    let timer2;
    let timer3;

    if (isFormValid) {
      /* form is valid */

      if (isAPISubmitValid) {
        /* form API submit was successful */

        if (navigateURL && navigateURL !== "") {
          setIsCheckIcon(true);

          if (!isBufferBeforeNavigationDisabled) {
            /* add some buffer time before navigating on successful API submit */
            timer1 = setTimeout(() => {
              history.push({
                pathname: navigateURL,
                state: navigateURLStateFrom ? { from: navigateURLStateFrom } : null,
              });
            }, 2000);
          } else {
            history.push({
              pathname: navigateURL,
              state: navigateURLStateFrom ? { from: navigateURLStateFrom } : null,
            });
          }
        }
      }
    } else if (!isFormValid && isFormValid != null) {
      /* form is invalid */

      /* Start hiding the error after 2 seconds */
      timer2 = setTimeout(() => {
        setFormErrorHideClass("form__error--hidden");
        /* Remove error from DOM after hiding animation is done */

        timer3 = setTimeout(() => {
          resetInvalidForm();
          setFormErrorHideClass("");
          setIsDisabled(false);
        }, 300);
      }, 5000);
    }

    return () => {
      clearTimeout(timer1);
      clearTimeout(timer2);
      clearTimeout(timer3);
    };
  }, [isFormValid, isAPISubmitValid]);

  // Set submit button text when it's loading/complete/default
  let submitButtonText = "";

  if (!isSubmitResettable) {
    if (isFormValid && isAPISubmitValid && buttonSuccessText) {
      submitButtonText = (
        <div className="button--submit-form__success button button-primary">
          <span>{buttonSuccessText}</span>
          {isCheckIcon && <IconCheck />}
        </div>
      );
    } else {
      submitButtonText = buttonText;
    }
  } else {
    submitButtonText = isAPIsubmitting ? buttonLoadingText : buttonText;
  }

  return (
    <div className={`button--submit-form-wrapper ${buttonWrapperClass || ""}`}>
      {!isFormValid && isFormValid != null && (
        <div className={`form__error ${formErrorHideClass}`} role="alert">
          {formError}
        </div>
      )}
      <button
        id={`${formId}__submit-button`}
        form={formId}
        className={`button ${submitButtonCustomClass || "button--primary "}`}
        onClick={(e) => {
          setIsDisabled(true);
          onSubmit(e);
        }}
        disabled={isDisabled}
        type="submit"
      >
        {submitButtonText}
      </button>
    </div>
  );
};
