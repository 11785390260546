import React, { useState, useEffect } from "react";
import { PaymentMethodCreditCardForm } from "./PaymentMethodCreditCardForm";
import "./PaymentMethodCreditCard.css";

export const PaymentMethodCreditCard = (props) => {
  const {
    paymentMethodExpanded,
    appliedGiftCards,
    creditCardTypes,
    orderTotal,
    rewards,
    promoCode,
    coupon,
    isDebitForm,
  } = props;

  // Scroll to the top of the expand/collapse toggle button after details panel is expanded
  useEffect(() => {
    const timer = setTimeout(() => paymentMethodExpanded(), 300);
    return () => clearTimeout(timer);
  }, []);

  // Updates on Credit Card input change
  const [activeCreditCardType, setActiveCreditCardType] = useState("");
  const updateActiveCreditCardType = (type) => setActiveCreditCardType(type.toLowerCase());

  return (
    <PaymentMethodCreditCardForm
      appliedGiftCards={appliedGiftCards}
      creditCardTypes={creditCardTypes}
      updateActiveCreditCardType={updateActiveCreditCardType}
      orderTotal={orderTotal}
      rewards={rewards}
      promoCode={promoCode}
      coupon={coupon}
      activeType={activeCreditCardType}
      isDebitForm={isDebitForm}
    />
  );
};
