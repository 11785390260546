import { useContext, useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import localforage from "localforage";

import { getAPIAccountInfo } from "../../Account/apiHelpers/getAPIAccountInfo";

import AppLanguageContext from "../../App/AppLanguageContext";
import MerchantConfigContext from "../../App/MerchantConfigContext";

export const useUserRoleSetup = (setIsSessionExpiredDialog) => {
  const [userRoleContext, setUserRoleContext] = useState(null);

  const appLanguage = useContext(AppLanguageContext);
  const merchantConfigContext = useContext(MerchantConfigContext);

  const history = useHistory();

  const merchantSkinName = merchantConfigContext.skin;

  const [userStatus, setUserStatus] = useState(null);
  useEffect(() => {
    localforage.getItem(merchantSkinName + "__isLoggedIn").then((storedIsLoggedIn) => {
      if (storedIsLoggedIn) setUserStatus("logged-in");
      else setUserStatus("guest");
    });
  }, []);

  const [loginToken, setLoginToken] = useState("idle");
  useEffect(() => {
    localforage.getItem(merchantSkinName + "__loginToken").then((storedLoginToken) => {
      if (storedLoginToken) setLoginToken(storedLoginToken);
      else setLoginToken(null);
    });
  }, []);

  useEffect(() => {
    if (userStatus && loginToken !== "idle") {
      const userRole = {
        isGuestEnabled: merchantConfigContext.merchant.I18 === "t",
        status: userStatus,
        updateStatus,
        loginToken,
        updateLoginToken,
        handleLoginTokenExpiration,
        declineExpiredSessionRenewal,
        updateAccountInfoInStorage,
      };

      setUserRoleContext(userRole);
    }
  }, [userStatus, loginToken]);

  /** when user reaches the cart page, check to see if their login token is valid or expired */
  useEffect(() => {
    if (
      window.location.href.includes("/review-order") &&
      !window.location.href.includes("account-deletion-confirmation") &&
      !window.location.href.includes("account/payment-methods")
    ) {
      getAPIAccountInfo(merchantSkinName, loginToken, appLanguage).then((apiAccountInfo) => {
        if (apiAccountInfo && apiAccountInfo.status === "expiredLoginToken") {
          handleLoginTokenExpiration();
        }
      });
    }
  }, [window.location.href]);

  function updateStatus(newStatus) {
    setUserStatus(newStatus);

    if (newStatus === "logged-in") {
      localforage.setItem(merchantSkinName + "__isLoggedIn", true);
    } else if (newStatus === "guest") {
      localforage.removeItem(merchantSkinName + "__isLoggedIn");
      updateLoginToken();
    }
  }

  function updateLoginToken(newToken) {
    if (newToken) {
      setLoginToken(newToken);
      localforage.setItem(merchantSkinName + "__loginToken", newToken);
    } else {
      // Clearing token as a result of logout
      setLoginToken(null);
      localforage.removeItem(merchantSkinName + "__loginToken");
    }
  }

  function handleLoginTokenExpiration() {
    const storedLogin = JSON.parse(sessionStorage.getItem(merchantSkinName + "__login"));

    if (storedLogin) {
      setIsSessionExpiredDialog(true);
    } else {
      // Login credentials got cleared from storage for any reason
      declineExpiredSessionRenewal();
    }
  }

  function declineExpiredSessionRenewal() {
    updateStatus("guest");

    // Remove all of the stored data meant only for login users
    sessionStorage.removeItem(merchantSkinName + "__login");
    localforage.removeItem(merchantSkinName + "__customerInfo");
    localforage.removeItem(merchantSkinName + "__customerID");
    localforage.removeItem(merchantSkinName + "__userFirstName");
    localforage.removeItem(merchantSkinName + "__isSessionLogin");
    localforage.removeItem(merchantSkinName + "__lastLoginTimeStampDisplayed");
    sessionStorage.removeItem(merchantSkinName + "__activeLoyaltyCard");
    localforage.removeItem(merchantSkinName + "__isLoggedIn");
    localforage.removeItem(merchantSkinName + "__loginToken");
    localforage.removeItem(merchantSkinName + "__storedRewards");
    // END OF: Remove all of the stored data meant only for login users

    setIsSessionExpiredDialog(false);

    sessionStorage.setItem(
      merchantSkinName + "__lastVisitedLink",
      window.location.hash.split("#")[1]
    );
    history.push("/login-register");
  }

  /**
   * Parses and stores the customer information data in localforage based on the data of 950
   * @param {Object} apiData - the result object from the data of API 950
   * @returns {Object} customer information object
   */
  async function updateAccountInfoInStorage(apiData) {
    let customerInfo = {};

    const currentCustomerInfo = await localforage.getItem(merchantSkinName + "__customerInfo");
    // if there is a customer info already stored in localforage
    if (currentCustomerInfo) {
      customerInfo = {
        ...currentCustomerInfo,
        id: apiData.result.I2 || "",
        login: apiData.result.I5 || "",
        title: apiData.result.I6 || "",
        firstName: apiData.result.I7 || "",
        middleName: apiData.result.I8 || "",
        lastName: apiData.result.I9 || "",
        gender: apiData.result.I10 || "",
        birthday: apiData.result.I11 || "",
        address: apiData.result.I12 || "",
        city: apiData.result.I13 || "",
        province: apiData.result.I14 || "",
        county: apiData.result.I15 || "",
        country: apiData.result.I16 || "",
        postal: apiData.result.I17 || "",
        phone: apiData.result.I18 || "",
        discount: apiData.result.I19 || "",
        optInEmail: apiData.result.I20 || "",
        email: apiData.result.I21 || "",
        mobile: apiData.result.I22 || "",
        company: apiData.result.I23 || "",
        messageType: apiData.result.I24 || "",
        messageDeliveryMethod: apiData.result.I25 || "",
        promotionOptInMail: apiData.result.I26 || "",
        companyABN: apiData.result.I28 || "",
        redemptionOptIn: apiData.result.I29 || "",
        paymentMethods: apiData.result.I30 || "",
        cpfNumber: apiData.result.I31 || "",
        customerAlternativeName: apiData.result.I32 || "",
        customerNationality: apiData.result.I33 || "",
        customFields: apiData.result.I35 || "",
        promotionOptInPhone: apiData.result.I36 || "",
        invalidEmailStatus: apiData.result.I37 || "",
        memberType: apiData.result.I38 || "",
      };

      localforage.setItem(merchantSkinName + "__customerInfo", customerInfo);
      localforage.setItem(merchantSkinName + "__customerID", customerInfo.id);

      return customerInfo;
    } else {
      // no record of the customer info in localforage
      customerInfo = {
        id: apiData.result.I2,
        login: apiData.result.I5,
        title: apiData.result.I6,
        firstName: apiData.result.I7,
        middleName: apiData.result.I8,
        lastName: apiData.result.I9,
        gender: apiData.result.I10,
        birthday: apiData.result.I11,
        address: apiData.result.I12,
        city: apiData.result.I13,
        province: apiData.result.I14,
        county: apiData.result.I15,
        country: apiData.result.I16,
        postal: apiData.result.I17,
        phone: apiData.result.I18,
        discount: apiData.result.I19,
        optInEmail: apiData.result.I20,
        email: apiData.result.I21,
        mobile: apiData.result.I22,
        company: apiData.result.I23,
        messageType: apiData.result.I24,
        messageDeliveryMethod: apiData.result.I25,
        promotionOptInMail: apiData.result.I26,
        storedCreditCard: {
          id: apiData.result.I27,
          maskedNumber: apiData.result.I34.split("|")[0],
          expiry: apiData.result.I34.split("|")[1],
        },
        companyABN: apiData.result.I28,
        redemptionOptIn: apiData.result.I29,
        paymentMethods: apiData.result.I30,
        cpfNumber: apiData.result.I31,
        customerAlternativeName: apiData.result.I32,
        customerNationality: apiData.result.I33,
        customFields: apiData.result.I35,
        promotionOptInPhone: apiData.result.I36,
        invalidEmailStatus: apiData.result.I37,
        memberType: apiData.result.I38,
      };

      localforage.setItem(merchantSkinName + "__customerInfo", customerInfo);
      localforage.setItem(merchantSkinName + "__customerID", customerInfo.id);

      return customerInfo;
    }
  }

  return userRoleContext;
};
