import React from "react";
import localforage from "localforage";
import {
  getTrueBusinessDate,
  isLastDayOfMonth,
  newTime,
  roundTime,
  roundToNearestQuarter,
} from "../Dashboard/DashboardOrder/dateHelpers";
import {
  setIsOpenPassedMidnightProperties,
  setStoreHoursProperties,
} from "../OnlineOrdering/GoogleMaps/dateHelpers";
import {
  formatStoreTimeOverrides,
  getClosingTime,
  getDayOfWeekFromIndex,
  getFormattedStoreHoursWithMidnight,
  getIntermittentIncrementInterval,
  getMinInterval,
  getOpeningTime,
  isTimeSlotBlocked,
  isWithinBusinessHours,
} from "../OnlineOrdering/Locations/helpers/isStoreOpenOrClosed";
import { callAPI } from "../_common/Api";

/** Updates the data of the current activeOrderStore, in case the store hours were changed etc. */
export const updateLocationData = async (activeOrderStore, activeOrderType, skin, appLanguage) => {
  let storedDeliveryAddress;
  if (activeOrderType === "delivery") {
    storedDeliveryAddress = await localforage.getItem(skin + "__userDeliveryAddress");
  }

  /** Call API 1306 to get the configuration of the store and its store hours */
  const apiStoreConfig = await callAPI(skin, "info_vxl_1306", {
    params: [
      appLanguage,
      "1306",
      "mqid",
      "mqpass",
      "OUTLET",
      activeOrderStore.storeId,
      "t",
      activeOrderType === "delivery" && storedDeliveryAddress ? storedDeliveryAddress.postal : "", //if order type is delivery send the delivery address postal code to 1306
    ],
    id: "1306",
  });

  if (apiStoreConfig && apiStoreConfig.result.I1 === "0") {
    const vexilorConfig = apiStoreConfig.result.I2;

    let pickupIncrement = Number(vexilorConfig.pickup_inc_interval);
    let pickupMinInterval = Number(vexilorConfig.pickup_min_interval);

    if (Number(pickupIncrement) === 0) pickupIncrement = 15; // fallback increment
    if (Number(pickupMinInterval) === 0) pickupMinInterval = 30; // fallback min interval

    vexilorConfig.pickup_inc_interval = pickupIncrement;
    vexilorConfig.pickup_min_interval = pickupMinInterval;

    /** Extract the store hours from the 1306 data */

    let reformattedStore = {
      ...activeOrderStore,
      hours: {
        monday: vexilorConfig.outlet_hours.monday_hours || "closed",
        tuesday: vexilorConfig.outlet_hours.tuesday_hours || "closed",
        wednesday: vexilorConfig.outlet_hours.wednesday_hours || "closed",
        thursday: vexilorConfig.outlet_hours.thursday_hours || "closed",
        friday: vexilorConfig.outlet_hours.friday_hours || "closed",
        saturday: vexilorConfig.outlet_hours.saturday_hours || "closed",
        sunday: vexilorConfig.outlet_hours.sunday_hours || "closed",
      },
      hoursComment: vexilorConfig.outlet_hours_comment,
    };

    reformattedStore.vexilorConfig = vexilorConfig;

    reformattedStore.openPassedMidnight = setIsOpenPassedMidnightProperties(reformattedStore.hours);

    reformattedStore = setStoreHoursProperties(reformattedStore);

    return reformattedStore;
  }
};

/** Checks is the orderTime and the current time are not passed the order cutoff time */
export const isValidOrderTime = (orderStore, orderTimeContext) => {
  const storeConfig = orderStore.vexilorConfig;

  let orderCutOffTimeMinutes = 0;

  if (storeConfig.ordering_cut_off_time && storeConfig.ordering_cut_off_time !== "None") {
    orderCutOffTimeMinutes = Number(storeConfig.ordering_cut_off_time);
  }
  const orderMonth = orderTimeContext.value.value.getMonth();
  const orderDate = orderTimeContext.value.value.getDate();
  //const orderDayOfWeek = orderTimeContext.value.value.getDay();
  const orderDayOfWeek = orderTimeContext.value.trueBusinessDate.getDay();
  const orderDayHours = orderStore.hours[getDayOfWeekFromIndex(orderDayOfWeek)];
  if (orderDayHours === "closed") {
    return false;
  } else {
    const orderDayOpeningTime = orderDayHours.split(" - ")[0];
    const orderDayClosingTime = orderDayHours.split(" - ")[1];
    const storeClosingTime = orderDayClosingTime.split(":");
    const storeClosingHour = storeClosingTime[0];
    const storeClosingMinute = storeClosingTime[1];

    const closingTime = new Date();
    closingTime.setMonth(orderMonth, 1); // 1 is a dummy value to prevent JS from skipping months without the current date
    closingTime.setDate(orderDate);
    closingTime.setHours(storeClosingHour);
    closingTime.setMinutes(storeClosingMinute);
    closingTime.setSeconds(0, 0);

    const formattedStoreHours = getFormattedStoreHoursWithMidnight(
      orderDayOpeningTime,
      orderDayClosingTime,
      orderTimeContext.value.trueBusinessDate,
      orderStore
    );

    // Calculate the cut-off time by subtracting the minimum interval from closing time
    const orderCutOffTime = new Date(closingTime);
    orderCutOffTime.setTime(orderCutOffTime.getTime() - orderCutOffTimeMinutes * 60000);
    orderCutOffTime.setSeconds(0, 0);
    orderCutOffTime.setDate(formattedStoreHours.formattedClosingTime.getDate());

    /** if the opening time is the last day of the month and store is open passed midnight for that day,
     * adjust the month of the cut off time
     */
    if (
      isLastDayOfMonth(formattedStoreHours.formattedOpeningTime) &&
      orderStore.openPassedMidnight[
        getDayOfWeekFromIndex(formattedStoreHours.formattedOpeningTime.getDay())
      ] === true
    ) {
      orderCutOffTime.setMonth(orderCutOffTime.getMonth() + 1);
    }

    const currentTime = new Date();
    return (
      orderTimeContext.value.value.getTime() <= orderCutOffTime.getTime() &&
      currentTime.getTime() <= orderCutOffTime.getTime()
    );
  }
};

export const isStoreClosedToday = (orderStore, orderTimeContext) => {
  let orderDayOfWeek = orderTimeContext.value.trueBusinessDate.getDay();
  let orderDayHours = orderStore.hours[getDayOfWeekFromIndex(orderDayOfWeek)];
  let today = new Date().getDay();
  let dayClosingTime = orderDayHours.split(" - ")[1];
  let hours = dayClosingTime.substring(0, dayClosingTime.indexOf(":"));
  let minutes = dayClosingTime.substring(dayClosingTime.indexOf(":") + 1);
  let actualClosingTimestamp = null;
  let dayName = orderTimeContext.value.trueBusinessDate.toLocaleString("en-us", {
    weekday: "long",
  });
  let openPassedMidnight = orderStore.openPassedMidnight[dayName.toLowerCase()];

  if (openPassedMidnight) {
    let newDate = new Date();
    let today = new Date();
    let nextDay = new Date();
    nextDay.setDate(today.getDate() + 1);
    newDate = nextDay;

    newDate.setHours(hours);
    newDate.setMinutes(minutes);
    newDate.setSeconds("00");
    actualClosingTimestamp = newDate;
  } else {
    let currDate = new Date();
    currDate.setDate(orderTimeContext.value.trueBusinessDate.getDate());
    currDate.setMonth(orderTimeContext.value.trueBusinessDate.getMonth());
    currDate.setHours(hours);
    currDate.setMinutes(minutes);
    currDate.setSeconds("00");
    actualClosingTimestamp = currDate;
  }

  if (
    orderTimeContext.value.value.getTime() === orderTimeContext.value.trueBusinessDate.getTime()
  ) {
    today = new Date();
    let orderingDay = orderTimeContext.value.value.getDay();
    if (today.getDay() === orderingDay) {
      if (orderTimeContext.value.value < actualClosingTimestamp && today < actualClosingTimestamp) {
        return false;
      } else {
        return true;
      }
    } else {
      if (today < actualClosingTimestamp) {
        return false;
      } else {
        return true;
      }
    }
  } else if (
    orderTimeContext.value.value.getTime() !== orderTimeContext.value.trueBusinessDate.getTime() &&
    orderTimeContext.value.value.getTime() > orderTimeContext.value.trueBusinessDate.getTime()
  ) {
    today = new Date();
    if (orderTimeContext.value.value < actualClosingTimestamp && today < actualClosingTimestamp) {
      return false;
    } else {
      return true;
    }
  } else {
    let currentTime = new Date();
    if (
      currentTime.getTime() > orderTimeContext.value.value.getTime() &&
      currentTime.getTime() >= actualClosingTimestamp.getTime()
    ) {
      return true;
    } else {
      return false;
    }
  }
};

/** Checks if the current time is passed the selected order time */
export const isOrderTimePassed = (
  orderStore,
  activeOrderType,
  orderTimeContext,
  setNextAvailableOrderTime
) => {
  //for dinein orders this function should return false
  if (activeOrderType === "dinein") {
    return false;
  }

  const storeConfig = orderStore.vexilorConfig;
  const orderTime = orderTimeContext.value.value;
  const formattedStoreOverrides = formatStoreTimeOverrides(
    activeOrderType === "delivery"
      ? storeConfig.delivery_min_interval_override
      : storeConfig.pickup_min_interval_override,
    orderTime
  );

  const blockedHours =
    Object.keys(formattedStoreOverrides).length === 0
      ? []
      : formattedStoreOverrides[getDayOfWeekFromIndex(orderTime.getDay())].filter(
          (override) => override.isBlocked
        );
  const minInterval = getOverrideMinInterval(
    formattedStoreOverrides,
    orderTime,
    activeOrderType,
    storeConfig
  );

  const orderMonth = orderTimeContext.value.value.getMonth();
  const orderDate = orderTimeContext.value.value.getDate();
  //const orderDayOfWeek = orderTimeContext.value.value.getDay();
  const orderDayOfWeek = orderTimeContext.value.trueBusinessDate.getDay();
  const orderDayHours = orderStore.hours[getDayOfWeekFromIndex(orderDayOfWeek)];

  if (orderDayHours === "closed") {
    return true;
  } else {
    const orderDayOpeningTime = orderDayHours.split(" - ")[0];
    const orderDayClosingTime = orderDayHours.split(" - ")[1];
    const storeClosingTime = orderDayClosingTime.split(":");
    const storeClosingHour = storeClosingTime[0];
    const storeClosingMinute = storeClosingTime[1];
    const closingTime = new Date();
    closingTime.setMonth(orderMonth, 1); // 1 is a dummy value to prevent JS from skipping months without the current date
    closingTime.setDate(orderDate);
    closingTime.setHours(storeClosingHour);
    closingTime.setMinutes(storeClosingMinute);
    closingTime.setSeconds(0, 0);

    // Calculate the cut-off time by subtracting the minimum interval from closing time
    let orderCutOffTimeMinutes = 0;

    if (storeConfig.ordering_cut_off_time && storeConfig.ordering_cut_off_time !== "None") {
      orderCutOffTimeMinutes = Number(storeConfig.ordering_cut_off_time);
    }
    const orderCutOffTime = new Date(closingTime);
    orderCutOffTime.setTime(orderCutOffTime.getTime() - orderCutOffTimeMinutes * 60000);
    orderCutOffTime.setSeconds(0, 0);

    const currentTime = new Date();
    let nextOrderTimeWithInterval = new Date();
    let initialDate;
    if (currentTime.getTime() > orderTimeContext.value.value.getTime()) {
      const nextOrderTime = new Date();
      nextOrderTime.setTime(nextOrderTime.getTime() + minInterval * 60000);
      nextOrderTimeWithInterval = nextOrderTime;
      initialDate = nextOrderTime.getDate();
      while (
        isTimeSlotBlocked(nextOrderTime, blockedHours) ||
        !isValidOrderTime(orderStore, {
          value: {
            value: nextOrderTime,
            trueBusinessDate: getTrueBusinessDate(nextOrderTime, orderStore),
          },
        }) ||
        !isWithinBusinessHours(orderStore, nextOrderTime) ||
        !isValidOrderTime(orderStore, {
          value: {
            value: nextOrderTimeWithInterval,
            trueBusinessDate: getTrueBusinessDate(nextOrderTimeWithInterval, orderStore),
          },
        })
      ) {
        if (nextOrderTime.getDate() !== initialDate) {
          const formattedStoreHours = getFormattedStoreHoursWithMidnight(
            orderDayOpeningTime,
            orderDayClosingTime,
            nextOrderTime,
            orderStore
          );
          nextOrderTime.setTime(formattedStoreHours.formattedOpeningTime.getTime());
          initialDate = nextOrderTime.getDate();
        }

        //if the next order time is passed the order cutoff time or the closing time, update the date of the nextOrderTime
        nextOrderTime.setTime(nextOrderTime.getTime() + minInterval * 60000);
        nextOrderTimeWithInterval = nextOrderTime;
      }
      setNextAvailableOrderTime(nextOrderTime);

      return true;
    } else {
      return false;
    }
  }
};

/** Checks if the selected order time is too near to closing time */
export const isLateOrderAttempted = (
  orderStore,
  activeOrderType,
  orderTimeContext,
  skin,
  setNextAvailableOrderTime
) => {
  const storeConfig = orderStore.vexilorConfig;
  const orderTime = orderTimeContext.value.value;
  const formattedStoreOverrides = formatStoreTimeOverrides(
    activeOrderType === "delivery"
      ? storeConfig.delivery_min_interval_override
      : storeConfig.pickup_min_interval_override,
    orderTime
  );

  const minInterval = getOverrideMinInterval(
    formattedStoreOverrides,
    orderTime,
    activeOrderType,
    storeConfig
  );
  const cutOffTime = storeConfig.ordering_cut_off_time;
  const orderDayOfWeek = orderTimeContext.value.trueBusinessDate.getDay();
  const orderDayHours = orderStore.hours[getDayOfWeekFromIndex(orderDayOfWeek)];

  const orderDayOpeningTime = orderDayHours.split(" - ")[0];
  const orderDayClosingTime = orderDayHours.split(" - ")[1];

  const formattedStoreHours = getFormattedStoreHoursWithMidnight(
    orderDayOpeningTime,
    orderDayClosingTime,
    orderTimeContext.value.trueBusinessDate,
    orderStore
  );

  const storeClosingTime = formattedStoreHours.formattedClosingTime;
  const currentTime = new Date();

  const nextAvailableOrderTime = calculateNextAvailableOrderTime(orderStore, activeOrderType, skin);

  if (
    currentTime.getTime() + minInterval * 60000 >=
    storeClosingTime.getTime() - cutOffTime * 60000
  ) {
    setNextAvailableOrderTime(nextAvailableOrderTime);
    return true;
  } else {
    return false;
  }
};

/** Checks if the selected order time is before the opening time of the selected location */
export const isOrderTimeBeforeOpeningTime = (orderStore, orderTimeContext) => {
  const orderDayOfWeek = orderTimeContext.value.trueBusinessDate.getDay();
  const orderDayHours = orderStore.hours[getDayOfWeekFromIndex(orderDayOfWeek)];

  const orderDayOpeningTime = orderDayHours.split(" - ")[0];
  const orderDayClosingTime = orderDayHours.split(" - ")[1];

  const formattedStoreHours = getFormattedStoreHoursWithMidnight(
    orderDayOpeningTime,
    orderDayClosingTime,
    orderTimeContext.value.trueBusinessDate,
    orderStore
  );

  const matchingTrueBusinessDateTime = new Date(orderTimeContext.value.trueBusinessDate);
  matchingTrueBusinessDateTime.setHours(orderTimeContext.value.value.getHours());
  matchingTrueBusinessDateTime.setMinutes(orderTimeContext.value.value.getMinutes());
  matchingTrueBusinessDateTime.setSeconds(orderTimeContext.value.value.getSeconds());
  matchingTrueBusinessDateTime.setMilliseconds(orderTimeContext.value.value.getMilliseconds());

  return (
    orderTimeContext.value.value.getTime() < formattedStoreHours.formattedOpeningTime.getTime() &&
    orderTimeContext.value.value.getTime() >= matchingTrueBusinessDateTime.getTime()
  );

  /*//check the previous date and see if the store is open passed midnight for the day before and the order time is before the closing time of of the previous day
   const previousDay = new Date(orderTimeContext.value.value);
  previousDay.setDate(orderTimeContext.value.value.getDate() - 1);
  const formattedStoreHoursForPreviousDay = getFormattedStoreHoursWithMidnight(
    orderDayOpeningTime,
    orderDayClosingTime,
    previousDay,
    orderStore
  );
  
  if (
    orderStore.openPassedMidnight[getDayOfWeekFromIndex(previousOrderDayOfWeek)] === true &&
    formattedStoreHoursForPreviousDay !== "closed" &&
    orderTimeContext.value.value.getTime() <
      formattedStoreHoursForPreviousDay.formattedClosingTime.getTime()
  ) {
    return false;
  } else {
    return orderTimeContext.value.value.getTime() < openingTime.getTime();
  }*/
};

/** Checks to see if the order time falls in blocked time slots */
export const isOrderTimeBlocked = (orderStore, orderTimeContext, activeOrderType) => {
  const storeConfig = orderStore.vexilorConfig;
  const orderTime = orderTimeContext.value.value;
  const formattedStoreOverrides =
    activeOrderType === "dinein"
      ? []
      : formatStoreTimeOverrides(
          activeOrderType === "delivery"
            ? storeConfig.delivery_min_interval_override
            : storeConfig.pickup_min_interval_override,
          orderTime
        );
  const blockedHours =
    Object.keys(formattedStoreOverrides).length === 0
      ? []
      : formattedStoreOverrides[getDayOfWeekFromIndex(orderTime.getDay())].filter(
          (override) => override.isBlocked
        );

  //if there are not blocked hours return false
  if (blockedHours.length === 0) {
    return false;
  } else {
    //check the block hours and compare them to order time
    if (isTimeSlotBlocked(orderTime, blockedHours)) {
      return true;
    } else {
      return false;
    }
  }
};

const getOverrideMinInterval = (
  formattedStoreOverrides,
  orderTime,
  activeOrderType,
  storeConfig
) => {
  let minInterval;
  if (activeOrderType === "pickup" || activeOrderType === "dinein") {
    minInterval = Number(storeConfig.pickup_min_interval);
  } else if (activeOrderType === "delivery") {
    minInterval = Number(storeConfig.delivery_min_interval);
  }

  //Compare the order time with formattedStoreOverrides and see if the order time falls between any of the overrider periods.
  //If so use the min interval value from the override

  const currentTime = new Date();
  currentTime.setDate(orderTime.getDate());
  const storeOverrideForOrderTime =
    Object.keys(formattedStoreOverrides).length === 0
      ? []
      : formattedStoreOverrides[getDayOfWeekFromIndex(orderTime.getDay())];
  for (let i = 0; i < storeOverrideForOrderTime.length; i++) {
    const override = storeOverrideForOrderTime[i];
    if (
      currentTime.getTime() >= override.start.getTime() &&
      currentTime.getTime() <= override.end.getTime() &&
      override.leadTime > 0
    ) {
      minInterval = Number(override.leadTime);
      break;
    }
  }

  return minInterval;
};

/** this function ensures that the final order timestamp meets the min interval requirements  */
export const ensureOrderTimeMinInterval = (orderDate, activeOrderStore, activeOrderType) => {
  let orderTime = new Date(orderDate);
  orderTime.setSeconds(0);
  orderTime.setMilliseconds(0);

  const storeConfig = activeOrderStore.vexilorConfig;
  const formattedStoreOverrides =
    activeOrderType === "dinein"
      ? []
      : formatStoreTimeOverrides(
          activeOrderType === "delivery"
            ? storeConfig.delivery_min_interval_override
            : storeConfig.pickup_min_interval_override,
          orderTime
        );

  const minInterval = getOverrideMinInterval(
    formattedStoreOverrides,
    orderTime,
    activeOrderType,
    storeConfig
  );

  const currentTime = new Date();
  const timeDiffInMillieseconds = Math.abs(currentTime.getTime() - orderTime.getTime());
  const minIntervalInMilliseconds = minInterval * 60000;

  if (timeDiffInMillieseconds < minIntervalInMilliseconds) {
    const offset = minIntervalInMilliseconds - timeDiffInMillieseconds;
    let updatedOrderTime = new Date(orderTime.getTime() + offset);
    updatedOrderTime.setSeconds(0);
    return updatedOrderTime;
  } else {
    orderTime.setSeconds(0);
    return orderTime;
  }
};

export const calculateNextAvailableOrderTime = (
  foundStore,
  orderType,
  skin,
  orderTimeContext = null
) => {
  /* Set the order time as now + min interval */

  const storeConfig = foundStore.vexilorConfig;

  const formattedStoreOverrides =
    orderType === "dinein"
      ? []
      : formatStoreTimeOverrides(
          orderType === "delivery"
            ? storeConfig.delivery_min_interval_override
            : storeConfig.pickup_min_interval_override,
          new Date()
        );
  const blockedTimeIntervals =
    Object.keys(formattedStoreOverrides).length === 0
      ? []
      : formattedStoreOverrides[getDayOfWeekFromIndex(new Date().getDay())].filter(
          (interval) => interval.isBlocked
        );

  if (storeConfig) {
    const minInterval = getMinInterval(storeConfig, orderType, skin);

    const nowPlusMinInterval = new Date();
    nowPlusMinInterval.setTime(nowPlusMinInterval.getTime() + minInterval * 60000);

    if (
      foundStore.isOpen &&
      isValidOrderTime(foundStore, {
        value: {
          value: nowPlusMinInterval,
          trueBusinessDate: getTrueBusinessDate(nowPlusMinInterval, foundStore),
        },
      })
    ) {
      const orderTime = new Date();
      orderTime.setTime(orderTime.getTime() + minInterval * 60000);
      let openTime = null;
      if (orderTimeContext) {
        let orderingDate = orderTimeContext.value.trueBusinessDate;
        let selectedDayApiOpeningTime = getOpeningTime(foundStore.hours, orderingDate.getDay());
        let selectedDayApiClosingTime = getClosingTime(foundStore.hours, orderingDate.getDay());

        let actualStoreHours = getFormattedStoreHoursWithMidnight(
          selectedDayApiOpeningTime,
          selectedDayApiClosingTime,
          orderingDate,
          foundStore
        );
        openTime = actualStoreHours.formattedOpeningTime;
      }

      if (!isTimeSlotBlocked(new Date(orderTime), blockedTimeIntervals)) {
        if (openTime && orderTime < openTime) {
          orderTime.setTime(openTime.getTime() + minInterval * 60000);
          return orderTime;
        } else {
          return orderTime;
        }
      } else {
        //if the order time falls in a blocked time interval
        //calculate the next available time slot after the block finishes

        let lookingForNextAvailableTime = true;

        let now = new Date();
        let iterationIndex = 0;
        const pickupIncrement = storeConfig.pickup_inc_interval;
        while (lookingForNextAvailableTime) {
          const roundedMinutes = roundToNearestQuarter(now.getMinutes());
          const roundedTime = roundTime(now, roundedMinutes, true);

          const increment = getIntermittentIncrementInterval(
            formattedStoreOverrides,
            roundedTime,
            pickupIncrement
          );
          const nextTime = newTime(roundedTime, iterationIndex === 0 ? minInterval : increment); // "In {pickupMinInterval OR pickupIncrement} min"
          const nextTimeWithResetSeconds = new Date(nextTime);
          nextTimeWithResetSeconds.setSeconds(0, 0); // reset seconds to 0 to allow pre-select based on storage value
          if (!isTimeSlotBlocked(nextTimeWithResetSeconds, blockedTimeIntervals)) {
            lookingForNextAvailableTime = false;
            return new Date(nextTimeWithResetSeconds);
          } else {
            now = new Date(nextTimeWithResetSeconds);
          }
          iterationIndex++;

          // fail safe to break out of the loop
          if (iterationIndex > 1000) {
            break;
          }
        }
      }
    } else {
      // Store is closed and/or ordering for future date
      let dayInFutureIndex = 0;

      /** if ordering for a future date, use the default min pickup/delivery interval */
      let defaultMinInterval =
        orderType === "delivery"
          ? storeConfig.delivery_min_interval
          : storeConfig.pickup_min_interval;

      let today = new Date();
      let futureDate = new Date();
      let nextDay = new Date();
      nextDay.setDate(today.getDate() + dayInFutureIndex);
      futureDate = nextDay;
      let selectedDay = futureDate;

      let selectedDayApiOpeningTime = getOpeningTime(foundStore.hours, selectedDay.getDay());
      let selectedDayApiClosingTime = getClosingTime(foundStore.hours, selectedDay.getDay());

      let realHours = getFormattedStoreHoursWithMidnight(
        selectedDayApiOpeningTime,
        selectedDayApiClosingTime,
        futureDate,
        foundStore
      );

      let todayStoreHours = getFormattedStoreHoursWithMidnight(
        selectedDayApiOpeningTime,
        selectedDayApiClosingTime,
        today,
        foundStore
      );

      let futureDatePlusMinInterval = new Date();
      futureDatePlusMinInterval.setTime(today.getTime() + minInterval * 60000);

      while (
        (isNaN(selectedDayApiOpeningTime.replace(":", "")) &&
          selectedDayApiOpeningTime !== "closed") ||
        (isNaN(selectedDayApiClosingTime.replace(":", "")) &&
          selectedDayApiClosingTime !== "closed") ||
        (realHours !== "closed" && isNaN(realHours.formattedClosingTime.getTime())) ||
        (realHours !== "closed" && isNaN(realHours.formattedOpeningTime.getTime())) ||
        realHours === "closed" ||
        foundStore.hours[getDayOfWeekFromIndex(today.getDay() + dayInFutureIndex)] === "closed" ||
        (todayStoreHours !== "closed" &&
          futureDate.getTime() >= todayStoreHours.formattedClosingTime.getTime() &&
          futureDate.getDate() === todayStoreHours.formattedClosingTime.getDate()) ||
        today.getTime() > futureDate.getTime() ||
        !isValidOrderTime(foundStore, {
          value: {
            value: futureDatePlusMinInterval,
            trueBusinessDate: getTrueBusinessDate(futureDatePlusMinInterval, foundStore),
          },
        })
      ) {
        dayInFutureIndex++;
        let nextDay = new Date();
        nextDay.setDate(today.getDate() + dayInFutureIndex);
        futureDate = nextDay;
        selectedDayApiOpeningTime = getOpeningTime(foundStore.hours, futureDate.getDay());
        selectedDayApiClosingTime = getClosingTime(foundStore.hours, futureDate.getDay());

        realHours = getFormattedStoreHoursWithMidnight(
          selectedDayApiOpeningTime,
          selectedDayApiClosingTime,
          futureDate,
          foundStore
        );

        if (realHours === "closed") {
          futureDate.setTime(
            new Date(new Date(futureDate).setDate(futureDate.getDate() + 1)).getTime()
          );
        } else {
          futureDate.setTime(realHours.formattedOpeningTime.getTime());
        }

        futureDatePlusMinInterval.setTime(futureDate.getTime() + minInterval * 60000);

        if (dayInFutureIndex >= 7) {
          break;
        }
      }

      selectedDayApiOpeningTime = getOpeningTime(foundStore.hours, futureDate.getDay());
      selectedDayApiClosingTime = getClosingTime(foundStore.hours, futureDate.getDay());
      const selectedDayStoreHours = getFormattedStoreHoursWithMidnight(
        selectedDayApiOpeningTime,
        selectedDayApiClosingTime,
        futureDate,
        foundStore
      );

      let selectedDayStoreOpeningTime = selectedDayStoreHours.formattedOpeningTime;
      let selectedDayStoreOpeningTimeWithMinInterval = new Date(selectedDayStoreOpeningTime);
      selectedDayStoreOpeningTimeWithMinInterval.setTime(
        selectedDayStoreOpeningTimeWithMinInterval.getTime() + defaultMinInterval * 60000
      );

      /** Make sure when the blocked intervals are being checked, they have the same date as the calculated time
       * otherwise isTimeSlotBlocked could return incorrect results
       */
      let blockedTimeIntervalsForTheSelectedDay =
        Object.keys(formattedStoreOverrides).length === 0
          ? []
          : formattedStoreOverrides[
              getDayOfWeekFromIndex(new Date(selectedDayStoreOpeningTimeWithMinInterval).getDay())
            ].filter((interval) => interval.isBlocked);

      if (blockedTimeIntervalsForTheSelectedDay.length > 0) {
        blockedTimeIntervalsForTheSelectedDay.forEach((blockedInterval) => {
          blockedInterval.end.setDate(selectedDayStoreOpeningTimeWithMinInterval.getDate());
          blockedInterval.end.setMonth(selectedDayStoreOpeningTimeWithMinInterval.getMonth());
          blockedInterval.end.setFullYear(selectedDayStoreOpeningTimeWithMinInterval.getFullYear());

          blockedInterval.start.setDate(selectedDayStoreOpeningTimeWithMinInterval.getDate());
          blockedInterval.start.setMonth(selectedDayStoreOpeningTimeWithMinInterval.getMonth());
          blockedInterval.start.setFullYear(
            selectedDayStoreOpeningTimeWithMinInterval.getFullYear()
          );
        });
      }

      while (
        isTimeSlotBlocked(
          new Date(selectedDayStoreOpeningTimeWithMinInterval),
          blockedTimeIntervalsForTheSelectedDay
        )
      ) {
        selectedDayStoreOpeningTimeWithMinInterval.setTime(
          selectedDayStoreOpeningTimeWithMinInterval.getTime() + defaultMinInterval * 60000
        );

        if (blockedTimeIntervalsForTheSelectedDay.length > 0) {
          blockedTimeIntervalsForTheSelectedDay.forEach((blockedInterval) => {
            blockedInterval.end.setDate(selectedDayStoreOpeningTimeWithMinInterval.getDate());
            blockedInterval.end.setMonth(selectedDayStoreOpeningTimeWithMinInterval.getMonth());
            blockedInterval.end.setFullYear(
              selectedDayStoreOpeningTimeWithMinInterval.getFullYear()
            );

            blockedInterval.start.setDate(selectedDayStoreOpeningTimeWithMinInterval.getDate());
            blockedInterval.start.setMonth(selectedDayStoreOpeningTimeWithMinInterval.getMonth());
            blockedInterval.start.setFullYear(
              selectedDayStoreOpeningTimeWithMinInterval.getFullYear()
            );
          });
        }
      }
      return selectedDayStoreOpeningTimeWithMinInterval;
    }
  }
};

export const getOrderStoreAddressForDialogModal = (
  activeOrderStore,
  appLabels,
  orderDate,
  orderTime,
  activeOrderType,
  orderTimeContext,
  skin
) => {
  let currentDate = new Date();
  const storeConfig = activeOrderStore.vexilorConfig;
  const formattedStoreOverrides = formatStoreTimeOverrides(
    activeOrderType === "delivery"
      ? storeConfig.delivery_min_interval_override
      : storeConfig.pickup_min_interval_override,
    orderTime
  );
  const minInterval = getOverrideMinInterval(
    formattedStoreOverrides,
    orderTimeContext.value.value,
    activeOrderType,
    storeConfig
  );
  currentDate.setSeconds("00");
  orderTimeContext.value.value.setSeconds("00");
  let timeDiff = Math.abs(currentDate - orderTimeContext.value.value) / 60000;
  // round time to nearest minute
  timeDiff = Math.round(timeDiff);
  let newOrderDate = "";
  if (timeDiff < minInterval && orderTimeContext.value.value.getDate() === currentDate.getDate()) {
    let nextTime = calculateNextAvailableOrderTime(activeOrderStore, activeOrderType, skin);
    newOrderDate =
      `${appLabels["order"]["today"]} ` +
      nextTime
        .toLocaleString("en-US", { hour: "numeric", minute: "numeric", hour12: true })
        .substring(0, 8)
        .toLowerCase();
  } else {
    let orderDayName =
      orderTimeContext.value.value.toLocaleString("en-us", { weekday: "short" }) + ", ";
    let orderDayMonth = orderTimeContext.value.value.toLocaleString("en-us", { month: "short" });
    let orderDayDate = orderTimeContext.value.value.getDate();
    let orderDayHours = orderTimeContext.value.value.getHours();
    let orderDayMinutes = orderTimeContext.value.value.getMinutes();

    if (orderDayMinutes >= 0 && orderDayMinutes <= 9) {
      orderDayMinutes = "0" + orderDayMinutes;
    }
    let ampm = orderDayHours >= 12 ? "pm" : "am";
    orderDayHours = orderDayHours % 12;
    orderDayHours = orderDayHours ? orderDayHours : 12;
    let strTime = orderDayHours + ":" + orderDayMinutes + " " + ampm;
    let today = new Date().getDay();
    if (today === orderTimeContext.value.value.getDay()) {
      orderDayName = `${appLabels["order"]["today"]}`;
      orderDayMonth = "";
      orderDayDate = "";
    }
    newOrderDate = orderDayName + "" + orderDayMonth + " " + orderDayDate + " " + strTime;
  }
  return (
    <>
      <span className="order-status__order-details-date-address">
        <h2 className="order-status__order-details-confirm-label">
          {appLabels["order"]["confirm-your-pickup-location"]}
        </h2>
        <p>
          <b>{activeOrderStore.name}</b>
          <br />
          {`${activeOrderStore.address}, ${activeOrderStore.city},`}
          <br />
          {`${activeOrderStore.province}, ${activeOrderStore.postal}`}
          <br />
          <br />
          <b>{newOrderDate}</b>
        </p>
      </span>
    </>
  );
};
