import React, { useContext } from "react";
import { Link } from "react-router-dom";
import AppLabelsContext from "../../App/AppLabelsContext";
import MerchantConfigContext from "../../App/MerchantConfigContext";
import { LoginRegisterGuestLink } from "../LoginRegisterGuestLink";
import "./LoginRegisterLinks.css";

export const LoginRegisterLinks = ({ proceedToDashboard }) => {
  const appLabels = useContext(AppLabelsContext);

  const merchantConfigContext = useContext(MerchantConfigContext);
  const merchantConfig = merchantConfigContext.merchant;
  const isRegistrationEnabled = merchantConfig.I24 === "t";
  const isGuestEnabled = merchantConfig.I18 === "t";

  return (
    <div className="login-or-register-buttons">
      {isRegistrationEnabled && (
        <div className="login-or-register__register">
          <Link to="/register">
            {appLabels["login-register"]["dont-have-account"]}?
            <span>{appLabels["login-register"]["register"]}</span>
          </Link>
        </div>
      )}

      {isGuestEnabled && <LoginRegisterGuestLink proceedToDashboard={proceedToDashboard} />}
    </div>
  );
};
