import React, { useState, useEffect, useRef, useContext } from "react";
import InputMask from "react-input-mask";
import AppLabelsContext from "../../App/AppLabelsContext";
import { PasswordVisibilityToggle } from "./PasswordVisibilityToggle";
import "./FormInput.css";
import StoreContext from "../../OnlineOrdering/StoreContext";
import LocationsContext from "../../OnlineOrdering/Locations/LocationsContext";
import MerchantConfigContext from "../../App/MerchantConfigContext";
import AppLanguageContext from "../../App/AppLanguageContext";

// API helper functions
import { get1300Stores } from "../../OnlineOrdering/GoogleMaps/get1300Stores";

export const FormInput = (props) => {
  const {
    resetInvalidField,
    resetPatternTooltip,
    defaultValue,
    fieldData,
    isSmall,
    id,
    isMaskedInput,
    name,
    handleInputChangeFromParent,
    onInputChange,
    value,
    placeholder,
    type,
    title,
    maxlength,
    isRequired,
    autocomplete,
    label,
    showAstr,
    onInputBlur,
    onFocusAction,
    customLabelClass,
    customerWrapperClass,
  } = props;

  const [inputInFocusClass, setInputInFocusClass] = useState("");
  
  const activeOrderStore = useContext(StoreContext).activeOrderStore;
  let allLocations = useContext(LocationsContext);
  const merchantConfigContext = useContext(MerchantConfigContext);
  const appLanguage = useContext(AppLanguageContext);
  const { skin } = merchantConfigContext;

  const onFocus = (field) => {
    resetInvalidField(field);
    setInputInFocusClass(" form__field-wrapper--in-focus");
    if (onFocusAction) {
      onFocusAction();
    }
  };

  const onClickAway = (field) => {
    if (onInputBlur) {
      onInputBlur(field);
    } else {
      /* If any value is present keep placeholder minimized, otherwise expand it */
      field.value === "" && setInputInFocusClass("");
      resetPatternTooltip(field);
    }
  };

  // if Input has a default value - Set focused label class and perform its onSelectChange
  const inputRef = useRef();
  useEffect(() => {
    if (defaultValue && !inputInFocusClass && inputRef.current) {
      setInputInFocusClass(" form__field-wrapper--in-focus");
      onFocus(inputRef.current);
    }
  }, [inputRef.current]);

  const appLabels = useContext(AppLabelsContext);

  const [passwordType, setPasswordType] = useState("password");
  let [showWarningMessage, setShowWarningMessage] = useState(false)
  let [formattedPhoneNumber,setFormattedPhoneNumber] = useState("")
  let [countryCode, setCountryCode] = useState()
  let countryCodeFromContext = ""
 
  //converts a valid 10 digit phone number into (xxx) xxx-xxxx format
	let phoneNumberAutoFormat = (phoneNumber) => {
		const number = phoneNumber.trim().replace(/[^0-9]/g, '');

		if (number.length < 4) return number;
		if (number.length < 7) return number.replace(/(\d{3})(\d{1})/, '($1) $2');
		if (number.length < 11)
			return number.replace(/(\d{3})(\d{3})(\d{1})/, '($1) $2-$3');
		return number.replace(/(\d{3})(\d{4})(\d{4})/, '$1-$2-$3');
	};
  
  //get the value of phone number and format it if user is in CA/US
  const handlePhoneNumberInput = (e) => {
    // only do formatting if US/CA country is selected
    if (countryCode === "US" || countryCode === "CA") {
      let value = e.target.value;
      value = value.replace(/([^+0-9]+)/gi, ''); //removes any character that is not number
      let formattedPhn = value;
      if (value.charAt(0) === "1") {
        setShowWarningMessage(true)
      }

      if (value.length <= 10)
        formattedPhn = phoneNumberAutoFormat(value); // retuns phone number in format (xxx) xxx-xxxx
      else if (value.length > 10) {
        // if input value is exceeding 10, set warning to true and return a substring of value upto 10 digits.
        value = value.substr(0, 10);
        formattedPhn = phoneNumberAutoFormat(value);
      }
		
      let length = formattedPhn.length;
      if (length <= 14 && value.charAt(0) !== "1") {
        setShowWarningMessage(false);
      }
      setFormattedPhoneNumber(formattedPhn)
    }
    else {
      let value = e.target.value
      setFormattedPhoneNumber(value.replace(/([^+0-9]+)/gi, ''))
    }
  };
  
  
  
  //called when an input of type tel has a change in value.
  function onTelInputChange(e) {
		if (e.target.name === "phone" || e.target.name === "phone-number" || e.target.name === "cell-number")
			handlePhoneNumberInput(e);
  }

  useEffect(() => {
    
    if (!allLocations && (name === "phone" ||name === "phone-number" || name === "cell-number")) {
      get1300Stores(
        skin,
        "TAKE_OUT",
        appLanguage
      ).then((apiStores) => {
        if (!apiStores || apiStores.length === 0)
          countryCodeFromContext = ""
        else
          allLocations = apiStores;
      });
    }

    if (activeOrderStore){
      countryCodeFromContext = activeOrderStore.country
      setCountryCode(activeOrderStore.country)
    }
    else if (allLocations && allLocations[0]) {
      countryCodeFromContext = allLocations[0].country;
      setCountryCode(allLocations[0].country)
      
    }
    // if there is an exisiting default value for input type tel ,format it for CA/US only
    if (
      (name === "phone" || name === "phone-number" || name === "cell-number") &&
      defaultValue &&
      defaultValue.length > 0 &&
      (countryCodeFromContext === "US" || countryCodeFromContext === "CA")
    ) {
      let formattedDefaultValue = phoneNumberAutoFormat(defaultValue);
      setFormattedPhoneNumber(formattedDefaultValue);
    } 
    else if((name === "phone" || name === "phone-number" || name === "cell-number") && defaultValue ) {
      setFormattedPhoneNumber(defaultValue);
    }
  },[])


  return (
    <>
      {!fieldData.isHidden && (
        <div
          style={!!props.isHidden ? { display: "none" } : {}}
          className={`form__field-wrapper${
            fieldData.isValid ? "" : " form__field-wrapper--invalid"
          }${isSmall ? " form__field-wrapper--small" : ""}
          ${customerWrapperClass ? customerWrapperClass : ""}
          ${inputInFocusClass}`}
        >
          <label
            htmlFor={id}
            className={`${customLabelClass ? customLabelClass : "form__field-label"}`}
          >
            {!!showAstr && <span aria-hidden="true">*</span>}
            {(fieldData.label || label) +
              (fieldData.isRequired || isRequired
                ? ""
                : ` (${appLabels["form"]["optional-label"]})`)}
          </label>
          {isMaskedInput && fieldData.maskPattern ? (
            <InputMask
              ismaskedinput="true"
              className="form__field"
              name={name}
              mask={fieldData.maskPattern}
              maskPlaceholder=""
              onChange={(e) => {
                if (handleInputChangeFromParent) handleInputChangeFromParent(e);
                onInputChange(e);
              }}
              onFocus={(e) => onFocus(e.target)}
              onBlur={(e) => onClickAway(e.target)}
              value={
                (name === "phone" || name === "phone-number" || name === "cell-number")
                  ? formattedPhoneNumber
                  : value || fieldData.value
              }
              title={fieldData.title}
              placeholder={placeholder || fieldData.placeholder}
              minLength={fieldData.minLength}
              ref={inputRef}
            />
          ) : (
            <input
              className={`form__field${
                type === "password" ? " form__field--with-right-button" : ""
              }`}
              type={type === "password" ? passwordType : type}
              value={
                (name === "phone" || name === "phone-number" || name === "cell-number")
                  ? formattedPhoneNumber
                  : value || fieldData.value
              }
              placeholder={placeholder || fieldData.placeholder}
              id={id}
              name={name}
              onChange={(e) => {
                if (handleInputChangeFromParent) handleInputChangeFromParent(e);
                onInputChange(e);

                if (name === "phone" || name === "phone-number" || name === "cell-number") onTelInputChange(e);
              }}
              onFocus={(e) => onFocus(e.target)}
              onBlur={(e) => onClickAway(e.target)}
              aria-required={isRequired}
              title={title}
              maxLength={`${name === "credit-card-number" ? fieldData.maxLength : maxlength}`}
              minLength={fieldData.minLength}
              ref={inputRef}
              autoCapitalize={name.includes("email") ? "none" : "on"} // prevent iOS auto-capitalization
              autoComplete={autocomplete === "off" || !autocomplete ? "new-password" : ""}
            />
          )}
          {type === "password" && (
            <PasswordVisibilityToggle
              type={passwordType}
              toggleVisibility={(newType) => setPasswordType(newType)}
            />
          )}
          {(!fieldData.isValid && !showWarningMessage) && <div className="form__field-error">{fieldData.errorMessage}</div>}
          {(fieldData.isPatternTooltip && !showWarningMessage) && (
            <div className="form__field-error">
              {appLabels["form"]["enter-in-input-mask-pattern-format"].split("[mask-pattern]")[0] +
                appLabels["form"]["enter-in-input-mask-pattern-format"].split("[mask-pattern]")[1]}
            </div>
          )}
          {showWarningMessage && (
            <div className="form__field-error">
              {appLabels["form"]["do-not-include-country-code"]}
            </div>
          )}
        </div>
      )}
    </>
  );
};

FormInput.displayName = "FormInput";
