// Libraries
import React, { useContext } from "react";

// Custom hooks
import useWindowSize from "../hooks/useWindowSize";

// Contexts
import BrandingContext from "../../App/BrandingContext";

// UI components
import { AppHeader } from "../../App/AppHeader/AppHeader";
import { AppFooter } from "../../App/AppFooter/AppFooter";
import { AppDesktopFooter } from "../../App/AppFooter/AppDesktopFooter";
import { AppDesktopHeader } from "../../App/AppHeader/AppDesktopHeader";
import { HeroSection } from "../DesktopLayout/HeroSection";
import OrderSettingsComponent from "../../OrderSettings/OrderSettingsComponent";

export const PageComponentTemplate = ({
  pageHeading,
  isBackButton,
  backButtonDestination,
  isHomeLink,
  isCart,
  bannerImage,
  mainClassOverride,
  isTopBorder,
  component,
  isFooter = true,
  hasHeaderLogo,
  isLanguageButton,
  isMapPin,
  backgroundURL = "",
  isOrderSettingsHidden = true,
  isXButton,
  isFavButton,
}) => {
  const deviceWidth = useWindowSize().width;
  const classOverride = mainClassOverride ? " " + mainClassOverride : "";
  const branding = useContext(BrandingContext);

  return (
    <>
      {deviceWidth < 660 && (pageHeading || hasHeaderLogo) && (
        <AppHeader
          pageHeading={pageHeading}
          isBackButton={isBackButton}
          backButtonDestination={backButtonDestination}
          isHomeLink={isHomeLink}
          isCart={isCart}
          isLanguageButton={isLanguageButton}
          isLogo={hasHeaderLogo}
          isMapPin={isMapPin}
          isXButton={isXButton}
          isFavButton={isFavButton}
        />
      )}
      {deviceWidth >= 660 && <AppDesktopHeader />}
      {bannerImage && deviceWidth >= 660 && <HeroSection imageUrl={bannerImage} />}
      <main
        id="main-content"
        className={`main-content${classOverride}`}
        style={{
          borderTop: isTopBorder ? `5px solid ${branding["primary-colour"]}` : `none`,
          backgroundImage: backgroundURL && deviceWidth >= 660 ? `url(${backgroundURL})` : "none",
          backgroundSize: backgroundURL && deviceWidth >= 660 ? `cover` : "",
        }}
      >
        {component}
      </main>
      {<OrderSettingsComponent customClass={isOrderSettingsHidden ? "visually-hidden" : ""} />}
      {isFooter && <>{deviceWidth < 660 ? <AppFooter /> : <AppDesktopFooter />}</>}
    </>
  );
};
