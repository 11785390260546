// Libraries
import React, { useContext, useEffect, useState } from "react";
import { Link, useHistory } from "react-router-dom";
import Barcode from "react-barcode";
import QrCode from "qrcode.react";

// Custom hooks
import { useAutoReloadScheduleText } from "../_common/hooks/useAutoReloadScheduleText";

// API helper functions
import { getApiSUN } from "./getApiSUN";
import { get1023AutomatedReloadSchedule } from "./get1023AutomatedReloadSchedule";
import { removeAPIAutoReload } from "./removeAPIAutoReload";

import { AddToWalletLink } from "../Account/AddToWalletLink";

// Contexts
import AppLanguageContext from "../App/AppLanguageContext";
import AppLabelsContext from "../App/AppLabelsContext";
import MerchantConfigContext from "../App/MerchantConfigContext";
import UserRoleContext from "../App/UserRoleContext";

// Assets
import { ReactComponent as IconRefresh } from "../_common/icons/IconRefresh.svg";

const BARCODE_TYPES = ["code128", "code39", "qrcode"];

export const ScanInStoreQRCodeInfo = (props) => {
  const {
    activeLoyaltyCard,
    setActiveLoyaltyCard,
    currentSUNNumber,
    setCurrentSUNNumber,
    reloadScreen,
    isAddToWalletServiceEnabled,
    isGiftCardAutoReloadServiceEnabled,
  } = props;

  const appLanguage = useContext(AppLanguageContext);
  const appLabels = useContext(AppLabelsContext);
  const merchantConfigContext = useContext(MerchantConfigContext);
  const userRoleContext = useContext(UserRoleContext);

  const isAutoReloadEnabled = merchantConfigContext.merchant.I12 === "t";
  const isManualReloadEnabled = merchantConfigContext.merchant.I9 === "t";

  const history = useHistory();

  const [activeBarcodeFormat, setActiveBarcodeFormat] = useState(null);
  useEffect(() => {
    if (activeLoyaltyCard && !!activeLoyaltyCard.preferredCodeFormat) {
      setActiveBarcodeFormat(activeLoyaltyCard.preferredCodeFormat);
    } else {
      setActiveBarcodeFormat("qrcode");
    }
  }, [activeLoyaltyCard]);

  const [qrCodeImage, setQRCodeImage] = useState(false);
  useEffect(() => {
    if (currentSUNNumber && activeBarcodeFormat) {
      if (activeBarcodeFormat === "code128") {
        setQRCodeImage(
          <Barcode
            value={activeLoyaltyCard.SUN}
            format="CODE128"
            width={2.5}
            height={50}
            displayValue={false}
          />
        );
      } else if (activeBarcodeFormat === "code39") {
        setQRCodeImage(
          <Barcode
            value={activeLoyaltyCard.SUN}
            format="CODE39"
            width={1.5}
            height={50}
            displayValue={false}
          />
        );
      } else if (activeBarcodeFormat === "qrcode") {
        setQRCodeImage(
          <QrCode value={activeLoyaltyCard.SUN} renderAs="svg" size={128} includeMargin={true} />
        );
      }
    }
  }, [currentSUNNumber, activeBarcodeFormat]);

  const skin = merchantConfigContext.skin;
  const loginToken = userRoleContext.loginToken;

  const removeAutoReload = () => {
    // TODO: add a confirmation dialog

    removeAPIAutoReload(skin, loginToken, activeLoyaltyCard, appLanguage).then(
      (apiAutoReloadRemovalResult) => {
        if (apiAutoReloadRemovalResult) {
          if (apiAutoReloadRemovalResult.status === "expiredLoginToken") {
            userRoleContext.handleLoginTokenExpiration();
          } else if (apiAutoReloadRemovalResult.isRemoved) {
            history.push("/pay-in-store");
            window.location.reload(); //TEMP: SOLUTION TO UPDATE ALL THE DATA
          }
        }
      }
    );
  };

  const [autoReloadSchedule, setAutoReloadSchedule] = useState(null);
  useEffect(() => {
    if (
      loginToken &&
      activeLoyaltyCard &&
      isAutoReloadEnabled &&
      isGiftCardAutoReloadServiceEnabled
    ) {
      // Check and see if the select card already has an auto-reload set
      get1023AutomatedReloadSchedule(skin, loginToken, activeLoyaltyCard, appLanguage).then(
        (apiResult) => {
          if (apiResult.data) {
            setAutoReloadSchedule(apiResult.data);
          } else if (apiResult.status === "expiredLoginToken") {
            userRoleContext.handleLoginTokenExpiration();
          }
        }
      );
    }
  }, [activeLoyaltyCard, reloadScreen, isAutoReloadEnabled, isGiftCardAutoReloadServiceEnabled]);

  const updateSUN = () => {
    const tempActiveLoyaltyCard = { ...activeLoyaltyCard };

    getApiSUN(skin, loginToken, activeLoyaltyCard, appLanguage).then((apiSUN) => {
      if (apiSUN) {
        if (apiSUN.status === "expiredLoginToken") {
          userRoleContext.handleLoginTokenExpiration();
        } else {
          setCurrentSUNNumber(apiSUN.SUN);
          tempActiveLoyaltyCard.SUN = apiSUN.SUN;
          tempActiveLoyaltyCard.preferredCodeFormat = apiSUN.preferredCodeFormat;
        }
      }
    });

    setActiveLoyaltyCard(tempActiveLoyaltyCard);
  };

  const autoReloadScheduleText = useAutoReloadScheduleText(autoReloadSchedule);

  return (
    <div className="payInStore__selectedCard-card-container">
      <div className="payInStore__selectedCard-card">
        <div className="scan-in-store__qr-code-content">
          {qrCodeImage && <div className="payInStore__selectedCard-barcode">{qrCodeImage}</div>}
          {currentSUNNumber && (
            <div className="payInStore__selectedCard-barcode-number">{currentSUNNumber}</div>
          )}
          <button
            onClick={updateSUN}
            className="button button--primary scan-in-store-qr-card__refresh-code-button"
            type="button"
          >
            <IconRefresh aria-hidden="true" />
            <span>{appLabels["account"]["refresh-code"]}</span>
          </button>
          <div className="payInStore__selectedCard-barcode-buttons">
            {BARCODE_TYPES.map(
              (barcodeType) =>
                barcodeType !== activeBarcodeFormat && (
                  <React.Fragment key={barcodeType}>
                    {barcodeType === "code39" && (
                      <button
                        key={barcodeType}
                        onClick={() => setActiveBarcodeFormat("code39")}
                        aria-label={`Change to ${appLabels["general"]["barcode"]} 39 type`}
                        className="barcode-type"
                        type="button"
                      >
                        {appLabels["general"]["barcode"]} 39
                      </button>
                    )}
                    {barcodeType === "code128" && (
                      <button
                        key={barcodeType}
                        onClick={() => setActiveBarcodeFormat("code128")}
                        aria-label={`Change to ${appLabels["general"]["barcode"]} 128 type`}
                        className="barcode-type"
                        type="button"
                      >
                        {appLabels["general"]["barcode"]} 128
                      </button>
                    )}
                    {barcodeType === "qrcode" && (
                      <button
                        key={barcodeType}
                        onClick={() => setActiveBarcodeFormat("qrcode")}
                        aria-label={`Change to ${appLabels["order"]["qr-code"]} type`}
                        className="barcode-type"
                        type="button"
                      >
                        {appLabels["order"]["qr-code"]}
                      </button>
                    )}
                  </React.Fragment>
                )
            )}
          </div>
        </div>
        <div className="scan-in-store__add-money-and-autoreload">
          {autoReloadScheduleText && (
            <p className="scan-in-store__autoreload-status">{autoReloadScheduleText}</p>
          )}
          <div className="payInStore__selectedCard-buttons">
            {(isAutoReloadEnabled || isManualReloadEnabled) && (
              <Link
                to="/pay-in-store/add-money"
                className="button button--primary button--primary-with-border button--primary-small"
              >
                {appLabels["account"]["add-money"]}
              </Link>
            )}
            {autoReloadSchedule && autoReloadSchedule.isActive && (
              <button
                onClick={removeAutoReload}
                className="button button--primary button--primary-with-border button--primary-small"
                type="button"
              >
                {appLabels["account"]["button-remove-autoreload"]}
              </button>
            )}
          </div>

          {isAddToWalletServiceEnabled && (
            <div className="payInStore__selectedCard-buttons">
              <AddToWalletLink activeLoyaltyCard={activeLoyaltyCard} loginToken={loginToken} />
            </div>
          )}
        </div>
      </div>
    </div>
  );
};
