import React, { useContext } from "react";
import ReactDOM from "react-dom";
import { withRouter, Link } from "react-router-dom";

import BrandingContext from "../../App/BrandingContext";
import AppLabelsContext from "../../App/AppLabelsContext";

import { ReactComponent as IconX } from "../icons/IconX.svg";
import { ReactComponent as IconArrowBack } from "../icons/IconArrowBack.svg";

import "./Modal.css";

export const Modal = withRouter((props) => {
  const brandingContext = useContext(BrandingContext);
  const primaryColor = brandingContext["primary-colour"];

  const appLabels = useContext(AppLabelsContext);

  return (
    !!props.isModal &&
    ReactDOM.createPortal(
      <>
        <div
          className={`modal-wrapper${props.type ? " modal-wrapper--" + props.type : ""}${
            props.isTransparent ? " modal-wrapper--transparent" : ""
          } ${props.wrapperClass ? props.wrapperClass : ""}`}
          style={{
            borderTop: props.type === "fullscreen" ? `5px solid ${primaryColor}` : `none`,
          }}
          aria-label={props.description}
          aria-modal
          aria-hidden
          tabIndex={-1}
          role="dialog"
          onClick={(event) => {
            if (props.isClickAway && event.target.classList.contains("modal-wrapper")) {
              props.toggleModal(event);
            }
          }}
        >
          <div
            className={`modal${props.type ? " modal--" + props.type : ""} ${
              props.closeAnimation || ""
            }${props.disableAnimation ? " modal--static" : ""}${
              props.isTransparent ? " modal--transparent" : ""
            } ${props.customModalClass ? props.customModalClass : ""}`}
          >
            {props.heading && <h1 className="modal__heading">{props.heading}</h1>}
            {props.isCloseButton && (
              <button
                className="button modal__button--close"
                onClick={props.toggleModal}
                aria-label={appLabels["general"]["close"] +" "+ props.description}
                type="button"
              >
                <IconX />
              </button>
            )}
            {props.isLinkBack && (
              <Link
                to={{
                  pathname: props.linkBackPathname ? props.linkBackPathname : "/",
                  state: { from: props.location.pathname },
                }}
                className="modal__link--back"
              >
                <IconArrowBack />
              </Link>
            )}
            {props.content}
            {(props.isCancelButton || props.isSubmitButton) && (
              <div className="modal__buttons">
                {props.isCancelButton && (
                  <button
                    className="button button--primary button--primary-with-border button--primary-small"
                    onClick={(event) => {
                      if (props.onCancel) props.onCancel();
                      props.toggleModal(event);
                    }}
                    type="button"
                  >
                    {appLabels["general"]["dialog-modal-cancel"]}
                  </button>
                )}
                {props.isSubmitButton && (
                  <button
                    className="button button--primary button--primary-small"
                    onClick={() => {
                      if (props.onSubmit) props.onSubmit();
                      props.toggleModal();
                    }}
                    type="button"
                  >
                    {props.fabButtonText
                      ? props.fabButtonText
                      : appLabels["general"]["dialog-modal-ok"]}
                  </button>
                )}
              </div>
            )}
          </div>
        </div>
      </>,
      document.body
    )
  );
});
