// Libraries
import React, { useContext } from "react";
import ReactDOM from "react-dom";

// Contexts
import AppLabelsContext from "../../App/AppLabelsContext";
import StoreContext from "../../OnlineOrdering/StoreContext";
import { MenuContext } from "../../OnlineOrdering/Menu/MenuContext";

//UI Components
import { LoadingSpinner } from "../LoadingSpinner";

// Assets
import { ReactComponent as IconX } from "../icons/IconX.svg";

// CSS
import "./DialogModal.css";

const DialogModalPortal = ({
  isOrderSettingsModal = false,
  description,
  heading,
  message,
  confirmAction,
  isCancelConfirm,
  isReorderFlow,
  resetRemoveDialog,
  isHTMLContent,
  isConfirmText,
  isModifyText,
  isCancelConfirmText,
  closeModalAction,
  hideCloseButton,
  hideConfirmButton,
  customClass,
  showConfirmLoadingSpinner,
  isCustomConfirmText,
}) => {
  const appLabels = useContext(AppLabelsContext);
  const { activeOrderStore } = useContext(StoreContext);
  const orderMenu = useContext(MenuContext);

  const confirmChoice = () => {
    confirmAction();
  };

  const closeModal = () => {
    resetRemoveDialog();
  };

  return ReactDOM.createPortal(
    <div
      role="dialog"
      aria-modal="true"
      aria-label={description}
      className={`dialog-modal-container ${customClass ? customClass : ""} `}
    >
      <div className="dialog-modal">
        {heading && <h1 className="dialog-modal__heading">{heading}</h1>}
        {!!isHTMLContent ? message : <div className="dialog-modal__message">{message}</div>}
        <div
          className={`dialog-modal__buttons ${isCancelConfirm ? " dialog-modal__buttons-two" : ""}`}
        >
          {isCancelConfirm ? (
            <>
              <button
                onClick={closeModal}
                className={`button dialog-modal__button  button--primary-with-border button--primary button--primary-small ${
                  isReorderFlow ? " dialog-modal__reorder-flow" : ""
                }`}
                type="button"
              >
                {isModifyText ? isModifyText : appLabels["general"]["dialog-modal-cancel"]}
              </button>
              <button
                onClick={confirmChoice}
                className={`button dialog-modal__button button--primary button--primary-small ${
                  isReorderFlow ? " dialog-modal__reorder-flow" : ""
                }`}
                type="button"
              >
                {showConfirmLoadingSpinner ? (
                  <LoadingSpinner />
                ) : isCancelConfirmText ? (
                  isCancelConfirmText
                ) : (
                  appLabels["general"]["dialog-modal-confirm"]
                )}
              </button>
            </>
          ) : (
            <>
              {!hideConfirmButton && (
                <button
                  onClick={closeModal}
                  className={`button dialog-modal__button button--primary ${
                    isReorderFlow ? " dialog-modal__reorder-flow" : ""
                  }`}
                  type="button"
                  disabled={
                    isOrderSettingsModal &&
                    activeOrderStore &&
                    (activeOrderStore["__isUnavailable"] || !orderMenu.apiData)
                  }
                >
                  <>
                    {showConfirmLoadingSpinner && <LoadingSpinner />}
                    {isCustomConfirmText
                      ? isCustomConfirmText
                      : isConfirmText
                      ? appLabels["general"]["dialog-modal-confirm"]
                      : appLabels["general"]["dialog-modal-ok"]}
                  </>

                  {/*activeOrderStore && !orderMenu.apiData && <LoadingSpinner />*/}
                </button>
              )}
            </>
          )}
        </div>
        {!hideCloseButton && (
          <button
            onClick={closeModalAction ? () => closeModalAction() : closeModal}
            aria-label={appLabels["general"]["close-dialog-modal"]}
            className="button dialog-modal__close-button"
            type="button"
          >
            <IconX aria-hidden="true" />
          </button>
        )}
      </div>
    </div>,
    document.body
  );
};

export const DialogModal = React.memo(DialogModalPortal);
