import React, { useState, useEffect, useContext } from "react";
import { Link } from "react-router-dom";
import useWindowSize from "../../_common/hooks/useWindowSize";

import { toDollars } from "../../_common/helpers";

import BrandingContext from "../../App/BrandingContext";
import AppLabelsContext from "../../App/AppLabelsContext";
import MerchantConfigContext from "../../App/MerchantConfigContext";
import AppSettingsContext from "../../App/AppSettingsContext";
import AppLanguageContext from "../../App/AppLanguageContext";

import { LoadingSpinner } from "../../_common/LoadingSpinner";

import { ReactComponent as IconStar } from "../../_common/icons/IconStar.svg";

import "./DashboardRewards.css";

export const DashboardRewards = ({ userGiftCards }) => {
  const merchantConfigContext = useContext(MerchantConfigContext);
  //const merchantConfig = merchantConfigContext.merchant;
  const skin = merchantConfigContext.skin;

  const brandingContext = useContext(BrandingContext);
  const secondaryColor = brandingContext["secondary-colour"];

  const [activeCard, setActiveCard] = useState(null);

  useEffect(() => {
    const storedActiveLoyaltyCard = JSON.parse(
      sessionStorage.getItem(skin + "__activeLoyaltyCard")
    );

    if (storedActiveLoyaltyCard) setActiveCard(storedActiveLoyaltyCard);
    else if (userGiftCards) setActiveCard(userGiftCards[0]);
  }, []);

  const appSettings = useContext(AppSettingsContext);
  const appLanguage = useContext(AppLanguageContext);

  /*const [showProgressBar, setShowProgressBar] = useState(null);
  //const [merchantRewards, setMerchantRewards] = useState(null);
  useEffect(() => {
    const apiMerchantRewards = merchantConfig.I14;

    if (apiMerchantRewards) {
      let reformattedMerchantRewards = [];

      apiMerchantRewards.forEach((apiMerchantReward) => {
        const reformattedReward = {
          id: apiMerchantReward[0],
          name: apiMerchantReward[1],
          description: apiMerchantReward[2],
          price: parseInt(apiMerchantReward[3]),
          typeCode: apiMerchantReward[5],
          typeName: apiMerchantReward[6],
        };

        if (apiMerchantReward[4].includes("/images/")) {
          reformattedReward.image = apiMerchantReward[4];
        }
        reformattedMerchantRewards.push(reformattedReward);
      });

      reformattedMerchantRewards = reformattedMerchantRewards.sort(
        (item1, item2) => parseInt(item1.price) - parseInt(item2.price)
      );
      //setMerchantRewards(reformattedMerchantRewards);

      /**If merchant does not have any rewards of type
       * 6(Points to Money Conversion: Manual Redemption) or
       * 7(Points to Reward Conversion),
       * do not show the rewards progress bar 
      if (
        reformattedMerchantRewards.filter(
          (reward) => reward.typeCode === "6" || reward.typeCode === "7"
        ).length > 0
      ) {
        setShowProgressBar(true);
      } else {
        setShowProgressBar(false);
      }
    }
  }, []);*/

  //const [activeReward, setActiveReward] = useState(null);

  const appLabels = useContext(AppLabelsContext);

  const deviceWidth = useWindowSize().width;
  return (
    <section className="dashboard__module dashboard__rewards-module ">
      <div className="dashboard__module-main">
        {userGiftCards && activeCard ? (
          <>
            <div className="rewards-module__balance-and-my-rewards-link">
              {deviceWidth < 600 && (
                <div className="rewards-module__row">
                  <span className="rewards-module__balance-label-header">
                    {" "}
                    {appLabels["loyalty"]["my-rewards"]}
                  </span>
                </div>
              )}

              <div
                className={`${
                  deviceWidth < 600 ? "rewards-module__row" : "rewards-module__column"
                }`}
              >
                <div
                  className={`${
                    deviceWidth < 600 ? "rewards-module__column" : "rewards-module__row"
                  }`}
                >
                  <span className="rewards-module__balance-label rewards-module__balance-label-rewards">
                    {appLabels["loyalty"]["reward-balance"]}
                  </span>

                  <span className="rewards-module__balance">
                    {activeCard.points}
                    <IconStar aria-hidden="true" />
                  </span>
                </div>

                <div
                  className={`${
                    deviceWidth < 600 ? "rewards-module__column" : "rewards-module__row"
                  }`}
                >
                  <span className="rewards-module__balance-label">
                    {appLabels["general"]["gift-card"]}
                  </span>

                  <span className="rewards-module__balance rewards-module__balance-money">
                    {toDollars(
                      appSettings["currency-symbol"],
                      appSettings["currency-symbol-side"],
                      activeCard.balance,
                      appLanguage
                    )}
                  </span>
                </div>
              </div>

              <div className="rewards-module__row view-rewards-link">
                <Link
                  to="/my-rewards"
                  className="link--section-header rewards-module__my-rewards-link"
                  style={{ color: secondaryColor }}
                >
                  {appLabels["loyalty"]["view-rewards"]}
                </Link>
              </div>
            </div>
          </>
        ) : (
          <LoadingSpinner />
        )}
      </div>
    </section>
  );
};
