// Libraries
import React, { useState, useEffect, useContext, useRef } from "react";
import { useHistory, useParams, useLocation } from "react-router-dom";
import useWindowSize from "../../../_common/hooks/useWindowSize";
import localforage from "localforage";

// Helper functions
import { findPos, getArray, isArrItems, jsonCopy, toDollars } from "../../../_common/helpers";
import {
  checkOutOfStockAddons,
  checkRequiredSelection,
  getTotalItemPriceBasedOnSelection,
} from "../../../_common/MenuHelpers";
import {
  determineNextOptionGroupButtonText,
  determineOrderingStateActiveButton,
  getActiveItemDetails,
  hideCartIcon,
  reachedMinRequirements,
  showCartIcon,
} from "./MenuItemDetailHelpers";
import { isFavouriteItem } from "../../Favorites/favoriteHelpers";
import { onProductClickXtreme } from "../../../_common/helpers/xtremePushHelper";

// Contexts
import AppSettingsContext from "../../../App/AppSettingsContext";
import AppLanguageContext from "../../../App/AppLanguageContext";
import StoreContext from "../../StoreContext";
import { MenuContext } from "../MenuContext";
import CartContext from "../../Cart/CartContext";
import BrandingContext from "../../../App/BrandingContext";
import AppLabelsContext from "../../../App/AppLabelsContext";
import MerchantConfigContext from "../../../App/MerchantConfigContext";
import UserRoleContext from "../../../App/UserRoleContext";
import OrderTypeContext from "../../OrderTypeContext";

// UI components
import { AddonsOrModifiers } from "./temp/AddonsOrModifiers";
import { SpecialInstructions } from "./SpecialInstructions";
import { CaloriesDisclaimer } from "../CaloriesDisclaimer";
import { MenuAddToOrder } from "./MenuAddToOrder";
import CartSaveChanges from "./CartSaveChanges";
import { LoadingSpinner } from "../../../_common/LoadingSpinner";
import { QuantityButtons } from "./QuantityButtons";
import { AdditionalDisclaimer } from "../../../_common/AdditionalDisclaimer";
import { DialogModal } from "../../../_common/DialogModal/DialogModal";
import OptionGroupNextButton from "./OptionGroupNextButton";
import { ButtonAddToFavorites } from "../../../_common/buttons/ButtonAddToFavorites/ButtonAddToFavorites";

//Assets
import { ReactComponent as IconX } from "../../../_common/icons/IconX.svg";

// CSS
import "./MenuItemDetail.css";

export const MenuItemDetail = (props) => {
  const {
    displayType,
    cartItem,
    //setShowEditItemModal,
    //setIsCartFAB,
    isInCart,
    //setOrderSummaryData,
    backButtonDestination,
    hasItemInProgress,
  } = props;

  const history = useHistory();
  const { category, subcategory, item } = useParams();

  const deviceWidth = useWindowSize().width;
  const { state } = useLocation();

  const orderMenu = useContext(MenuContext);
  const appSettings = useContext(AppSettingsContext);
  const appLanguage = useContext(AppLanguageContext);
  const storeContext = useContext(StoreContext);
  const cartContext = useContext(CartContext);
  const skin = useContext(MerchantConfigContext).skin;
  const brandingContext = useContext(BrandingContext);
  const appLabels = useContext(AppLabelsContext);
  const userRoleContext = useContext(UserRoleContext);
  const orderTypeContext = useContext(OrderTypeContext);

  const activeOrderType = orderTypeContext.value;
  const isLoggedIn = userRoleContext.status === "logged-in";

  const [menuItem, setMenuItem] = useState(null);

  const performAutoScroll = useRef(true);

  const [closingAnimationClass, setClosingAnimationClass] = useState("");
  const [isFAB, setIsFAB] = useState(true);
  const [specialInstructions, setSpecialInstructions] = useState("");
  const [addonsOutOfStock, setAddonsOutOfStock] = useState(false);
  const [outOfStockError, setOutOfStockError] = useState("");
  const [isAddonOrModifier, setIsAddonOrModifier] = useState(null);
  const [isGuestFavoriteAttempt, setIsGuestFavoriteAttempt] = useState(null);
  const [isFavAttemptBeforeSelection, setIsFavAttemptBeforeSelection] = useState(null);
  const [isFavItem, setIsFavItem] = useState(false);

  const [addToOrderButtonText, setAddToOrderButtonText] = useState("");

  const [formattedOptionGroups, setFormattedOptionGroups] = useState({});
  const [orderingState, setOrderingState] = useState({
    currentActiveOptionGroupIndex: 0,
    numberOfOptionGroups: null,
    hideAllButtons: false,
    activeButton: "add" /** possible values: add (for add to order), next (For next button) */,
  });

  const isMenuDisplay = displayType === "menu";
  const isModifyDisplay = displayType === "cart-modify";
  const isThreeLevels = orderMenu.menuLevel === "threeLevels";
  const isTwoLevels = orderMenu.menuLevel === "twoLevels";
  const isStepByStepOrderingEnabled =
    appSettings["enable-step-by-step-ordering"] === "yes" && isMenuDisplay && !hasItemInProgress;
  const isSpecialInstructionEnabled =
    storeContext.activeOrderStore.vexilorConfig.cooking_instruction === "t";

  const noImagePlaceholder = brandingContext["no-image-available-placeholder"];
  const outOfStockImage = brandingContext["item-out-of-stock-image"];
  const isItemOutOfStock = menuItem ? Number(menuItem.item_count) <= 0 : false;

  const showPriceOnOrderButton =
    appSettings["show-price-for-order-button"] === "Yes" ||
    !appSettings["show-price-for-order-button"];

  const [isAtLastStage, setIsAtLastStage] = useState(isStepByStepOrderingEnabled ? false : true);
  const [isShowingFullItemDescription, setIsShowingFullItemDescription] = useState(
    appSettings["enable-step-by-step-ordering"] === "yes" ? false : true
  );
  const [isItemDescriptionMinimized, setIsItemDescriptionMinimized] = useState(
    appSettings["enable-step-by-step-ordering"] === "yes" ? true : false
  );
  /** see how many addon and modifier groups this item has */
  useEffect(() => {
    if (menuItem) {
      const updateState = async () => {
        const activeButton = await determineOrderingStateActiveButton(
          menuItem,
          orderingState.currentActiveOptionGroupIndex,
          formattedOptionGroups,
          isStepByStepOrderingEnabled,
          orderingState.numberOfOptionGroups,
          false,
          skin
        );
        if (activeButton) {
          updateOrderingState({
            numberOfOptionGroups:
              Object.keys(menuItem.addonGroups).length +
              Object.keys(menuItem.modifierGroups).length,
            activeButton: activeButton,
          });
        }
      };
      updateState();
    }
  }, [menuItem, formattedOptionGroups]);

  useEffect(() => {
    if (orderMenu && menuItem) {
      const favorites = orderMenu.favorites;
      if (favorites === null || favorites.length === 0) {
        setIsFavItem(false);
      } else {
        for (let i = 0; i < favorites.length; i++) {
          if (favorites[i].id === menuItem.id) {
            setIsFavItem(true);
            break;
          } else if (i === favorites.length - 1) {
            setIsFavItem(false);
          }
        }
      }
    }
  }, [orderMenu, menuItem]);

  useEffect(() => {
    if (deviceWidth < 660) {
      if (orderingState !== null && !orderingState.hideAllButtons) {
        document.getElementsByClassName("main-content")[0].style.paddingBottom = "57px";
        showCartIcon();
      } else {
        document.getElementsByClassName("main-content")[0].style.paddingBottom = 0;
        hideCartIcon();
      }
    }
  }, [orderingState]);

  useEffect(() => {
    return () => {
      showCartIcon();
    };
  }, []);

  useEffect(() => {
    if (menuItem) {
      if (
        (getArray(menuItem, "modifierGroups") && getArray(menuItem, "modifierGroups").length > 0) ||
        (getArray(menuItem, "addonGroups") && getArray(menuItem, "addonGroups").length > 0)
      ) {
        setIsAddonOrModifier(true);
      } else setIsAddonOrModifier(false);
    }
  }, [menuItem]);

  useEffect(() => {
    if (menuItem) {
      const { isOutOfStock, errorMessage } = checkOutOfStockAddons(menuItem, appLabels["order"]);
      setAddonsOutOfStock(isOutOfStock);
      if (errorMessage) setOutOfStockError(errorMessage);
    }
  }, [menuItem]);

  useEffect(() => {
    // Update food menu context with currently active food menu item
    if (isMenuDisplay && !orderMenu.activeItem) {
      orderMenu.updateMenu({
        ...orderMenu,
        activeTopCategory: category,
        activeSubcategory: subcategory,
        activeItem: item,
        activeItemDetails: getActiveItemDetails(category, subcategory, item, orderMenu),
      });
    }
  }, [orderMenu, category, subcategory, item]);

  useEffect(() => {
    if (menuItem) {
      orderMenu.updateMenu({
        ...orderMenu,
        activeItemDetails: menuItem,
      });
    }
  }, [menuItem]);

  useEffect(() => {
    if (isMenuDisplay && !menuItem) {
      const menu = { ...orderMenu.apiData };

      let subcategoryData;

      if (isThreeLevels) {
        const categoryData = menu["category-" + category];
        if (!categoryData) {
          // no category data
          return;
        }
        subcategoryData = categoryData.subcategories["subcategory-" + subcategory];
      } else if (isTwoLevels) {
        if (!subcategory) {
          //no subcategory data
          return;
        }
        subcategoryData = menu["subcategory-" + subcategory];
      }

      const menuItemData = subcategoryData.items["item-" + item];

      const modifierGroupsData = getArray(menuItemData, "modifierGroups");
      if (isArrItems(modifierGroupsData)) {
        modifierGroupsData.forEach(([key]) => {
          menuItemData.modifierGroups[key].type = "modifierGroup";
        });
      }

      const addonGroupsData = getArray(menuItemData, "addonGroups");
      if (isArrItems(addonGroupsData)) {
        addonGroupsData.forEach(([key]) => {
          menuItemData.addonGroups[key].type = "addonGroup";
        });
      }

      /** At initial landing, the localforage,
       * if item exists with key __itemSelectionInProgress, use that item
       * otherwise set the default menuItem
       */
      localforage.getItem(skin + "__itemSelectionInProgress").then((storedItem) => {
        if (storedItem && JSON.parse(storedItem).id === menuItemData.id) {
          const storedItemObject = JSON.parse(storedItem);
          setMenuItem(storedItemObject);
          setTimeout(() => {
            if (document.getElementById("item-details-page__fav-button") && isLoggedIn) {
              //Trigger the click for the fav button only if the item is not favourited
              const allFavItems = orderMenu.favorites;
              const itemIsAlreadyAFavorite = isFavouriteItem(allFavItems, storedItemObject);
              if (!itemIsAlreadyAFavorite) {
                document.getElementById("item-details-page__fav-button").click();
              }
            }
          }, 1000);
        } else {
          /** Clean the menuItemData before assigning it to the menuItem state */
          if (menuItemData) {
            Object.keys(menuItemData.addonGroups).forEach((addonGroupKey) => {
              const addonGroup = menuItemData.addonGroups[addonGroupKey];
              Object.keys(addonGroup.items).forEach((addonKey) => {
                const addon = addonGroup.items[addonKey];
                delete addon.isSelected;
                delete addon.quantity;
                Object.keys(addon.modifierGroups).forEach((modifierGroupKey) => {
                  const modifierGroup = addon.modifierGroups[modifierGroupKey];
                  Object.keys(modifierGroup.items).forEach((modifierKey) => {
                    const modifier = modifierGroup.items[modifierKey];
                    delete modifier.isSelected;
                    delete modifier.quantity;
                  });
                });
              });
            });

            Object.keys(menuItemData.modifierGroups).forEach((modifierGroupKey) => {
              const modifierGroup = menuItemData.modifierGroups[modifierGroupKey];
              Object.keys(modifierGroup.items).forEach((modifierKey) => {
                const modifier = modifierGroup.items[modifierKey];
                delete modifier.isSelected;
                delete modifier.quantity;
              });
            });
          }
          setMenuItem(menuItemData);
        }
      });
    }
  }, [menuItem]);

  useEffect(() => {
    if (isModifyDisplay && cartItem) {
      updateMenuItem(null, true);
      setMenuItem(cartItem);
      setSpecialInstructions(cartItem.specialInstructions);
    }
  }, [displayType, isInCart, cartItem]);

  useEffect(() => {
    if (deviceWidth < 660) {
      if (orderingState.activeButton === "next") {
        document.getElementById("main-content").style.paddingBottom = 0;
      } else {
        document.getElementById("main-content").style.paddingBottom = "57px";
      }
    } else {
      document.getElementById("main-content").style.paddingBottom = 0;
    }
  }, [orderingState, deviceWidth]);

  useEffect(() => {
    return () => {
      if (deviceWidth < 660) {
        document.getElementById("main-content").style.paddingBottom = "57px";
      }
    };
  }, []);

  useEffect(() => {
    document.body.style.overflow = "hidden";
    return () => {
      document.body.style.overflow = "auto";
    };
  }, []);

  const updateOrderingState = (value) => {
    setOrderingState({ ...orderingState, ...value });
  };

  const updateSpecialInstructions = (instructions) => {
    setSpecialInstructions(instructions);
  };

  const updateMenuItem = (updatedItem, isReset) => {
    if (isReset) {
      setMenuItem(null);
      setSpecialInstructions("");
    } else {
      setMenuItem(jsonCopy(updatedItem));
    }
  };

  // Close Item Details
  const closeItemDetail = () => {
    setClosingAnimationClass(" menu-item-detail--closing");
    setIsFAB(false);
    localforage.removeItem(skin + "__itemSelectionInProgress");
    sessionStorage.removeItem(skin + "__orderStoreState");
    setTimeout(() => {
      if (backButtonDestination && !isModifyDisplay) {
        if (state && state.from === "suggestedItems") {
          history.push({
            pathname: `/online-ordering/${
              activeOrderType === "dinein" ? "review-order" : "checkout"
            }`,
            state: { from: "item-page" },
          });
        } else {
          history.push({
            pathname: backButtonDestination,
            state: { from: "item-page" },
          });
        }
      } else {
        history.goBack();
      }
    }, 300);
  };

  /** handles page scroll to minimize or maximize item description */

  const scrollToTop = () => {
    document.getElementsByClassName("item-details-main__Wrapper")[0].scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };

  /** updates the active option group index */
  const updateActiveOptionGroupIndex = async () => {
    let currentOptionGroupIndex = orderingState.currentActiveOptionGroupIndex;

    let moveForward = true;
    Object.keys(formattedOptionGroups).forEach((groupKey) => {
      let optionGroup = formattedOptionGroups[groupKey];
      if (optionGroup.positioningTemplateIndex === currentOptionGroupIndex) {
        if (Number(optionGroup.minQuantity) > 0 && !reachedMinRequirements(optionGroup)) {
          moveForward = false;
          currentOptionGroupIndex = optionGroup.positioningTemplateIndex;
        }
      }
    });

    if (moveForward) {
      currentOptionGroupIndex++;
    }

    if (currentOptionGroupIndex >= orderingState.numberOfOptionGroups) {
      // scroll to quantity and special instructions

      setTimeout(() => {
        if (document.getElementsByClassName("item-details-main__Wrapper").length > 0) {
          document.getElementsByClassName("item-details-main__Wrapper")[0].scrollTo({
            top:
              findPos(document.getElementById("quantity-special-instruction__container")) -
              (deviceWidth >= 660 ? 160 : 227),
            behavior: "smooth",
          });
        }
      }, 500);

      setIsAtLastStage(true);
    }

    const activeButton = await determineOrderingStateActiveButton(
      menuItem,
      currentOptionGroupIndex,
      formattedOptionGroups,
      isStepByStepOrderingEnabled,
      orderingState.numberOfOptionGroups,
      false,
      skin
    );
    if (activeButton) {
      updateOrderingState({
        currentActiveOptionGroupIndex: currentOptionGroupIndex,
        activeButton: activeButton,
      });
      performAutoScroll.current = true;
    }
  };

  /** handles click event for the option group */
  const onOptionGroupNextButtonClick = async () => {
    performAutoScroll.current = true;
    await updateActiveOptionGroupIndex();
  };

  /** This useEffect will keep track of the an item selection and stores it in localforage  */
  useEffect(() => {
    if (menuItem) {
      localforage.setItem(skin + "__itemSelectionInProgress", JSON.stringify(jsonCopy(menuItem)));
    }
  }, [menuItem]);

  const [numberOfRequiredChoices, setNumberOfRequiredChoices] = useState("idle");

  useEffect(() => {
    if (menuItem) {
      const getNumberOfRequiredChoices = async () => {
        setNumberOfRequiredChoices(
          await checkRequiredSelection(
            menuItem,
            isStepByStepOrderingEnabled,
            orderingState.currentActiveOptionGroupIndex,
            orderingState.numberOfOptionGroups,
            skin
          )
        );
      };
      getNumberOfRequiredChoices();
    }
  }, [orderingState, isStepByStepOrderingEnabled, menuItem]);

  // if user lands on this page by clicking the slides from dashabord, trigger the onProductClickXtreme to XP
  const triggeredXPEvent = useRef(false);
  useEffect(() => {
    if (
      menuItem &&
      history.location.state &&
      history.location.state.from === "dashboard-card-slider" &&
      triggeredXPEvent.current === false
    ) {
      triggeredXPEvent.current = true;
      onProductClickXtreme(menuItem, category, subcategory, skin);
    }
  }, [menuItem]);

  return menuItem ? (
    <div className={`item-details-main__Wrapper ${closingAnimationClass}`}>
      {deviceWidth >= 660 && (
        <div className="item-details__desktop-close-button-container">
          <button
            type="button"
            onClick={closeItemDetail}
            aria-label={appLabels["order"]["close-item-details"].replace(
              "[item-name]",
              menuItem.name
            )}
          >
            <IconX aria-hidden="true" />
          </button>

          <ButtonAddToFavorites
            item={menuItem}
            category={orderMenu.activeTopCategory}
            subcategory={orderMenu.activeSubcategory}
            updatedState={isFavItem}
            setIsGuestFavoriteAttempt={setIsGuestFavoriteAttempt}
            setIsFavAttemptBeforeSelection={setIsFavAttemptBeforeSelection}
          />
        </div>
      )}

      <div
        className={`item-detail__content ${
          isItemDescriptionMinimized ? " isMinimized" : " isMaximized"
        } ${isShowingFullItemDescription ? " isExpanded" : "isCollapsed"} `}
        id="top-hidden-item-name"
      >
        <div className={` item_detail__top-content flex__row space-between wizard`}>
          <>
            {/**item description */}
            <div className="flex__column item_detail__secondary-info_wrapper space-between expanded">
              <div className="flex__row item-detail__image-container">
                <div className={`item-detail__image-wrapper flex__column wizard expanded `}>
                  {isItemOutOfStock && menuItem.imageUrl && (
                    <div className="item-detail__image-overlap-cotnainer">
                      <img
                        src={menuItem.imageUrl}
                        alt={menuItem.name}
                        className="item-detail__image item-detail__image-underlay"
                      />
                      <img
                        src={outOfStockImage}
                        alt={menuItem.name}
                        className=" item-detail__image item-detail__image-overlay"
                      />
                    </div>
                  )}
                  {isItemOutOfStock && !menuItem.imageUrl && (
                    <img src={outOfStockImage} alt={menuItem.name} className="item-detail__image" />
                  )}
                  {!isItemOutOfStock && (
                    <img
                      src={menuItem.imageUrl || noImagePlaceholder}
                      alt={menuItem.name}
                      className="item-detail__image"
                    />
                  )}
                </div>

                <div
                  className={`${
                    deviceWidth < 660
                      ? "flex__row item_details__name-price-container"
                      : "full__width"
                  }`}
                >
                  <div className="item_details__name-price-wrapper flex__column space-between full__width">
                    <h2 className="item-detail__heading wizard">{menuItem.name}</h2>
                    <div className="item-detail__price-calories">
                      <p className="flex__row space-between full-width-column">
                        {menuItem.calorie_count && (
                          <span className="item-detail__calories wizard">
                            {menuItem.calorie_count} {appLabels["order"]["calories"]}
                          </span>
                        )}

                        <span
                          className={`item-detail__price wizard ${
                            !menuItem.calorie_count ? " item-detail__price-width" : ""
                          }`}
                        >
                          {menuItem.price > 0
                            ? toDollars(
                                appSettings["currency-symbol"],
                                appSettings["currency-symbol-side"],
                                showPriceOnOrderButton
                                  ? getTotalItemPriceBasedOnSelection(menuItem)
                                  : menuItem.price,
                                appLanguage
                              )
                            : appLabels["order"]["price-based-on-selection"]}
                        </span>
                      </p>
                    </div>
                  </div>

                  {/** Desktop description */}
                  {deviceWidth >= 660 && (
                    <div
                      className={` item-detail__description__wrapper ${
                        isItemDescriptionMinimized && !isShowingFullItemDescription
                          ? "flex__row"
                          : ""
                      }`}
                    >
                      <p
                        className={`item-detail__description wizard item-detail__description--expanded ${
                          isShowingFullItemDescription ? " item-detail__description--expanded" : ""
                        }`}
                      >
                        {menuItem.description}
                      </p>
                      {!!menuItem.description && (
                        <>
                          {!isShowingFullItemDescription && isItemDescriptionMinimized ? (
                            <button
                              type="button"
                              className="item__description--read-more"
                              onClick={() => {
                                setIsShowingFullItemDescription(true);
                                setIsItemDescriptionMinimized(false);
                                scrollToTop();
                              }}
                              aria-label={appLabels["order"]["read-more"]}
                            >
                              {appLabels["order"]["read-more"]}
                            </button>
                          ) : (
                            <button
                              type="button"
                              className="item__description--read-more item__description--read-less"
                              onClick={() => {
                                setIsShowingFullItemDescription(false);
                                setIsItemDescriptionMinimized(true);
                              }}
                              aria-label={appLabels["order"]["read-less"]}
                            >
                              {appLabels["order"]["read-less"]}
                            </button>
                          )}
                        </>
                      )}
                    </div>
                  )}
                </div>
              </div>

              {/** Mobile description */}
              {deviceWidth < 660 && (
                <div
                  className={` item-detail__description__wrapper ${
                    isItemDescriptionMinimized && !isShowingFullItemDescription ? "flex__row" : ""
                  }`}
                >
                  <p
                    className={`item-detail__description wizard item-detail__description--expanded ${
                      isShowingFullItemDescription ? " item-detail__description--expanded" : ""
                    }`}
                  >
                    {menuItem.description}
                  </p>
                  {!!menuItem.description && (
                    <>
                      {!isShowingFullItemDescription && isItemDescriptionMinimized ? (
                        <button
                          type="button"
                          className="item__description--read-more"
                          onClick={() => {
                            setIsShowingFullItemDescription(true);
                            setIsItemDescriptionMinimized(false);
                            scrollToTop();
                          }}
                          aria-label={appLabels["order"]["read-more"]}
                        >
                          {appLabels["order"]["read-more"]}
                        </button>
                      ) : (
                        <button
                          type="button"
                          className="item__description--read-more item__description--read-less"
                          onClick={() => {
                            setIsShowingFullItemDescription(false);
                            setIsItemDescriptionMinimized(true);
                          }}
                          aria-label={appLabels["order"]["read-less"]}
                        >
                          {appLabels["order"]["read-less"]}
                        </button>
                      )}
                    </>
                  )}
                </div>
              )}
            </div>
            {/** End of Large item description when expanded */}
          </>
        </div>
      </div>
      <div
        className={` menu-item-detail-wizard ${
          history.location.state && history.location.state.from === "menu-slider"
            ? " menu-item-detail--animate-in"
            : ""
        }`}
        id="menu-item-detail-container"
      >
        <div
          className="item-detail__options-container"
          id="item-detail__options-container"
          style={isStepByStepOrderingEnabled ? {} : { paddingBottom: "50vh" }}
        >
          {isAddonOrModifier && (
            <AddonsOrModifiers
              menuItem={menuItem}
              updateMenuItem={updateMenuItem}
              isModifier={getArray(menuItem, "modifierGroups").length >= 1}
              isAddon={getArray(menuItem, "addonGroups").length >= 1}
              cartItem={cartItem}
              isInCart={isInCart}
              addonsOutOfStock={addonsOutOfStock}
              currentActiveOptionGroupIndex={orderingState.currentActiveOptionGroupIndex}
              updateActiveOptionGroupIndex={updateActiveOptionGroupIndex}
              isStepByStepOrderingEnabled={isStepByStepOrderingEnabled}
              displayType={displayType}
              setAddToOrderButtonText={setAddToOrderButtonText}
              updateOrderingState={updateOrderingState}
              orderingState={orderingState}
              formattedOptionGroups={formattedOptionGroups}
              setFormattedOptionGroups={setFormattedOptionGroups}
              performAutoScroll={performAutoScroll}
              skin={skin}
            />
          )}

          <div
            className="quantity-special-instruction__container"
            id="quantity-special-instruction__container"
          >
            {!isStepByStepOrderingEnabled ? (
              <>
                {/** Quantity buttons */}
                <QuantityButtons
                  menuItem={menuItem}
                  cartItem={cartItem}
                  isMenuDisplay={isMenuDisplay}
                  isModifyDisplay={isModifyDisplay}
                  updateMenuItem={updateMenuItem}
                  cartData={cartContext}
                />

                {isSpecialInstructionEnabled && (
                  <SpecialInstructions
                    specialInstructions={specialInstructions}
                    updateSpecialInstructions={updateSpecialInstructions}
                    onFocus={() => {
                      if (
                        deviceWidth < 660 &&
                        isItemDescriptionMinimized &&
                        !isShowingFullItemDescription &&
                        document.getElementById("top-hidden-item-name")
                      ) {
                        document.getElementById("top-hidden-item-name").style = "position:relative";
                      }
                    }}
                    onBlur={() => {
                      if (
                        deviceWidth < 660 &&
                        isItemDescriptionMinimized &&
                        !isShowingFullItemDescription &&
                        document.getElementById("top-hidden-item-name")
                      ) {
                        document.getElementById("top-hidden-item-name").style = "position:sticky";
                      }
                    }}
                  />
                )}
              </>
            ) : (
              <>
                {orderingState.currentActiveOptionGroupIndex >=
                  orderingState.numberOfOptionGroups && (
                  <>
                    {/** Quantity buttons */}
                    <QuantityButtons
                      menuItem={menuItem}
                      cartItem={cartItem}
                      isMenuDisplay={isMenuDisplay}
                      isModifyDisplay={isModifyDisplay}
                      updateMenuItem={updateMenuItem}
                      cartData={cartContext}
                    />

                    {isSpecialInstructionEnabled && (
                      <SpecialInstructions
                        specialInstructions={specialInstructions}
                        updateSpecialInstructions={updateSpecialInstructions}
                        onFocus={() => {
                          if (
                            deviceWidth < 660 &&
                            isItemDescriptionMinimized &&
                            !isShowingFullItemDescription &&
                            document.getElementById("top-hidden-item-name")
                          ) {
                            document.getElementById("top-hidden-item-name").style =
                              "position:relative";
                          }
                        }}
                        onBlur={() => {
                          if (
                            deviceWidth < 660 &&
                            isItemDescriptionMinimized &&
                            !isShowingFullItemDescription &&
                            document.getElementById("top-hidden-item-name")
                          ) {
                            document.getElementById("top-hidden-item-name").style =
                              "position:sticky";
                          }
                        }}
                      />
                    )}
                  </>
                )}
              </>
            )}
          </div>

          {menuItem.calorie_count && appLabels["order"]["calories-disclaimer"] && (
            <CaloriesDisclaimer caloriesDisclaimer={appLabels["order"]["calories-disclaimer"]} />
          )}
          <AdditionalDisclaimer
            disclaimer={appLabels["order"]["menu-item-disclaimer"]}
            styleObject={{ padding: "0px 24px" }}
          />
        </div>

        {deviceWidth < 660 && isMenuDisplay && numberOfRequiredChoices !== "idle" && (
          <>
            {isStepByStepOrderingEnabled && !orderingState.hideAllButtons ? (
              <>
                {orderingState.activeButton === "add" && (
                  <MenuAddToOrder
                    cartContext={cartContext}
                    menuItem={menuItem}
                    category={category}
                    subcategory={subcategory}
                    isFAB={isFAB}
                    specialInstructions={specialInstructions}
                    isOutOfStock={isItemOutOfStock ? true : false}
                    addonsOutOfStock={addonsOutOfStock}
                    outOfStockError={outOfStockError}
                    updateMenuItem={updateMenuItem}
                    totalPriceText={toDollars(
                      appSettings["currency-symbol"],
                      appSettings["currency-symbol-side"],
                      getTotalItemPriceBasedOnSelection(menuItem),
                      appLanguage
                    )}
                    itemQuantity={menuItem.quantity || 1}
                    numberOfRequiredChoices={numberOfRequiredChoices}
                    addToOrderButtonText={addToOrderButtonText}
                    isStepByStepOrderingEnabled={isStepByStepOrderingEnabled}
                    animationClass={
                      orderingState.hideAllButtons ? "hide-add-to-order" : "show-add-to-order"
                    }
                    useAddToOrderButtonText={
                      orderingState.currentActiveOptionGroupIndex <
                      Object.keys(formattedOptionGroups).length
                    }
                    isAtLastStage={isAtLastStage}
                  />
                )}

                {orderingState.activeButton === "next" && (
                  <OptionGroupNextButton
                    onClick={async () => {
                      await onOptionGroupNextButtonClick();
                    }}
                    buttonText={determineNextOptionGroupButtonText(
                      formattedOptionGroups,
                      orderingState.currentActiveOptionGroupIndex,
                      appLabels
                    )}
                  />
                )}
              </>
            ) : (
              <MenuAddToOrder
                cartContext={cartContext}
                menuItem={menuItem}
                category={category}
                subcategory={subcategory}
                isFAB={isFAB}
                specialInstructions={specialInstructions}
                isOutOfStock={isItemOutOfStock ? true : false}
                addonsOutOfStock={addonsOutOfStock}
                outOfStockError={outOfStockError}
                updateMenuItem={updateMenuItem}
                totalPriceText={toDollars(
                  appSettings["currency-symbol"],
                  appSettings["currency-symbol-side"],
                  getTotalItemPriceBasedOnSelection(menuItem),
                  appLanguage
                )}
                itemQuantity={menuItem.quantity || 1}
                numberOfRequiredChoices={numberOfRequiredChoices}
                addToOrderButtonText={addToOrderButtonText}
                isStepByStepOrderingEnabled={isStepByStepOrderingEnabled}
                animationClass={
                  orderingState.hideAllButtons ? "hide-add-to-order" : "show-add-to-order"
                }
                useAddToOrderButtonText={
                  orderingState.currentActiveOptionGroupIndex <
                  Object.keys(formattedOptionGroups).length
                }
                isAtLastStage={isAtLastStage}
              />
            )}
          </>
        )}

        {isModifyDisplay && deviceWidth < 660 && (
          <CartSaveChanges
            cart={cartContext}
            item={menuItem}
            isFAB={isFAB}
            closeItemDetail={closeItemDetail}
            specialInstructions={specialInstructions}
          />
        )}

        {isGuestFavoriteAttempt && (
          <DialogModal
            isConfirmText={false}
            hideConfirmButton={true}
            description="Guest User attempting to set item as favourite"
            message={
              <div className="menu-item__favourite-dialog-modal">
                <div>
                  <p>{appLabels["order"]["guest-attempt-favorite-item"]}</p>
                </div>
                <div className="menu-item__favourite-dialog-modal-buttons-wrapper">
                  <button
                    onClick={() => {
                      sessionStorage.setItem(
                        skin + "__lastVisitedLink",
                        window.location.hash.split("#")[1]
                      );
                      history.push("/login-register");
                    }}
                    className="button dialog-modal__button  button--primary button--primary-small"
                    type="button"
                  >
                    {appLabels["login-register"]["sign-in"]}
                  </button>

                  <button
                    onClick={() => {
                      sessionStorage.setItem(
                        skin + "__lastVisitedLink",
                        window.location.hash.split("#")[1]
                      );
                      history.push("/register");
                    }}
                    className="button dialog-modal__button  button--primary button--primary-small"
                    type="button"
                  >
                    {appLabels["login-register"]["register"]}
                  </button>
                </div>
              </div>
            }
            resetRemoveDialog={() => {
              setIsGuestFavoriteAttempt(false);
            }}
            isHTMLContent={true}
          />
        )}

        {isFavAttemptBeforeSelection && (
          <DialogModal
            isConfirmText={false}
            description="User attempting to set an item as favorite before making mandatory selections"
            message={appLabels["order"]["setting-favorite-error"]}
            resetRemoveDialog={() => {
              setIsFavAttemptBeforeSelection(false);
            }}
          />
        )}

        <div
          className="desktop-next-group-button__container"
          id="desktop-next-group-button__container"
        >
          {/** Next and add to order button for desktop */}
          {deviceWidth >= 660 && isMenuDisplay && numberOfRequiredChoices !== "idle" && (
            <>
              {!isStepByStepOrderingEnabled ? (
                <MenuAddToOrder
                  cartContext={cartContext}
                  menuItem={menuItem}
                  category={category}
                  subcategory={subcategory}
                  isFAB={isFAB}
                  specialInstructions={specialInstructions}
                  isOutOfStock={isItemOutOfStock ? true : false}
                  addonsOutOfStock={addonsOutOfStock}
                  outOfStockError={outOfStockError}
                  updateMenuItem={updateMenuItem}
                  totalPriceText={toDollars(
                    appSettings["currency-symbol"],
                    appSettings["currency-symbol-side"],
                    getTotalItemPriceBasedOnSelection(menuItem),
                    appLanguage
                  )}
                  itemQuantity={menuItem.quantity || 1}
                  numberOfRequiredChoices={numberOfRequiredChoices}
                  addToOrderButtonText={addToOrderButtonText}
                  isStepByStepOrderingEnabled={isStepByStepOrderingEnabled}
                  animationClass={
                    orderingState.hideAllButtons ? "hide-add-to-order" : "show-add-to-order"
                  }
                  isRelativePositioning={true}
                  relativeContainerId={"desktop-next-group-button__container"}
                  useAddToOrderButtonText={
                    orderingState.currentActiveOptionGroupIndex <
                    Object.keys(formattedOptionGroups).length
                  }
                  isAtLastStage={isAtLastStage}
                />
              ) : (
                <>
                  {!orderingState.hideAllButtons && (
                    <>
                      {orderingState.activeButton === "add" && (
                        <MenuAddToOrder
                          cartContext={cartContext}
                          menuItem={menuItem}
                          category={category}
                          subcategory={subcategory}
                          isFAB={isFAB}
                          specialInstructions={specialInstructions}
                          isOutOfStock={isItemOutOfStock ? true : false}
                          addonsOutOfStock={addonsOutOfStock}
                          outOfStockError={outOfStockError}
                          updateMenuItem={updateMenuItem}
                          totalPriceText={toDollars(
                            appSettings["currency-symbol"],
                            appSettings["currency-symbol-side"],
                            getTotalItemPriceBasedOnSelection(menuItem),
                            appLanguage
                          )}
                          itemQuantity={menuItem.quantity || 1}
                          numberOfRequiredChoices={numberOfRequiredChoices}
                          addToOrderButtonText={addToOrderButtonText}
                          isStepByStepOrderingEnabled={isStepByStepOrderingEnabled}
                          animationClass={
                            orderingState.hideAllButtons ? "hide-add-to-order" : "show-add-to-order"
                          }
                          isRelativePositioning={true}
                          relativeContainerId={"desktop-next-group-button__container"}
                          useAddToOrderButtonText={
                            orderingState.currentActiveOptionGroupIndex <
                            Object.keys(formattedOptionGroups).length
                          }
                          isAtLastStage={isAtLastStage}
                        />
                      )}

                      {orderingState.activeButton === "next" && (
                        <OptionGroupNextButton
                          onClick={async () => {
                            await onOptionGroupNextButtonClick();
                          }}
                          customClass={" desktop-next-group-button"}
                          buttonText={determineNextOptionGroupButtonText(
                            formattedOptionGroups,
                            orderingState.currentActiveOptionGroupIndex,
                            appLabels
                          )}
                          container={document.getElementById(
                            "desktop-next-group-button__container"
                          )}
                        />
                      )}
                    </>
                  )}
                </>
              )}
            </>
          )}

          {isModifyDisplay && (
            <CartSaveChanges
              cart={cartContext}
              item={menuItem}
              isFAB={isFAB}
              closeItemDetail={closeItemDetail}
              specialInstructions={specialInstructions}
              isRelativePositioning={true}
              relativeContainerId={"desktop-next-group-button__container"}
            />
          )}
        </div>
      </div>
    </div>
  ) : (
    <LoadingSpinner />
  );
};
