// Libraries
import React, { useState, useEffect, useContext, useRef } from "react";
import { Link, useHistory } from "react-router-dom";
import localforage from "localforage";

// Custom hooks
import useWindowSize from "../_common/hooks/useWindowSize";
import { useHideOrderModule } from "../App/useHideOrderModule";

// Helper functions
import { toDollars } from "../_common/helpers";
import { onRewardClickXtreme } from "../_common/helpers/xtremePushHelper";

// API helper functions
import { callAPI } from "../_common/Api";
import { getAPIFullCardNumber } from "../Dashboard/DashboardRewards/apiHelpers/getAPIFullCardNumber";
import { getAPIRewards } from "../Dashboard/DashboardRewards/apiHelpers/getAPIRewards";
import { getAPICardList } from "../OnlineOrdering/Payment/PaymentMethod/PaymentMethodGiftCard/apiHelpers/getAPICardList";

// Contexts
import UserRoleContext from "../App/UserRoleContext";
import AppLanguageContext from "../App/AppLanguageContext";
import BrandingContext from "../App/BrandingContext";
import MerchantConfigContext from "../App/MerchantConfigContext";
import AppLabelsContext from "../App/AppLabelsContext";
import AppSettingsContext from "../App/AppSettingsContext";
import OrderContext from "../App/OrderContext";

// UI components
import { LoadingSpinner } from "../_common/LoadingSpinner";
import { RedeemRewards } from "./RedeemRewards/RedeemRewards";
import { RewardPointsScroll } from "./RewardPointsScroll";
import { DialogModal } from "../_common/DialogModal/DialogModal";
import MyRewardsCard from "./MyRewardsCard";

// Assets
import { IconCheck } from "../_common/icons";
import { ReactComponent as IconArrowDown } from "../_common/icons/IconArrowDown.svg";
import { ReactComponent as IconStar } from "../_common/icons/IconStar.svg";
import iconBirthdayCelebration from "./icon--birthday-celebration.png";
import iconTwoPeopleHighfiving from "./icon--two-people-highfiving.png";

// CSS
import "./MyRewards.css";

const OPTION_ICONS = [iconBirthdayCelebration, iconTwoPeopleHighfiving];

export const MyRewards = () => {
  const userRoleContext = useContext(UserRoleContext);
  const loginToken = userRoleContext.loginToken;

  const appLabels = useContext(AppLabelsContext);

  const merchantConfigContext = useContext(MerchantConfigContext);
  const skin = merchantConfigContext.skin;
  const apiMerchantRewards = merchantConfigContext.merchant.I14;
  const orderContext = useContext(OrderContext);

  const brandingContext = useContext(BrandingContext);
  const secondaryColor = brandingContext["secondary-colour"];

  const [updateInfo, setUpdateInfo] = useState(null);

  const appSettings = useContext(AppSettingsContext);
  const appLanguage = useContext(AppLanguageContext);

  const history = useHistory();

  const { hideOrderModule } = useHideOrderModule();

  useEffect(() => {
    if (!loginToken) {
      if (sessionStorage.getItem(skin + "_isComingFromRewards")) {
        sessionStorage.removeItem(skin + "_isComingFromRewards");
        history.goBack();
      } else {
        history.push("/rewards");
      }
    }
  }, []);

  const [userGiftCards, setUserGiftCards] = useState(null);
  useEffect(() => {
    if (loginToken) {
      getAPICardList(
        skin,
        loginToken,
        appSettings["currency-symbol"],
        appSettings["currency-symbol-side"],
        appLanguage,
        brandingContext
      ).then((fetchedData) => {
        if (fetchedData) {
          if (fetchedData.length > 0) {
            setUserGiftCards(fetchedData);
          } else if (fetchedData.status === "expiredLoginToken") {
            userRoleContext.handleLoginTokenExpiration();
          } else if (fetchedData.error) {
            history.push("/rewards");
          }
        }
      });
    }
  }, [loginToken, updateInfo]);

  const [selectedCard, setSelectedCard] = useState(null);

  useEffect(() => {
    const storedActiveLoyaltyCard = JSON.parse(
      sessionStorage.getItem(skin + "__activeLoyaltyCard")
    );

    if (storedActiveLoyaltyCard) setSelectedCard(storedActiveLoyaltyCard);
    else if (userGiftCards) setSelectedCard(userGiftCards[0]);
  }, [updateInfo, userGiftCards]);

  const updateSelectedCard = (e) => {
    const select = e.target;

    if (userGiftCards) {
      userGiftCards.forEach((card) => {
        if (card.iso + "-" + card.serial === select.value) {
          setSelectedCard(card);
          sessionStorage.setItem(skin + "__activeLoyaltyCard", JSON.stringify(card));
        }
      });
    }
  };

  const [fullCardNumber, setFullCardNumber] = useState(null);
  const isCallingAPI = useRef(false);
  useEffect(() => {
    if (loginToken && selectedCard) {
      if (isCallingAPI.current === true) {
        return;
      }
      isCallingAPI.current = true;
      getAPIFullCardNumber(
        skin,
        loginToken,
        selectedCard.iso + "-" + selectedCard.serial,
        appLanguage
      ).then((apiFullCardNumber) => {
        isCallingAPI.current = false;
        if (apiFullCardNumber) {
          if (apiFullCardNumber.status === "expiredLoginToken") {
            userRoleContext.handleLoginTokenExpiration();
          } else {
            setFullCardNumber(apiFullCardNumber);
          }
        }
      });
    }
  }, [selectedCard]);

  const [cardSpecificRewards, setCardSpecificRewards] = useState(null);
  useEffect(() => {
    if (fullCardNumber) {
      getAPIRewards(skin, fullCardNumber, appLanguage).then((apiRewards) => {
        if (apiRewards) {
          setCardSpecificRewards(apiRewards.filter((reward) => reward.price === 0));
        }
      });
    }
  }, [fullCardNumber]);

  const sortByPriceAscending = (rewards) => {
    return rewards.sort((item1, item2) => parseInt(item1.price) - parseInt(item2.price));
  };

  const [merchantRewards, setMerchantRewards] = useState(null);
  const [showProgressBar, setShowProgressBar] = useState(null);
  useEffect(() => {
    let reformattedRewards = [];

    apiMerchantRewards.forEach((apiReward) => {
      const reformattedReward = {
        id: apiReward[0],
        name: apiReward[1],
        description: apiReward[2],
        price: parseInt(apiReward[3]),
        image: apiReward[4] || false,
        typeCode: apiReward[5],
        typeName: apiReward[6],
      };

      reformattedRewards.push(reformattedReward);
    });

    reformattedRewards = sortByPriceAscending(reformattedRewards);

    setMerchantRewards(reformattedRewards);
    /**If merchant does not have any rewards of type
     * 6(Points to Money Conversion: Manual Redemption) or
     * 7(Points to Reward Conversion),
     * do not show the rewards progress bar */
    if (
      reformattedRewards.filter((reward) => reward.typeCode === "6" || reward.typeCode === "7")
        .length > 0
    ) {
      setShowProgressBar(true);
    } else {
      setShowProgressBar(false);
    }
  }, []);

  const imagePlaceholder = brandingContext["no-image-available-giftcard-placeholder"];

  const deviceWidth = useWindowSize().width;

  const [confirmRewardRedemption, setConfirmRewardRedemption] = useState(null);
  const [isRedemptionConfirmed, setIsRedemptionConfirmed] = useState(null);
  const [selectedReward, setSelectedReward] = useState(null);

  const [redeemedSuccessfully, setRedeemedSuccessfully] = useState(null);
  const [redeemedSuccessMessageContent, setRedeemedSuccessMessageContent] = useState("");
  const [redirectionRequired, setRedirectionRequired] = useState(null);

  useEffect(() => {
    if (loginToken && isRedemptionConfirmed) {
      callAPI(skin, "dc_912", {
        params: [
          appLanguage, //language code
          "912", //transaction id
          loginToken, //user id
          "", //password
          fullCardNumber, //givex number
          selectedReward.price, //points
          "", //security code
          selectedReward.id, //reward
          "1", //reward units
        ],
        id: "912",
      }).then((data912) => {
        if (data912.result.I0) {
          const oldBalance = parseFloat(selectedCard.balance);
          const newBalance = parseFloat(data912.result.I4);
          const amountAdded = newBalance - oldBalance;

          setRedeemedSuccessMessageContent(
            <div>
              <div className="my-reward__success-message-header-wrapper">
                <div>
                  <IconCheck />
                </div>
                <div>
                  <h2 className="my-reward__success-message-header">
                    <span>{appLabels["loyalty"]["redeem-reward-success-message"]}</span>
                  </h2>
                </div>
              </div>

              <p className="my-reward__success-message-reward-detail">{`              
              ${toDollars(
                appSettings["currency-symbol"],
                appSettings["currency-symbol-side"],
                amountAdded,
                appLanguage
              )} 
              ${appLabels["loyalty"]["was-added-to-card"].replace(
                "[card-number]",
                ` ${selectedCard.maskedNumber}`
              )}  
             `}</p>
              <p className="my-reward__success-message-reward-detail">{`${
                appLabels["loyalty"]["your-new-card-balance"].split("[card-balance]")[0]
              } ${toDollars(
                appSettings["currency-symbol"],
                appSettings["currency-symbol-side"],
                newBalance,
                appLanguage
              )}`}</p>
              <hr />
              <i className="my-reward__success-message-reward-transaction">
                {appLabels["loyalty"]["transaction-reference-number"]}: {data912.result.I2}
              </i>
            </div>
          );

          setRedeemedSuccessfully(true);
          setIsRedemptionConfirmed(null);
          setSelectedReward(null);
        } else {
          //setErrorNotificationText(data912.result.message);
          //setIsErrorNotification(true);
          setSelectedReward(null);
          setIsRedemptionConfirmed(null);
        }
      });
    }
  }, [isRedemptionConfirmed]);

  const [storedRewards, setStoredRewards] = useState(null);
  const [storedRewardsDetails, setStoredRewardDetails] = useState(null);
  const handleRedemption = (reward) => {
    onRewardClickXtreme(reward, skin);
    //if reward code is 6 (points to money conversion) call API 912 to redeem
    if (reward.typeCode === "6") {
      setSelectedReward(reward);
      setConfirmRewardRedemption(true);
    } else {
      //store the reward in the storage
      let redeemableRewards = [];
      localforage.getItem(skin + "__storedRewards").then((storedRewards) => {
        if (storedRewards) {
          redeemableRewards = storedRewards.filter((r) => r[1] !== Number(reward.id));
          redeemableRewards.push([fullCardNumber, Number(reward.id)]);
          setStoredRewards(redeemableRewards);
        } else {
          redeemableRewards.push([fullCardNumber, Number(reward.id)]);
          setStoredRewards(redeemableRewards);
        }
      });

      let tempStoredRewardsDetails = [];
      localforage.getItem(skin + "__storedRewardsDetails").then((storedRewardsDetails) => {
        if (storedRewardsDetails) {
          tempStoredRewardsDetails = storedRewardsDetails.filter((r) => r.id !== reward.id);
          tempStoredRewardsDetails.push(reward);
          setStoredRewardDetails(tempStoredRewardsDetails);
        } else {
          tempStoredRewardsDetails.push(reward);
          setStoredRewardDetails(tempStoredRewardsDetails);
        }
      });

      if (hideOrderModule) {
        history.push("/pay-in-store");
      } else {
        setRedirectionRequired(true);
      }
    }
  };

  return (
    <>
      {deviceWidth >= 660 && selectedCard && (
        <section className="my-rewards__section my-rewards__points-and-card-select">
          <div className="desktop-container">
            <div className="rewards-module__balance-wrapper">
              <div className="rewards-module__balance-and-star">
                {showProgressBar && (
                  <div>
                    <span className="rewards-module__balance">{selectedCard.points}</span>
                    <IconStar />
                  </div>
                )}

                <div>
                  <span
                    className={`rewards-module__balance ${
                      !showProgressBar ? "" : " rewards-module__balance-money"
                    }`}
                  >
                    {toDollars(
                      appSettings["currency-symbol"],
                      appSettings["currency-symbol-side"],
                      selectedCard.balance,
                      appLanguage
                    )}
                  </span>
                </div>
              </div>
            </div>
            <div className="rewards-module__select-wrapper">
              {userGiftCards && (
                <select
                  onChange={updateSelectedCard}
                  className="rewards-module__select"
                  value={selectedCard.iso + "-" + selectedCard.serial}
                >
                  {userGiftCards.map((card) => (
                    <option key={card.iso + "-" + card.serial} value={card.iso + "-" + card.serial}>
                      {card.maskedNumber}
                    </option>
                  ))}
                </select>
              )}
              <IconArrowDown />
            </div>
          </div>
        </section>
      )}
      <div className="desktop-container">
        {deviceWidth < 660 && selectedCard && (
          <section className="my-rewards__section my-rewards__points-and-card-select">
            <div className="rewards-module__balance-and-star my-rewards">
              {showProgressBar && (
                <div>
                  <span className="rewards-module__balance">{selectedCard.points}</span>
                  <IconStar />
                </div>
              )}
              <div>
                <span
                  className={`rewards-module__balance ${
                    !showProgressBar ? "" : " rewards-module__balance-money"
                  }`}
                >
                  {toDollars(
                    appSettings["currency-symbol"],
                    appSettings["currency-symbol-side"],
                    selectedCard.balance,
                    appLanguage
                  )}
                </span>
              </div>
            </div>
            <div className="rewards-module__select-wrapper">
              {userGiftCards && (
                <select
                  onChange={updateSelectedCard}
                  className="rewards-module__select"
                  value={selectedCard.iso + "-" + selectedCard.serial}
                >
                  {userGiftCards.map((card) => (
                    <option key={card.iso + "-" + card.serial} value={card.iso + "-" + card.serial}>
                      {card.maskedNumber}
                    </option>
                  ))}
                </select>
              )}
              <IconArrowDown />
            </div>
          </section>
        )}
        {deviceWidth >= 660 && merchantRewards && selectedCard && showProgressBar && (
          <RewardPointsScroll merchantRewards={merchantRewards} selectedCard={selectedCard} />
        )}
        <section className="my-rewards__section my-rewards__options-container">
          <div className="my-rewards-section__header">
            <h2 className="my-rewards-section__heading">
              {appLabels["loyalty"]["reward-options"]}
            </h2>
            <Link
              to="/how-rewards-work"
              className="my-rewards-section__header-link"
              style={{ color: secondaryColor }}
            >
              {appLabels["loyalty"]["learn-how-it-works"]}
            </Link>
          </div>
          <p className="my-rewards-section__text">
            {appLabels["loyalty"]["start-collecting-points-toward"]}
          </p>
          {cardSpecificRewards ? (
            <div className="my-rewards__options-table">
              <div className="visually-hidden">{appLabels["loyalty"]["reward-options"]}</div>
              {cardSpecificRewards.filter((reward) => reward.price === 0).length > 0 &&
                selectedCard && (
                  <div className="my-rewards__options-table-section">
                    <h3 className="my-rewards__options-table-section-heading">
                      {appLabels["loyalty"]["rewards"]}
                    </h3>
                    {cardSpecificRewards.map(
                      (reward, index) =>
                        reward.price === 0 && (
                          <React.Fragment key={reward.id}>
                            <MyRewardsCard
                              reward={reward}
                              imagePlaceholder={imagePlaceholder}
                              selectedCard={selectedCard}
                              handleRedemption={handleRedemption}
                              itemIndex={index}
                            />
                          </React.Fragment>
                        )
                    )}
                  </div>
                )}
              {merchantRewards &&
                selectedCard &&
                !!merchantRewards.filter((reward) => reward.typeCode !== "21" && reward.price > 0)
                  .length && (
                  <div className="my-rewards__options-table-section">
                    <h3 className="my-rewards__options-table-section-heading">
                      {appLabels["loyalty"]["rewards"]}
                    </h3>
                    {merchantRewards.map(
                      (reward, index) =>
                        reward.typeCode !== "21" &&
                        reward.price > 0 && (
                          <React.Fragment key={reward.id}>
                            <MyRewardsCard
                              reward={reward}
                              imagePlaceholder={imagePlaceholder}
                              selectedCard={selectedCard}
                              handleRedemption={handleRedemption}
                              itemIndex={index}
                            />
                          </React.Fragment>
                        )
                    )}
                  </div>
                )}
            </div>
          ) : (
            <LoadingSpinner />
          )}
        </section>
        {deviceWidth < 660 && merchantRewards && selectedCard && (
          <RedeemRewards
            cardSpecificRewards={cardSpecificRewards}
            icons={OPTION_ICONS}
            rewards={merchantRewards}
            selectedCard={selectedCard}
            skin={skin}
            fullCardNumber={fullCardNumber}
          />
        )}
      </div>
      {deviceWidth >= 660 && merchantRewards && selectedCard && (
        <RedeemRewards
          cardSpecificRewards={cardSpecificRewards}
          icons={OPTION_ICONS}
          rewards={merchantRewards}
          selectedCard={selectedCard}
          skin={skin}
          fullCardNumber={fullCardNumber}
        />
      )}

      {confirmRewardRedemption && (
        <DialogModal
          isHTMLContent={true}
          message={
            <div className="my-rewards__confirmation-popup">
              <span>
                {appLabels["loyalty"]["reward-redemption-confirmation"].split("[reward-name]")[0]} "
                {selectedReward.name}"
                {appLabels["loyalty"]["reward-redemption-confirmation"]
                  .split("[reward-name]")[1]
                  .replace("[reward-cost]", selectedReward.price)}{" "}
                <IconStar />
              </span>
              <span>?</span>
            </div>
          }
          isCancelConfirm={true}
          resetRemoveDialog={() => {
            setSelectedReward(null);
            setConfirmRewardRedemption(null);
          }}
          confirmAction={() => {
            setConfirmRewardRedemption(null);
            setIsRedemptionConfirmed(true);
          }}
        />
      )}

      {redeemedSuccessfully && (
        <DialogModal
          isHTMLContent={true}
          message={redeemedSuccessMessageContent}
          resetRemoveDialog={() => {
            setRedeemedSuccessfully(false);
            setUpdateInfo(true);
          }}
        />
      )}

      {redirectionRequired && (
        <DialogModal
          isHTMLContent={true}
          message={
            <div>
              <p>{`${appLabels["loyalty"]["where-to-redeem-this-reward"]}?`}</p>
              <div className="my-rewards__redirect-buttons-container">
                <button
                  type="button"
                  className="button button--primary button--primary-small"
                  onClick={() => history.push("/pay-in-store")}
                  disabled={deviceWidth >= 660}
                >
                  {appLabels["loyalty"]["in-store"]}
                </button>
                <button
                  type="button"
                  className="button button--primary button--primary-small"
                  onClick={(e) => {
                    localforage.setItem(skin + "__storedRewardsDetails", storedRewardsDetails);
                    localforage.setItem(skin + "__storedRewards", storedRewards);
                    orderContext.checkOrderSettings();
                  }}
                >
                  {appLabels["loyalty"]["online-order"]}
                </button>
              </div>
            </div>
          }
          isCancelConfirm={false}
          hideConfirmButton={true}
          resetRemoveDialog={() => {
            setRedirectionRequired(false);
            localforage.removeItem(skin + "__storedRewards");
            localforage.removeItem(skin + "__storedRewardsDetails");
          }}
          customClass={"my-rewards__redirect-dialog"}
        />
      )}
    </>
  );
};
