import React, { useContext } from "react";
import PropTypes from "prop-types";
import AppLabelsContext from "../../../App/AppLabelsContext";

export const SpecialInstructions = (props) => {
  const { specialInstructions, updateSpecialInstructions, onFocus, onBlur } = props;

  const appLabels = useContext(AppLabelsContext);

  return (
    <div className="special-instructions-wrapper">
      <h4 className="order-item__option-header item-detail__options-subheading item-detail__quantity-header">
        {`${appLabels["order"]["special-instructions"]} (${appLabels["general"]["optional"]})`}
      </h4>
      <label htmlFor="special-instructions" className="visually-hidden">
        {appLabels["form"]["special-instructions"]}
      </label>
      <textarea
        onChange={(e) => updateSpecialInstructions(e.target.value)}
        onFocus={() => {
          if (!!onFocus) {
            onFocus();
          }
        }}
        onBlur={() => {
          if (!!onBlur) {
            onBlur();
          }
        }}
        rows="5"
        value={specialInstructions}
        className="special-instructions__field"
        id="special-instructions"
      ></textarea>
    </div>
  );
};

SpecialInstructions.propTypes = {
  specialInstructions: PropTypes.string,
  updateSpecialInstructions: PropTypes.func.isRequired,
};
