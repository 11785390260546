import React, { useContext, useState } from "react";
import { useHistory } from "react-router-dom";

//UI Components
import FlagOutlinedIcon from "@material-ui/icons/FlagOutlined";
import FlagIcon from "@material-ui/icons/Flag";
import KeyboardArrowRightIcon from "@material-ui/icons/KeyboardArrowRight";
import AddressInfoCard from "./AddressInfoCard";

//Contexts
import UserRoleContext from "../App/UserRoleContext";

//helper functions
import { DialogModal } from "../_common/DialogModal/DialogModal";
import {
  formatDeliveryAddress,
  getAllStoredAddresses,
  removeAddress,
} from "../Account/apiHelpers/userAddressHelpers";

/**Assets */
import { IconDelete, IconEdit } from "../_common/icons";

const DeliveryAddressComponent = (props) => {
  const {
    userAddress,
    appLabels,
    skin,
    appLanguage,
    loginToken,
    setUserAddresses,
    setPrimaryAddress,
    handleSelectedAddress,
  } = props;
  const [showRemoveDialog, setShowRemoveDialog] = useState(false);

  const history = useHistory();
  const isAccountPage = window.location.href.includes("account");
  const userRoleContext = useContext(UserRoleContext);

  const userAddressComponents = formatDeliveryAddress(userAddress);

  const handleAddressRemoval = () => {
    removeAddress(skin, appLanguage, loginToken, userAddressComponents.addressId).then(
      (removedAddress) => {
        if (removedAddress) {
          getAllStoredAddresses(skin, loginToken, appLanguage, "stored_shipping").then(
            (data1002) => {
              if (data1002) {
                if (data1002.status === "expiredLoginToken") {
                  userRoleContext.handleLoginTokenExpiration();
                } else if (data1002.storedAddresses) {
                  setUserAddresses(data1002.storedAddresses);
                }
              }
            }
          );
        }
      }
    );
  };

  const handleRemove = () => {
    setShowRemoveDialog(true);
  };

  const handleModify = () => {
    let userDeliveryAddress = {
      addressId: userAddressComponents.addressId,
      "first-address": userAddressComponents["first-address"],
      "second-address": userAddressComponents["second-address"],
      city: userAddressComponents.city,
      country: userAddressComponents.country,
      postal: userAddressComponents.postal,
      province: userAddressComponents.province,
      isDefaultAddress: userAddressComponents.isDefaultAddress,
    };

    if (isAccountPage) {
      history.push({
        pathname: "/account/confirm-address",
        state: {
          from: "modify-delivery-address",
          userAddress: userDeliveryAddress,
        },
      });
    } else {
      history.push({
        pathname: "/online-ordering/confirm-address",
        state: {
          from: "modify-delivery-address",
          userAddress: userDeliveryAddress,
        },
      });
    }
  };

  return (
    <>
      <div
        className={`delivery-address__addresses_container ${
          userAddressComponents.isDefaultAddress === "t" ? " isDefault" : ""
        }`}
      >
        <div className="delivery-address__address-conatiner">
          <button
            type="button"
            onClick={() => {
              handleSelectedAddress(userAddress);
            }}
            disabled={window.location.href.includes("account/delivery-address")}
            aria-label="Select delivery address"
            className="delivery-address-container__overlay-button"
          >
            <AddressInfoCard address={userAddressComponents} />

            {!isAccountPage && (
              <div className="delivery-address__selected-address-button">
                <KeyboardArrowRightIcon />
              </div>
            )}
          </button>
        </div>
        <div className="delivery-address__buttons-container">
          <div className="delivery-address__remove-modify-buttons">
            <button
              type="button"
              className={`delivery-address__action-button `}
              onClick={handleRemove}
            >
              <IconDelete className="cartItem__icon cartItem__icon--delete" aria-hidden="true" />
              {appLabels["general"]["remove"]}
            </button>
            <button
              type="button"
              className={`delivery-address__action-button `}
              onClick={handleModify}
            >
              <IconEdit className="cartItem__icon cartItem__icon--edit" aria-hidden="true" />
              {appLabels["order"]["modify"]}
            </button>
          </div>

          <div>
            <button
              type="button"
              className={`delivery-address__action-button ${
                userAddressComponents.isDefaultAddress === "t" ? " isDefault" : ""
              } `}
              onClick={() => {
                setPrimaryAddress(userAddress);
              }}
            >
              {userAddressComponents.isDefaultAddress === "t" ? (
                <FlagIcon className="icon icon--flag" fontSize="small" />
              ) : (
                <FlagOutlinedIcon className="icon icon--flag-outline" fontSize="small" />
              )}
              {appLabels["form"]["primary-address"]}
            </button>
          </div>
        </div>
      </div>
      {showRemoveDialog && (
        <DialogModal
          message={appLabels["account"]["remove-address-confirmation"]}
          isCancelConfirmText={appLabels["general"]["remove"]}
          isCancelConfirm={true}
          confirmAction={handleAddressRemoval}
          resetRemoveDialog={() => {
            setShowRemoveDialog(!showRemoveDialog);
          }}
          closeModalAction={() => {
            setShowRemoveDialog(!showRemoveDialog);
          }}
        />
      )}
    </>
  );
};

export default DeliveryAddressComponent;
