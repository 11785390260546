// Libraries
import React, { useContext } from "react";
import { Helmet } from "react-helmet";
import { getContrastColor, hexToHSLA } from "../_common/colorHelpers";

// Contexts
import AppLanguageContext from "./AppLanguageContext";
import AppSettingsContext from "./AppSettingsContext";
import BrandingContext from "./BrandingContext";
import ManifestContext from "./ManifestContext";

export const HelmetComponent = ({ customHeadScriptContents }) => {
  const appLanguage = useContext(AppLanguageContext);
  const branding = useContext(BrandingContext);
  const pwaManifest = useContext(ManifestContext);

  const appSettings = useContext(AppSettingsContext);
  const relativePath = appSettings["relative-path"];
  const iosAssetsPath = relativePath + "files/Images/pwa/ios/";

  const isDevEnvironment = process.env.NODE_ENV === "development";
  const isAlphaEnvironment =
    !isDevEnvironment &&
    (window.location.host.includes("alpha") || window.location.host.includes("dilbert"));
  const isBetaEnvironment = !isDevEnvironment && window.location.host.includes("beta");
  const isLiveEnvironment = !isDevEnvironment && !isAlphaEnvironment && !isBetaEnvironment;

  /**
   * Assets need to be injected here instead of /public/index.html.
   * This allows multilingual routes to reference the same assets.
   */

  return (
    <>
      <Helmet>
        <html lang={appLanguage} />

        <title>{pwaManifest.name}</title>
        <meta name="apple-mobile-web-app-title" content={pwaManifest.name} />

        {process.env.NODE_ENV === "production" && (
          <meta http-equiv="Content-Security-Policy" content="manifest-src https://*.givex.com" />
        )}

        <meta name="theme-color" content={branding["primary-colour"]} />

        {/* <link rel="icon" href={`${relativePath}files/Images/favicon.png`} /> */}

        {/* Splash screens for iOS */}

        {/* iPhone 5 (640px x 1136px) */}
        <link
          rel="apple-touch-startup-image"
          media="(device-width: 320px) and (device-height: 568px) and (-webkit-device-pixel-ratio: 2) and (orientation: portrait)"
          href={`${iosAssetsPath}launch-640x1136.png`}
        />

        {/* iPhone 6/7/8 (750px x 1294px) */}
        <link
          rel="apple-touch-startup-image"
          media="(device-width: 375px) and (device-height: 667px) and (-webkit-device-pixel-ratio: 2) and (orientation: portrait)"
          href={`${iosAssetsPath}launch-750x1294.png`}
        />

        {/* iPhone 6/7/8 Plus (750px x 1294px) */}
        <link
          rel="apple-touch-startup-image"
          media="(device-width: 414px) and (device-height: 736px) and (-webkit-device-pixel-ratio: 3) and (orientation: portrait)"
          href={`${iosAssetsPath}launch-1242x2148.png`}
        />

        {/* iPhone X, Xs (1125px x 2436px) */}
        <link
          rel="apple-touch-startup-image"
          media="(device-width: 375px) and (device-height: 812px) and (-webkit-device-pixel-ratio: 3) and (orientation: portrait)"
          href={`${iosAssetsPath}launch-1125x2436.png`}
        />

        {/* 9.7" iPad, 7.9" iPad mini 4 (1536px × 2048px) */}
        <link
          rel="apple-touch-startup-image"
          media="(min-device-width: 768px) and (max-device-width: 1024px) and (-webkit-min-device-pixel-ratio: 2) and (orientation: portrait)"
          href={`${iosAssetsPath}launch-1536x2048.png`}
        />

        {/* iPad Pro 10.5" (1668px x 2224px) */}
        <link
          rel="apple-touch-startup-image"
          media="(min-device-width: 834px) and (max-device-width: 834px) and (-webkit-min-device-pixel-ratio: 2) and (orientation: portrait)"
          href={`${iosAssetsPath}launch-1668x2224.png`}
        />

        {/* iPhone Xr (828px x 1792px) */}
        <link
          rel="apple-touch-startup-image"
          media="(device-width: 414px) and (device-height: 896px) and (-webkit-device-pixel-ratio: 2)"
          href={`${iosAssetsPath}launch-828x1792.png`}
        />

        {/* iPad Pro 12.9" (2048px x 2732px) */}
        <link
          rel="apple-touch-startup-image"
          media="(min-device-width: 1024px) and (max-device-width: 1024px) and (-webkit-min-device-pixel-ratio: 2) and (orientation: portrait)"
          href={`${iosAssetsPath}launch-2048x2732.png`}
        />

        {/** Analytics DataLayer */}
        <script>dataLayer = [];</script>

        {/* Global CSS variables */}
        <style>{`
          :root {
            --color-primary: ${branding["primary-colour"]};
            --color-secondary: ${branding["secondary-colour"]};
            --color-button-text: ${branding["button-text-colour"]};
            --color-secondary-button-text: ${branding["secondary-button-text-colour"]};
            --color-secondary-button-border: ${branding["secondary-button-border-colour"]};
            --color-pay-in-store-button: ${branding["pay-in-store-button-colour"]};
            --color-pay-in-store-button-text: ${branding["pay-in-store-button-text-colour"]};
            --color-add-to-order-button: ${branding["add-to-order-button-colour"]};
            --color-add-to-order-button-text: ${branding["add-to-order-button-text-colour"]};
            --color-header-background-colour: ${branding["header-background-colour"]};
            --color-header-text-and-icon-colour:${branding["header-text-and-icon-colour"]};
            --color-footer-background-colour:${branding["footer-background-colour"]};
            --color-footer-text-and-icon-colour:${branding["footer-text-and-icon-colour"]};
            --color-header-footer-active-text-colour:${
              branding["header-footer-active-text-colour"]
            };
            --color-header-active-text-colour:${branding["header-active-text-colour"]};
            --color-footer-navigation-item-border:${hexToHSLA(
              branding["footer-text-and-icon-colour"],
              0.2
            )};
            --color-header-navigation-item-border:${hexToHSLA(
              branding["header-text-and-icon-colour"],
              0.2
            )};
            --color-online-order-settings-background: ${
              branding["online-order-settings-background-colour"]
            };
            --color-online-order-settings-active-color: ${
              branding["online-order-settings-active-colour"]
            };

            --color-active-button-generic-background:${hexToHSLA(branding["primary-colour"], 0.2)};
            --color-primary-color-contrast: ${getContrastColor(branding["primary-colour"])};
            --color-online-order-settings-background-contrast: ${getContrastColor(
              branding["online-order-settings-background-colour"] || "#fff"
            )};
          }
        `}</style>
      </Helmet>

      {/* Analytics scripts */}
      {appSettings["analytics"] &&
        appSettings["analytics"].length > 0 &&
        appSettings["analytics"].map((analytics, index) => (
          <React.Fragment key={index}>
            {analytics.name === "google-analytics" && analytics.id && (
              <Helmet>
                <script
                  async
                  src={`https://www.googletagmanager.com/gtag/js?id=${analytics.id}`}
                ></script>
                <script>
                  {`window.dataLayer = window.dataLayer || [];
                    function gtag(){dataLayer.push(arguments);}
                    gtag('js', new Date());

                    gtag('config', '${analytics.id}');`}
                </script>
              </Helmet>
            )}
            {analytics.name === "google-tag-manager" && analytics.id && (
              <Helmet>
                <script>
                  {`(function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
                      new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
                      j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
                      'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
                      })(window,document,'script','dataLayer','${analytics.id}')
                  `}
                </script>
              </Helmet>
            )}
            {analytics.name === "facebook-pixel" && analytics.id && (
              <Helmet>
                <script>
                  {` !function(f,b,e,v,n,t,s)
                    {if(f.fbq)return;n=f.fbq=function(){n.callMethod?
                    n.callMethod.apply(n,arguments):n.queue.push(arguments)};
                    if(!f._fbq)f._fbq=n;n.push=n;n.loaded=!0;n.version='2.0';
                    n.queue=[];t=b.createElement(e);t.async=!0;
                    t.src=v;s=b.getElementsByTagName(e)[0];
                    s.parentNode.insertBefore(t,s)}(window, document,'script',
                    'https://connect.facebook.net/en_US/fbevents.js');
                    fbq('init', '${analytics.id}');
                    fbq('track', 'PageView');

                  `}
                </script>
              </Helmet>
            )}
            {/*{analytics.name === "xtreme-push" && analytics.id && (
              <Helmet>
                <script>
                  {`
                  (function(p,u,s,h,e,r,l,i,b) {p['XtremePushObject']=s;p[s]=function(){ (p[s].q=p[s].q||[]).push(arguments)};i=u.createElement('script');i.async=1; i.src=h;b=u.getElementsByTagName('script')[0];b.parentNode.insertBefore(i,b); })(window,document,'xtremepush','https://us.webpu.sh/${analytics.id}/sdk.js');
                `}
                </script>
              </Helmet>
            )}*/}
          </React.Fragment>
        ))}

      {((appSettings["xtremepush-alpha-key"] && isAlphaEnvironment) ||
        (appSettings["xtremepush-beta-key"] && isBetaEnvironment) ||
        (appSettings["xtremepush-live-key"] && isLiveEnvironment)) && (
        <Helmet>
          <script>
            {`
          (function(p,u,s,h,e,r,l,i,b) {p['XtremePushObject']=s;p[s]=function(){ (p[s].q=p[s].q||[]).push(arguments)};i=u.createElement('script');i.async=1; i.src=h;b=u.getElementsByTagName('script')[0];b.parentNode.insertBefore(i,b); })(window,document,'xtremepush','https://us.webpu.sh/${
            isDevEnvironment || isAlphaEnvironment
              ? appSettings["xtremepush-alpha-key"]
              : isBetaEnvironment
              ? appSettings["xtremepush-beta-key"]
              : isLiveEnvironment
              ? appSettings["xtremepush-live-key"]
              : ""
          }/sdk.js');
        `}
          </script>
        </Helmet>
      )}

      {/** Inject custom code into the header*/}
      {customHeadScriptContents && (
        <Helmet>
          <script id="custom-head-snippets">{customHeadScriptContents}</script>
        </Helmet>
      )}

      <Helmet>
        <script>
          {`
         (function (w) {
           // This fix addresses an iOS bug, so return early if the UA claims it's something else.
           var ua = navigator.userAgent;
           if (
             !(
               /iPhone|iPad|iPod/.test(navigator.platform) &&
               /OS [1-5]_[0-9_]* like Mac OS X/i.test(ua) &&
               ua.indexOf("AppleWebKit") > -1
             )
           ) {
             return;
           }
         
           var doc = w.document;
         
           if (!doc.querySelector) {
             return;
           }
         
           var meta = doc.querySelector("meta[name=viewport]"),
             initialContent = meta && meta.getAttribute("content"),
             disabledZoom = initialContent + ",maximum-scale=1",
             enabledZoom = initialContent + ",maximum-scale=10",
             enabled = true,
             x,
             y,
             z,
             aig;
         
           if (!meta) {
             return;
           }
         
           function restoreZoom() {
             meta.setAttribute("content", enabledZoom);
             enabled = true;
           }
         
           function disableZoom() {
             meta.setAttribute("content", disabledZoom);
             enabled = false;
           }
         
           function checkTilt(e) {
             aig = e.accelerationIncludingGravity;
             x = Math.abs(aig.x);
             y = Math.abs(aig.y);
             z = Math.abs(aig.z);
         
             // If portrait orientation and in one of the danger zones
             if (
               (!w.orientation || w.orientation === 180) &&
               (x > 7 || (((z > 6 && y < 8) || (z < 8 && y > 6)) && x > 5))
             ) {
               if (enabled) {
                 disableZoom();
               }
             } else if (!enabled) {
               restoreZoom();
             }
           }
         
           w.addEventListener("orientationchange", restoreZoom, false);
           w.addEventListener("devicemotion", checkTilt, false);
         })(this);
         
        `}
        </script>
      </Helmet>

      {/* inject apple pay button script */}
      <Helmet>
        <script src="https://applepay.cdn-apple.com/jsapi/v1/apple-pay-sdk.js"></script>
      </Helmet>
    </>
  );
};
