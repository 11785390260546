// Libraries
import React, { useState, useContext, useEffect } from "react";
import { Link, useHistory } from "react-router-dom";

// Custom hooks
import useWindowSize from "../_common/hooks/useWindowSize";

// Contexts
import UserRoleContext from "../App/UserRoleContext";
import BrandingContext from "../App/BrandingContext";
import AppLabelsContext from "../App/AppLabelsContext";
import MerchantConfigContext from "../App/MerchantConfigContext";

// UI components
import { AppFooter } from "../App/AppFooter/AppFooter";
import { DialogModal } from "../_common/DialogModal/DialogModal";

// Assets
import { ReactComponent as IconPlus } from "../_common/icons/IconPlus.svg";

// CSS
import "./Rewards.css";

export const Rewards = (props) => {
  const { handleExpiredLogin } = props;

  const userStatus = useContext(UserRoleContext).status;
  const skin = useContext(MerchantConfigContext).skin;
  const history = useHistory();

  const branding = useContext(BrandingContext);
  const secondaryColor = branding["secondary-colour"];

  const [isErrorNotification, setIsErrorNotification] = useState(false);
  const [errorNotificationText, setErrorNotificationText] = useState("");

  const appLabels = useContext(AppLabelsContext);
  const deviceWidth = useWindowSize().width;

  useEffect(() => {
    if (userStatus === "logged-in") {
      history.push("/my-rewards");
    }
  }, [userStatus]);

  return (
    <>
      <section id="rewards-intro" className="rewards-section rewards-intro-section">
        <h2 className="rewards-section__heading">{appLabels["loyalty"]["start-collecting-now"]}</h2>
        <p>{appLabels["loyalty"]["add-new-card-to-join"]}</p>
      </section>
      <section id="rewards-intro" className="rewards-section rewards-intro-section bottom-padding">
        <h3 className="rewards-section__subheading">
          {appLabels["loyalty"]["still-not-convinced"]}
        </h3>
        <p>
          <Link to="/how-rewards-work" style={{ color: secondaryColor }}>
            {appLabels["loyalty"]["check-out-how-rewards-work"]}
          </Link>{" "}
          {appLabels["loyalty"]["check-out-how-rewards-work-extra-text"]}
        </p>
        <div
          className={`button-container--single-bottom button-container--single-desktop ${
            deviceWidth < 660 ? " button-container--multiple-bottom" : ""
          }`}
        >
          {userStatus === "logged-in" ? (
            <Link
              to="/pay-in-store/add-new-card"
              className="button  button--primary-with-border add-new-loyalty-card-button"
              style={{ color: secondaryColor, borderColor: secondaryColor }}
            >
              <IconPlus style={{ color: secondaryColor }} />
              <span>{appLabels["loyalty"]["add-a-new-card"]}</span>
            </Link>
          ) : (
            <>
              <button
                className="button button--primary loyalty-guest-landing__register-link"
                aria-label={appLabels["loyalty"]["register-now"]}
                onClick={() => {
                  sessionStorage.setItem(
                    skin + "__lastVisitedLink",
                    window.location.hash.split("#")[1]
                  );
                  history.push("/register");
                }}
              >
                <span>{appLabels["loyalty"]["register-now"]}</span>
              </button>

              <button
                className="button button--primary loyalty-guest-landing__register-link"
                aria-label={appLabels["login-register"]["sign-in"]}
                onClick={() => {
                  sessionStorage.setItem(
                    skin + "__lastVisitedLink",
                    window.location.hash.split("#")[1]
                  );
                  history.push("/login-register");
                }}
              >
                <span>{appLabels["login-register"]["sign-in"]}</span>
              </button>
            </>
          )}
        </div>
      </section>
      {deviceWidth < 660 && (
        <AppFooter
          showErrorNotification={() => setIsErrorNotification(true)}
          updateErrorText={(error) => setErrorNotificationText(error)}
          handleExpiredLogin={handleExpiredLogin}
        />
      )}
      {isErrorNotification && (
        <DialogModal
          message={errorNotificationText}
          resetRemoveDialog={() => setIsErrorNotification(false)}
        />
      )}
    </>
  );
};
