import React, { useState, useEffect, useRef, useContext } from "react";
import { jsonCopy } from "../../../../_common/helpers";

/** Contexts */
import AppLabelsContext from "../../../../App/AppLabelsContext";
import BrandingContext from "../../../../App/BrandingContext";

/** Helpers */
import { checkNumberOfSelectedItemsInOptionsGroup } from "../../helpers/getMenuData";
import {
  groupHasDefaultSelection,
  optionGroupHasAtLeastOneImage,
} from "../../helpers/orderingHelpers";
import { reachedMaxNumberOfSelection } from "../MenuItemDetailHelpers";

/** UI Components */
import { AddonModifierLabel } from "./AddonModifierLabel";
import AddonQuantityButtons from "./AddonQuantityButtons/AddonQuantityButtons";
import { NestedAddonModifierGroup } from "./NestedAddonModifierGroup";
import AppSettingsContext from "../../../../App/AppSettingsContext";

export const MultipleChoiceAddonModifier = (props) => {
  const {
    topLevelOptionGroup,
    selectedOptions,
    updateSelectedOptions,
    instructionsText,
    mandatoryOptionClass,
    topLevelOptionGroupCopy,
    isInCart,
    isBeingSelected,
    unselectedMultipleOptions,
    updateUnselectedMultipleOptions,
    comboChildItem,
    displayType,
    setGoToNextGroup,
    updateOrderingState,
    isStepByStepOrderingEnabled,
    isEditingComboItem,
    cartItem,
    comboCartItem,
  } = props;

  const currentFocusedCheckboxKey = useRef();

  const appLabels = useContext(AppLabelsContext);
  const brandingContext = useContext(BrandingContext);
  const appSettings = useContext(AppSettingsContext);

  const [zeroSelectionSelected, setZeroSelectionSelected] = useState(false);
  const [disableNoSelectionOption, setDisableNoSelectionOption] = useState(false);
  const [showAddonQuantityButtons, setShowAddonQuantityButtons] = useState(false);
  const [topLevelOptions, setTopLevelOptions] = useState({});
  const [maxNumberOfOptionsReached, setMaxNumberOfOptionsReached] = useState(false);
  const [activeCheckboxes, setActiveCheckboxes] = useState(0);
  const [isDisabled, setIsDisabled] = useState(false);
  const [defaultAddonsChecked, setDefaultAddonChecked] = useState("idle");
  const [openNestedGroups, setOpenNestedGroups] = useState(false);
  const [openNestedGroup, setOpenNestedGroup] = useState(true);
  const [openNestedGroupParentId, setOpenNestedGroupParentId] = useState(null);
  const [nestedGroupIsOpen, setNestedGroupIsOpen] = useState(null);

  const defaultAddonImage = brandingContext["no-image-available-placeholder"];
  const noThankyouAddonImage = brandingContext["no-thank-you-addon-image"];

  const isStandalone = !topLevelOptionGroup.id || topLevelOptionGroup.isStandalone;
  const atLeastOneAddonImage = optionGroupHasAtLeastOneImage(topLevelOptionGroup);
  const showAddonGroupImages =
    atLeastOneAddonImage &&
    (topLevelOptionGroup.type === "addonGroup" ||
      (Object.keys(topLevelOptionGroup.items).length > 0 &&
        Object.keys(topLevelOptionGroup.items)[0].includes("addon")));
  const isAddonQuantityButtonsEnabled = appSettings["enable-addon-quantity-buttons"] === "yes";
  const isModifyDisplay = displayType === "cart-modify";

  /** This Ref is used to flag that the initial state of the nested groups were set to collapsed when modifying */
  const setInitialStateAsCollapsed = useRef([]);

  const noSelectionAddonOption = {
    "addon-noselection": {
      name: appLabels["order"]["addon-no-thank-you-option"],
      id: `noSelectionItem-` + topLevelOptionGroup.id,
      type: "addOn",
      modifierGroups: [],
      item_count: "None",
      isDefault: "False",
      description: "",
      calorie_count: "",
      parentId: topLevelOptionGroup.id,
      price: "0.00",
      substitution_list: [],
    },
  };

  const allNestedGroupsAreCollapsed = () => {
    let allNestedGroupsAreCollapsed = true;

    Object.keys(topLevelOptions).forEach((groupKey) => {
      if (topLevelOptions[groupKey].isSelected) {
        const topLevelOption = topLevelOptions[groupKey];
        if (topLevelOption.modifierGroups) {
          Object.keys(topLevelOption.modifierGroups).forEach((key) => {
            if (
              document.getElementById(
                `${topLevelOption.modifierGroups[key].id}_${
                  topLevelOption.modifierGroups[key].parentId
                }_${topLevelOption.isStandalone ? topLevelOption.id : topLevelOption.parentId}`
              ) &&
              document.getElementById(
                `${topLevelOption.modifierGroups[key].id}_${
                  topLevelOption.modifierGroups[key].parentId
                }_${topLevelOption.isStandalone ? topLevelOption.id : topLevelOption.parentId}`
              ).style.display !== "none"
            ) {
              allNestedGroupsAreCollapsed = false;
            }
          });
        }
      }
    });
    return allNestedGroupsAreCollapsed;
  };

  const onCheckboxChange = (event, setTarget) => {
    const field = event ? event.target : setTarget;

    currentFocusedCheckboxKey.current = field.getAttribute("data-key-name");

    const mainFieldContainer = field.parentElement.parentElement.parentElement;
    const elementLabels = mainFieldContainer.querySelectorAll(`label`);
    elementLabels.forEach((label) => {
      label.classList.remove("error");
    });

    setOpenNestedGroups(false);

    let tempSelectedOptions = {};
    let tempUnselectedOptions = {};

    if (selectedOptions) {
      tempSelectedOptions = { ...selectedOptions };
    }

    if (unselectedMultipleOptions) {
      tempUnselectedOptions = { ...unselectedMultipleOptions };
    }

    if (field.getAttribute("data-key-name") === "addon-noselection") {
      if (field.checked) {
        setZeroSelectionSelected(true);
      } else {
        setZeroSelectionSelected(false);
      }
      Object.keys(topLevelOptions).forEach((key) => {
        if (!key.includes("noselection")) {
          tempUnselectedOptions[key] = topLevelOptions[key];
          delete tempUnselectedOptions[key].quantity;

          if (tempSelectedOptions[key]) {
            delete tempSelectedOptions[key].isSelected;
            delete tempSelectedOptions[key].quantity;
            delete tempSelectedOptions[key];
          }
        }
      });

      setActiveCheckboxes(0);
      updateUnselectedMultipleOptions(tempUnselectedOptions);
      updateSelectedOptions(tempSelectedOptions);
      setGoToNextGroup(true);
      return;
    }

    const tempTopLevelOptions = { ...topLevelOptions };

    tempSelectedOptions[field.dataset.keyName] = tempTopLevelOptions[field.dataset.keyName];

    Object.keys(tempTopLevelOptions).forEach((keyName) => {
      if (tempTopLevelOptions[keyName].isSelected) {
        tempSelectedOptions[keyName] = tempTopLevelOptions[keyName];
      }
    });

    if (field.checked) {
      tempSelectedOptions[field.dataset.keyName].isSelected = true;
      tempSelectedOptions[field.dataset.keyName].quantity =
        tempSelectedOptions[field.dataset.keyName].quantity || 1;
      setOpenNestedGroups(true);
      if (isStandalone) {
        tempSelectedOptions[field.dataset.keyName].isStandalone = true;
      }

      setActiveCheckboxes(activeCheckboxes + 1);
      isMaxQuantityReached(activeCheckboxes + 1);

      //remove any selection options that exist in the unselected options list, when user is selecting an option (checking the checkbox)
      Object.keys(tempSelectedOptions).forEach((key) => {
        if (!!tempUnselectedOptions[key]) {
          delete tempUnselectedOptions[key];
        }
      });
    } else {
      setOpenNestedGroups(false);
      tempUnselectedOptions[field.dataset.keyName] = tempSelectedOptions[field.dataset.keyName];

      delete tempSelectedOptions[field.dataset.keyName].isSelected;
      delete tempSelectedOptions[field.dataset.keyName].quantity;
      delete tempSelectedOptions[field.dataset.keyName];

      setActiveCheckboxes(activeCheckboxes - 1);
      isMaxQuantityReached(activeCheckboxes - 1);
    }

    Object.keys(tempUnselectedOptions).forEach((key) => {
      delete tempUnselectedOptions[key].isSelected;
    });

    /** loop through all the selected options
     * check if any of them has a nested modifier group,
     * if so check if any of the items in the modifier groups is set to default
     * if so set the isSelected Flag to true
     */

    Object.keys(tempSelectedOptions).forEach((addonGroupKey) => {
      const hasNestedModifierGroup =
        tempSelectedOptions[addonGroupKey].modifierGroups &&
        Object.keys(tempSelectedOptions[addonGroupKey].modifierGroups).length > 0;

      if (hasNestedModifierGroup) {
        const nestedModifierGroups = tempSelectedOptions[addonGroupKey].modifierGroups;

        Object.keys(nestedModifierGroups).forEach((nestedModGroupKey) => {
          const nestedModifierGroup = nestedModifierGroups[nestedModGroupKey];
          if (checkNumberOfSelectedItemsInOptionsGroup(nestedModifierGroup) === 0) {
            const nestedModifierGroupItems = nestedModifierGroup.items;

            Object.keys(nestedModifierGroupItems).forEach((nestedModItemKey) => {
              const nestedModItem = nestedModifierGroupItems[nestedModItemKey];
              if (nestedModItem.isDefault === "True") {
                nestedModItem.isSelected = true;
                nestedModifierGroup.isSelected = true;
              }
            });
          }
        });
      }
    });

    if (Object.keys(tempSelectedOptions).length > 0) {
      setDisableNoSelectionOption(true);
    } else {
      setDisableNoSelectionOption(false);
    }

    updateUnselectedMultipleOptions(tempUnselectedOptions);
    updateSelectedOptions(tempSelectedOptions);
  };

  const isMaxQuantityReached = (numberOfActiveCheckboxes) => {
    if (
      parseInt(topLevelOptionGroup.maxQuantity) >= 1 &&
      numberOfActiveCheckboxes === parseInt(topLevelOptionGroup.maxQuantity)
    ) {
      setIsDisabled(true);
    } else {
      setIsDisabled(false);
    }
  };

  useEffect(() => {
    if (
      isAddonQuantityButtonsEnabled &&
      (topLevelOptionGroup.type === "addonGroup" ||
        (Object.keys(topLevelOptionGroup.items).length > 0 &&
          Object.keys(topLevelOptionGroup.items)[0].includes("addon"))) &&
      (Number(topLevelOptionGroup.maxQuantity) > 1 || topLevelOptionGroup.maxQuantity === "0")
    ) {
      setShowAddonQuantityButtons(true);
    }
  }, []);

  useEffect(() => {
    let tempTopLevelOptions =
      isInCart && !!comboChildItem ? topLevelOptionGroup.items : topLevelOptionGroupCopy.items;

    if (showAddonQuantityButtons && topLevelOptionGroup.minQuantity === "0") {
      tempTopLevelOptions = {
        ...tempTopLevelOptions,
        "addon-noselection": noSelectionAddonOption["addon-noselection"],
      };
    }
    setTopLevelOptions(tempTopLevelOptions);
  }, [showAddonQuantityButtons, topLevelOptionGroup, topLevelOptionGroupCopy]);

  /**check if the maximum number of selection has reached */
  useEffect(() => {
    if (topLevelOptions) {
      if (
        topLevelOptionGroup.maxQuantity !== "0" &&
        reachedMaxNumberOfSelection(
          jsonCopy(topLevelOptions),
          topLevelOptionGroup.maxQuantity,
          jsonCopy(topLevelOptionGroup).id
        )
      ) {
        setMaxNumberOfOptionsReached(true);
        if (allNestedGroupsAreCollapsed() && !groupHasDefaultSelection(topLevelOptions)) {
          setGoToNextGroup(true);
        }
      } else {
        setMaxNumberOfOptionsReached(false);
      }
    }
  }, [topLevelOptions]);

  //check and see if there are any previously selected choices
  useEffect(() => {
    if (!isStandalone && isBeingSelected) {
      const tempSelectedOptions = {};

      Object.keys(topLevelOptions).forEach((key) => {
        if (topLevelOptions[key].isSelected) {
          //setHasPreviousChoices(true);
          tempSelectedOptions[key] = topLevelOptions[key];

          // make sure the checkboxes still get disabled based on the max quantity
          setActiveCheckboxes(activeCheckboxes + 1);
          isMaxQuantityReached(activeCheckboxes + 1);
        }
      });

      updateSelectedOptions(tempSelectedOptions);
    }

    /**
     * if the addon/modifier group is being opened for the first time,
     * clear all the isSelected key is any
     */
    if (!isInCart && !isBeingSelected && !isEditingComboItem) {
      const tempSelectedOptions = {};

      Object.keys(topLevelOptions).forEach((addonItemKey) => {
        const addon = topLevelOptions[addonItemKey];
        tempSelectedOptions[addonItemKey] = topLevelOptions[addonItemKey];

        if (
          addon.isSelected &&
          addon.isDefault === "False" &&
          checkNumberOfSelectedItemsInOptionsGroup(topLevelOptionGroupCopy) === 0
        ) {
          delete addon.isSelected;

          if (addon.modifierGroups && Object.keys(addon.modifierGroups).length > 0) {
            Object.keys(addon.modifierGroups).forEach((addonModGroupKey) => {
              const modGroup = addon.modifierGroups[addonModGroupKey];

              if (modGroup.isSelected) {
                delete modGroup.isSelected;

                const modGroupItems = modGroup.items;

                Object.keys(modGroupItems).forEach((nestedModItemKey) => {
                  const nestedItem = modGroupItems[nestedModItemKey];

                  if (nestedItem.isSelected) {
                    delete nestedItem.isSelected;
                  }
                });
              }
            });
          }
        }
      });

      if (!selectedOptions || Object.keys(selectedOptions).length === 0) {
        updateSelectedOptions({});
      }
    }
  }, []);

  useEffect(() => {
    if (topLevelOptions && !isBeingSelected) {
      let tempActiveCheckboxes = 0;

      Object.keys(topLevelOptions).forEach((addonModKey) => {
        if (topLevelOptions[addonModKey].isSelected) {
          tempActiveCheckboxes++;
        }
      });

      setActiveCheckboxes(tempActiveCheckboxes);
      isMaxQuantityReached(tempActiveCheckboxes);
    }
  }, [topLevelOptions]);

  useEffect(() => {
    if (
      defaultAddonsChecked === "idle" &&
      selectedOptions != null &&
      !isDisabled &&
      Object.keys(topLevelOptions).length > 0
    ) {
      const numberOfAlreadySelectedAddons = Object.keys(selectedOptions).length;

      const addonGroupMaxQuantity = parseInt(topLevelOptionGroup.maxQuantity);

      const selectedOptionsCopy = { ...selectedOptions };
      Object.keys(topLevelOptions).forEach((key) => {
        const addon = topLevelOptions[key];
        const isOptionalAddonGroup = topLevelOptionGroup.minQuantity === "0";
        if (
          addon.isDefault === "True" &&
          (addon.item_count === "None" || Number(addon.item_count > 0)) &&
          ((numberOfAlreadySelectedAddons + 1 <= addonGroupMaxQuantity && !isOptionalAddonGroup) ||
            isOptionalAddonGroup) &&
          checkNumberOfSelectedItemsInOptionsGroup(topLevelOptionGroup) === 0 &&
          !isInCart
        ) {
          const type = addon.type === "modifier" ? "modifier" : "addon";
          selectedOptionsCopy[`${type}-${addon.id}`] = addon;
          selectedOptionsCopy[`${type}-${addon.id}`].isSelected = true;
        }
      });

      /** This delay will ensure that default options gets selected and checked correctly */
      if (Object.keys(selectedOptionsCopy).length > 0) {
        setTimeout(() => {
          setActiveCheckboxes(Object.keys(selectedOptionsCopy).length);
          isMaxQuantityReached(Object.keys(selectedOptionsCopy).length);
          updateSelectedOptions(selectedOptionsCopy);
          setDefaultAddonChecked(true);
        }, 250);
      }
    }
  }, [defaultAddonsChecked, selectedOptions, isDisabled, activeCheckboxes, topLevelOptions]);

  /** this useEffect will remove the closed nested mod widget if it's parent option become unselected */
  useEffect(() => {
    if (topLevelOptions) {
      Object.keys(topLevelOptions).forEach((itemKey) => {
        const option = topLevelOptions[itemKey];
        if (
          !option.isSelected &&
          option.modifierGroups &&
          !!Object.keys(option.modifierGroups).length
        ) {
          Object.keys(option.modifierGroups).forEach((nestModGroupKey) => {
            if (
              document.getElementById(`${option.modifierGroups[nestModGroupKey].id}-${option.id}`)
            ) {
              document
                .getElementById(`${option.modifierGroups[nestModGroupKey].id}-${option.id}`)
                .remove();
            }
          });
        }
      });
    }
  }, [topLevelOptions]);

  const scrolledToNestedGroup = useRef(false);
  useEffect(() => {
    if (nestedGroupIsOpen !== null && isStepByStepOrderingEnabled) {
      updateOrderingState({ hideAllButtons: nestedGroupIsOpen });
      if (!nestedGroupIsOpen) {
        scrolledToNestedGroup.current = false;
      }
    }
  }, [nestedGroupIsOpen]);

  /** this useEffect will remove the closed nested mod widget if it's parent option become unselected */
  useEffect(() => {
    if (topLevelOptions) {
      Object.keys(topLevelOptions).forEach((itemKey) => {
        const option = topLevelOptions[itemKey];
        if (
          !option.isSelected &&
          option.modifierGroups &&
          !!Object.keys(option.modifierGroups).length
        ) {
          Object.keys(option.modifierGroups).forEach((nestModGroupKey) => {
            if (
              document.getElementById(
                `${option.modifierGroups[nestModGroupKey].id}_${option.id}_${option.parentId}-${option.id}_${option.parentId}`
              )
            ) {
              document
                .getElementById(
                  `${option.modifierGroups[nestModGroupKey].id}_${option.id}_${option.parentId}-${option.id}_${option.parentId}`
                )
                .remove();
            } else if (
              option.isStandalone &&
              document.getElementById(
                `${option.modifierGroups[nestModGroupKey].id}_${option.id}_${option.id}-${option.id}-standalone`
              )
            ) {
              document
                .getElementById(
                  `${option.modifierGroups[nestModGroupKey].id}_${option.id}_${option.id}-${option.id}-standalone`
                )
                .remove();
            }
          });
        }
      });
    }
  }, [topLevelOptions]);

  /** This state will serve as a flag to determine if the group was already selected at time of initial render,
   * This flag will serve as a condition to see  if the initial state of any nested modifier group of this group should have a collapsed or expanded view
   */
  const [isParentGroupAlreadySelected, setIsParentGroupAlreadySelected] = useState(false);
  useEffect(() => {
    Object.keys(topLevelOptionGroup.items).forEach((itemKey) => {
      if (topLevelOptionGroup.items[itemKey].isSelected) {
        setIsParentGroupAlreadySelected(true);
      }
    });
  }, []);

  /** Clean up the options and remove quantity if they are not selected, mainly used for when user clicked on "Add New Item" from customization window */
  useEffect(() => {
    if (topLevelOptions && Object.keys(topLevelOptions).length > 0) {
      Object.keys(topLevelOptions).forEach((optionKey) => {
        if (
          topLevelOptions[optionKey].quantity > 0 &&
          (!topLevelOptions[optionKey].hasOwnProperty("isSelected") ||
            topLevelOptions[optionKey].isSelected === false ||
            topLevelOptions[optionKey].isSelected === undefined)
        ) {
          let addonToReset = topLevelOptions[optionKey];
          delete addonToReset.quantity;

          if (addonToReset.modifierGroups && Object.keys(addonToReset.modifierGroups).length > 0) {
            Object.keys(addonToReset.modifierGroups).forEach((modGroupKey) => {
              delete addonToReset.modifierGroups[modGroupKey].isSelected;
              const modGroupItems = addonToReset.modifierGroups[modGroupKey].items;
              Object.keys(modGroupItems).forEach((modItemKey) => {
                delete modGroupItems[modItemKey].isSelected;
              });
            });
          }
        }
      });
    }
  }, [topLevelOptions]);

  return (
    <fieldset
      className={`form__fieldset  form__fieldset--top-level${
        showAddonGroupImages ? " form__fieldset-addonModifier with-image-group" : " no-image-group"
      }`}
    >
      <legend className="form__fieldset-legend">
        <span>{topLevelOptionGroup.name}</span>
        <span className={`item-detail__option-note${mandatoryOptionClass}`}>
          {instructionsText}
        </span>
      </legend>

      {Object.keys(topLevelOptions).map((key, index) => (
        <React.Fragment key={key}>
          <div
            className={`form__field-wrapper--checkbox-radio__background ${
              topLevelOptions[key].isSelected ? " is-checked" : ""
            } ${!showAddonGroupImages ? " no-image" : " with-image"} ${
              showAddonQuantityButtons ? "with-quantity-buttons" : " no-quantity-buttons"
            }`}
            id={
              topLevelOptions[key].id
                ? topLevelOptions[key].isStandalone
                  ? `${topLevelOptions[key].id}-standalone`
                  : `${topLevelOptions[key].id}_${topLevelOptions[key].parentId}`
                : `noSelection-` + topLevelOptionGroup.id
            }
            style={
              showAddonGroupImages
                ? {
                    animation: "scaleExpand 0.3s forwards",
                    animationDelay: `${index * 150}ms`,
                  }
                : {
                    animation: "scaleExpandY 0.5s forwards",
                    animationDelay: showAddonQuantityButtons ? 0 : `${index * 150}ms`,
                  }
            }
          >
            <div
              className={`form__field-wrapper form__field-wrapper--checkbox-radio form__field-wrapper--checkbox form__field-wrapper--radio ${
                topLevelOptions[key].isSelected ? " is-checked" : ""
              } ${
                !showAddonGroupImages && showAddonQuantityButtons
                  ? "no-image-addon_with-quantity-wrapper"
                  : ""
              } `}
            >
              {showAddonGroupImages && (
                <img
                  src={
                    topLevelOptions[key].id === `noSelectionItem-` + topLevelOptionGroup.id
                      ? noThankyouAddonImage
                      : topLevelOptions[key].imageUrl || defaultAddonImage
                  }
                  alt={topLevelOptions[key].name}
                  className="addon-modifier_image"
                />
              )}

              <input
                defaultChecked={
                  (topLevelOptions[key].isSelected && (isInCart || isBeingSelected)) ||
                  (topLevelOptions[key].isSelected &&
                    topLevelOptions[key].isDefault === "True" &&
                    checkNumberOfSelectedItemsInOptionsGroup(topLevelOptionGroupCopy) === 0)
                }
                checked={
                  zeroSelectionSelected
                    ? topLevelOptions[key].id === `noSelectionItem-` + topLevelOptionGroup.id
                    : topLevelOptions[key].isSelected
                    ? true
                    : false
                }
                type="checkbox"
                value={topLevelOptions[key].id}
                name={topLevelOptions[key].name}
                id={`option-${topLevelOptions[key].parentId}-${topLevelOptions[key].id}`}
                className={`form__field--checkbox-radio form__field--checkbox visually-hidden ${
                  topLevelOptions[key].isSelected ||
                  (zeroSelectionSelected &&
                    topLevelOptions[key].id === `noSelectionItem-` + topLevelOptionGroup.id)
                    ? " is-checked"
                    : "is-not-checked"
                }`}
                data-key-name={key}
                data-index={index}
                disabled={
                  (isDisabled && !topLevelOptions[key].isSelected) ||
                  Number(topLevelOptions[key].item_count) <= 0 ||
                  (openNestedGroupParentId &&
                    openNestedGroupParentId !== topLevelOptions[key].id) ||
                  (maxNumberOfOptionsReached && !topLevelOptions[key].isSelected) ||
                  (zeroSelectionSelected &&
                    topLevelOptions[key].id !== `noSelectionItem-` + topLevelOptionGroup.id) ||
                  (isInCart &&
                    checkNumberOfSelectedItemsInOptionsGroup(topLevelOptionGroupCopy) > 0 &&
                    topLevelOptions[key].id === `noSelectionItem-` + topLevelOptionGroup.id)
                }
                onChange={onCheckboxChange}
              />

              <AddonModifierLabel
                option={topLevelOptions[key]}
                customClass={`${
                  !showAddonGroupImages && showAddonQuantityButtons
                    ? "no-image-addon_with-quantity"
                    : ""
                }      ${
                  topLevelOptions[key].id === `noSelectionItem-` + topLevelOptionGroup.id
                    ? " no-thankyou-option"
                    : " regular-option"
                } ${
                  showAddonGroupImages
                    ? topLevelOptions[key].isSelected ||
                      (zeroSelectionSelected &&
                        topLevelOptions[key].id === `noSelectionItem-` + topLevelOptionGroup.id)
                      ? " is-checked"
                      : "is-not-checked"
                    : ""
                }`}
                id={`option-${topLevelOptions[key].parentId}-${topLevelOptions[key].id}-label`}
              />

              {showAddonGroupImages && (
                <button
                  type="button"
                  data-key-name={key}
                  onClick={() => {
                    document
                      .getElementById(
                        `option-${topLevelOptions[key].parentId}-${topLevelOptions[key].id}-label`
                      )
                      .click();
                  }}
                  className={`addon-modifier-choice__overlay-button ${
                    showAddonQuantityButtons ? "with-quantity-buttons" : ""
                  }`}
                  aria-label={appLabels["general"]["select"] + " " + topLevelOptions[key].name}
                ></button>
              )}

              {showAddonQuantityButtons && key !== "addon-noselection" && (
                <AddonQuantityButtons
                  addon={topLevelOptions[key]}
                  displayType={displayType}
                  updateSelectedOptions={updateSelectedOptions}
                  updateUnselectedMultipleOptions={updateUnselectedMultipleOptions}
                  onCheckboxChange={onCheckboxChange}
                  inputId={`option-${topLevelOptions[key].parentId}-${topLevelOptions[key].id}`}
                  selectedOptions={selectedOptions}
                  unselectedMultipleOptions={unselectedMultipleOptions}
                  disableAllButtons={zeroSelectionSelected}
                  maxNumberOfOptionsReached={maxNumberOfOptionsReached}
                  isEditingComboItem={isEditingComboItem}
                  customStyle={
                    !showAddonGroupImages
                      ? {
                          animation: "fadeIn 0.5s forwards",
                          animationDelay: `0.5s`,
                        }
                      : {}
                  }
                />
              )}
            </div>

            {((showAddonGroupImages &&
              ((openNestedGroupParentId && openNestedGroupParentId !== topLevelOptions[key].id) ||
                (zeroSelectionSelected &&
                  topLevelOptions[key].id !== `noSelectionItem-` + topLevelOptionGroup.id) ||
                (maxNumberOfOptionsReached && !topLevelOptions[key].isSelected))) ||
              (checkNumberOfSelectedItemsInOptionsGroup(topLevelOptionGroupCopy) > 0 &&
                topLevelOptions[key].id === `noSelectionItem-` + topLevelOptionGroup.id)) && (
              <div className="form__field-wrapper--checkbox-radio__gray-overlay"></div>
            )}

            {(showAddonGroupImages &&
              disableNoSelectionOption &&
              topLevelOptions[key].id === `noSelectionItem-` + topLevelOptionGroup.id) ||
              (checkNumberOfSelectedItemsInOptionsGroup(topLevelOptionGroupCopy) > 0 &&
                topLevelOptions[key].id === `noSelectionItem-` + topLevelOptionGroup.id && (
                  <div className="form__field-wrapper--checkbox-radio__gray-overlay "></div>
                ))}
          </div>
          {topLevelOptions[key].isSelected && (
            <>
              {topLevelOptions[key].modifierGroups && !openNestedGroups.current && (
                <>
                  {Object.keys(topLevelOptions[key].modifierGroups).map((nestedGroupKey) => (
                    <NestedAddonModifierGroup
                      key={nestedGroupKey}
                      nestedOptionGroup={topLevelOptions[key].modifierGroups[nestedGroupKey]}
                      selectedOption={selectedOptions}
                      updateSelectedOption={updateSelectedOptions}
                      topLevelOptionKey={key}
                      type="modifier"
                      isInCart={isInCart}
                      isBeingSelected={isBeingSelected}
                      topLevelOption={topLevelOptions[key]}
                      openNestedGroup={openNestedGroup}
                      setOpenNestedGroup={setOpenNestedGroup}
                      showAddonGroupImages={showAddonGroupImages}
                      setOpenNestedGroupParentId={setOpenNestedGroupParentId}
                      setNestedGroupIsOpen={setNestedGroupIsOpen}
                      setGoToNextGroup={setGoToNextGroup}
                      parentAddonGroupIsSingleChoice={false}
                      labelId={`option-${topLevelOptions[key].parentId}-${topLevelOptions[key].id}-label`}
                      topLevelOptionGroup={topLevelOptionGroup}
                      nestedGroupContainerId={
                        topLevelOptions[key].id
                          ? topLevelOptions[key].id
                          : `noSelection-` + topLevelOptionGroup.id
                      }
                      scrolledToNestedGroup={scrolledToNestedGroup}
                      isStepByStepOrderingEnabled={isStepByStepOrderingEnabled}
                      isStandaloneParent={topLevelOptions[key].isStandalone}
                      isEditingComboItem={isEditingComboItem}
                      isModifyDisplay={isModifyDisplay}
                      mainAddonGroupParentId={
                        isStandalone ? topLevelOptions[key].id : topLevelOptions[key].parentId
                      }
                      mainAddonGroupId={topLevelOptions[key].id}
                      comboChildItem={comboChildItem}
                      cartItem={cartItem}
                      setInitialStateAsCollapsed={setInitialStateAsCollapsed}
                      comboCartItem={comboCartItem}
                      isParentGroupAlreadySelected={isParentGroupAlreadySelected}
                    />
                  ))}
                </>
              )}
            </>
          )}
        </React.Fragment>
      ))}
    </fieldset>
  );
};
