import React, { useContext, useState } from "react";
import { Link } from "react-router-dom";
import localforage from "localforage";

/** Hooks */
import { useHideOrderModule } from "../useHideOrderModule";

/** Contexts */
import AppLabelsContext from "../AppLabelsContext";
import MerchantConfigContext from "../MerchantConfigContext";

/** UI Components */
import { LogoutModalDialog } from "../../_common/components/LogoutModalDialog";

/** CSS */
import "./AppDesktopHeaderMegaSubmenu.css";

export const AppDesktopHeaderMegaSubmenu = () => {
  const appLabels = useContext(AppLabelsContext);

  const merchantConfigContext = useContext(MerchantConfigContext);
  const merchantVexilorConfig = merchantConfigContext.vexilor.I2;
  const isOrderStatusEnabled = merchantVexilorConfig["order_status_access"] === "t";
  const isStadiumSchema = merchantConfigContext.merchant.I55 === "stadium";

  const [isLogoutAttempt, setIsLogoutAttempt] = useState(false);

  const { hideOrderModule } = useHideOrderModule();
  const skin = merchantConfigContext.skin;

  const handleClick = () => {
    localforage.removeItem(skin + "__itemSelectionInProgress");
    localforage.removeItem(skin + "__storeIdToBeFaved");
    sessionStorage.removeItem(skin + "__orderStoreState");
  };

  return (
    <div className="desktop-header__subnav desktop-header__subnav--mega">
      <div className="desktop-header-subnav__content desktop-header-mega-subnav__content">
        <div
          className="desktop-header-mega-subnav__column"
          role="group"
          aria-labelledby="desktop-header-mega-subnav__heading--profile"
        >
          <h2
            id="desktop-header-mega-subnav__heading--profile"
            className="desktop-header-mega-subnav__heading"
          >
            {appLabels["account"]["profile"]}
          </h2>
          <ul className="desktop-header-mega-subnav__list">
            <li>
              <Link
                to="/account/profile/personal-info"
                className="desktop-header-nav__submenu-link"
                onClick={handleClick}
              >
                {appLabels["account"]["personal-info"]}
              </Link>
            </li>
            {merchantVexilorConfig.active_order_types.includes("delivery") && !isStadiumSchema && (
              <li>
                <Link
                  to="/account/delivery-address"
                  className="desktop-header-nav__submenu-link"
                  onClick={handleClick}
                >
                  {appLabels["order"]["delivery-addresses"]}
                </Link>
              </li>
            )}
            <li>
              <Link
                to="/account/profile/change-password"
                className="desktop-header-nav__submenu-link"
                onClick={handleClick}
              >
                {appLabels["account"]["change-password"]}
              </Link>
            </li>
            <li>
              <Link
                to="/account/payment-methods"
                className="desktop-header-nav__submenu-link"
                onClick={handleClick}
              >
                {appLabels["account"]["payment-methods"]}
              </Link>
            </li>

            <li>
              <Link
                to="/account/account-deletion"
                className="desktop-header-nav__submenu-link"
                onClick={handleClick}
              >
                {appLabels["account"]["delete-account"]}
              </Link>
            </li>
          </ul>
        </div>
        {!hideOrderModule && (
          <div
            className="desktop-header-mega-subnav__column"
            role="group"
            aria-labelledby="desktop-header-mega-subnav__heading--transaction-history"
          >
            <h2
              id="desktop-header-mega-subnav__heading--transaction-history"
              className="desktop-header-mega-subnav__heading"
            >
              {appLabels["account"]["my-orders"]}
            </h2>
            <ul className="desktop-header-mega-subnav__list">
              {isOrderStatusEnabled && (
                <li>
                  <Link
                    to="/online-ordering/recent-orders/active-orders"
                    className="desktop-header-nav__submenu-link"
                    onClick={handleClick}
                  >
                    {appLabels["order"]["active-orders"]}
                  </Link>
                </li>
              )}
              <li>
                <Link
                  to="/online-ordering/recent-orders/past-orders"
                  className="desktop-header-nav__submenu-link"
                  onClick={handleClick}
                >
                  {appLabels["order"]["past-orders"]}
                </Link>
              </li>
            </ul>
          </div>
        )}
        <div className="desktop-header-mega-subnav__column">
          <button
            onClick={() => setIsLogoutAttempt(true)}
            className="button desktop-header-mega-subnav__logout-button"
            type="button"
          >
            {appLabels["account"]["log-out"]}
          </button>
          {isLogoutAttempt && (
            <LogoutModalDialog resetRemoveDialog={() => setIsLogoutAttempt(false)} />
          )}
        </div>
      </div>
    </div>
  );
};
