import React, { useContext, useState } from "react";
import { ReactComponent as IconStar } from "../_common/icons/IconStar.svg";

// Contexts
import AppLabelsContext from "../App/AppLabelsContext";

//Custom Hooks
import useWindowSize from "../_common/hooks/useWindowSize";

const MyRewardsCard = (props) => {
  const { reward, imagePlaceholder, selectedCard, handleRedemption, itemIndex } = props;
  const appLabels = useContext(AppLabelsContext);

  const [isRewardDetailsCollapsed, setIsRewardDetailsCollapsed] = useState(true);

  const deviceWidth = useWindowSize().width;

  const toggleItemDetails = (reward) => {
    const currentState = isRewardDetailsCollapsed;
    setIsRewardDetailsCollapsed(!currentState);

    const content = document.getElementById(`${reward.id}-${itemIndex}`);

    if (!currentState) {
      content.style.maxHeight = null;
    } else {
      content.style.maxHeight = content.scrollHeight + "px";
    }
  };

  return (
    <>
      {deviceWidth < 660 ? (
        <div className="my-rewards__wrapper" id={reward.id}>
          <div className="my-rewards__option-container">
            <div className="my-rewards__option--icon-rewards">
              <img src={reward.image || imagePlaceholder} alt={reward.name} />
            </div>
            <div className="my-rewards__option-container-rewards">
              {reward.price > 0 && (
                <div className="my-rewards__option--cost-rewards">
                  {reward.price}
                  <IconStar />
                </div>
              )}
              <div className="my-rewards__option--name-rewards">
                <span className="my-rewards__option-name">{reward.name}</span>
              </div>
              {reward.rewardBalance && reward.rewardBalance > 0 && (
                <div className="my-rewards__option--balance">
                  <span>{`${appLabels["loyalty"][
                    reward.rewardBalance === "1" ? "rewards-remaining-single" : "rewards-remaining"
                  ].replace("[reward-balance]", reward.rewardBalance)}`}</span>
                </div>
              )}

              {reward.expiry && (
                <div className="my-rewards__option--expiry-rewards">
                  <span>{`${appLabels["general"]["expires-on"]} : ${reward.expiry}`}</span>
                </div>
              )}
              <div>
                <button
                  type="button"
                  className="suggested_items__view-details"
                  onClick={() => {
                    toggleItemDetails(reward);
                  }}
                  style={
                    reward.description === "" ? { visibility: "hidden" } : { visibility: "visible" }
                  }
                >
                  {isRewardDetailsCollapsed
                    ? appLabels["order"]["view-details"]
                    : appLabels["order"]["hide-details"]}{" "}
                </button>
              </div>
            </div>
          </div>
          <div
            className={`my-rewards__reward-description ${
              isRewardDetailsCollapsed
                ? " my-rewards__reward-description-hidden"
                : " my-rewards__reward-description-visible"
            }`}
            id={`${reward.id}-${itemIndex}`}
          >
            <p>{reward.description}</p>
          </div>
          <div className="my-rewards__option--button-container-rewards">
            <button
              type="button"
              className="button button--primary button--primary-small"
              onClick={() => {
                handleRedemption(reward);
              }}
              disabled={selectedCard.points < reward.price}
            >
              {appLabels["loyalty"]["redeem"]}
            </button>
          </div>
        </div>
      ) : (
        <div className="my-rewards__wrapper-desktop" id={reward.id}>
          <div className="my-rewards__option-container">
            <div className="my-rewards__option--icon-rewards">
              <img src={reward.image || imagePlaceholder} alt={reward.name} />
            </div>
            {reward.price > 0 && (
              <div className="my-rewards__option--cost-rewards">
                {reward.price}
                <IconStar />
              </div>
            )}

            <div className="my-rewards__option-container-rewards">
              <div className="my-rewards__option--name-rewards">
                <span className="my-rewards__option-name">{reward.name}</span>
              </div>
              {reward.rewardBalance && reward.rewardBalance > 0 && (
                <div className="my-rewards__option--balance">
                  <span>{`${appLabels["loyalty"][
                    reward.rewardBalance === "1" ? "rewards-remaining-single" : "rewards-remaining"
                  ].replace("[reward-balance]", reward.rewardBalance)}`}</span>
                </div>
              )}
              {reward.expiry && (
                <div className="my-rewards__option--expiry-rewards">
                  <span>{`${appLabels["general"]["expires-on"]} : ${reward.expiry}`}</span>
                </div>
              )}
              <div>
                <button
                  type="button"
                  className="suggested_items__view-details"
                  onClick={() => {
                    toggleItemDetails(reward);
                  }}
                  style={
                    reward.description === "" ? { visibility: "hidden" } : { visibility: "visible" }
                  }
                >
                  {isRewardDetailsCollapsed
                    ? appLabels["order"]["view-details"]
                    : appLabels["order"]["hide-details"]}{" "}
                </button>
              </div>
              <div
                className={`my-rewards__reward-description ${
                  isRewardDetailsCollapsed
                    ? " my-rewards__reward-description-hidden"
                    : " my-rewards__reward-description-visible"
                }`}
                id={`${reward.id}-${itemIndex}`}
              >
                <p>{reward.description}</p>
              </div>
            </div>
            <div className="my-rewards__option--button-container-rewards">
              <button
                type="button"
                className="button button--primary button--primary-small"
                onClick={() => {
                  handleRedemption(reward);
                }}
                disabled={selectedCard.points < reward.price}
              >
                {appLabels["loyalty"]["redeem"]}
              </button>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default MyRewardsCard;
