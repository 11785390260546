// Libraries
import React, { useState, useEffect, useContext } from "react";
import { useHistory } from "react-router-dom";
import { ReactSVG } from "react-svg";

// Contexts
import UserRoleContext from "../UserRoleContext";
import AppSettingsContext from "../AppSettingsContext";
import MerchantConfigContext from "../MerchantConfigContext";
import OrderTypeContext from "../../OnlineOrdering/OrderTypeContext";
import OrderTimeContext from "../../OnlineOrdering/OrderTimeContext";
import AppLabelsContext from "../AppLabelsContext";
import BrandingContext from "../BrandingContext";
import OrderContext from "../OrderContext";
import StaticPagesDataContextContext from "../contexts/StaticPagesDataContext";
import StoreContext from "../../OnlineOrdering/StoreContext";

// Hooks
import { useHideOrderModule } from "../useHideOrderModule";

// UI components
import { AppFooterItem } from "./AppFooterItem";
//import { AppFooterPayInStore } from "./AppFooterPayInStore";
import { LogoutModalDialog } from "../../_common/components/LogoutModalDialog";

// Assets
import { ReactComponent as IconUser } from "../../_common/icons/IconUser.svg";
import { ReactComponent as IconQR } from "../../_common/icons/IconQR.svg";
import { ReactComponent as IconStar } from "../../_common/icons/IconStar.svg";
import { ReactComponent as IconUtensils } from "../../_common/icons/IconUtensils.svg";
import { ReactComponent as IconKebab } from "../../_common/icons/IconKebab.svg";
import { ReactComponent as IconScript } from "../../_common/icons/IconScript.svg";
import { ReactComponent as IconLogout } from "../../_common/icons/IconLogout.svg";
//import { ReactComponent as IconMapPin } from "../../_common/icons/IconMapPin.svg";
import { ReactComponent as IconDocumentPen } from "../../_common/icons/IconDocumentPen.svg";
import { ReactComponent as IconExternalLink } from "../../_common/icons/IconExternalLink.svg";
import { ReactComponent as IconOrderStatus } from "../../_common/icons/IconOrderStatus.svg";
import { ReactComponent as IconGift } from "../../_common/icons/IconGift.svg";

// CSS
import "./AppFooter.css";

export const AppFooter = () => {
  const appSettings = useContext(AppSettingsContext);
  const appLabels = useContext(AppLabelsContext);
  const branding = useContext(BrandingContext);
  const merchantConfigContext = useContext(MerchantConfigContext);
  const userRoleContext = useContext(UserRoleContext);
  const staticPagesData = useContext(StaticPagesDataContextContext);
  const history = useHistory();

  const merchantConfig = merchantConfigContext.merchant;
  const vexilorConfig = merchantConfigContext.vexilor.I2;
  const isAccountRegistrationModuleEnabled = merchantConfig.I24 === "t";
  const isLoyaltyModuleEnabled = merchantConfig.I22 === "t";
  const isOrderingModuleEnabled = merchantConfig.I36 === "t";
  const isScanInStoreModuleEnabled = merchantConfig.I28 === "t";
  const isOrderStatusEnabled = vexilorConfig["order_status_access"] === "t";

  const { hideOrderModule } = useHideOrderModule();
  const relativePath = appSettings["relative-path"];

  const userStatus = userRoleContext.status;
  const orderTimeContext = useContext(OrderTimeContext);

  const orderContext = useContext(OrderContext);
  const orderType = useContext(OrderTypeContext).value;
  const storeContext = useContext(StoreContext);

  const [hideOrderButtonOnLanding, setHideOrderButtonOnLanding] = useState(false);
  useEffect(() => {
    if (vexilorConfig && storeContext && appSettings) {
      const isPayOnly = appSettings["is-pay-only"] === "yes";
      if (isPayOnly) {
        if (
          storeContext.activeOrderStore === null &&
          vexilorConfig.active_order_types === "dinein"
        ) {
          setHideOrderButtonOnLanding(true);
        } else {
          setHideOrderButtonOnLanding(false);
        }
      } else {
        setHideOrderButtonOnLanding(false);
      }
    }
  }, [vexilorConfig, storeContext, appSettings]);

  //const [isScanInStoreFAB, setIsScanInStoreFAB] = useState(false);

  const [navData, setNavData] = useState(null);
  useEffect(() => {
    const location = history.location.pathname;

    const tempNavData = [];

    if (isAccountRegistrationModuleEnabled) {
      /*tempNavData.push({
        name: "account",
        displayName: appLabels["general"]["account"],
        icon: <IconUser aria-hidden="true" />,
        isActive: location.includes("/account"),
        isLink: true,
        linkPathname: userStatus === "logged-in" ? "/account/profile" : "/login-register",
      });*/

      const accountNavData = {
        name: "account",
        displayName: appLabels["general"]["account"],
        icon: <IconUser aria-hidden="true" />,
        isActive: location.includes("/account"),
        isLink: userStatus !== "logged-in",
        linkPathname: userStatus === "logged-in" ? "/account/profile" : "/login-register",
        subnavItems: [
          {
            name: "account",
            displayName: appLabels["general"]["my-account"],
            icon: <IconUser aria-hidden="true" />,
            isLink: true,
            linkPathname: userStatus === "logged-in" ? "/account/profile" : "/login-register",
          },
        ],
      };

      if (userStatus === "logged-in" && !hideOrderModule && !hideOrderButtonOnLanding) {
        //check if app footer should show order status tab
        if (isOrderStatusEnabled) {
          accountNavData.subnavItems.push({
            name: "active-orders",
            displayName: appLabels["order"]["active-orders"],
            isLink: true,
            linkPathname: "/online-ordering/recent-orders/active-orders",
            icon: <IconOrderStatus aria-hidden="true" />,
          });
        }
        accountNavData.subnavItems.push({
          name: "past-orders",
          displayName: appLabels["order"]["past-orders"],
          isLink: true,
          linkPathname: "/online-ordering/recent-orders/past-orders",
          icon: <IconDocumentPen aria-hidden="true" />,
        });
      }

      tempNavData.push(accountNavData);
    }

    if (isScanInStoreModuleEnabled && userStatus === "logged-in") {
      tempNavData.push({
        name: "scan-in-store",
        displayName: appLabels["general"]["scan-in-store"],
        icon: <IconQR aria-hidden="true" />,
        isActive: location.includes("/pay-in-store"),
        isLink: true,
        linkPathname: "/pay-in-store",
      });
    }

    if (isLoyaltyModuleEnabled) {
      tempNavData.push({
        name: "loyalty",
        displayName: appLabels["general"]["rewards"],
        icon: <IconStar aria-hidden="true" />,
        isActive: location.includes("rewards"),
        isLink: true,
        linkPathname: userStatus === "logged-in" ? "/my-rewards" : "/rewards",
      });
    }

    if (isOrderingModuleEnabled && !hideOrderModule && !hideOrderButtonOnLanding) {
      const orderNavData = {
        name: "order",
        ariaLabel: "order options",
        displayName: appLabels["general"]["order"],
        icon: <IconUtensils aria-hidden="true" />,
        isActive: location.includes("/online-ordering"),
        action: () => {
          orderContext.checkOrderSettings(orderType, storeContext, orderTimeContext);
        },
        isAction: true,
      };
      tempNavData.push(orderNavData);
    }

    const settingsNavData = {
      name: "settings",
      ariaLabel: "app settings",
      icon: <IconKebab aria-hidden="true" />,
      subnavItems: [],
    };

    if (!!branding["client-gift-card-url"]) {
      const svgComponent = (
        <ReactSVG src={`${relativePath}${branding["client-gift-card-url-icon"]}`} wrapper={"svg"} />
      );

      settingsNavData.subnavItems.push({
        name: "merchant-gift-card-url",
        displayName: appLabels["general"]["client-gift-card-website-label"],
        isLink: true,
        isExternalLink: true,
        linkPathname: branding["client-gift-card-url"],
        icon:
          branding["client-gift-card-url-icon"].includes("_blank") ||
          branding["client-gift-card-url-icon"] === "" ||
          !branding["client-gift-card-url-icon"] ? (
            <IconGift aria-hidden="true" />
          ) : (
            svgComponent
          ),
      });
    }
    if (branding["client-site-url"]) {
      settingsNavData.subnavItems.push({
        name: "merchant-website",
        displayName: appLabels["general"]["main-website"],
        isLink: true,
        isExternalLink: true,
        linkPathname: branding["client-site-url"],
        icon: <IconExternalLink aria-hidden="true" />,
      });
    }

    if (appSettings["enable-terms"] === "Yes") {
      settingsNavData.subnavItems.push({
        name: "terms-and-conditions",
        displayName: appLabels["general"]["terms-and-conditions"],
        icon: <IconScript aria-hidden="true" />,
        isLink: true,
        linkPathname: "/terms-and-conditions",
      });
    }

    if (staticPagesData && staticPagesData.length > 0) {
      staticPagesData.forEach((staticPageData, index) => {
        if (staticPageData.isEnabled === "yes") {
          settingsNavData.subnavItems.push({
            name: `static-page-${index + 1}`,
            displayName: staticPageData.displayName,
            pageHeading: staticPageData.pageHeading,
            marquiIconFilename: staticPageData.iconFilename,
            isLink: true,
            linkPathname: staticPageData.path,
          });
        }
      });
    }

    if (userStatus === "logged-in") {
      settingsNavData.subnavItems.push({
        name: "logout",
        displayName: appLabels["account"]["log-out"],
        action: () => setIsLogoutAttempt(true),
        icon: <IconLogout aria-hidden="true" />,
      });
    }

    tempNavData.push(settingsNavData);

    /*if (location.includes("/dashboard") && userStatus === "logged-in") {
      setIsScanInStoreFAB(true);
    }*/

    setNavData(tempNavData);
  }, [
    orderTimeContext,
    userRoleContext,
    userStatus,
    staticPagesData,
    orderType,
    storeContext,
    hideOrderModule,
    hideOrderButtonOnLanding,
  ]);

  const [isLogoutAttempt, setIsLogoutAttempt] = useState(false);

  return (
    <>
      <footer className="footer">
        <nav>
          <ul className="footer__nav-list">
            {navData &&
              navData.map((module, index) => <AppFooterItem module={module} key={index} />)}
          </ul>
        </nav>
      </footer>
      {/*isScanInStoreFAB && isScanInStoreModuleEnabled && <AppFooterPayInStore />*/}
      {isLogoutAttempt && <LogoutModalDialog resetRemoveDialog={() => setIsLogoutAttempt(false)} />}
    </>
  );
};
