import React, { useContext, useState, useEffect } from "react";
import localforage from "localforage";
import { useHistory } from "react-router-dom";

import { placeAPIOrder } from "./apiHelpers/placeAPIOrder";
import {
  updateLocationData,
  ensureOrderTimeMinInterval,
  isOrderTimePassed,
  isOrderTimeBeforeOpeningTime,
  isOrderTimeBlocked,
  isLateOrderAttempted,
  getOrderStoreAddressForDialogModal,
  isStoreClosedToday,
} from "../../../../_common/PaymentHelpers";
import { checkBillRequestStatus, generateBillsForItems } from "../../../Menu/Bill/BillHelpers";
import { placeUnifiedAPIOrder } from "./apiHelpers/placeUnifiedAPIOrder";

import AppLanguageContext from "../../../../App/AppLanguageContext";
import OrderTypeContext from "../../../OrderTypeContext";
import StoreContext from "../../../StoreContext";
import CartContext from "../../../Cart/CartContext";
import AppLabelsContext from "../../../../App/AppLabelsContext";
import OrderTimeContext from "../../../OrderTimeContext";
import BillContext from "../../../Menu/Bill/BillContext";
import UserRoleContext from "../../../../App/UserRoleContext";

import { Form } from "../../../../_common/Form/Form";
import PaymentOverlay from "../../PaymentOverlay";
import { LoadingSpinner } from "../../../../_common/LoadingSpinner";
import { DialogModal } from "../../../../_common/DialogModal/DialogModal";

export const CompleteOrderWithGiftCard = (props) => {
  const {
    appliedGiftCards,
    isDisabled,
    merchantConfig,
    rewards,
    promoCode,
    setIsPassedOrderTime,
    setNextAvailableOrderTime,
    setIsOrderTimeBeforeOpening,
    orderTimeValue,
    coupon,
    setOrderTimeIsBlocked,
    setIsStoreClosed
  } = props;

  const history = useHistory();
  const skin = merchantConfig.skin;

  const orderTypeContext = useContext(OrderTypeContext);
  const storeContext = useContext(StoreContext);
  const cartContext = useContext(CartContext);
  const orderTimeContext = useContext(OrderTimeContext);
  const userRoleContext = useContext(UserRoleContext);

  const billContext = useContext(BillContext);
  const billItems = billContext.value;
  const billDetails = billContext.details;

  const activeOrderStore = storeContext.activeOrderStore;
  const activeOrderType = orderTypeContext.value;

  const loginToken = userRoleContext.loginToken;
  const isLoggedIn = userRoleContext.status === "logged-in"; 

  const [isAPISubmitValid, setIsAPISubmitValid] = useState(null);
  const [apiError, setAPIError] = useState("");
  const [isAPISubmitting, setAPISubmitting] = useState(false);

  const [customerInfo, setCustomerInfo] = useState({});
  const [orderDate, setOrderDate] = useState(null);
  const [orderTime, setOrderTime] = useState(null);

  useEffect(() => {
    localforage.getItem(skin + "__customerInfo").then((customerInfo) => {
      if (customerInfo) setCustomerInfo(customerInfo);
    });

    localforage.getItem(skin + "__orderDate").then((orderDate) => {
      if (orderDate) {
        setOrderDate(orderDate.displayValue);
      }
    });

    localforage.getItem(skin + "__orderTime").then((orderTime) => {
      if (orderTime) {
        setOrderTime(orderTime.displayValue);
      }
    });
  }, [merchantConfig]);

  const getCurrentTimestamp = () => {
    if (orderTimeValue) {
      const date = new Date(
        ensureOrderTimeMinInterval(orderTimeValue, activeOrderStore, activeOrderType)
      );
      const month = date.getMonth() + 1;
      const day = date.getDate();
      const year = date.getFullYear();
      const hours = date.getHours();
      const minutes = date.getMinutes();
      const seconds = date.getSeconds();

      const timestamp = `${year}-${month}-${day} ${hours}:${minutes}:${seconds}`;
      return timestamp;
    } else {
      return false;
    }
  };

  const checkBillStatus = async (hostTransactionId) => {
    const billStatusData = await checkBillRequestStatus(
      appLanguage,
      skin,
      hostTransactionId,
      activeOrderStore.storeId
    );

    if (billStatusData) {
      return billStatusData;
    }
  };

  const processPayment = (hostTransactionId) => {
    const giftCardNumberList = [];

    if (coupon) giftCardNumberList.push(coupon.code);
    appliedGiftCards.forEach((appliedGiftCard) => {
      giftCardNumberList.push([
        appliedGiftCard.fullCardNumber,
        appliedGiftCard.pin,
        appliedGiftCard.balance,
      ]);
    });

    placeUnifiedAPIOrder(
      skin,
      storeContext.activeOrderStore,
      orderTypeContext.value,
      customerInfo,
      rewards,
      promoCode,
      giftCardNumberList,
      orderTypeInstruction,
      tipAmount,
      appLanguage,
      appLabels["general"],
      hostTransactionId
    ).then((apiPlacedOrderResult) => {
      setAPISubmitting(false);

      if (apiPlacedOrderResult) {
        if (apiPlacedOrderResult.isPlaced) {
          localforage.setItem(skin + "__completedOrderId", apiPlacedOrderResult.orderId);
          setIsAPISubmitValid(true);
          if (apiPlacedOrderResult.pointsIssued) {
            localforage.setItem(skin + "__pointsIssued", apiPlacedOrderResult.pointsIssued);
          }
        } else {
          setShowPaymentOverlay(false);
          setAPIError(apiPlacedOrderResult.error);
          setIsAPISubmitValid(false);
        }
      }
    });
  };

  const [paymentProgressSpeed, setPaymentProgressSpeed] = useState(0);

  const payDineinBill = async () => {
    let orderLineList = [];

    billItems.forEach((billItem) => {
      orderLineList.push(billItem["vxl_order_line_id"]);
    });

    let billAPIData = await generateBillsForItems(
      appLanguage,
      skin,
      billDetails["vxl_order_id"], // vexilor order id
      orderLineList, //order line id list
      "", //online order id
      "", //loyalty card
      activeOrderStore.storeId
    );

    setPaymentProgressSpeed(10000);
    setHeaderLabel(appLabels["order"]["preparing-bill-for-verification"]);
    setSecondaryHeaderLabel(appLabels["order"]["retrieving-bill-details"]);

    setTimeout(() => {
      setPaymentProgressSpeed(10000);
      setHeaderLabel(appLabels["order"]["retrieving-bill-details"]);
      setSecondaryHeaderLabel(appLabels["order"]["processing-your-bill"]);
    }, 10000);

    setTimeout(() => {
      setPaymentProgressSpeed(5000);
      setHeaderLabel(appLabels["order"]["processing-your-bill"]);
      setSecondaryHeaderLabel(appLabels["order"]["verifying-bill-details"]);
    }, 20000);

    setTimeout(() => {
      setPaymentProgressSpeed(5000);
      setHeaderLabel(appLabels["order"]["verifying-bill-details"]);
      setSecondaryHeaderLabel(appLabels["order"]["confirming-bill-details"]);
    }, 25000);

    if (billAPIData && billAPIData.status === 0) {
      const hostTransactionId = billAPIData.result.I2;

      setTimeout(async () => {
        setPaymentProgressSpeed(5000);
        setHeaderLabel(appLabels["order"]["confirming-bill-details"]);
        setSecondaryHeaderLabel(appLabels["order"]["processing-your-payment"]);

        const billStatusData = await checkBillStatus(hostTransactionId);

        if (billStatusData && billStatusData.status === 0) {
          /** if the bill status is complete */
          if (billStatusData.result.I2["request_status"] === "complete") {
            setAPISubmitting(true);

            setTimeout(() => {
              setPaymentProgressSpeed(3000);
              setHeaderLabel(appLabels["order"]["processing-your-payment"]);
              setSecondaryHeaderLabel(appLabels["order"]["all-done"]);
            }, 3000);

            setTimeout(() => {
              setPaymentProgressSpeed(3000);
              setHeaderLabel(appLabels["order"]["all-done"]);
            }, 6000);

            processPayment(hostTransactionId);
          } else if (
            billStatusData.result.I2["request_status"] === "pending" ||
            billStatusData.result.I2["request_status"] === "ready"
          ) {
            /** if bill status is not complete but it is pending or ready, wait three seconds and then check again */
            setPaymentProgressSpeed(5000);
            setHeaderLabel(appLabels["order"]["double-checking-your-bill"]);
            setSecondaryHeaderLabel(appLabels["order"]["processing-your-payment"]);

            setTimeout(() => {
              setPaymentProgressSpeed(5000);
              setHeaderLabel(appLabels["order"]["confirming-bill-details"]);
              setSecondaryHeaderLabel(appLabels["order"]["processing-your-payment"]);

              checkBillStatus(hostTransactionId).then((billStatusDataSecondTry) => {
                if (billStatusDataSecondTry && billStatusDataSecondTry.status === 0) {
                  if (billStatusDataSecondTry.result.I2["request_status"] === "complete") {
                    setAPISubmitting(true);

                    setTimeout(() => {
                      setPaymentProgressSpeed(3000);
                      setHeaderLabel(appLabels["order"]["processing-your-payment"]);
                      setSecondaryHeaderLabel(appLabels["order"]["all-done"]);
                    }, 3000);

                    setTimeout(() => {
                      setPaymentProgressSpeed(3000);
                      setHeaderLabel(appLabels["order"]["all-done"]);
                    }, 6000);

                    processPayment(hostTransactionId);
                  } else if (
                    billStatusDataSecondTry.result.I2["request_status"] === "pending" ||
                    billStatusDataSecondTry.result.I2["request_status"] === "ready"
                  ) {
                    setPaymentProgressSpeed(5000);
                    setHeaderLabel(appLabels["order"]["double-checking-your-bill"]);
                    setSecondaryHeaderLabel(appLabels["order"]["processing-your-payment"]);

                    setTimeout(() => {
                      setPaymentProgressSpeed(5000);
                      setHeaderLabel(appLabels["order"]["confirming-bill-details"]);
                      setSecondaryHeaderLabel(appLabels["order"]["processing-your-payment"]);

                      /** Second retry, in case the after the first retry bill was still not ready */
                      checkBillStatus(hostTransactionId).then((billStatusDataThirdTry) => {
                        if (billStatusDataThirdTry && billStatusDataThirdTry.status === 0) {
                          if (billStatusDataSecondTry.result.I2["request_status"] === "complete") {
                            setAPISubmitting(true);

                            setTimeout(() => {
                              setPaymentProgressSpeed(3000);
                              setHeaderLabel(appLabels["order"]["processing-your-payment"]);
                              setSecondaryHeaderLabel(appLabels["order"]["all-done"]);
                            }, 3000);

                            setTimeout(() => {
                              setPaymentProgressSpeed(3000);
                              setHeaderLabel(appLabels["order"]["all-done"]);
                            }, 6000);

                            processPayment(hostTransactionId);
                          } else {
                            setPaymentProgressSpeed(0);
                            setShowPaymentOverlay(false);
                            setAPIError(appLabels["order"]["bill-api-error"]);
                          }
                        } else {
                          setPaymentProgressSpeed(0);
                          setShowPaymentOverlay(false);
                          setAPIError(appLabels["order"]["bill-api-error"]);
                        }
                      });
                    }, 5000);
                  } else {
                    setPaymentProgressSpeed(0);
                    setShowPaymentOverlay(false);
                    setAPIError(appLabels["order"]["bill-api-error"]);
                  }
                } else {
                  setPaymentProgressSpeed(0);
                  setShowPaymentOverlay(false);
                  setAPIError(appLabels["order"]["bill-api-error"]);
                }
              });
            }, 5000);
          } else {
            setPaymentProgressSpeed(0);
            setShowPaymentOverlay(false);
            setAPIError(appLabels["order"]["bill-api-error"]);
          }
        } else {
          setPaymentProgressSpeed(0);
          setShowPaymentOverlay(false);
          setAPIError(appLabels["order"]["bill-api-error"]);
        }
      }, 30000);
    } else {
      setPaymentProgressSpeed(0);
      setShowPaymentOverlay(false);
      setAPIError(appLabels["order"]["bill-api-error"]);
    }
  };
  const [showLoadingSpinnerWhenCallingAPIs, setShowLoadingSpinnerWhenCallingAPIs] = useState(false);
  const [showDoubleConfirmation, setShowDoubleConfirmation] = useState(false);

  const onCompleteOrderClick = async () => {
    /** if it is dinein order type and user is on bill-payment screen, generate a bill for the items in the billContext and then use the unified bill payment */
    if (activeOrderType === "dinein" && window.location.href.includes("bill-payment")) {
      //generate bill, and call dc_vxl_pay_bill
      setShowPaymentOverlay(true);
      payDineinBill();
      return;
    }

    setShowLoadingSpinnerWhenCallingAPIs(true);
    //update the location with the latest data, in case the store hours have been changed
    const updatedLocation = await updateLocationData(
      activeOrderStore,
      activeOrderType,
      skin,
      appLanguage
    );
    if (updatedLocation) {
      setShowLoadingSpinnerWhenCallingAPIs(false);

      let isStoreClosedForTheDay = isStoreClosedToday(updatedLocation, orderTimeContext)
      const orderTimeIsPassed = isOrderTimePassed(
        updatedLocation,
        activeOrderType,
        orderTimeContext,
        setNextAvailableOrderTime
      );
      const orderTimeIsBeforeOpening = isOrderTimeBeforeOpeningTime(
        updatedLocation,
        orderTimeContext
      );
      const orderTimeIsBlocked = isOrderTimeBlocked(
        updatedLocation,
        orderTimeContext,
        activeOrderType
      );
      const lateOrderAttempt = isLateOrderAttempted(
        updatedLocation,
        activeOrderType,
        orderTimeContext,
        skin,
        setNextAvailableOrderTime
      );

      if (lateOrderAttempt) {
        isStoreClosedForTheDay = true
      }

      if (isStoreClosedForTheDay) {
        setIsStoreClosed(true)
        return;
      }
      else if (orderTimeIsPassed) {
        setIsPassedOrderTime(true);

        setAPISubmitting(false);
        setIsAPISubmitValid(false);
        setAPIError(<>&nbsp;</>); // reset the disabled state on submit FAB
      } else if (orderTimeIsBeforeOpening) {
        setIsOrderTimeBeforeOpening(true);

        setAPISubmitting(false);
        setIsAPISubmitValid(false);
        setAPIError(<>&nbsp;</>); // reset the disabled state on submit FAB
      } else if (orderTimeIsBlocked) {
        setOrderTimeIsBlocked(true);
        return;
      } else {
        if (activeOrderType === "pickup") {
          setShowDoubleConfirmation(true);
        } else {
          setShowPaymentOverlay(true);
          completeOrder(); // call 1302
        }
      }
    } else {
      // if updatedLocation returns null, it means the selected location does not exist anymore
      history.push("/dashboard");
    }
  };

  const onConfirmClick = () => {
    setShowDoubleConfirmation(false);
    setShowPaymentOverlay(true);
    completeOrder(); // call 1302
  };

  const [deliveryAddress, setDeliveryAddress] = useState(null);
  const [orderTypeInstruction, setOrderTypeInstructions] = useState("None");
  const [tipAmount, setTipAmount] = useState(0);
  useEffect(() => {
    localforage.getItem(skin + "__userDeliveryAddress").then((storedDeliveryAddress) => {
      if (storedDeliveryAddress) setDeliveryAddress(storedDeliveryAddress);
      else setDeliveryAddress(false);
    });

    localforage.getItem(skin + "__orderTypeSpecialInstruction").then((orderInstruction) => {
      if (orderInstruction) {
        localforage.getItem(skin + "__stadium-schema").then((schema) => {
          if (schema) {
            let parsedSchema = JSON.parse(schema);
            let orderInstructionCopy = orderInstruction;
            orderInstructionCopy += `, ${
              appLabels["form"]["section"]
            }:${parsedSchema.section.toUpperCase()}/${
              appLabels["form"]["row"]
            }:${parsedSchema.row.toUpperCase()}/${
              appLabels["form"]["seat"]
            }:${parsedSchema.seat.toUpperCase()}`;

            setOrderTypeInstructions(orderInstructionCopy);
          } else {
            setOrderTypeInstructions(orderInstruction);
          }
        });
      } else {
        //there is no order instruction set
        localforage.getItem(skin + "__stadium-schema").then((schema) => {
          if (schema) {
            let parsedSchema = JSON.parse(schema);
            let sectionRowSeatInfo = `${
              appLabels["form"]["section"]
            }:${parsedSchema.section.toUpperCase()}/${
              appLabels["form"]["row"]
            }:${parsedSchema.row.toUpperCase()}/${
              appLabels["form"]["seat"]
            }:${parsedSchema.seat.toUpperCase()}`;

            setOrderTypeInstructions(sectionRowSeatInfo);
          }
        });
      }
    });

    localforage.getItem(skin + "__storedTip").then((storedTip) => {
      if (storedTip) {
        setTipAmount(storedTip.tipAmount);
      }
    });
  }, []);

  const appLanguage = useContext(AppLanguageContext);
  const appLabels = useContext(AppLabelsContext);

  const [showPaymentOverlay, setShowPaymentOverlay] = useState(false);
  const [headerLabel, setHeaderLabel] = useState(null);
  const [secondaryHeaderLabel, setSecondaryHeaderLabel] = useState(null);

  const isStadiumSchema = merchantConfig.merchant.I55 === "stadium";

  const completeOrder = () => {
    setAPISubmitting(true);
    setHeaderLabel(appLabels["order"]["connecting"]);

    setTimeout(() => {
      setHeaderLabel(appLabels["order"]["hang-tight-processing-payment"]);
    }, 2000);

    setTimeout(() => {
      setHeaderLabel(appLabels["order"]["almost-done-passing-order-to-store"]);
    }, 4000);

    const timestamp = getCurrentTimestamp();

    const giftCardNumberList = [];

    if (coupon) giftCardNumberList.push(coupon.code);
    appliedGiftCards.forEach((appliedGiftCard) => {
      giftCardNumberList.push([
        appliedGiftCard.fullCardNumber,
        appliedGiftCard.pin,
        appliedGiftCard.balance,
      ]);
    });

    placeAPIOrder(
      skin,
      storeContext.activeOrderStore,
      orderTypeContext.value,
      customerInfo,
      rewards,
      promoCode,
      cartContext.value,
      giftCardNumberList,
      timestamp,
      deliveryAddress,
      orderTypeInstruction,
      storeContext.activeOrderStoreTable,
      tipAmount,
      appLanguage,
      appLabels["general"],
      isStadiumSchema,
      isLoggedIn,
      loginToken
    ).then((apiPlacedOrderResult) => {
      setAPISubmitting(false);

      if (apiPlacedOrderResult) {
        if (apiPlacedOrderResult.isPlaced) {
          localforage.setItem(skin + "__completedOrderId", apiPlacedOrderResult.orderId);
          localforage.setItem(skin + "__usedPaymentMethod", "GIFTCARD");
          setIsAPISubmitValid(true);
          if (apiPlacedOrderResult.pointsIssued) {
            localforage.setItem(skin + "__pointsIssued", apiPlacedOrderResult.pointsIssued);
          }
        } else {
          setShowPaymentOverlay(false);
          if (
            apiPlacedOrderResult.error &&
            apiPlacedOrderResult.error.includes(
              "Customer ID can only be used when a customer is logged in"
            )
          ) {
            userRoleContext.handleLoginTokenExpiration();
          } else {
            setAPIError(apiPlacedOrderResult.error);
            setIsAPISubmitValid(false);
          }
        }
      }
    });
  };

  return (
    <>
      <Form
        id="form--pay-with-giftcard"
        isFAB={true}
        submitButtonText={appLabels["order"]["submit-payment-button"]}
        submitButtonSuccessText={appLabels["order"]["submit-payment-button-processing"]}
        submitForm={onCompleteOrderClick}
        submitAPIError={apiError}
        resetAPIError={() => setAPIError("")}
        submitNavigateURL={
          window.location.href.includes("bill-payment")
            ? "/online-ordering/payment-confirmation"
            : "/online-ordering/order-confirmation"
        }
        isAPISubmitValid={isAPISubmitValid}
        isAPISubmitting={isAPISubmitting}
        isDisableSubmit={isDisabled}
        isAllowEmptySubmit={true}
        buttonWrapperClass={"form--credit-card__submit-button"}
        isBufferBeforeNavigationDisabled={true}
      ></Form>

      {showLoadingSpinnerWhenCallingAPIs && <LoadingSpinner />}

      {showDoubleConfirmation && (
        <DialogModal
          message={getOrderStoreAddressForDialogModal(
            activeOrderStore,
            appLabels,
            orderDate,
            orderTime,
            activeOrderType,
            orderTimeContext,
            skin
          )}
          isConfirmText={true}
          isModifyText={appLabels["order"]["modify-location"]}
          isCancelConfirm={true}
          confirmAction={onConfirmClick}
          resetRemoveDialog={() => {
            history.push("/online-ordering/order-store");
          }}
          closeModalAction={() => {
            setShowDoubleConfirmation(!showDoubleConfirmation);
            window.location.reload(); // This will have to change when the BE adds support for validating store hours.
          }}
        />
      )}

      {showPaymentOverlay && headerLabel && (
        <PaymentOverlay
          headerLabels={headerLabel}
          paymentProgressSpeed={paymentProgressSpeed}
          secondaryHeaderLabel={secondaryHeaderLabel}
        />
      )}
    </>
  );
};
