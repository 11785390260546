// Libraries
import React, { useContext, useState, useEffect } from "react";
import useWindowSize from "../../../_common/hooks/useWindowSize";

// Contexts
import AppLabelsContext from "../../../App/AppLabelsContext";
import AppSettingsContext from "../../../App/AppSettingsContext";

// UI Components
import { ButtonAddToFavorites } from "../../../_common/buttons/ButtonAddToFavorites/ButtonAddToFavorites";

//Helpers
import { jsonCopy } from "../../../_common/helpers";
import { consolidateComboChildItems } from "../../../_common/CartHelpers";
import { consolidateAndSumQuantities } from "../helpers/orderingHelpers";

// Assets
import { IconEdit } from "../../../_common/icons";

// CSS
import "./MenuComboSummary.css";

export const MenuComboSummary = (props) => {
  const {
    comboCartItem,
    isQuantityCombo,
    menuItem,
    favItem,
    orderMenu,
    setIsGuestFavoriteAttempt,
    updateCurrentComboSubgroupNumber,
    comboSubgroupNumber,
    isModifyDisplay,
    setFormattedComboCartItem,
    formattedComboCartItem,
  } = props;

  const appLabels = useContext(AppLabelsContext);
  const deviceWidth = useWindowSize().width;
  const appSettings = useContext(AppSettingsContext);
  const isAddonQuantityButtonsEnabled = appSettings["enable-addon-quantity-buttons"] === "yes";

  const [formattedComboChildItems, setFormattedComboChildItems] = useState(null);

  useEffect(() => {
    if (
      comboCartItem &&
      !!comboCartItem.combo_child_items &&
      !!comboCartItem.combo_child_items.length
    ) {
      let formattedList = {};
      let formattedComboCartItem = jsonCopy(comboCartItem);

      let loopLimit = isModifyDisplay
        ? comboCartItem.combo_child_items.length
        : comboSubgroupNumber;

      for (let i = 0; i < loopLimit; i++) {
        if (
          comboCartItem.combo_child_items.filter((item) => Number(item.group_id) === i + 1).length >
          0
        ) {
          formattedList[i + 1] = comboCartItem.combo_child_items.filter(
            (item) => Number(item.group_id) === i + 1
          );
        }
      }

      /** Clean up the addon quantity data and consolidate matching items */
      Object.keys(formattedList).forEach((key) => {
        const groupItems = formattedList[key];
        groupItems.forEach((childItem) => {
          const addonGroups = childItem.addonGroups;
          if (Object.keys(addonGroups).length > 0) {
            Object.keys(addonGroups).forEach((addonGroupKey) => {
              const addonGroup = addonGroups[addonGroupKey];
              const addons = addonGroup.items;
              Object.keys(addons).forEach((addonKey) => {
                const addon = addons[addonKey];
                if (addon.isSelected) {
                  addon.quantity = addon.addonQuantity || addon.quantity || 1;
                  delete addon.addonQuantity;
                }
              });
            });
          }
        });
      });

      formattedComboCartItem.combo_child_items.forEach((childItem) => {
        const addonGroups = childItem.addonGroups;
        if (Object.keys(addonGroups).length > 0) {
          Object.keys(addonGroups).forEach((addonGroupKey) => {
            const addonGroup = addonGroups[addonGroupKey];
            const addons = addonGroup.items;
            Object.keys(addons).forEach((addonKey) => {
              const addon = addons[addonKey];
              if (addon.isSelected) {
                addon.quantity = addon.addonQuantity || addon.quantity || 1;
                delete addon.addonQuantity;
              }
            });
          });
        }
      });

      const listOfKeysToIgnore = [
        "quantity",
        "entryKey",
        "isFirstInstanceDefault",
        "positioningTemplateIndex",
        "type",
        "category",
        "subcategory",
        "parentId",
      ];

      let formattedListCopy = jsonCopy(formattedList);
      Object.keys(formattedListCopy).forEach((key) => {
        formattedListCopy[key] = consolidateComboChildItems(formattedListCopy[key]);
        formattedListCopy[key] = consolidateAndSumQuantities(
          formattedListCopy[key],
          listOfKeysToIgnore
        );
      });

      formattedComboCartItem.combo_child_items = consolidateComboChildItems(
        formattedComboCartItem.combo_child_items
      );
      formattedComboCartItem.combo_child_items = consolidateAndSumQuantities(
        formattedComboCartItem.combo_child_items,
        listOfKeysToIgnore
      );

      setFormattedComboChildItems(formattedListCopy);
      setFormattedComboCartItem(formattedComboCartItem);
    }
  }, [comboCartItem]);

  return (
    <>
      <div className="menu-combo-name">
        <>
          {deviceWidth >= 660 ? (
            <>
              <p className="combo-name-and-disclaimer-text">
                <ButtonAddToFavorites
                  item={favItem}
                  category={orderMenu.activeTopCategory}
                  subcategory={orderMenu.activeSubcategory}
                  customClassName={""}
                  setIsGuestFavoriteAttempt={setIsGuestFavoriteAttempt}
                  showingComboSummary={true}
                  comboCartItem={comboCartItem}
                  customStyle={{ marginRight: "5px" }}
                  buttonId={"combo-favorite-button"}
                />
                <span className="combo-name-and-disclaimer-text-menu-item-name">
                  {menuItem.name}
                </span>
              </p>
              <p className="combo-name-and-disclaimer-text">
                <span className="menu-combo-summary__total-price-disclaimer">
                  {appLabels["order"]["total-combo-price-disclaimer"]}
                </span>
              </p>
            </>
          ) : (
            <p className="combo-name-and-disclaimer-text">
              <span>{menuItem.name}</span>
              <span className="menu-combo-summary__total-price-disclaimer">
                {appLabels["order"]["total-combo-price-disclaimer"]}
              </span>
            </p>
          )}
        </>
      </div>

      <ul className="menu-combo-summary-details">
        <>
          {formattedComboChildItems &&
            Object.keys(formattedComboChildItems).map((groupIdKey, groupIdIndex) => (
              <React.Fragment key={`${groupIdKey}-${groupIdIndex}`}>
                <li className="menu-combo-summary-details__list-item">
                  <div className="combo-summary__edit-button-container">
                    <p>
                      <span className="menu-combo-summary-details__list-item-group-id">
                        {appLabels["order"]["combo-choice"]} {groupIdKey}
                      </span>
                    </p>
                    <button
                      type="button"
                      className="menu-combo-summary-details__list-item-edit-button"
                      /*aria-label={`${appLabels["order"]["edit-combo-item-arial"].replace(
                    "[item-name]",
                    item.name
                  )}`}*/
                      aria-label={
                        appLabels["general"]["modify"] +
                        " " +
                        appLabels["order"]["combo-choice"] +
                        " " +
                        groupIdKey
                      }
                      onClick={() => {
                        updateCurrentComboSubgroupNumber(groupIdKey, null);
                      }}
                    >
                      <IconEdit
                        className="cartItem__icon cartItem__icon--edit"
                        aria-hidden="true"
                      />
                      <span className="cartItem__button-text">{appLabels["order"]["modify"]}</span>
                    </button>
                  </div>

                  {formattedComboCartItem.combo_child_items.map((item, itemIndex) => (
                    <React.Fragment key={`${item.id}-${itemIndex}`}>
                      {item.group_id === groupIdKey && (
                        <div className="combo-summary__item-detail-container">
                          <p>
                            <span className="menu-combo-summary-details__list-item-name">
                              {isQuantityCombo && <span>{`${item.quantity || 1}x `}</span>}

                              {`${item.name}`}
                            </span>

                            {item.calorie_count && item.calorie_count !== "" && (
                              <span className="combo-menu-subgroup-item-price-calorie">
                                {item.calorie_count !== "" &&
                                  `${item.calorie_count} ${appLabels["order"]["calories"]}`}
                              </span>
                            )}
                          </p>

                          {(!!Object.keys(item.modifierGroups).length ||
                            !!Object.keys(item.addonGroups).length) && (
                            <ul className="menu-combo-summary-details__list-item__addonModifiers">
                              {Object.keys(item.modifierGroups).map((modkey) => (
                                <React.Fragment key={modkey}>
                                  {modkey &&
                                    Object.keys(item.modifierGroups[modkey].items).map(
                                      (modItemKey, modItemIndex) => (
                                        <React.Fragment key={`${modItemKey}-${modItemIndex}`}>
                                          {modItemKey &&
                                            item.modifierGroups[modkey].items[modItemKey]
                                              .isSelected && (
                                              <li
                                                data-key={`${modItemKey}-${modItemIndex}`}
                                                className="combo-addon-summary"
                                              >
                                                <span>
                                                  {isAddonQuantityButtonsEnabled && (
                                                    <span>
                                                      {`${
                                                        item.modifierGroups[modkey].items[
                                                          modItemKey
                                                        ].quantity || 1
                                                      }x `}
                                                    </span>
                                                  )}
                                                  {`${item.modifierGroups[modkey].items[modItemKey].name}`}
                                                </span>
                                              </li>
                                            )}
                                        </React.Fragment>
                                      )
                                    )}
                                </React.Fragment>
                              ))}
                              {Object.keys(item.addonGroups).map((addonKey, addonKeyIndex) => (
                                <React.Fragment key={addonKey}>
                                  {addonKey &&
                                    Object.keys(item.addonGroups[addonKey].items).map(
                                      (addonItemKey, addonItemIndex) => (
                                        <React.Fragment key={addonItemKey}>
                                          {addonItemKey &&
                                            item.addonGroups[addonKey].items[addonItemKey]
                                              .isSelected && (
                                              <>
                                                <li
                                                  key={`${addonItemKey}-${addonItemIndex}-${addonKeyIndex}`}
                                                  data-key={`${addonItemKey}-${addonItemIndex}-${addonKeyIndex}`}
                                                  className="combo-addon-summary"
                                                >
                                                  <span>
                                                    {isAddonQuantityButtonsEnabled && (
                                                      <span>
                                                        {`${
                                                          item.addonGroups[addonKey].items[
                                                            addonItemKey
                                                          ].quantity || 1
                                                        }x `}
                                                      </span>
                                                    )}
                                                    {`${item.addonGroups[addonKey].items[addonItemKey].name} `}
                                                  </span>

                                                  {item.addonGroups[addonKey].items[addonItemKey]
                                                    .calorie_count !== "" && (
                                                    <span>
                                                      {item.addonGroups[addonKey].items[
                                                        addonItemKey
                                                      ].calorie_count !== "" &&
                                                        item.addonGroups[addonKey].items[
                                                          addonItemKey
                                                        ].calorie_count +
                                                          ` ${appLabels["order"]["calories"]}`}
                                                    </span>
                                                  )}
                                                </li>
                                                {Object.keys(
                                                  item.addonGroups[addonKey].items[addonItemKey]
                                                    .modifierGroups
                                                ).map((addonModKey) => (
                                                  <React.Fragment key={addonModKey}>
                                                    {addonModKey &&
                                                      item.addonGroups[addonKey].items[addonItemKey]
                                                        .modifierGroups[addonModKey] &&
                                                      Object.keys(
                                                        item.addonGroups[addonKey].items[
                                                          addonItemKey
                                                        ].modifierGroups[addonModKey].items
                                                      ).map(
                                                        (addonModItemKey, addonModItemKeyIndex) => (
                                                          <React.Fragment
                                                            key={
                                                              addonModItemKey +
                                                              `-` +
                                                              addonModItemKeyIndex
                                                            }
                                                          >
                                                            {addonModItemKey &&
                                                              item.addonGroups[addonKey].items[
                                                                addonItemKey
                                                              ].modifierGroups[addonModKey].items[
                                                                addonModItemKey
                                                              ].isSelected && (
                                                                <ul className="combo-nested-modifier-summary">
                                                                  <li
                                                                    key={`${addonModItemKey}-${addonModItemKeyIndex}`}
                                                                    data-key={`${addonModItemKey}-${addonModItemKeyIndex}`}
                                                                    className="combo-addon-summary"
                                                                  >
                                                                    <span>
                                                                      {
                                                                        item.addonGroups[addonKey]
                                                                          .items[addonItemKey]
                                                                          .modifierGroups[
                                                                          addonModKey
                                                                        ].items[addonModItemKey]
                                                                          .name
                                                                      }
                                                                    </span>
                                                                  </li>
                                                                </ul>
                                                              )}
                                                          </React.Fragment>
                                                        )
                                                      )}
                                                  </React.Fragment>
                                                ))}
                                              </>
                                            )}
                                        </React.Fragment>
                                      )
                                    )}
                                </React.Fragment>
                              ))}
                            </ul>
                          )}
                        </div>
                      )}
                    </React.Fragment>
                  ))}
                </li>
              </React.Fragment>
            ))}
        </>
      </ul>
    </>
  );
};
