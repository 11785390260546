// Libraries
import React, { useState, useEffect, useContext } from "react";
import { useHistory } from "react-router-dom";

// Custom hooks
import { useModal } from "../../_common/Modal/useModal";
import useWindowSize from "../../_common/hooks/useWindowSize";

// Helper functions
import { getRegistrationForm } from "./helpers/getRegistrationForm";

// Contexts
import MerchantConfigContext from "../../App/MerchantConfigContext";
import AppLanguageContext from "../../App/AppLanguageContext";
import AppLabelsContext from "../../App/AppLabelsContext";
import UserRoleContext from "../../App/UserRoleContext";

// UI components
import { RegisterForm } from "./RegisterForm/RegisterForm";
import { Modal } from "../../_common/Modal/Modal";

// CSS
import "./Register.css";
import LocationsContext from "../../OnlineOrdering/Locations/LocationsContext";

export const Register = () => {
  const history = useHistory();

  const merchantConfigContext = useContext(MerchantConfigContext);
  const locationsContext = useContext(LocationsContext);
  const skin = merchantConfigContext.skin;
  const merchantConfig = merchantConfigContext.merchant;
  const isLoyaltyEnabled = merchantConfig.I22 === "t";

  const [modalSettings, setModalSettings] = useState(null);
  const { isModal, toggleModal, closeAnimation } = useModal();

  const appLabels = useContext(AppLabelsContext);

  const userRoleContext = useContext(UserRoleContext);
  const loginToken = userRoleContext.loginToken;

  const deviceWidth = useWindowSize().width;

  //if the user is already logged in, redirect them to their profile page
  useEffect(() => {
    if (loginToken) {
      history.push(deviceWidth < 660 ? "/account/profile" : "/account/profile/personal-info");
    }
  }, [loginToken]);

  const openSuccessScreen = () => {
    setModalSettings({
      content: (
        <div className="register__success-screen">
          <div className="register__success-content">
            <h1 className="successful-register__heading">
              {appLabels["login-register"]["successful-registration-heading"]}
            </h1>
            <p className="register__success-content-message">
              {appLabels["login-register"]["successful-registration-paragraph"]}
            </p>
          </div>
          <div className="register__success-login-button-wrapper">
            <button
              onClick={() => {
                history.push("/login-register");
              }}
              className="button button--primary button--form-next"
              type="button"
            >
              {appLabels["login-register"]["sign-in"]}
            </button>
          </div>
        </div>
      ),
      type: "fullscreen",
      isTransparent: false,
      description: "Successful registration confirmation",
      wrapperClass: "modal-wrapper--success-register",
      isCloseButton: false,
    });
    toggleModal();
  };

  const [showSuccessScreen, setShowSuccessScreen] = useState(false);
  useEffect(() => {
    if (showSuccessScreen) openSuccessScreen();
  }, [showSuccessScreen]);

  const appLanguage = useContext(AppLanguageContext);

  const [registrationForm, setRegistrationForm] = useState(null);
  useEffect(() => {
    getRegistrationForm(skin, isLoyaltyEnabled, appLabels, appLanguage).then((response) => {
      if (response) {
        var tempForm = [];

        let passwordIndex = 0;
        response.forEach((field, index) => {
          if (field.isEnabled) tempForm.push(field);
          //find which index password field is
          if (field.formIndex === 18) passwordIndex = index;
        });

        const confirmPasswordField = {
          fieldName: "Confirm Password",
          fieldLabel: appLabels["form"]["confirm-password"],
          isEnabled: response[passwordIndex].isEnabled,
          isRequired: response[passwordIndex].isRequired,
          formIndex: 181,
        };
        tempForm.splice(passwordIndex + 1, 0, confirmPasswordField);

        let emailIndex = 0;
        tempForm.forEach((field, index) => {
          if (field.formIndex === 17) emailIndex = index;
        });

        const confirmEmailField = {
          fieldName: "Confirm Email",
          fieldLabel: appLabels["form"]["confirm-email"],
          isEnabled: true,
          isRequired: true,
          formIndex: 171,
        };
        tempForm.splice(emailIndex + 1, 0, confirmEmailField);

        setRegistrationForm(tempForm);
      }
    });
  }, []);

  return (
    <>
      <div className="user-is-greeted">
        {registrationForm && locationsContext && (
          <RegisterForm
            registrationFormFields={registrationForm}
            setShowSuccessScreen={setShowSuccessScreen}
          />
        )}
      </div>
      {setShowSuccessScreen && modalSettings != null && (
        <Modal
          isModal={isModal}
          isTransparent={modalSettings.isTransparent}
          toggleModal={toggleModal}
          content={modalSettings.content}
          type={modalSettings.type}
          description={modalSettings.description}
          closeAnimation={closeAnimation}
          isCloseButton={modalSettings.isCloseButton}
          wrapperClass={modalSettings.wrapperClass}
        />
      )}
    </>
  );
};
