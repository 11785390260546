export const formLabels = {
  "card-details": "Detalles de su tarjeta",
  "credit-card-number": "Número de tarjeta de crédito",
  "name-on-card": "Nombre en la tarjeta",
  "expiry-date": "Fecha de Expiración",
  cvv: "CVV",
  "save-credit-card": "Guardar tarjeta de crédito",
  "billing-address": "Dirección de Facturación",
  "address-1": "Dirección 1",
  "address-2": "Dirección 2",
  country: "País",
  province: "Municipio",
  city: "Ciudad",
  postal: "Código Postal",
  phone: "Teléfono",
  email: "Correo Electrónico",
  "confirm-email": "Confirme correo electrónico",
  password: "Contraseña",
  "confirm-password": "Confirme contraseña",
  "keep-me-logged-in": "Recuérdame",
  "first-name": "Nombre",
  "last-name": "Apellido",
  "special-instructions": "Instrucciones Especiales",
  "form-security-message": "Esta información se guardara y cifrara de forma segura",
  "card-number": "Número de tarjeta",
  "card-description": "Descripción de la Tarjeta",
  required: "Requerido",
  "missing-required-fields-error": "Complete los campos indicados arriba",
  "missing-required-addons-modifiers-error": "Selecciones las opciones indicadas arriba",
  "title-numbers-error": "El título no puede contener números",
  "first-name-numbers-error": "El nombre no puede contener números",
  "middle-name-numbers-error": "El segundo nombre no puede contener números",
  "last-name-numbers-error": "El apellido no puede contener números",
  "invalid-email-error": "Ingrese un correo electrónico valido",
  "phone-only-numbers-error": "El número telefónico solo puede contener números",
  "invalid-card-number": "Ingrese un número valido",
  "credit-card-numbers-error": "El nombre de tarjeta de crédito no puede contener números",
  "invalid-date-error": "Ingrese una fecha valida",
  "invalid-cvv-error": "Ingrese un CVV valido",
  "invalid-postal-error": "Ingrese un código postal valido",
  "invalid-birthday-error": "Ingrese una fecha de cumpleaños valida",
  "password-char-length-error": "Debe tener al menos 8 caracteres de longitud",
  "passwords-dont-match": "Las contraseñas no coinciden",
  "emails-dont-match": "Los correos electrónicos no coinciden",
  "city-numbers-error": "La ciudad no puede contener números",
  "country-numbers-error": "El país no puede contener números",
  "county-numbers-error": "El municipio no puede contener números",
  "select-one": "Seleccione uno",
  weekly: "Semanal",
  monthly: "Mensual",
  male: "Masculino",
  female: "Femenino",
  "other-gender": "Otro",
  "yes-to-promo-email": "Si",
  "no-to-promo-email": "No",
  english: "Inglés",
  french: "Francés",
  spanish: "Español",
  portuguese: "Portugués",
  "simplified-chinese": "Chino (Simplificado)",
  "traditional-chinese": "Chino (Tradicional)",
  "default-submit-button": "Enviar",
  "default-submit-button-success": "Enviado",
  "default-submit-button-processing": "Enviando ...",
  "optional-label": "Opcional",
  "enter-in-input-mask-pattern-format": "Ingrese en [enmascarar patrón] formato",
  "generic-fallback-api-error": "Algo salió mal con el envío, por favor intente de nuevo",
  "invalid-address-error": "Ingrese una dirección valida",
  "accept-terms-text":
    "He leído, comprendido y acepto estar sujeto a los [términos y condiciones] de uso.",
  "accept-terms-link-text": "términos y condiciones",
  "add-new-gift-card-invalid-card-error": "Numero de Tarjeta invalido, intente de nuevo",
  "special-delivery-instructions-field-label": "Instrucciones Especiales para Entrega",
  "zip-code": "Codigo Postal",
  "unit-number": "Numero unitario",
  "invalid-phone-number": "Telefono Invalido",
  "incorrect-username-password": "Usuario/Contraseña incorrecots",
  "save-credit-card-login-error":
    "Inicie sesión o cree una cuenta para guardar su tarjeta de crédito para pagar mas rápido la próxima vez.",
  "invalid-delivery-address-error": "Por favor ingrese una dirección válida y completa",
  "street-address": "Dirección",
  "apt-suit-unit": "Apto, local o casa",
  "primary-address": "Dirección primaria",
  section: "Sección",
  row: "Fila",
  seat: "Asiento",
  "do-not-include-country-code": "Por favor no utilice el codigo de pais",
};
