// Libraries
import React, { useState, useContext, useEffect } from "react";

// Helper functions
import { jsonCopy, toDollars } from "../../../_common/helpers";
import { getContrastColor } from "../../../_common/colorHelpers";
import { isOutOfStock } from "../helpers/orderingHelpers";

// Contexts
import AppSettingsContext from "../../../App/AppSettingsContext";
import AppLanguageContext from "../../../App/AppLanguageContext";
//import { MenuContext } from "../MenuContext";
import BrandingContext from "../../../App/BrandingContext";
import AppLabelsContext from "../../../App/AppLabelsContext";

/** UI Components */
import MenuItemQuickAddButton from "../MenuCategoryCard/QuickOrderButton/MenuItemQuickAddButton";

// Assets
import { ReactComponent as IconCheck } from "../../../_common/icons/IconCheck.svg";

// CSS
import "./MenuComboSliderItem.css";

export const MenuComboItem = (props) => {
  const {
    comboItem,
    comboCartItem,
    showSelectedItem,
    //setIsModal,
    childImage,
    //setSelectedComboChildItem,
    //setIsRelatedComboItem,
    updateActiveItem,
    isModifyDisplay,
    isComboChildItem,
    subGroupQuantityReached,
    isQuantityCombo,
    setValidateGoingToNextLevel,
    setIsEditingComboItem,
  } = props;

  //const foodMenu = useContext(MenuContext);

  const brandingContext = useContext(BrandingContext);
  const appSettings = useContext(AppSettingsContext);
  const appLanguage = useContext(AppLanguageContext);
  const appLabels = useContext(AppLabelsContext);
  const itemNameBackgroundColor = brandingContext["menu-card-item-name-background"];

  const displayName = comboItem.name;

  const outOfStockImage = brandingContext["item-out-of-stock-image"];
  const noImageAvailable = brandingContext["no-image-available-placeholder"];

  const [isImageLoaded, setIsImageLoaded] = useState(false);

  const textColor = itemNameBackgroundColor ? getContrastColor(itemNameBackgroundColor) : "#2a2a2a";

  const displayPrice = toDollars(
    appSettings["currency-symbol"],
    appSettings["currency-symbol-side"],
    comboItem.price,
    appLanguage
  );

  const modifiedComboItem =
    isQuantityCombo &&
    !!comboCartItem.combo_child_items.length &&
    comboCartItem.combo_child_items[comboItem.group_id - 1]
      ? comboCartItem.combo_child_items.find(
          (item) => item.id === comboItem.id && item.group_id === comboItem.group_id
        )
      : null;

  const itemIsSelected = (itemId, groupId) => {
    let selected = false;
    if (comboCartItem) {
      comboCartItem.combo_child_items.forEach((item) => {
        if (item.id === itemId && item.group_id === groupId) {
          selected = true;
        }
      });
    }

    return selected;
  };

  const itemIsAddedToCombo = itemIsSelected(comboItem.id, comboItem.group_id);

  /*const isQuickAddButtonEnabled =
    (!subGroupQuantityReached(comboItem["group_id"]) &&
      Object.keys(comboItem.modifierGroups).length === 0 &&
      Object.keys(comboItem.addonGroups).length === 0) ||
    !!modifiedComboItem;*/

  const hasNoAddonModifier =
    Object.keys(comboItem.modifierGroups).length === 0 &&
    Object.keys(comboItem.addonGroups).length === 0 &&
    (Number(comboItem.item_count > 0) || comboItem.item_count === "None");
  const [isQuickAddButtonEnabled, setIsQuickAddButtonEnabled] = useState(false);
  const [preSelectedItem, setPreSelectedItem] = useState(null);
  useEffect(() => {
    if (
      (comboItem || !!modifiedComboItem) &&
      (Number(comboItem.item_count > 0) || comboItem.item_count === "None")
    ) {
      let tempPreSelectedItem = jsonCopy(comboItem);
      delete tempPreSelectedItem.entryKey;
      delete tempPreSelectedItem.onlineQuantityLimit;
      //** there is no addon or modifier groups, show the quick add button
      if (
        Object.keys(tempPreSelectedItem.modifierGroups).length === 0 &&
        Object.keys(tempPreSelectedItem.addonGroups).length === 0
      ) {
        setIsQuickAddButtonEnabled(true);
      } else if (
        Object.keys(tempPreSelectedItem.modifierGroups).length > 0 ||
        Object.keys(tempPreSelectedItem.addonGroups).length > 0
      ) {
        //** if there is addon or modifier group check and see if there is default selection
        const addonGroups = tempPreSelectedItem.addonGroups;
        const modifierGroups = tempPreSelectedItem.modifierGroups;

        let hasDefaultSelectionForAddons = false;
        let hasDefaultSelectionForNestedModifiers = false;
        let hasDefaultSelectionForModifiers = false;

        let numberOfDefaultRequired = 0;
        // Loop the addon groups
        Object.keys(addonGroups).forEach((addonGroupKey) => {
          const addonGroup = addonGroups[addonGroupKey];
          const isSingleAddon = !addonGroup.id;
          const addonGroupMinQuantity = Number(addonGroup.minQuantity);
          const addons = addonGroup.items;

          if (!isSingleAddon) {
            if (addonGroupMinQuantity > 0) {
              numberOfDefaultRequired += addonGroupMinQuantity;
            }

            Object.keys(addons).forEach((addonKey) => {
              const addon = addons[addonKey];
              if (addon.isDefault === "True" && !isOutOfStock(addon)) {
                tempPreSelectedItem.addonGroups[addonGroupKey].items[addonKey].isSelected = true;
                tempPreSelectedItem.addonGroups[addonGroupKey].items[addonKey].quantity = 1;
                if (addonGroupMinQuantity > 0) {
                  numberOfDefaultRequired--;
                }
                //check if addon has a nested modifier group
                if (Object.keys(addon.modifierGroups).length > 0) {
                  const nestedModifierGroups = addon.modifierGroups;
                  Object.keys(nestedModifierGroups).forEach((nestedModifierGroupKey) => {
                    const nestedModifierGroup = nestedModifierGroups[nestedModifierGroupKey];
                    const nestedModifierGroupMinQuantity = Number(nestedModifierGroup.minQuantity);

                    if (nestedModifierGroupMinQuantity > 0) {
                      numberOfDefaultRequired += nestedModifierGroupMinQuantity;
                    }
                    const nestedModifiers = nestedModifierGroup.items;
                    Object.keys(nestedModifiers).forEach((nestedModifierKey) => {
                      const nestedModifier = nestedModifiers[nestedModifierKey];
                      if (nestedModifier.isDefault === "True") {
                        hasDefaultSelectionForNestedModifiers = true;
                        if (nestedModifierGroupMinQuantity > 0) {
                          numberOfDefaultRequired--;
                        }
                        tempPreSelectedItem.addonGroups[addonGroupKey].items[
                          addonKey
                        ].modifierGroups[nestedModifierGroupKey].items[
                          nestedModifierKey
                        ].isSelected = true;
                      } else {
                        delete tempPreSelectedItem.addonGroups[addonGroupKey].items[addonKey]
                          .modifierGroups[nestedModifierGroupKey].items[nestedModifierKey]
                          .isSelected;
                      }
                    });
                  });
                }
              } else {
                delete tempPreSelectedItem.addonGroups[addonGroupKey].items[addonKey].isSelected;
                delete tempPreSelectedItem.addonGroups[addonGroupKey].items[addonKey].quantity;
              }
            });
          } else if (isSingleAddon) {
            addonGroup.isStandalone = true;
            Object.keys(addons).forEach((addonKey) => {
              const addon = addons[addonKey];
              addonGroup.name = addon.name;
              addonGroup.id = addon.id;
              addon.isStandalone = true;
              if (addon.isDefault === "True" && !isOutOfStock(addon)) {
                tempPreSelectedItem.addonGroups[addonGroupKey].items[addonKey].isSelected = true;
                tempPreSelectedItem.addonGroups[addonGroupKey].items[addonKey].quantity = 1;
              }
            });
          }
        });

        // numberOfDefaultRequired will reach a negative value if there are multiple default selectons in a group which has a min quantity of 1
        if (numberOfDefaultRequired < 0) {
          numberOfDefaultRequired = 0;
        }

        // Loop the modifier groups
        Object.keys(modifierGroups).forEach((modifierGroupKey) => {
          const modifierGroup = modifierGroups[modifierGroupKey];
          const modifiers = modifierGroup.items;
          const modifierGroupMinQuantity = Number(modifierGroup.minQuantity);

          if (modifierGroupMinQuantity > 0) {
            numberOfDefaultRequired += modifierGroupMinQuantity;
          }
          Object.keys(modifiers).forEach((modifierKey) => {
            const modifier = modifiers[modifierKey];
            if (modifier.isDefault === "True") {
              if (modifierGroupMinQuantity > 0) {
                numberOfDefaultRequired--;
              }
              tempPreSelectedItem.modifierGroups[modifierGroupKey].items[
                modifierKey
              ].isSelected = true;
            } else {
              delete tempPreSelectedItem.modifierGroups[modifierGroupKey].items[modifierKey]
                .isSelected;
            }
          });
        });

        if (numberOfDefaultRequired === 0) {
          hasDefaultSelectionForAddons = true;
          hasDefaultSelectionForNestedModifiers = true;
          hasDefaultSelectionForModifiers = true;
        } else {
          hasDefaultSelectionForAddons = false;
          hasDefaultSelectionForNestedModifiers = false;
          hasDefaultSelectionForModifiers = false;
        }

        if (
          hasDefaultSelectionForModifiers &&
          hasDefaultSelectionForAddons &&
          hasDefaultSelectionForNestedModifiers
        ) {
          setIsQuickAddButtonEnabled(true);
          setPreSelectedItem(tempPreSelectedItem);
        } else if (
          comboCartItem.combo_child_items.filter((item) => item.id === comboItem.id).length > 0
        ) {
          setIsQuickAddButtonEnabled(true);
        } else {
          setIsQuickAddButtonEnabled(false);
        }
      }
    }
  }, [comboCartItem]);

  return (
    <>
      <li
        className={`menu__category-card ${itemIsAddedToCombo ? "chosen-combo" : ""} ${
          isQuickAddButtonEnabled ? " withQuickAddButton" : ""
        }`}
        style={{
          backgroundColor: itemNameBackgroundColor || "#fff",
        }}
      >
        <div
          className={`menu-category-card__content-wrapper  ${
            isImageLoaded ? "menu-slider__item-image--loaded" : "skeleton-loader"
          }`}
        >
          <div className="menu-category-card__image-wrapper">
            <img
              onLoad={() => setIsImageLoaded(true)}
              src={comboItem.imageUrl || childImage || noImageAvailable}
              alt={comboItem.name}
              className="menu-slider__item-image .menu-slider__image-underlay"
              loading="lazy"
            />
            {Number(comboItem.item_count) <= 0 && (
              <img
                src={outOfStockImage}
                alt={displayName}
                className="menu-category-card__image-overlay"
              />
            )}
          </div>
          <div
            className="menu-category-card___text-wrapper"
            style={{
              backgroundColor: itemNameBackgroundColor || "#fff",
              borderBottomLeftRadius: isQuickAddButtonEnabled ? "0px" : "",
              borderBottomRightRadius: isQuickAddButtonEnabled ? "0px" : "",
            }}
          >
            <h2
              className="menu-category-card__name"
              style={{
                color: textColor,
              }}
            >
              {displayName}
            </h2>
            {comboItem.price > 0 && (
              <div
                className="menu-category-card__price"
                style={{
                  color: textColor,
                }}
              >
                {displayPrice}
              </div>
            )}

            {comboItem.calorie_count && (
              <div
                className="menu-category-card__calories"
                style={{
                  color: textColor,
                }}
              >{`${comboItem.calorie_count} ${appLabels["order"]["calories"]}`}</div>
            )}
          </div>
          <button
            onClick={() => {
              updateActiveItem(
                comboItem.id,
                comboItem.parentId,
                comboItem.type,
                isComboChildItem ? true : false,
                comboItem,
                isQuantityCombo ? false : isModifyDisplay, //if quantity combo, clicking on the item means attempt to add a new item
                isQuantityCombo ? false : itemIsAddedToCombo ? true : false
              );
            }}
            className="menu-category-card__button"
            aria-label={appLabels["order"]["go-to-item-name"].replace(
              "[item-name]",
              comboItem.name
            )}
            type="button"
            style={modifiedComboItem ? { height: "80%" } : {}}
            disabled={subGroupQuantityReached(comboItem["group_id"])}
          />

          {isQuickAddButtonEnabled && (
            <MenuItemQuickAddButton
              itemData={modifiedComboItem || comboItem}
              requiresCustomization={
                Object.keys(comboItem.modifierGroups).length > 0 ||
                Object.keys(comboItem.addonGroups).length > 0
              }
              comboCartItem={comboCartItem}
              updateActiveItem={updateActiveItem}
              subGroupQuantityReached={subGroupQuantityReached}
              isModifyDisplay={isModifyDisplay}
              setValidateGoingToNextLevel={setValidateGoingToNextLevel}
              setIsEditingComboItem={setIsEditingComboItem}
              isQuantityCombo={isQuantityCombo}
              preSelectedItem={preSelectedItem}
              hasNoAddonModifier={hasNoAddonModifier}
            />
          )}
        </div>

        {itemIsAddedToCombo && (
          <div className="desktop-order-menu__combo-item-check-mark">
            <IconCheck />
            {showSelectedItem()}
          </div>
        )}

        {subGroupQuantityReached(comboItem["group_id"]) &&
          !itemIsAddedToCombo &&
          !modifiedComboItem && (
            <div className="form__field-wrapper--checkbox-radio__gray-overlay"></div>
          )}
      </li>
    </>
  );
};
