// Libraries
import React, { useContext, useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import localforage from "localforage";

// Hooks
import useWindowSize from "../../_common/hooks/useWindowSize";

// API helper functions
import { callAPI } from "../../_common/Api";

//Helpers
import { onLoginXtreme } from "../../_common/helpers/xtremePushHelper";

// Contexts
import UserRoleContext from "../../App/UserRoleContext";
import MerchantConfigContext from "../../App/MerchantConfigContext";
import AppLanguageContext from "../../App/AppLanguageContext";
import AppLabelsContext from "../../App/AppLabelsContext";

// UI components
import { Form } from "../../_common/Form/Form";
import { FormInput } from "../../_common/Form/FormInput";
import { FormCheckbox } from "../../_common/Form/FormCheckbox";

// CSS`
import "./LoginForm.css";

const AES = require("crypto-js/aes");
const CRYPTO_JS = require("crypto-js");

export const LoginForm = ({ customFormWrapperClass, isResetPasswordLogin }) => {
  const history = useHistory();
  const deviceWidth = useWindowSize().width;
  const merchantConfigContext = useContext(MerchantConfigContext);
  const skin = merchantConfigContext.skin;
  const userRoleContext = useContext(UserRoleContext);

  const [isAPISubmitValid, setIsAPISubmitValid] = useState(null);
  const [apiError, setAPIError] = useState("");
  const [submitNavigateURL, setSubmitNavigateURL] = useState("");
  const [isAPISubmitting, setAPISubmitting] = useState(false);

  const storedIsRememberMeFlag = localStorage.getItem(skin + "__isRememberMeOn");
  const [isAutocomplete, setIsAutocomplete] = useState(storedIsRememberMeFlag);

  const [storedEmailPass, setStoredEmailPass] = useState("");
  const [decryptedPassword, setDecryptedPassword] = useState(null);

  useEffect(() => {
    if (isAutocomplete && localStorage.getItem(skin + "__login")) {
      setStoredEmailPass(JSON.parse(localStorage.getItem(skin + "__login")));
    } else {
      setStoredEmailPass(true);
      setDecryptedPassword(true);
    }
  }, [isAutocomplete, skin]);

  useEffect(() => {
    if (storedEmailPass && !!storedEmailPass.password && !!storedEmailPass.email) {
      var bytes = CRYPTO_JS.AES.decrypt(storedEmailPass.password, "blurb");
      var originalPassword = bytes.toString(CRYPTO_JS.enc.Utf8);
      setDecryptedPassword(originalPassword);
    }
  }, [storedEmailPass]);

  const appLanguage = useContext(AppLanguageContext);

  const login = (data) => {
    let email;
    let password;
    let keepLogin;

    data.forEach((field) => {
      if (field.name === "email") email = field.value;
      else if (field.name === "password") password = field.value;
      else if (field.name === "keep-logged-in") keepLogin = field.value;
    });

    setAPISubmitting(true);

    callAPI(skin, "dc_958", {
      params: [appLanguage, "958", "mqid", "mqpass", email, password, "t"],
      id: "958",
    }).then((data958) => {
      if (data958.result.I2) {
        /** Store the last login time */
        localforage.setItem(skin + "__lastLoginTimestamp", data958.result.I5);
        localforage.removeItem(skin + "__lastLoginTimeStampDisplayed");

        setAPISubmitting(false);

        if (data958.result.I2 === "0") {
          const isTemporaryPasswordDetected = data958.result.I3 === "t";
          const loginToken = data958.result.I4;

          callAPI(skin, "dc_950", {
            params: [appLanguage, "950", "mqid", "mqpass", loginToken, ""],
            id: "950",
          }).then(async (data950) => {
            if (isTemporaryPasswordDetected && data950.result.I0) {
              // Temporary password change requested by API -> navigate to Change Password form

              sessionStorage.setItem(skin + "__isSessionLogin", true);

              if (keepLogin) {
                setIsAutocomplete(true);
                localStorage.setItem(skin + "__isRememberMeOn", true);
              } else {
                setIsAutocomplete(false);
                localStorage.removeItem(skin + "__isRememberMeOn");
                localStorage.removeItem(skin + "__login");
              }

              const customerInfo = await userRoleContext.updateAccountInfoInStorage(data950);

              localforage.setItem(skin + "__userFirstName", customerInfo.firstName);

              userRoleContext.updateStatus("logged-in");
              userRoleContext.updateLoginToken(loginToken);

              history.push({
                pathname: "/change-password",
                state: { currentEmail: email, currentPassword: password },
              });
            } else {
              // User successfully logged in

              sessionStorage.setItem(skin + "__isSessionLogin", true);

              const customerInfo = await userRoleContext.updateAccountInfoInStorage(data950);
              localforage.setItem(skin + "__userFirstName", customerInfo.firstName);

              if (keepLogin) {
                setIsAutocomplete(true);
                localStorage.setItem(skin + "__isRememberMeOn", true);
                localStorage.setItem(
                  skin + "__login",
                  JSON.stringify({ email, password: AES.encrypt(password, "blurb").toString() })
                );
              } else {
                setIsAutocomplete(false);
                localStorage.removeItem(skin + "__isRememberMeOn");
                localStorage.removeItem(skin + "__login");
              }

              sessionStorage.setItem(
                skin + "__login",
                JSON.stringify({ email, password: AES.encrypt(password, "blurb").toString() })
              );

              userRoleContext.updateStatus("logged-in");
              userRoleContext.updateLoginToken(loginToken);
              onLoginXtreme(customerInfo, deviceWidth);

              const pathName = window.location.pathname;
              if (sessionStorage.getItem(pathName + "scannedQRCodeURLHash")) {
                const scannedQRCodeURLHash = sessionStorage.getItem(
                  pathName + "scannedQRCodeURLHash"
                );
                setSubmitNavigateURL(scannedQRCodeURLHash);
              } else if (
                sessionStorage.getItem(skin + "__lastVisitedLink") &&
                !sessionStorage.getItem(skin + "__lastVisitedLink").includes("register") &&
                !sessionStorage
                  .getItem(skin + "__lastVisitedLink")
                  .includes("account-deletion-confirmation")
              ) {
                setSubmitNavigateURL(sessionStorage.getItem(skin + "__lastVisitedLink"));
                sessionStorage.removeItem(skin + "__lastVisitedLink");
              } else if (
                sessionStorage.getItem(skin + "__redirectToCheckout") &&
                sessionStorage.getItem(skin + "__redirectToCheckout") === "true"
              ) {
                setSubmitNavigateURL("/online-ordering/checkout");
              } else {
                setSubmitNavigateURL("/dashboard");
              }

              setIsAPISubmitValid(true);
            }
          });
        } else if (data958.result.I2 === "1") {
          // Invalid email and/or password
          // wxget: login's incorrect username/password error
          setAPIError(appLabels["form"]["incorrect-username-password"]);
          setIsAPISubmitValid(false);
        } else {
          // wxget: generic fallback API error
          setAPIError(appLabels["form"]["generic-fallback-api-error"]);
          setIsAPISubmitValid(false);
        }
      }
    });
  };

  const resetAPIError = () => {
    setAPIError("");
  };

  const appLabels = useContext(AppLabelsContext);

  return (
    <>
      {!!storedEmailPass && decryptedPassword !== null && (
        <div
          className={`form-wrapper login-register__form-wrapper login__form-wrapper${
            customFormWrapperClass || ""
          }`}
        >
          <Form
            id="form--login"
            submitButtonText={appLabels["login-register"]["sign-in"]}
            submitButtonSuccessText={appLabels["login-register"]["signing-in"]}
            submitForm={login}
            submitAPIError={apiError}
            resetAPIError={resetAPIError}
            submitNavigateURL={submitNavigateURL}
            submitNavigateURLStateFrom="login-register"
            isAPISubmitValid={isAPISubmitValid}
            isAPISubmitting={isAPISubmitting}
            submitButtonWrapperClass={"form--login__submit-button-wrapper"}
          >
            <FormInput
              type="text"
              label={appLabels["form"]["email"]}
              id="input--email"
              name="email"
              isRequired={true}
              autocomplete={isAutocomplete ? "on" : "off"}
              defaultValue={isAutocomplete ? storedEmailPass.email : null}
            />
            <FormInput
              type="password"
              label={appLabels["form"]["password"]}
              id="input--password"
              name="password"
              isRequired={true}
              autocomplete={isResetPasswordLogin || !isAutocomplete ? "off" : "on"}
              defaultValue={isAutocomplete ? decryptedPassword : null}
            />
            <FormCheckbox
              label={appLabels["form"]["keep-me-logged-in"]}
              id="keep-logged-in"
              name="keep-logged-in"
              value="keep-logged-in"
              isDefaultChecked={isAutocomplete}
            />
          </Form>
        </div>
      )}
    </>
  );
};
